import { HISTORY } from 'constants/api'
import useAxios from 'axios-hooks'
import { useSession } from 'contexts/session-context'
import { useEffect, useState } from 'react'
import LoadingScreen from 'components/loading-screen'
import {
  CrownState,
  CrownStateText,
  DivStateAccount,
  LiStateAccount,
  PStateAccount,
  TextStateAccount,
  TextStateAccountDate
} from 'theme/profile'
import { TextNotFoundHistory } from 'views/profile/text.style'
import textServices from 'services/text-services'
import useMainClass from 'hooks/use-main-cass'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import PopUpCoupon from './components/PopUp'

function History() {
  useMainClass('profile__history')

  dayjs.extend(calendar)

  const [{ data: session }] = useSession()
  const [{ data, loading, error }, refetch] = useAxios({
    url: HISTORY,
    headers: { Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}` }
  })

  useEffect(() => {
    refetch()
  }, [])

  const [showPopUpCoupon, setShowPopUpCoupon] = useState<any>(null)
  const [productExchange, setProductExchange] = useState<any>(null)

  const closePopup = () => {
    if (showPopUpCoupon) {
      setProductExchange(null)
    }
    setShowPopUpCoupon(!showPopUpCoupon)
    refetch()
  }

  const productExchangeCoupon = (item: any) => {
    setProductExchange(item)
    setShowPopUpCoupon(!showPopUpCoupon)
  }

  const timeResult = (date: any) => {
    const currentDate = dayjs(new Date())

    const compareDate = new Date(date as string)

    const diff = dayjs(compareDate)

    const result = currentDate <= diff

    if (result) {
      return true
    }

    return false
  }

  if (error) {
    return (
      <div className="flex-1 m-7 flex justify-center items-center">
        <h1 className="text-2xl font-bold">Ha ocurrido un error</h1>
      </div>
    )
  }

  if (productExchange) {
    return <PopUpCoupon closePopup={closePopup} setProductExchange={setProductExchange} item={productExchange} timeResult={timeResult} />
  }

  return loading ? (
    <LoadingScreen />
  ) : (
    <div>
      {data.length ? (
        <ul>
          {data.map((item: any) => {
            return (
              <LiStateAccount key={item.exchangeAt} className="relative border-b-2 state-container">
                <DivStateAccount className="h-16 flex items-center px-10 state-bg"></DivStateAccount>
                <div className="h-28 state-bg-two"></div>
                <div className="absolute top-0 grid state-grid" style={{ gridTemplateColumns: '176px 1fr' }}>
                  <div className="flex justify-center items-center h-full state-image-container" style={{ height: 176 }}>
                    {!item?.imageUrl && (
                      <CrownState
                        className="w-28 h-28 flex justify-center items-center state-crown"
                        style={{ backgroundSize: 'contain' }}
                      >
                        <CrownStateText className="text-4xl font-bold">{item.pointsEarned}</CrownStateText>
                      </CrownState>
                    )}
                    {item?.imageUrl && (
                      <img
                        src={item?.imageUrl}
                        alt={`Foto del premio ${item.title}`}
                        style={{ height: 'auto', width: 'auto', maxHeight: 124, maxWidth: 124 }}
                        className='state-img'
                      />
                    )}
                  </div>
                  <div className="grid state-text-container" style={{ gridTemplateRows: '64px 112px' }}>
                    <div className="flex items-center">
                      <PStateAccount className={`font-bold state-title ${item.title.length > 30 ? 'text-xl' : 'text-2xl'}`}>
                        {item.title}
                      </PStateAccount>
                    </div>
                    <div className="py-2 text-left">
                      {item.costInPoints && item.costInPoints !== '0' && (
                        <TextStateAccount className="din font-bold text-sm state-text state-cost-points">
                          {item?.costInPoints > 0 && '-'}{item.costInPoints} {POINTS_NAME}
                        </TextStateAccount>
                      )}
                      {item.dynamicCostInPoints && item.dynamicCostInPoints !== '0' && (
                        <TextStateAccount className="din font-bold text-sm state-text state-dynamic-points">
                          {item.dynamicCostInPoints} {POINTS_NAME} USADAS
                        </TextStateAccount>
                      )}
                      {item.pointsEarned && item.pointsEarned !== '0' && (
                        <TextStateAccount className="din font-bold text-sm state-text state-earned-points">
                          {item.pointsEarned} {POINTS_NAME} {textServices.findByText('profile/view/history/acquireds', 'ADQUIRIDAS')}
                        </TextStateAccount>
                      )}
                      {item.dynamicTitle && item.dynamicTitle !== '0' && (
                        <TextStateAccount className="text-sm state-text state-dynamic-title">
                          {item?.dynamicType}: {item.dynamicTitle}
                        </TextStateAccount>
                      )}
                      <TextStateAccountDate className="text-sm state-text state-text-date">
                        #{item?.id} - {textServices.findByText('profile/view/history/acquired', 'Adquirido el')}{' '}
                        {new Date(item.exchangeAt).toLocaleString('es-MX', {
                          day: '2-digit',
                          month: '2-digit',
                          year: '2-digit'
                        })}
                      </TextStateAccountDate>
                      {(item?.orderStatus === 6 || item?.temporalCouponExpiresAt) && (
                        <button
                          onClick={() => {
                            if (item?.orderStatus === 6 || timeResult(item?.temporalCouponExpiresAt)) {
                              productExchangeCoupon(item)
                            }
                          }}
                          className={`profile__history__button__base ${item?.orderStatus === 6 ? 'profile__history__button__active' : ''} ${timeResult(item?.temporalCouponExpiresAt) ? 'profile__history__button__progress' : ''} ${item?.orderStatus === 7 && !timeResult(item?.temporalCouponExpiresAt) ? 'profile__history__button__finish' : ''}`}
                          disabled={item?.orderStatus === 7 && !timeResult(item?.temporalCouponExpiresAt)}
                        >
                          {item?.orderStatus === 6 && textServices.findByText('profile/coupon/activate', 'ACTIVAR')}
                          {timeResult(item?.temporalCouponExpiresAt) && textServices.findByText('profile/coupon/active', 'Ver cupón activo')}
                          {item?.orderStatus === 7 && !timeResult(item?.temporalCouponExpiresAt) && textServices.findByText('profile/coupon/used', 'cupón usado')}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </LiStateAccount>
            )
          })}
        </ul>
      ) : (
        <TextNotFoundHistory className="text-2xl font-bold text-center mt-20">
          {textServices.findByText(
            'profile/stateNotFound',
            <>
              No has canjeado ningún premio aún<br></br>
              <br></br>
              ¡Venga, tú puedes!
            </>
          )}
        </TextNotFoundHistory>
      )}
    </div>
  )
}

export default History
