import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import { Link } from 'react-router-dom'
import textServices from 'services/text-services'
import { SignUpDiv } from 'theme/theme'

function SignUp() {
  return (
    <Link
      to="/signup"
      className="w-full sm:w-72"
      onClick={() => {
        dlTrackEvent('user_interaction', {
          action: 'click',
          element: textServices.findByText('start/signup', 'REGISTRARME'),
          section: 'sign up'
        })

        gtag('event', 'Click', {
          event_category: GTM_EVENT_CATEGORY,
          event_label: 'Registro - Email',
          value: 'Email'
        })

      }}
    >
      <SignUpDiv className="sign-up">{textServices.findByText('start/signup', 'REGISTRARME')}</SignUpDiv>
    </Link>
  )
}

export default SignUp
