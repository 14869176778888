import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import { MouseEventHandler, useState, VoidFunctionComponent } from 'react'
import { TextRecovery } from 'views/login/text.style'

export interface PasswordRecoveryProps {
  shouldEnable: boolean
  recoverPassword: MouseEventHandler<HTMLButtonElement>
  mailSent: boolean
}

const PasswordRecovery: VoidFunctionComponent<PasswordRecoveryProps> = ({
  shouldEnable,
  recoverPassword,
  mailSent
}) => {
  if (!shouldEnable) {
    return null
  }

  const [isDisabled, setIsDisabled] = useState(0)

  const handleOnClick = (event: any) => {
    event.preventDefault()
    dlTrackEvent('user_interaction', {
      action: 'click',
      element: 'olvide mi contraseña',
      section: 'login'
    })
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'OLVIDE MI CONTRASEÑA', value: '10' })
    if (isDisabled >= 2) return null
    setIsDisabled((state) => state + 1)
    recoverPassword(event)
  }

  return (
    <button type='button' className="cursor-pointer" onClick={handleOnClick}>
      <TextRecovery
        className={`din text-center mt-3 hover:text-red-100 uppercase underline login__recovery`}
      >
        {isDisabled >= 2 ? 'Ya reenviamos el mail' : mailSent ? 'Da click aquí para reenviarlo' : 'OLVIDÉ MI CONTRASEÑA'}
      </TextRecovery>
    </button>
  )
}

export default PasswordRecovery
