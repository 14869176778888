import { Type } from 'theme/types';
import styled from "styled-components";
import { SubTitle, Text, Title } from "theme/theme";

interface Active extends Type {
  active: boolean
}

export const ContainerErrorUpload = styled.div`
  ${({ theme }: Type) => theme?.photographer?.uploadImage?.errorUpload?.container}
`

// -- OnBoarding --

export const ContainerOnBoarding = styled.section`
  ${({ theme }: Type) => theme?.social?.onBoarding?.container}
`
export const ContainerOnBoardingRules = styled(ContainerOnBoarding)`
  ${({ theme }: Type) => theme?.social?.onBoarding?.containerRules}
`

export const TitleOnBoarding = styled(Title)`
  ${({ theme }: Type) => theme?.social?.onBoarding?.title}
`

export const RulesOnBoarding = styled(Title)`
  ${({ theme }: Type) => theme?.social?.onBoarding?.rules}
`

export const ArticleOnBoarding = styled.article`
  ${({ theme }: Type) => theme?.social?.onBoarding?.article}
`

export const DivButtonOnBoarding = styled.a`
  ${({ theme }: Type) => theme?.social?.onBoarding?.divButton}
`

// -- Start --

export const ContainerStart = styled.section`
  ${({ theme }: Type) => theme?.social?.start?.container}
`

export const TitleStart = styled(Title)`
  ${({ theme }: Type) => theme?.social?.start?.title}
`

export const DescriptionStart = styled(SubTitle)`
  ${({ theme }: Type) => theme?.social?.start?.description}
`

export const ArticleStart = styled.article`
  ${({ theme }: Type) => theme?.social?.start?.article}
`

// -- Card --

export const ContainerCard = styled.div`
  ${({ theme }: Type) => theme?.social?.card?.container}
`

export const ContainerVariation = styled(ContainerCard)`
  ${({ theme }: Type) => theme?.social?.card?.containerVariation}
`

export const ContainerRanking = styled(ContainerCard)`
  ${({ theme }: Type) => theme?.social?.card?.containerRanking}
`

export const ImageCard = styled.img`
  ${({ theme }: Type) => theme?.social?.card?.img}
`

export const ImageVariation = styled(ImageCard)`
  ${({ theme }: Type) => theme?.social?.card?.imgVariation}
`

export const ImageRanking = styled(ImageCard)`
  ${({ theme }: Type) => theme?.social?.card?.imgRanking}
`

export const ContainerTextCard = styled.div`
  ${({ theme }: Type) => theme?.social?.card?.containerText}
`

export const ContainerTextVariation = styled(ContainerTextCard)`
  ${({ theme }: Type) => theme?.social?.card?.containerTextVariation}
`

export const ContainerTextRanking = styled(ContainerTextCard)`
  ${({ theme }: Type) => theme?.social?.card?.containerTextRanking}
`

export const LikeCard = styled.div`
  ${({ theme }: Type) => theme?.social?.card?.like}
`

export const LikeVariation = styled(LikeCard)`
  ${({ theme }: Type) => theme?.social?.card?.likeVariation}
`

export const LikeRanking = styled(LikeCard)`
  ${({ theme }: Type) => theme?.social?.card?.likeRanking}
`

export const PositionRanking = styled(LikeCard)`
  ${({ theme }: Type) => theme?.social?.card?.positionRanking}
`

export const TitleCard = styled.h3`
  ${({ theme }: Type) => theme?.social?.card?.title}
`

export const TitleVariation = styled(TitleCard)`
  ${({ theme }: Type) => theme?.social?.card?.titleVariation}
`

export const TitleRanking = styled(TitleCard)`
  ${({ theme }: Type) => theme?.social?.card?.titleRanking}
`

export const DescriptionCard = styled.p`
  ${({ theme }: Type) => theme?.social?.card?.description}
`

export const DescriptionVariation = styled(DescriptionCard)`
  ${({ theme }: Type) => theme?.social?.card?.descriptionVariation}
`

export const DescriptionRanking = styled(DescriptionCard)`
  ${({ theme }: Type) => theme?.social?.card?.descriptionRanking}
`

// -- ImageId --

export const ContainerImageId = styled.section`
  ${({ theme }: Type) => theme?.social?.imageId?.container}
`

export const TitleImageId = styled(Title)`
  ${({ theme }: Type) => theme?.social?.imageId?.title}
`

// -- Profile --

export const ContainerProfile = styled.section`
  ${({ theme }: Type) => theme?.social?.profile?.container}
`

export const TitleProfile = styled(Title)`
  ${({ theme }: Type) => theme?.social?.profile?.title}
`

// -- Upload --

export const ContainerUpload = styled.section`
  ${({ theme }: Type) => theme?.social?.upload?.container}
`

export const TitleUpload = styled(Title)`
  ${({ theme }: Type) => theme?.social?.upload?.title}
`

// -- Voted --

export const IconContainerVoted = styled.div`
  ${({ theme }: Type) => theme?.social?.voted?.iconContainer}
`

export const TitleVoted = styled(Title)`
  ${({ theme }: Type) => theme?.social?.voted?.title}
`

export const DescriptionVoted = styled(Text)`
  ${({ theme }: Type) => theme?.social?.voted?.description}
`

// -- TAKE PHOTO --

export const TitleStartPhoto = styled.h1`
  ${({ theme }: Type) => theme?.photographer?.uploadImage?.title}
`;

export const SubtitleStart = styled.p`
  ${({ theme }: Type) => theme?.photographer?.uploadImage?.subtitle}
`;

export const SubtitleContainer = styled.button`
  ${({ theme }: Type) => theme?.photographer?.uploadImage?.subtitleContainer}
`;

export const TitleRule = styled.h1`
${({ theme }: Type) => theme?.photographer?.uploadImage?.rules?.title}
`;

export const BackgroundTitle = styled.div`
${({ theme }: Type) => theme?.photographer?.uploadImage?.rules?.backgroundTitle}
`;

export const BackgroundContent = styled.div`
${({ theme }: Type) => theme?.photographer?.uploadImage?.rules?.backgroundContent}
`;

export const BackBtn = styled.button`
  ${({ theme }: Type) => theme?.photographer?.uploadImage?.backBtn}
`;

export const TitleValidate = styled.button`
  ${({ theme }: Type) => theme?.photographer?.validatingImage?.title}
`;

export const ButtonOk = styled.button`
  ${({ theme }: Type) => theme?.photographer?.validatingImage?.buttonOk}
`;

export const ButtonCancel = styled.button`
  ${({ theme }: Type) => theme?.photographer?.validatingImage?.buttonCancel}
`;

// WRITE TEXT STYLES COMPONENT

export const TextTitleWrite = styled.h1`
  ${({ theme }: Type) => theme?.writeText?.textTitleWrite}
`

export const ContainerWriteText = styled.button`
  ${({ theme }: Type) => theme?.writeText?.container}
`

export const ContainerWriteTextTitle = styled.h2`
  ${({ theme }: Type) => theme?.writeText?.containerWriteTextTitle}
`

export const InputWriteText = styled.textarea`
  ${({ theme }: Type) => theme?.writeText?.input}
`

export const TextHistoryTitle = styled.h2`
  ${({ theme }: Type) => theme?.writeText?.textHistoryTitle}
`

export const RulesContainer = styled.div`
  ${({ theme }: Type) => theme?.writeText?.rulesContainer}
`

export const UploadContainer = styled.form`
  ${({ theme }: Type) => theme?.writeText?.uploadContainer}
`
// -- HOW TO PARTICIPATE --

export const TitleHowToParticipate = styled.h1`
  ${({ theme }: Type) => theme?.photographer?.howToParticipate?.title}
`;

export const SubtitleHowToParticipate = styled.p`
  ${({ theme }: Type) => theme?.photographer?.howToParticipate?.subtitle}
`;

export const ArticleHowToParticipate = styled.article`
  ${({ theme }: Type) => theme?.photographer?.howToParticipate?.article}
`;

export const ButtonRulesHowToParticipate = styled.button`
  ${({ theme }: Type) => theme?.photographer?.howToParticipate?.buttonRules}
`;

// TEXT CARD

export const TextCardContainer = styled.div`
  ${({ theme }: Type) => theme?.writeText?.textCard?.container}
`

export const TextCardContent = styled.p`
  ${({ theme }: Type) => theme?.writeText?.textCard?.content}
`

// -- LOADER VALIDATE --

export const LoaderValidateContainer = styled.div`
  ${({ theme }: Type) => theme?.writeText?.loaderValidate?.container}
`

export const LoaderValidateImage = styled.img`
  ${({ theme }: Type) => theme?.writeText?.loaderValidate?.image}
`

export const LoaderValidateTitle = styled.h1`
  ${({ theme }: Type) => theme?.writeText?.loaderValidate?.title}
`

export const LoaderValidateSubtitle = styled.p`
  ${({ theme }: Type) => theme?.writeText?.loaderValidate?.subtitle}
`

export const RankingTitle = styled.h1`
  ${({ theme }: Type) => theme?.ranking?.title}
`

export const RankingParticipate = styled.button<{ active: boolean }>`
  ${({ theme }: Type) => theme?.ranking?.participateActive}
  ${({ active, theme }: Active) => active && theme?.ranking?.participate}
`

export const RankingStatus = styled.div<{ active: boolean }>`
  ${({ theme }: Type) => theme?.ranking?.statusActive}
  ${({ active, theme }: Active) => active && theme?.ranking?.status}
`

export const RankingStatusDate = styled.p`
  ${({ theme }: Type) => theme?.ranking?.statusDate}
`

export const ModalTitle = styled.h2`
  ${({ theme }: Type) => theme?.writeText?.modal?.title}
`

export const ModalSubtitle = styled.p`
  ${({ theme }: Type) => theme?.writeText?.modal?.subtitle}
`

export const HistoryContainerBig = styled.div`
  ${({ theme }: Type) => theme?.writeText?.containerBig}
`
export const HistoryContainerText = styled.p`
  ${({ theme }: Type) => theme?.writeText?.containerBigText}
`

export const BackBtnP = styled.p`
  ${({ theme }: Type) => theme?.writeText?.backBtn}
`

export const RankingSubtitle = styled.p`
  ${({ theme }: Type) => theme?.writeText?.subtitle}
`

export const TextVote = styled.p`
  ${({ theme }: Type) => theme?.writeText?.textVote}
`
// how take photo

export const HowTakePhotoText = styled.p`
  ${({ theme }: Type) => theme?.photographer?.upload?.howTakePhotoText}
`

export const HowTakePhotoContainer = styled.div`
  ${({ theme }: Type) => theme?.photographer?.upload?.howTakePhotoContainer}
`

export const HowTakePhotoTitle = styled.h2`
  ${({ theme }: Type) => theme?.photographer?.upload?.howTakePhotoTitle}
`

export const HowTakePhotoSubtitle = styled.h3`
  ${({ theme }: Type) => theme?.photographer?.upload?.howTakePhotoSubtitle}
`

export const HowTakePhotoUl = styled.ul`
  ${({ theme }: Type) => theme?.photographer?.upload?.howTakePhotoUl}
`

export const HowTakePhotoLi = styled.li`
  ${({ theme }: Type) => theme?.photographer?.upload?.howTakePhotoLi}
`
export const BtnBack = styled.p`
  ${({ theme }: Type) => theme?.photographer?.upload?.btnBack}
`