import ButtonCloseMenu from "components/button-close";
import { dlTrackEvent } from "components/dataLayer/dataLayer";
import Header from "components/header";
import { SubTitleAge } from "components/legal-age/components/style/text";
import { TitleTriviaQr, DescriptionTriviaQr } from "views/trivia-qr/styles/styled.components";
// import { UserData } from "contexts/session-context/session-reducer";
// import { TitleStart, SubTitleStart } from "views/start/text.style";

const Arrow = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#005943" />
      <path d="M11.3594 5.39062L15.6094 9.64062L15.9531 10L15.6094 10.3594L11.3594 14.6094L10.6406 13.8906L14.0312 10.5H4V9.5H14.0312L10.6406 6.10938L11.3594 5.39062Z" fill="white" />
    </svg>
  )
}

export const indio = {
  legalage: {
    title: ' ',
    imageType: 'png'
  },
  agegate: {
    title: ' ',
    description: <div className="w-full flex flex-col items-center justify-center gap-3">
      <img className="" src={`${AZURE_BASE_URL}/codigo_indio_agegate_portada.webp`} alt="Código Indio Agegate Portada" />
      <SubTitleAge className="text-center"> <span style={{ fontFamily: "Bebas Neue", fontSize: '32px' }}>¿Eres mayor de edad?</span><br /> Por favor, Ingresa tu año de nacimiento.</SubTitleAge>
    </div>
  },
  signup: {
    title: 'Bienvenido',
    description: 'Ahora eres parte del equipo. Revisa tus correos para comenzar a registrar tus latas para completar la colección. Consigue las 26 latas de Amstel Ultra y descubre los mensajes de Rafa Nadal.',
    welcome: {
      description: <>Te enviamos un código de 6 dígitos a tu correo electrónico EMAIL
        para que lo ingreses y valides tu cuenta.</>,
      description2: <> </>,
      description3: <><b>¿No te llegó ningún correo?</b> <br /> Intentémoslo de nuevo</>,
      "button-resend": <span className="mt-5">Da clic aquí para reenviarlo.</span>
    }
  },
  start: {
    signup: 'REGÍSTRATE CON CORREO',
    signin: 'ÉNTRALE CON CORREO',
    social: 'ENTRA CON:',
    title: {
      html:
        <div className="flex-1 w-full py-2 flex flex-col items-center justify-center">
          <img src={`${AZURE_BASE_URL}/codigo-indio_portada_login.webp`} className="w-full max-w-sm" alt="background" />
        </div>
    }
  },
  'coming-soon': {
    html: ({ logout }) => {
      return (
        <div className="w-full min-h-view flex flex-col">
          <Header />
          <ButtonCloseMenu open={true} paramFn={logout} />
          <section className="flex-1 flex items-center justify-center flex-col">
            <div className="flex-1 flex flex-col items-center justify-center gap-8">
              <img src={`${AZURE_BASE_URL}/coming-soon.png`} className="w-full max-w-sm" alt="background" />
              <h1 className="font-normal text-2xl text-center uppercase bebasneue max-w-sm px-4">Tu llave para elevar tu experiencia con INDIO, Con ella podrás participar por MERCH, boletos y OTROS beneficios exclusivos. </h1>
              <p className="font-normal text-3xl text-center uppercase bebasneue max-w-sm px-4">¡Espera noticias de nuestras <span style={{ color: '#008065' }}>próximas</span> dinámicas!</p>
            </div>
          </section>
        </div>
      )
    }
  },
  home: {
    title: <span className="bebasneue" style={{ color: '#008065' }}>¿QUÉ quieres hacer?</span>,
    description: '¡Complete sus datos!'
  },
  profile: {
    form: {
      information: {
        firstname: {
          label: '¿CÓMO TE LLAMAS?',
          placeholder: 'Pon tu nombre aquí'
        },
        lastname: {
          placeholder: 'Pon tu apellido aquí',
        },
        nickname: {
          label: 'APODO',
          placeholder: '¿Como te gusta que te llamen?'
        },
        gender: {
          label: '¿Cómo te identificas?'
        }
      }
    },
    information: 'DATOS PERSONALES',
    address: 'DIRECCIÓN',
    contact: 'CONTACTO',
    button: 'IR A VER LAS TRIVIAS',
    identification: {
      nav: 'MI ROCK ID',
      title: 'ROCK ID',
      button: 'DESCARGAR ROCK ID',
      label: 'Mi ROCK ID',
      name: 'ROCK ID'
    },
    view: {
      contact: {
        notFound: '(vacío)'
      },
      history: {
        acquired: 'canjeado'
      }
    },
    coupon: {
      activate: 'ACTIVAR CUPÓN',
      active: 'Ver cupón activo',
      used: 'cupón usado',
      popup: {
        description: 'Estás a punto de activar tu cupón para mostrar en el módulo de canje.',
        warning: <>Recuerda que este cupón es válido para usarlo {" "}<span>una sola vez</span>{" "} y tendrás  unicamente {" "}<span>15 minutos</span>{" "} para hacerlo.</>,
        sure: '¿Seguro que quieres activarlo ahora?',
        staff: <>Ahora acércate al módulo de canje y muestra tu pantalla a la persona que te esté atendiendo.</>,
        spinner: <span className="profile__history__popup__coupon__loader"></span>
      }
    }
  },
  config: {
    prizes: '/trivias'
  },
  congrats: {
    range: {
      title: "NUEVO LOGRO DESBLOQUEADO",
      description: <></>,
      description2: <>Felicitaciones, has desbloqueado nuevos <br /> premios y obtienes más chances de ganar.</>
    }
  },
  endroute: {
    title: <>¡Lo sentimos! <br /> Esto es solo <br /> para mayores</>,
    description: 'Vuelve cuando sea más grande.'
  },
  referrals: {
    description: 'Comparte tu código con amigos y gana más oportunidades de ganar.',
    subtitle: 'COMPARTE TU CÓDIGO Y GANA',
    left: 'COPIAR',
    right: 'COMPARTIR'
  },
  trivias: {
    congrats: '¡FELICIDADES',
    exit: {
      title: 'Si cancelas ahora pierdes y tiene que volver a empezar',
      subtitle: '¿Seguro que vamos por este camino?',
    },
    exchange: {
      description: 'OBTUVISTE',
      button: {
        text: 'cOMPLETAR MIS DATOS',
        url: '/profile/phone'
      }
    },
    triviaQr: {
      register: {
        title: <>¡CONTESTA <span style={{ color: '#D0B787' }}>Y GANA</span>!</>,
        typeImage: 'webp',
        html: (
          <div className="h-full flex justify-center items-center">
            <div className="flex flex-col gap-10">
              <TitleTriviaQr>¡CONTESTA <span style={{ color: '#D0B787' }}>Y GANA</span>!</TitleTriviaQr>
              <img style={{ width: '90%' }} className="mx-auto" src={`${AZURE_BASE_URL}/trivia-qr-card-id.webp`} alt="card id" />
              <div>
                <DescriptionTriviaQr className="">¿Ya tienes tu ROCK ID?</DescriptionTriviaQr>
                <p style={{
                  color: '#FFF',
                  textAlign: 'center',
                  fontFamily: 'DINPro',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal'
                }}>SI NO  CÓRRELE A REGISTRARTE PARA <br /> PODER PARTICIPAR</p>
              </div>
            </div>
          </div>
        )
      },
      late: {
        title: <>¡UPS! <br /> YA ES TARDE </>,
        description: <>Ya no quedan trivias disponibles para este horario. Estate atento a los próximos bloques para poder participar.<br /><br />Recuerda que las trivias se activan cada 2 horas en los bloques de: 4pm - 6pm - 8pm - 10pm.</>,
        button: {
          text: 'VOLVER AL HOME',
          link: '/home'
        }
      }
    },
    modal: {
      title: <>¿Estás seguro que <br /> quieres salir?</>,
      description: <>Si cancelas ahora se perderá tu progreso <br /> y tendrás que volver a empezar. <br /> <br /> <span className="font-light">¿Segurisimo que vamos por este camino?</span></>
    },
    "not-found": <>No hay trivias disponibles.</>
  },
  tivias: {
    challenge: {
      modal: {
        description: '¿Seguro que quieres empezar a jugar? Una vez que des clic no hay vuelta atrás.'
      }
    }
  },
  trivia: {
    exit: {
      accept: 'Si, claro',
      cancel: 'NO, VOLVER'
    }
  },
  survey: {
    title: 'CUÉNTANOS DE TI',
    button: 'RESPONDER'
  },
  error: {
    505: {
      title: <>¡OH NO!<br />FALLASTE </>,
      button: {
        text: 'VOLVER A INTENTAR',
      }
    },
    509: {
      title: '¡QUE PENA!',
      description: <>Ya no quedan trivias disponibles para este horario. Estate atento a los próximos bloques para poder participar.<br /><br />Recuerda que las trivias se activan cada 2 horas en los bloques de: 4pm - 6pm - 8pm - 10pm.</>
    },
    512: {
      title: <>¡UPS!<br />YA ES TARDE</>,
      description: <>Ya no quedan trivias disponibles para este horario. Estate atento a los próximos bloques para poder participar.<br /><br />Recuerda que las trivias se activan cada 2 horas en los bloques de: 4pm - 6pm - 8pm - 10pm.</>,
      button: {
        text: 'VOLVER A HOME'
      }
    },
    auth: {
      'email-already-in-use': {
        title: 'Este mail ya se encuentra registrado en la base de datos de Heineken México'
      }
    },
    10: {
      title: "¡UPS! El código OTP es inválido"
    },
    1108: {
      button: {
        text: 'Ver los diseños ganadores',
        url: '/album/redirect/Foto/ranking'
      }
    }
  },
  otp: {
    received: "¿No la recibiste?"
  },
  email: {
    recovery: 'Ya te enviamos el correo con las instrucciones para resetear tu contraseña. Revisa tus correos para poder volver a ingresar.',
    send: "¿No te llegó ningún correo?",
    resend: "Intentémoslo de nuevo."
  },
  album: {
    title: 'DINÁMICAS',
    notFound: 'No hay retos disponibles por el momento, estate atento a nuestras nuevas dinámicas.',
  },
  social: {
    loader: {
      title: 'ESTAMOS VALIDADO TU DISEÑO'
    },
    imgUpload: 'Espera mientras revisamos tu imagen.',
    Foto: {
      howToParticipate: {
        subtitle: 'SUBE TU DISEÑO Y PARTICIPA',
        list: (
          <ul className="flex-1 items-center justify-center flex-col flex w-full gap-3 pb-7">
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-1.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="text-base font-normal" style={{ fontFamily: 'DINPro', color: '#000000', lineHeight: '20px' }}>
                  <b className="text-lg font-bold" style={{ lineHeight: '20px' }}>Completa tu perfil</b><br />Checa tener tus datos personales completos.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-2.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="text-base font-normal" style={{ fontFamily: 'DINPro', color: '#000000', lineHeight: '20px' }}>
                  <b className="text-lg font-bold" style={{ lineHeight: '20px' }}>Crea tu diseño personalizado</b><br />Con el kit que se te proporciona tendrás que crear un diseño que te identifique.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-3.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="text-base font-normal" style={{ fontFamily: 'DINPro', color: '#000000', lineHeight: '20px' }}>
                  <b className="text-lg font-bold" style={{ lineHeight: '20px' }}>Sube tu diseño</b><br />En formato JPG.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-4.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="text-base font-normal" style={{ fontFamily: 'DINPro', color: '#000000', lineHeight: '20px' }}>
                  <b className="text-lg font-bold" style={{ lineHeight: '20px' }}>Contactaremos a los ganadores</b><br />Asegúrate de tener tu archivo vectorial listo (en Adobe Illustrator).
                </p>
              </div>
            </li>
            <li className="border border-dashed rounded-lg p-2 flex items-center gap-6 w-full" style={{ background: 'rgba(255, 229, 0, 0.08)', borderColor: '#AC9100' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                <rect width="56" height="56" rx="28" fill="white" />
                <path d="M40.6362 35.4906L30.2509 17.3162C30.0244 16.9166 29.696 16.5843 29.2992 16.3531C28.9024 16.1218 28.4513 16 27.9921 16C27.5328 16 27.0817 16.1218 26.6849 16.3531C26.2881 16.5843 25.9597 16.9166 25.7332 17.3162L15.3479 35.4906C15.119 35.887 14.999 36.3368 15 36.7946C15.001 37.2523 15.123 37.7017 15.3537 38.097C15.5843 38.4924 15.9154 38.8198 16.3133 39.046C16.7113 39.2722 17.162 39.3892 17.6197 39.3851H38.3904C38.8459 39.3846 39.2933 39.2643 39.6876 39.0362C40.0819 38.8082 40.4093 38.4804 40.6369 38.0857C40.8644 37.6911 40.9842 37.2436 40.9841 36.7881C40.9839 36.3325 40.864 35.8851 40.6362 35.4906Z" stroke="#AC9100" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M28.0054 23.807V28.9997" stroke="#AC9100" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M28.0054 34.1924H28.0184" stroke="#AC9100" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <p className="text-xs font-normal leading-normal" style={{ color: '#AC9100', fontFamily: 'DINPro' }}>
                Utilizar únicamente este <a className="underline font-bold" href="https://cdn-phcentral.azureedge.net/indio/dinamica/Gui%CC%81a%20para%20ilustrar%20Etiquetas%20INDIO-20240508T135630Z-001.zip" target="_blank" rel="noreferrer" onClick={() => {
                  dlTrackEvent('user_interaction', {
                    action: 'click',
                    element: 'Descarga Toolkit',
                    section: 'subir diseño'
                  })
                }}>kit de diseño</a>
              </p>
            </li>
          </ul>
        )
      },
      onBoarding: {
        rules: {
          list: (
            <>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal dinPro text-sm" style={{ color: '#040404' }}>
                    El diseño debe contener el logo de Indio.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal dinPro text-sm" style={{ color: '#040404' }}>
                    Seguir las instrucciones del kit de diseño.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal dinPro text-sm" style={{ color: '#040404' }}>
                    No incluir en el diseño: símbolos religiosos, niños, desnudos o que algo que pueda atentar contra una propiedad intelectual! (alebrijes, asociaciones de danza cultural, marcas, productos o negocios registrados)
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal dinPro text-sm" style={{ color: '#040404' }}>
                    Si el contenido es inapropiado se inactivará el usuario para que no pueda subir más contenido.
                  </p>
                </div>
              </div>
            </>
          )
        }
      },
      upload: {
        title: 'CARGAR DISEÑO',
        subtitle: 'Clic para subir diseño',
        warningPhoto: (
          <div className="border border-dashed rounded-lg p-2 flex items-center gap-6 w-full" style={{ background: 'rgba(255, 229, 0, 0.08)', borderColor: '#AC9100' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
              <rect width="56" height="56" rx="28" fill="white" />
              <path d="M40.6362 35.4906L30.2509 17.3162C30.0244 16.9166 29.696 16.5843 29.2992 16.3531C28.9024 16.1218 28.4513 16 27.9921 16C27.5328 16 27.0817 16.1218 26.6849 16.3531C26.2881 16.5843 25.9597 16.9166 25.7332 17.3162L15.3479 35.4906C15.119 35.887 14.999 36.3368 15 36.7946C15.001 37.2523 15.123 37.7017 15.3537 38.097C15.5843 38.4924 15.9154 38.8198 16.3133 39.046C16.7113 39.2722 17.162 39.3892 17.6197 39.3851H38.3904C38.8459 39.3846 39.2933 39.2643 39.6876 39.0362C40.0819 38.8082 40.4093 38.4804 40.6369 38.0857C40.8644 37.6911 40.9842 37.2436 40.9841 36.7881C40.9839 36.3325 40.864 35.8851 40.6362 35.4906Z" stroke="#AC9100" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M28.0054 23.807V28.9997" stroke="#AC9100" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M28.0054 34.1924H28.0184" stroke="#AC9100" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <p className="text-xs font-normal leading-normal" style={{ color: '#AC9100', fontFamily: 'DINPro' }}>
              Utilizar únicamente este <a className="underline font-bold" href="https://cdn-phcentral.azureedge.net/indio/dinamica/Gui%CC%81a%20para%20ilustrar%20Etiquetas%20INDIO-20240508T135630Z-001.zip" target="_blank" rel="noreferrer" onClick={() => {
                dlTrackEvent('user_interaction', {
                  action: 'click',
                  element: 'Descarga Toolkit',
                  section: 'subir diseño'
                })
              }}>kit de diseño</a>
            </p>
          </div>
        ),
        rules: {
          title: 'REGLAS PARA SUBIR TU DISEÑO',
          list: (
            <ul style={{ color: '#272727', fontSize: '14px', fontFamily: 'DINPro' }}>
              <li>
                <p className="flex gap-1">
                  <span>1.</span> El diseño debe contener el logo de Indio.
                </p>
              </li>
              <li className="my-3">
                <p className="flex gap-1">
                  <span>2.</span> Seguir las instrucciones del kit de diseño.
                </p>
              </li>
              <li>
                <p className="flex gap-1">
                  <span>3.</span> No incluir en el diseño: símbolos religiosos, niños, desnudos o que algo que pueda atentar contra una propiedad intelectual.
                </p>
              </li>
            </ul>
          )
        },
        isReady: '¿ESTA OK ESTE DISEÑO?',
        buttonSubmit: 'CONFIRMAR',
        alreadyUploaded: {
          title: '¡VEEEEEEENGA!',
          description: <>
            Sigue subiendo tus diseños y suma mas oportunidades de ganar.<br /><br />En caso de resultar ganador deberás tener tus datos de contacto completos y tu PSD ganador.
          </>,
          button: {
            url: '/profile',
            text: 'COMPLETAR MIS DATOS'
          },
          button2: {
            url: '/album/redirect/Foto/upload',
            text: 'CARGAR OTRO'
          }
        },
        "ranking-subtitle": {
          completed: 'Este reto ya ha finalizado pero puedes ver los ganadores'
        }
      }
    }
  },
}