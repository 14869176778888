import { bohemia } from './bohemia';
import { amstel } from './amstel';
import { silverWaiter } from './silverWaiter';
import { silverOnTrade } from './silverOnTrade';
import { sol } from './sol';
import { mixx } from './mixx';
import { silver } from './silver';
import { cartablanca } from './cartablanca';
import { tecate } from './tecate';
import { promohub } from './promohub';
import { matchpaint } from './matchpaint';
import { fabs } from './fabs';
import { indio } from './indio';
import { cristal } from './cristal';
import { miller } from './miller'
import { dosequis } from './dosequis'
import { silverMemorama } from './silverMemorama'
import { heinekenWaiter } from './heinekenWaiter'
import { heineken00 } from './heineken00'

export const Texts = { tecate, promohub, matchpaint, fabs, cartablanca, indio, cristal, silver, mixx, sol, miller, dosequis, silverOnTrade, silverWaiter, silverMemorama, amstel, bohemia, heinekenWaiter, heineken00 }
