import { prettyError, prettyErrorButton, prettyErrorDescription } from 'constants/errors'
import ButtonCloseMenu from 'components/button-close'
import GenericError from 'components/generic-error'
import { useContext, useEffect, useState } from 'react'
import { useBg } from 'hooks/use-white-bg'
import useRouter from 'hooks/use-router'
import { SectionError } from 'theme/error'
import { LiError, TextError, TitleError, IconError } from 'components/generic-error/components/style/text'
import { Link } from 'react-router-dom'
import useMainClass from 'hooks/use-main-cass'
import { LoaderContext } from 'contexts/loader-context'

type IFunctionRetry = (code: number | string | null) => void

const ContainerError = ({ children, handleMenu }: { children: JSX.Element, handleMenu: () => void }) => {
  const [shouldUseWhiteBg] = useState(useBg)
  useMainClass('error')

  const { setFooter } = useContext(LoaderContext)

  useEffect(() => {
    setFooter(false)
    return () => setFooter(true)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const event = new CustomEvent('background-color', {
        detail: {
          shouldUseWhiteBg: !THEME_CONFIG?.error?.dontUseSecondBg
        }
      })
      window.dispatchEvent(event)
    }, 200)

    return () => {
      const event = new CustomEvent('background-color', {
        detail: {
          shouldUseWhiteBg
        }
      })

      window.dispatchEvent(event)
    }
  }, [])


  return (
    <div className="flex justify-center items-center w-full flex-col text-center px-7 flex-1">
      <ButtonCloseMenu
        open={true}
        paramFn={handleMenu}
      />
      <div className="space-y-4 flex-1 flex flex-col items-center justify-center py-7 w-full">
        {children}
      </div>
    </div>
  )
}

function ErrorView({ code, onRetry, buttonMenu }: { code: number | string | null, onRetry?: IFunctionRetry, buttonMenu?: IFunctionRetry }) {
  const { title, description, button } = { title: prettyError(code), description: prettyErrorDescription(code), button: prettyErrorButton(code) }
  const { history } = useRouter()
  const { setFooter } = useContext(LoaderContext)

  const handleMenu = () => {
    if (buttonMenu) buttonMenu(code)
  }

  const handleButton = () => {
    if (onRetry) onRetry(code)
  }

  const handleRetry = () => {
    if (title === 'Ha ocurrido un error') return history.push('/help')
    return handleButton()
  }

  useEffect(() => {
    setFooter(false)
    return () => setFooter(true)
  }, [])

  return (
    <ContainerError handleMenu={handleMenu}>
      <GenericError error={title} description={description} buttonText={button} onRetry={handleRetry} />
    </ContainerError >
  )
}

export function ErrorListView({ code, list, buttonMenu }: { list: string[], code: number | string | null, onRetry?: IFunctionRetry, buttonMenu?: IFunctionRetry }) {
  const { title, description } = { title: prettyError(code), description: prettyErrorDescription(code) }

  const handleMenu = () => {
    if (buttonMenu) buttonMenu(code)
  }

  return (
    <ContainerError handleMenu={handleMenu}>
      <SectionError className="flex-1 flex flex-col justify-center items-center max-w-full space-y-4 mx-8 my-8 w-full">
        <div className="flex-1  flex flex-col itemx-center justify-center gap-4">
          <IconError className='flex justify-center items-center'>
            <svg width="124" height="124" viewBox="0 0 124 124" fill="none" className='icon-error' xmlns="http://www.w3.org/2000/svg">
              <circle cx="62" cy="62" r="60" stroke="currentColor" strokeWidth="4" />
              <path d="M35 87.5L89 36" stroke="currentColor" strokeWidth="6" strokeLinecap="square" />
              <path d="M36.25 34.75L87.75 88.75" stroke="currentColor" strokeWidth="6" strokeLinecap="square" />
            </svg>
          </IconError>
          <TitleError className="text-center text-3xl uppercase font-bold error__title">{title}</TitleError>
          <TextError className="text-center mx-2 error__description">{description}</TextError>
          <div className='px-4 flex justify-center items-center'>
            <ul style={{ listStyle: 'initial' }}>
              {
                list?.map((item: string, index) => (
                  <LiError className='font-semibold text-base error__li' key={index}>{item}</LiError>
                ))
              }
            </ul>
          </div>
        </div>
        <div className='flex flex-col items-center justify-center'>
          <TextError className='error__description'><b>¿Te parece que no es asi?</b></TextError>
          <TextError className='error__description'>Ponte en contacto con nosotros para que podamos ayudarte. <Link to='/help' className='underline'>Mándanos un mensaje</Link></TextError>
        </div>
      </SectionError>
    </ContainerError >
  );
};

export default ErrorView;