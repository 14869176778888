import { useSession } from 'contexts/session-context'
import useMainClass from 'hooks/use-main-cass'
import textServices from 'services/text-services'
import { TextProfileSecondary } from 'views/profile/text.style'

function ConsumptionProfile() {
  const [{ user }] = useSession()
  useMainClass('profile__consumption')

  return (
    <div className="font-bold text-2xl px-5 py-6 flex-1 flex break-all">
      <div className="flex-1">
        {user?.consumptionCenter ? (
          <div>
            <TextProfileSecondary className="font-normal dinPro text-lg normal-case">
              {
                user?.consumptionCenter?.internalId && (
                  <>
                    {user?.consumptionCenter?.internalId} <br/>
                  </>
                )
              }
              {user?.consumptionCenter?.name}<br />{user?.consumptionCenter?.state}<br />{user?.consumptionCenter?.city}
            </TextProfileSecondary>
          </div>
        ) : (
          <TextProfileSecondary className="font-normal dinPro text-lg normal-case profile__consumption__empty">
            {textServices.findByText('profile/consumptionEmpty', 'SIN INFORMACION')}
          </TextProfileSecondary>
        )}
      </div>
    </div>
  )
}

export default ConsumptionProfile
