import BirthdayInput from "components/birthday-input";
import LegalAge from "components/legal-age";
import useMainClass from "hooks/use-main-cass";
import { useAgegate } from "hooks/use-agegate";
import useRedirect from "hooks/use-redirect";
import useRouter from "hooks/use-router";
import { useEffect } from "react";

function Agegate() {
  const { setAgeLocal, showAgegate, isMinor } = useAgegate();
  const [{ url }, { redirect }] = useRedirect();
  const { push } = useRouter();
  useMainClass('agegate')
  useEffect(() => {

    if (showAgegate && !isMinor) {
      if (url) return redirect();
      push('/start');
    } else if (showAgegate && isMinor) {
      push('/end')
    }

  }, [showAgegate]);

  if (THEME_CONFIG.legalAgeDate) {
    return <BirthdayInput setAgeLocal={setAgeLocal} />;
  }

  return <LegalAge setAgeLocal={setAgeLocal} />;
}

export default Agegate