import { useCallback, useEffect, useState } from "react"
import { ErrorMessage } from "@hookform/error-message"
import { Controller, useFormContext } from "react-hook-form"
import { Question } from "contexts/survey-context/survey.interface"
import { ErrorInput } from "theme/theme"
import { BackBtn } from "views/login/text.style"
import Button from "components/button"
import Stepper from "components/stepper"
import Options from "../options"

interface IProps {
  question: Question
  step: number
  submit: (data: any) => Promise<void>
}

function Template({ step, question, submit }: IProps) {
  const [variant, setVariant] = useState('hidden');
  const { control, setValue, handleSubmit, formState: { errors } } = useFormContext();

  useEffect(() => {
    if (control?._formValues?.step >= step) {
      if (variant !== 'show') {
        setTimeout(() => {
          setVariant('show')
        }, 500)
      }
    } else {
      setVariant('hidden')
    }
  }, [control?._formValues?.step])

  const nextStepper = () => {
    setValue('step', control?._formValues?.step + 1)
  }

  const Render = useCallback(({ field: { onChange } }: any) => (
    <Options
      onChange={(_, select) => onChange(select.map((id) => +id))}
      options={question?.options}
      question={question?.question}
      subtitle={question?.subtitle}
      questionId={question?.questionId}
      type={question?.type}
      amountAnswers={question?.amountAwnsers}
    />
  ), [question])

  return (
    <form onSubmit={handleSubmit(control?._formValues?.step === control?._formValues?.max ? submit : nextStepper)} className={`bg-gray-300 rounded-t-lg h-full px-4 pt-2 pb-5 flex flex-col gap-2 survey__stepper-body w-full animations-stepper animations-stepper-${variant}`}>
      <Stepper current={step} total={control?._formValues?.max} />
      <section className="flex-1 overflow-y-auto">
        <Controller
          name={`${question?.questionId}`}
          control={control}
          render={Render}
        />
        <ErrorInput className='input-error'>
          <ErrorMessage errors={errors} name={`${question?.questionId}`} />
        </ErrorInput>
      </section>
      <div className="w-full flex flex-col items-center">
        <Button>{step === control?._formValues?.max ? 'RESPONDER' : 'SIGUIENTE'}</Button>
        {
          step !== 1 &&
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => setValue('step', step - 1)}
          >
            <BackBtn className={`din text-center mt-3 hover:text-red-100 uppercase underline button-back`} style={{ fontSize: 10 }}>
              VOLVER
            </BackBtn>
          </button>
        }
      </div>
    </form>
  )
}

export default Template