import { GET_MARKETPLACE } from 'constants/api'
import useAxios from 'axios-hooks'
import { LoaderContext } from 'contexts/loader-context'
import { useContext, useEffect } from 'react'
import ButtonCloseMenu from 'components/button-close'
import useRouter from 'hooks/use-router'
// import textServices from 'services/text-services'
import { TitleMarketPlace, SubTitleMarketPlace, ScopeMarketPlace } from './style/text'
import { ImageMarketPlace } from './style/image-container'

import { MarketPlace } from './types'

function Marketplaces() {
  const { setLoad } = useContext(LoaderContext)
  const { history } = useRouter()

  const [{ data = [], loading }] = useAxios<MarketPlace[]>({
    url: GET_MARKETPLACE
  })

  useEffect(() => {
    setLoad(loading)
    return () => {
      setLoad(false)
    }
  }, [loading])

  return (
    <div className="text-center px-7">
      <ButtonCloseMenu
        open={true}
        paramFn={() => {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'DESCRIPCION PRIZES - Ir a premios', value: '10' })
          history.goBack()
        }}
      />
      <TitleMarketPlace>TIENDAS</TitleMarketPlace>
      <SubTitleMarketPlace className="my-5">En estos sitios podrás comprar nuestros productos</SubTitleMarketPlace>
      <div className="grid gap-2 grid-cols-2">
        {data.map((marketplace, i) => {
          return (
            <a
              key={`link-${i}`}
              onClick={() => {
                gtag('event', 'Click', {
                  event_category: GTM_EVENT_CATEGORY,
                  event_label: 'marketplace_link',
                  value: marketplace.name
                })
                // window.location.href = marketplace.url
                window.open(marketplace.url, '_blank');
              }}
              target="_blank"
              rel="noreferrer"
            >
              <ImageMarketPlace key={i} className={` cursor-pointer border flex flex-col items-center justify-center h-32`}>
                <img src={marketplace.imageUrl} alt={marketplace.name} />
                <ScopeMarketPlace>{marketplace.scope}</ScopeMarketPlace>
              </ImageMarketPlace>
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default Marketplaces
