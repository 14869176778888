import Countdown from 'react-countdown'
import { Link } from 'react-router-dom'
import { useContext, useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar';
import textServices from 'services/text-services'
import { dlTrackEvent } from 'components/dataLayer/dataLayer';
import { useTrivia } from '../../contexts/trivia-context'
import { useSession } from '../../contexts/session-context'
import { LoaderContext } from '../../contexts/loader-context'
import {
  DivTriviaList,
  DivTriviaListBorder,
  DivTitleTriviaList,
  H2TitleTriviaList,
  TriviaDescription,
  DivTriviaContainer,
  TriviaTitle,
  TriviaSubtitle,
  TriviaSubtitleSpan,
  TriviaPrize,
  TriviaStock,
  TriviaTime,
  TriviaCost,
  TriviaTitlePage,
  TriviaNotFoundContainer,
  TriviaNotFoundText,
  TriviaTimeNumbers,
  TriviaCostNumber,
} from '../../theme/trivias'

export enum TriviaStatusEnum {
  AVAILABLE = 1,
  NOT_AVAILABLE = 2,
  WON = 3,
  LOST = 4
}

const TriviaList = () => {
  const [state, { getTrivias }] = useTrivia()
  const { setLoad } = useContext(LoaderContext)
  const [{ user, data }] = useSession()

  useEffect(() => {
    if (data) {
      getTrivias()
    }
  }, [data])

  if (!state.trivias) {
    setLoad(true)
  } else {
    setLoad(false)
  }

  dayjs.extend(calendar)

  const avaliableTrivias = useMemo(() => {
    return state?.trivias?.filter((item) => !item?.directAccess)
  }, [state.trivias])

  return (
    <DivTriviaList className="trivias__container md:max-w-screen-md lg:max-w-screen-lg">
      <div className="trivias__title-cnt relative w-screen flex justify-start items-center px-6 py-4 md:px-0">
        <TriviaTitlePage className="text-3xl font-bold uppercase trivias__title">Trivias</TriviaTitlePage>
        <button
          className="din uppercase right-0 top-0 absolute h-18 hidden"
          style={{ fontSize: 8 }}
          onClick={() => { gtag('event', 'event_to_ga', { event_category: "Trivias", event_label: "Ordenar" }); dlTrackEvent('user_interaction', { action: 'click', element: 'ordenar por', section: 'trivia' }) }}
        >
          ordenar por
        </button>
      </div>
      {state.trivias && avaliableTrivias.length ? (
        <div className="grid grid-cols-1 md:gap-4 md:grid-cols-2 lg:grid-cols-3 auto-rows-fr w-full trivias__container__cards">
          {avaliableTrivias.map((item) => {
            const hasEnoughPoints = parseInt(user?.totalPoints || '0', 10) >= item.costInPoints
            const isAvailable = item.stock !== 0
            const isUnlimited = isAvailable && item.stock === -1
            let shouldShowCountdown = false
            let isActive = hasEnoughPoints

            let dateCountdown
            let countdownType
            const nowDate = dayjs(new Date())
            if (item.validFrom) {
              const validFrom = new Date(item.validFrom as string)
              const date = dayjs(validFrom)
              if (nowDate <= date) {
                countdownType = 'Comienza en'
                shouldShowCountdown = true
                dateCountdown = date.toDate()
              }
              if (nowDate < date) isActive = false
            }

            if (item.validTo && !countdownType) {
              const validTo = new Date(item.validTo as string)
              const date = dayjs(validTo)
              if (nowDate <= date) {
                countdownType = 'Finaliza en'
                shouldShowCountdown = true
                dateCountdown = date.toDate()
              }

              if (nowDate > date) isActive = false
            }

            if (isAvailable && false as any && item.imageUrl !== '') {
              return (
                <Link
                  key={`trivia-item-${item.id}`}
                  to={`/trivia/${item.id}`}
                  className={`h-full trivias__card-list ${isActive ? 'trivias__card-list-active' : 'pointer-events-none'}`}
                >
                  <DivTriviaListBorder className={`border-b h-full flex flex-col card-container`} isActive={isActive}>
                    <DivTitleTriviaList className={` flex justify-start items-center card-title-cnt ${isActive ? '' : 'opacity-80'}`}>
                      <H2TitleTriviaList className="uppercase py-4 pl-6 pr-4 nersans text-[15px] card-title-int">
                        {item.interest}
                      </H2TitleTriviaList>
                      <h2 className="uppercase py-4 pl-4 pr-6 nersans text-[20px] card-title-name">{item?.name}</h2>
                    </DivTitleTriviaList>
                    <DivTriviaContainer className="pt-4 flex flex-col justify-between flex-1 card-body">
                      <TriviaDescription className="pb-2 text-center px-6 card-body-subtitle">{item.description}</TriviaDescription>
                      <div className="flex items-center card-body-img">
                        <img
                          src={item.imageUrl}
                          className="ml-5"
                          alt="logo"
                          style={{ width: '58px' }}
                        />
                        <h3 className="text-center uppercase text-2xl font-bold px-6 w-full nersans">{item.prize}</h3>
                        <img
                          src={item.imageUrl}
                          className="mr-5 invisible"
                          alt="logo"
                          style={{ width: '58px' }}
                        />
                      </div>

                      <div className="flex justify-between w-full items-center px-6 mt-4 py-1 card-footer">
                        <div>
                          {shouldShowCountdown && (
                            <p className='raleway font-medium card-footer-time-cnt'>
                              {countdownType} <br /> <Countdown className='card-footer-time-countdown' date={dateCountdown} />
                            </p>
                          )}
                        </div>
                        {!isUnlimited && (
                          <p className="raleway font-medium uppercase text-center text-xs card-body-stock">
                            {item.stock} disponible{item.stock > 1 ? 's' : ''}
                          </p>
                        )}
                        {item.costInPoints > 0 && (
                          <div className="flex justify-center items-center card-footer-cost-cnt">
                            <span className="text-3xl uppercase nersans font-bold card-footer-cost-points">{item.costInPoints}</span>
                            <span className="text-xs uppercase nersans mt-2 ml-2">{POINTS_NAME}</span>
                          </div>
                        )}
                        {item.costInPoints === 0 && (
                          <div className="flex justify-center items-center card-footer-cost-free">
                            GRATIS
                          </div>
                        )}
                      </div>
                    </DivTriviaContainer>
                    {!hasEnoughPoints && (
                      <div className="uppercase raleway text-xs text-center pb-4 card-footer-cost-not-found">
                        no tienes suficientes {POINTS_NAME}
                      </div>
                    )}
                  </DivTriviaListBorder>
                </Link>
              )
            } else if (isAvailable) {
              return (
                <Link
                  key={`trivia-item-${item.id}`}
                  to={`/trivia/${item.id}`}
                  className={`h-full trivias__card-list ${isActive ? 'trivias__card-list-active' : 'pointer-events-none'}`}
                >
                  <DivTriviaListBorder className={`border-b card-container h-full flex flex-col`} isActive={isActive}>
                    <DivTitleTriviaList className={` flex justify-start items-center card-title-cnt ${isActive ? '' : 'opacity-80'}`}>
                      <H2TitleTriviaList className="font-bold uppercase text-lg py-4 pl-6 pr-4 card-title-int">
                        {item.interest}
                      </H2TitleTriviaList>
                      <TriviaTitle className="text-sm py-4 pl-4 pr-6 flex justify-center items-center card-title-name">{item?.name}</TriviaTitle>
                    </DivTitleTriviaList>
                    <DivTriviaContainer className="pt-4 flex flex-col justify-between flex-1 card-body">
                      <TriviaSubtitle className="din pb-2 text-center px-6 card-body-subtitle">
                        {textServices.findByText('trivias/questions1', `Contesta `)}
                        <TriviaSubtitleSpan className='card-body-subtitle-question'>{item.nbrOfQuestions}</TriviaSubtitleSpan>
                        {textServices.findByText('trivias/questions2', ` preguntas sobre el tema y gana:`)}
                      </TriviaSubtitle>
                      <TriviaPrize className="text-center uppercase text-2xl px-6 pb-2 card-body-prize">{item.prize}</TriviaPrize>
                      {!isUnlimited && (
                        <TriviaStock className="din uppercase text-center text-xs card-body-stock">
                          {item.stock} disponible{item.stock > 1 ? 's' : ''}
                        </TriviaStock>
                      )}
                      <div className="flex justify-between w-full items-end px-6 mt-4 py-1 card-footer">
                        <div className='card-footer-time-cnt'>
                          {shouldShowCountdown && (
                            <TriviaTime className=''>
                              {countdownType}<br /> <TriviaTimeNumbers className='card-footer-time-countdown'><Countdown date={dateCountdown} /></TriviaTimeNumbers>
                            </TriviaTime>
                          )}
                        </div>
                        {item.costInPoints > 0 && (
                          <TriviaCost className="flex justify-center items-center card-footer-cost-cnt">
                            <p> <TriviaCostNumber className='card-footer-cost-points'>{item.costInPoints}</TriviaCostNumber> {POINTS_NAME} </p>
                            {/* <span className="text-3xl uppercase nersans font-bold">{item.costInPoints}</span>
                            <span className="text-xs uppercase nersans mt-2 ml-2">{POINTS_NAME}</span> */}
                          </TriviaCost>
                        )}
                        {item.costInPoints === 0 && (
                          <TriviaCost className="flex justify-center items-center card-footer-cost-free">
                            GRATIS
                          </TriviaCost>
                        )}
                      </div>
                    </DivTriviaContainer>
                    {!hasEnoughPoints && (
                      <div className="uppercase din text-xs text-center pb-2 pt-2 card-footer-cost-not-found">
                        no tienes suficientes {POINTS_NAME}
                      </div>
                    )}
                  </DivTriviaListBorder>
                </Link>
              )
            } else {
              return null
            }
          })}
        </div>
      ) : (
        <TriviaNotFoundContainer className="w-full h-full flex justify-center items-center trivias__not-found-container">
          <TriviaNotFoundText className="font-bold text-3xl text-center trivias__not-found-text">{textServices.findByText('trivias/not-found', 'NO HAY TRIVIAS DISPONIBLES')}</TriviaNotFoundText>
        </TriviaNotFoundContainer>
      )}
    </DivTriviaList>
  )
}

export default TriviaList
