import { AuthError, UserCredential } from '@firebase/auth'
import { dlSetUserInfo, createUserProperties} from 'components/dataLayer/dataLayer'

export type SessionActionType = 'error' | 'success' | 'user' | 'points' | 'restore' | 'set_survey' | 'refetch' | 'totalpoints'

export interface ConsumptionCenter {
  name: string
  address: string | null
  city: string
  state: string
  internalId: string
}
export interface UserData {
  state: any
  contactEmail?: string
  totalPoints: string
  id: number
  chances: number
  referralCode: string
  firstName: string
  lastName: string
  nickName: string
  phoneNumber: any
  shippingDetails: any
  email: string
  birthday: string
  address?: Address
  city: any
  genreId?: number
  genre?: string
  postalCode: any
  addressCount: number
  interests: { id: number; name: string }[]
  instagram?: string
  level?: {
    id: number
    name: string
    imageUrl: string
  },
  consumptionCenter?: ConsumptionCenter
  hashId?: string
}

export interface Address {
  neighborhood: string
  postalCode: string
  city: string
  state: string
  country: string
  street1: string
  street2: any
  streetNumber: string
  apartament: any
  shippingDetails: string
  phone: string
  lastName: string
  firstName: string
  curp: string
}

export interface SessionState {
  error?: AuthError
  data?: UserCredential
  user?: UserData
}

export interface SessionAction {
  type: SessionActionType
  payload: AuthError | UserCredential | UserData | number | SessionState | []
}

function sessionReducer(state: SessionState, action: SessionAction): SessionState {
  switch (action.type) {
  case 'error':
    return { ...state, error: action.payload as AuthError }
  case 'restore':
    return { ...state, ...action.payload as { user: UserData, data: UserCredential, error: AuthError } } as any
  case 'success':
    return { ...state, data: action.payload as UserCredential }
  case 'user':
    dlSetUserInfo((action.payload as UserData)?.hashId)
    createUserProperties((action.payload as UserData), null)
    return { ...state, user: action.payload as any }
  case 'points':
    return {
      ...state,
      user: {
        ...state.user,
        totalPoints: (parseInt(state.user?.totalPoints || '0', 10) + (action.payload as number)).toString() as any
      } as any
    }
  case 'totalpoints':
    return {
      ...state,
      user: {
        ...state.user,
        totalPoints: (action.payload as number) as any
      } as any
    }    
  default:
    return state
  }
}

export default sessionReducer
