import { FunctionComponent } from 'react'
import { ThemeInterface } from 'theme/types'
import images from '../../images.json'
import { LoadingDiv, Logo, Spinner, TextLoading } from '../../theme/loading'
import Theme from '../../theme/schema.json'

const LoadingScreen: FunctionComponent = () => {

  const theme = Theme?.data[THEME] as ThemeInterface

  if (THEME_CONFIG?.loaderPulse) {
    return (
      <LoadingDiv className="z-50 flex justify-center items-center flex-col app-loading-container">
        <div className={`app-loading anim-pulse`} style={{ backgroundImage: `url(${AZURE_BASE_URL}/spinner.svg)` }}>
          <div>
            {
              !theme?.loading?.config?.circlePulse && (
                <svg className="spinner" viewBox="25 25 50 50">
                  <circle
                    className="path"
                    cx="50"
                    cy="50"
                    r="20"
                    fill="none"
                    strokeOpacitye-width="2"
                    stroke-strokeMiterlimit="10"
                  />
                </svg>
              )
            }
          </div>
        </div>
      </LoadingDiv>
    )
  }

  return (
    <LoadingDiv className="z-50 app-loading">
      <div className="absolute " style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000 }}>
        <Spinner src={`${AZURE_BASE_URL}${images['loader-spinner']}`} className="animate-spin animation-8" />
      </div>
      {!theme?.loading.config.hideSpinningLogo && (
        <Logo
          src={`${AZURE_BASE_URL}${images['logo-positivo-svg']}`}
          className="absolute"
          style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 1050 }}
        />
      )}
      {
        theme?.loading?.config?.useText && (
          <TextLoading>Cargando ...</TextLoading>
        )
      }
    </LoadingDiv>
  )
}
export default LoadingScreen
