import { SpanPolitics } from 'components/birthday-input/style.custom'
import { Link } from 'react-router-dom'

const Arrow = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 0.46875C12.3392 0.46875 14.0482 0.932617 15.627 1.86035C17.1569 2.75553 18.3695 3.9681 19.2646 5.49805C20.1924 7.07682 20.6562 8.78581 20.6562 10.625C20.6562 12.4642 20.1924 14.1732 19.2646 15.752C18.3695 17.2819 17.1569 18.4945 15.627 19.3896C14.0482 20.3174 12.3392 20.7812 10.5 20.7812C8.66081 20.7812 6.95182 20.3174 5.37305 19.3896C3.8431 18.4945 2.63053 17.2819 1.73535 15.752C0.807617 14.1732 0.34375 12.4642 0.34375 10.625C0.34375 8.78581 0.807617 7.07682 1.73535 5.49805C2.63053 3.9681 3.8431 2.75553 5.37305 1.86035C6.95182 0.932617 8.66081 0.46875 10.5 0.46875ZM10.5 2.03125C8.9375 2.03125 7.48893 2.42188 6.1543 3.20312C4.86849 3.9681 3.8431 4.99349 3.07812 6.2793C2.29688 7.61393 1.90625 9.0625 1.90625 10.625C1.90625 12.1875 2.29688 13.6361 3.07812 14.9707C3.8431 16.2565 4.86849 17.2819 6.1543 18.0469C7.48893 18.8281 8.9375 19.2188 10.5 19.2188C12.0625 19.2188 13.5111 18.8281 14.8457 18.0469C16.1315 17.2819 17.1569 16.2565 17.9219 14.9707C18.7031 13.6361 19.0938 12.1875 19.0938 10.625C19.0938 9.0625 18.7031 7.61393 17.9219 6.2793C17.1569 4.99349 16.1315 3.9681 14.8457 3.20312C13.5111 2.42188 12.0625 2.03125 10.5 2.03125ZM11.0615 5.62012L16.0664 10.625L11.0615 15.6299L9.93848 14.5312L13.0635 11.4062H5.03125V9.84375H13.0635L9.93848 6.71875L11.0615 5.62012Z" fill="#B98B39" />
  </svg>
);

export const miller = {
  agegate: {
    description: <>
      <img src={`${AZURE_BASE_URL}/agegate.webp`} alt='Logo Miller Lite' className='mx-auto py-10' />
      <p className='agegate__description'>
        <span className='big'>¿Eres <span>mayor de edad</span>?</span><br />
        Ingresa tu año de nacimiento, para poder validar tu mayoría de edad.
      </p>
    </>,
    politics1: <span className='hidden'></span>,
    politics2: <SpanPolitics>
      AL CONFIRMAR, ACEPTAS LA <a href={`${process.env.PUBLIC_URL}/privacy-politics`} rel="noreferrer" target="_blank" className="underline">POLÍTICA DE PRIVACIDAD</a >, <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">TÉRMINOS Y CONDICIONES Y USO DE COOKIES</a > DE ESTE SITIO WEB. ESTE SITIO WEB UTILIZA COOKIES. PARA OBTENER MÁS INFORMACIÓN, LEE NUESTRA POLÍTICA DE <a href={`${process.env.PUBLIC_URL}/privacy-politics`} rel="noreferrer" target="_blank" className="underline">PRIVACIDAD</a > Y USO DE <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">COOKIES</a >.
    </SpanPolitics>
  },
  signup: {
    title: 'Bienvenido',
    // description: 'Ahora eres parte del equipo. Revisa tus correos para comenzar a registrar tus latas para completar la colección. Consigue las 26 latas de Amstel Ultra y descubre los mensajes de Rafa Nadal.'
    welcome: {
      title: <>¡BIENVENIDO,<br />nombre!</>,
      subtitle: <span style={{ color: '#B98B39', textTransform: 'uppercase', fontSize: '25px', fontFamily: 'Frederick' }}>Nos falta validar tu cuenta</span>,
      description: 'Te enviamos un código de 6 dígitos a tu correo electrónico para que lo ingreses y valides tu cuenta',
      description2: ' ',
      description3: <span style={{ fontSize: '16px', fontFamily: 'Roboto', fontWeight: 500 }}>¿No te llegó ningún mail?</span>,
      "button-resend": <span style={{ fontSize: '16px', fontFamily: 'Roboto', fontWeight: 500 }}>Da clic aquí para reenviarlo.</span>
    }
  },
  start: {
    signin: 'INGRESA CON CORREO',
    signup: 'REGÍSTRATE CON CORREO',
    social: 'ENTRA CON:',
    title: {
      html: (
        <img src={`${AZURE_BASE_URL}/start.webp`} alt='Lata Miller Lite y Asador' className='mx-auto pt-6' />
      )
    }
  },
  home: {
    title: '¿Qué quieres hacer?',
    description: '¡Completa tus datos!',
    buttonNav: {
      prizes: 'CANJEAR PREMIOS'
    },
    arrowText: <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.0391 0.0859375L17.4141 6.46094L17.9297 7L17.4141 7.53906L11.0391 13.9141L9.96094 12.8359L15.0469 7.75H0V6.25H15.0469L9.96094 1.16406L11.0391 0.0859375Z" fill="#B98B39" />
    </svg>

  },
  password: {
    title: 'RESETEAR'
  },
  profile: {
    form: {
      information: {
        firstname: {
          label: '¿CÓMO TE LLAMAS?',
          placeholder: 'Pon tu nombre aquí'
        },
        lastname: {
          placeholder: 'Pon tu apellido aquí'
        },
        nickname: {
          label: 'APODO (OPCIONAL)',
          placeholder: '¿Cómo te gusta que te llamen?'
        },
        gender: {
          label: '¿Cómo te identificas?'
        },
        birthdate: {
          label: 'FECHA DE NACIMIENTO'
        },
        password: {
          label: 'CONTRASEÑA',
        },
        email: {
          label: 'CORREO ELECTRÓNICO',
        },
        address: {
          label: '¿TU DIRECCIÓN?',
          placeholder: 'Ponga su dirección aquí'
        },
        curp: {
          label: 'DOCUMENTO',
          placeholder: ''
        }
      }
    },
    information: 'DATOS PERSONALES',
    address: 'DIRECCIÓN',
    contact: 'CONTACTO',
    button: 'IR A VER LOS PREMIOS',
    identification: {
      nav: 'MI MILLER ID',
      title: 'MILLER ID',
      label: 'Mi Miller ID',
      button: 'DESCARGAR ID'
    },
    view: {
      arrow: <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.96094 0.0859375L8.03906 1.16406L2.95312 6.25H18V7.75H2.95312L8.03906 12.8359L6.96094 13.9141L0.585938 7.53906L0.0703125 7L0.585938 6.46094L6.96094 0.0859375Z" fill="#002144" />
      </svg>,
      contact: {
        notFound: "(vacío)"
      }
    }
  },
  help: {
    subtitle: 'Acá estamos para lo que necesites, checa primero en nuestras preguntas frecuentes si encuentras la respuesta.',
    textArea: {
      placeholder: 'Ingresa tu mensaje'
    }
  },
  config: {
    prizes: '/prizes'
  },
  congrats: {
    range: {
      title: 'NUEVO LOGRO DESBLOQUEADO',
      description: <></>,
      description2: (
        <>
          Felicitaciones, has desbloqueado nuevos <br /> premios y obtienes más chances de ganar.
        </>
      )
    },
    description: <>SIGUE JUNTANDO PUNTOS <br /> para llevarte increíbles premios</>
  },
  endroute: {
    title: (
      <>
        <span style={{ color: '#B98B39' }}>¡Lo sentimos!</span> <br /> Esto es solo para <br /> mayores de edad
      </>
    ),
    description: 'Vuelve cuando seas mayor de edad.'
  },
  referrals: {
    title: <> <span style={{ color: '#002144' }}> Invita a un amigo y </span> <br /> <span style={{ color: '#B98B39' }}>acumula puntos</span> </>,
    description: <>Comparte tu código con amigos y gana <br /> más oportunidades de ganar.</>,
    subtitle: 'COMPARTE TU CÓDIGO Y GANA',
    left: 'COPIAR',
    right: 'COMPARTIR'
  },
  trivias: {
    exchange: {
      button: {
        text: 'VER MAS TRIVIAS',
        url: '/trivias'
      }
    },
    "not-found": <span style={{ textTransform: 'uppercase' }}>No hay trivias <br /> disponibles</span>,
    // triviaQr: {
    //   register: {
    //     title: (
    //       <div className="text-white">
    //         CONTESTA
    //         <br />
    //         <span style={{ color: '#CCB988' }}>Y GANA</span>
    //       </div>
    //     ),
    //     description: (
    //       <div className="text-white">
    //         <p>¿Ya tienes tu MILLER ID?</p>
    //         <b className="mt-3">SI NO LO TIENES CÓRRELE A REGISTRARTE PARA PODER PARTICIPAR</b>
    //         <img
    //           className="mx-auto mt-5"
    //           src="https://promohubstorage.blob.core.windows.net/MILLER/assets/crista_id_ej.svg"
    //         />
    //       </div>
    //     )
    //   },
    //   late: {
    //     description: (
    //       <p className="text-white">
    //         Lamentablemente esta trivia ya no está disponible. Busca nuevo QR dentro de las otras dinámicas que tenemos
    //         para ti, para contestar una trivia nueva
    //       </p>
    //     )
    //   }
    // },
    questions1: <>Contesta </>,
    questions2: (
      <>{' '}
        preguntas sobre el tema y gana:
      </>
    )
  },
  survey: {
    title: 'CUÉNTANOS DE TI',
    button: 'RESPONDER'
  },
  mail: {
    title: <>¡EXCELENTE name!</>,
    description: <>Has confirmado con éxito tu cuenta.<br />Ya puedes ingresar.</>
  },
  prizes: {
    start: {
      title: <span style={{ fontFamily: 'Frederick', fontWeight: 700, fontSize: '30px' }}>MIS PREMIOS</span>
    },
    congrats: {
      description: <>Ahora atentx al mail que te vamos a mandar <br /> con toda la info del envío de tu canje <br /> ¡Felicidades!</>
    },
    description: {
      politic: <>Para más información revisa nuestros{' '} <br />
        <Link to="/terms-and-conditions" className="underline">
          Términos y condiciones.
        </Link></>
    }
  },
  redeem: {
    imageUrl: `${AZURE_BASE_URL}/redeem-welcome.jpg`,
    description2: <span style={{ color: '#002144' }}>INGRESA EL CÓDIGO QUE SE ENCUENTRA <br /> DENTRO DE LOS 12 PACK</span>
  },
  cardid: {
    bottom: '27px'
  },
  error: {
    505: {
      button: {
        link: '/trivias',
        text: 'VUELVE A INTENTARLO'
      }
    },
    1114: {
      title: 'NO HEMOS PODIDO VALIDAR TU IMAGEN'
    },
    9630: {
      title: <>¡Gracias por <br /> escribirnos!</>    }
  },
  album: {
    title: 'DINÁMICAS',
    states: {
      progress: 'ACTUALES',
      completed: 'PASADOS',
    },
    notFound: 'No hay retos disponibles por el momento, estate atento a nuestras nuevas dinámicas.',
    challenges: {
      currentChallenges: 'Retos actuales',
      currentChallenge: 'Reto actual',
      pastChallenge: <span style={{ color: 'rgba(0, 33, 68, 0.25)' }}>Reto pasado</span>,
      pastChallenges: <span style={{ color: 'rgba(0, 33, 68, 0.25)' }}>Retos pasados</span>,
      icon: (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.1562 0C8.31706 0 6.60807 0.463867 5.0293 1.3916C3.49935 2.28678 2.28678 3.49935 1.3916 5.0293C0.463867 6.60807 0 8.31706 0 10.1562C0 11.9954 0.463867 13.7044 1.3916 15.2832C2.28678 16.8132 3.49935 18.0257 5.0293 18.9209C6.60807 19.8486 8.31706 20.3125 10.1562 20.3125C11.9954 20.3125 13.7044 19.8486 15.2832 18.9209C16.8132 18.0257 18.0257 16.8132 18.9209 15.2832C19.8486 13.7044 20.3125 11.9954 20.3125 10.1562C20.3125 8.31706 19.8486 6.60807 18.9209 5.0293C18.0257 3.49935 16.8132 2.28678 15.2832 1.3916C13.7044 0.463867 11.9954 0 10.1562 0ZM10.1562 1.5625C11.7188 1.5625 13.1673 1.95312 14.502 2.73438C15.7878 3.49935 16.8132 4.52474 17.5781 5.81055C18.3594 7.14518 18.75 8.59375 18.75 10.1562C18.75 11.7188 18.3594 13.1673 17.5781 14.502C16.8132 15.7878 15.7878 16.8132 14.502 17.5781C13.1673 18.3594 11.7188 18.75 10.1562 18.75C8.59375 18.75 7.14518 18.3594 5.81055 17.5781C4.52474 16.8132 3.49935 15.7878 2.73438 14.502C1.95312 13.1673 1.5625 11.7188 1.5625 10.1562C1.5625 8.59375 1.95312 7.14518 2.73438 5.81055C3.49935 4.52474 4.52474 3.49935 5.81055 2.73438C7.14518 1.95312 8.59375 1.5625 10.1562 1.5625ZM9.375 4.6875V12.5H7.03125L10.1562 15.625L13.2812 12.5H10.9375V4.6875H9.375Z"
            fill="rgba(0, 33, 68, 0.25)"
          />
        </svg>
      )
    }
  },
  social: {
    Ticket: {
      howToParticipate: {
        subtitle: 'SUBE TU TICKET Y PARTICIPA',
        list: (
          <>
            <div className='w-full p-2 city'>
              <p>
                Los premios de este reto solo podrán ser ganados por usuarios con código postal en <b>Monterrey, Ciudad Juarez, Reynosa, Nuevo Laredo y Matamoros</b>.
              </p>
            </div>
            <ul className="flex-1 items-center justify-center flex-col flex w-full gap-7 pb-7">
              <li className="flex flex-row w-full gap-5">
                <img src={`${AZURE_BASE_URL}/social-howToParticipate-1.svg`} />
                <div className="flex-1 flex flex-col justify-center" style={{ fontSize: '15px' }}>
                  <p className="font-bold ">
                    Completa tus datos personales
                  </p>
                  <p className="font-normal">
                    en el perfil de tu cuenta.
                  </p>
                </div>
              </li>
              <li className="flex flex-row w-full gap-5">
                <img src={`${AZURE_BASE_URL}/social-howToParticipate-2.svg`} />
                <div className="flex-1 flex flex-col justify-center">
                  <p className="font-normal " style={{ fontSize: '15px' }}>
                    <b>Sube una foto de tu recibo</b> de compra donde se vea bien el detalle de lo que compraste y el lugar.
                  </p>
                </div>
              </li>
              <li className="flex flex-row w-full gap-5">
                <img src={`${AZURE_BASE_URL}/social-howToParticipate-3.svg`} />
                <div className="flex-1 flex flex-col justify-center">
                  <p className=" font-normal" style={{ fontSize: '15px' }}>
                    <b>Acumula recibos,</b> mientras más tickets cargues tendrás mayores chances de estar entre los ganadores de <span>boletos dobles para Miller Lite Grill Together</span>.
                  </p>
                </div>
              </li>
            </ul>
          </>
        )
      },
      onBoarding: {
        rules: {
          list: (
            <>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="">
                    El ticket debe ser de productos <b>Miller Lite</b> en cualquiera de sus presentaciones.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="">
                    Se debe visualizar la fecha de compra y el centro de consumo.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1 flex flex-col gap-2">
                  <p className="">
                    Los premios de la dinámica de <b>BOLETO DOBLE</b> para el festival de BBQ <b>“MILLER LITE GRILL TOGETHER”</b> podrán ser ganados únicamente por usuarios de: <b>Monterrey, Ciudad Juarez, Reynosa, Nuevo Laredo y Matamoros</b>.Ver los <Link to={'/terms-and-conditions'} className='underline'>Términos y Condiciones</Link> para más información.
                  </p>
                  <p style={{ fontSize: '10px' }}>
                    <span className='underline'>Monterrey</span>: Boleto doble a Miller Lite Grill Together.<br />
                    <span className='underline'>Cd. Juarez</span>: Boleto doble a Miller Lite Grill Together con vuelo y una noche de hospedaje incluída.<br />
                    <span className='underline'>Reynosa, Nuevo Laredo, y Matamoros</span>: Boleto doble a Miller Lite Grill Together (el ganador tiene que ir por su cuenta a Monterrey).<br />
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="">
                    No se debe cargar un ticket repetido.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="">
                    Puedes subir como máximo dos tickets cada 18hrs.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="">
                    Los tickets validados se acumularán y al finalizar el reto los ganadores serán los usuarios que hayan cargado la mayor cantidad de recibos.
                  </p>
                </div>
              </div>
            </>
          )
        }
      },
      upload: {
        buttonSubmit: 'SI, AÑADIR',
        title: 'CARGAR TICKET',
        subtitle: 'CLIC PARA CARGAR TU RECIBO',
        icon: <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M32.75 0.25H39.25V32.75H71.75V39.25H39.25V71.75H32.75V39.25H0.25V32.75H32.75V0.25Z" fill="#B98B39" />
        </svg>,
        rules: {
          title: 'REGLAS PARA SUBIR TICKETS',
          list: (
            <ul>
              <li>
                <p className="flex gap-1">
                  <span>1.</span> Usa la cámara de tu celular para tomarle una foto al recibo.
                </p>
              </li>
              <li className="my-3">
                <p className="flex gap-1">
                  <span>2.</span> Busca un lugar iluminado para tomar la foto.
                </p>
              </li>
              <li>
                <p className="flex gap-1">
                  <span>3.</span> Mantente quieto al momento de tomar la foto para obtener una imagen de buena calidad.
                </p>
              </li>
            </ul>
          )
        },
        alreadyUploaded: {
          icon: <svg width="125" height="125" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="62.084" cy="62.084" r="60.084" stroke="#34A853" strokeWidth="4" />
            <path d="M26 56L55 85L98 42" stroke="#34A853" strokeWidth="6" strokeLinecap="square" />
          </svg>,
          description: <>Se ha agregado correctamente tu recibo. Continúa cargando tus tickets para tener más chances de estar entre los ganadores.<br /><br /><b>Si no tienes tus datos de dirección completos te pedimos que los completes.</b></>,
          button: {
            url: '/album/redirect/Ticket/upload',
            text: 'CARGAR OTRO RECIBO'
          },
          button2: {
            url: '/profile',
            text: 'VAMOS A COMPLETAR MIS DATOS'
          },
          buttonBack: {
            text: 'VOLVER'
          },
        },
        howToTakePhoto: {
          title: 'IMAGEN DE REFERENCIA',
          subtitle: 'CONSEJOS para tomar una foto correcta',
          "how-take-photo-list": (
            <ul style={{ padding: 'revert', listStyle: 'disc' }}>
              <li className="">
                Busca un lugar bien iluminado para tomar la foto.
              </li>
              <li className="">
                Ubica el recibo sobre alguna superficie plana y deja el ticket lo más estirado y recto posible. Si es posible sobre un fondo limpio y sin otros elementos.
              </li>
              <li className="">
                Toma la foto asegurándote que se vean bien los artículos, la fecha y el lugar de compra.
              </li>
            </ul>
          )
        },
        errorModalValidate: {
          description: <>No podemos subir tu recibo<br />correctamente debido a:</>,
          list: <div style={{ width: '320px', color: '#002144', fontFamily: 'Roboto' }}>
            <div className="">
              <p className='ml-5'> <span className="font-bold">• Llegaste al máximo de contenido que puedes cargar en 18 hs. </span> (puedes subir máx. 2 recibos cada 18hs.)</p>
            </div>
          </div>,
          help: <div style={{ width: '320px', color: '#002144', fontFamily: 'Roboto' }} className='flex justify-center items-center flex-col mb-12'>
            <span className="font-bold text-center ">¿Te parece que no es así?</span>
            <span className="text-center ">Ponte en contacto con nosotros para que <br /> podamos ayudarte. <Link to="/help" className='underline'>Envíanos un mensaje.</Link> </span>
          </div>
        }
      },
    },
    Consumo: {
      howToParticipate: {
        subtitle: 'SUBE UNA FOTO Y PARTICIPA',
        list: (
          <>
            <div className='w-full p-2 city'>
              <p>
                Los premios de este reto solo podrán ser ganados por usuarios con código postal en <b>Monterrey</b>.
              </p>
            </div>
            <ul className="flex-1 items-center justify-center flex-col flex w-full gap-7 pb-7">
              <li className="flex flex-row w-full gap-5">
                <img src={`${AZURE_BASE_URL}/social-howToParticipate-1.svg`} />
                <div className="flex-1 flex flex-col justify-center">
                  <p className="font-bold ">
                    Completa tus datos personales
                  </p>
                  <p className="font-normal">
                    en el perfil de tu cuenta.
                  </p>
                </div>
              </li>
              <li className="flex flex-row w-full gap-5">
                <img src={`${AZURE_BASE_URL}/social-howToParticipate-4.svg`} />
                <div className="flex-1 flex flex-col justify-center">
                  <p className="font-normal ">
                    <b>Sube una foto</b> de tu cerveza Miller Lite, en lata o botella.
                  </p>
                </div>
              </li>
              <li className="flex flex-row w-full gap-5">
                <img src={`${AZURE_BASE_URL}/social-howToParticipate-3.svg`} />
                <div className="flex-1 flex flex-col justify-center">
                  <p className=" font-normal">
                    <b>Acumula fotos de tus compras,</b> mientras más fotos cargues tendrás mayores chances de estar entre los ganadores de <span>boletos dobles para Miller Lite Grill Together</span>.
                  </p>
                </div>
              </li>
            </ul>
          </>
        )
      },
      onBoarding: {
        rules: {
          list: (
            <>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="">
                    La foto debe ser de productos <b>Miller Lite</b> en cualquiera de sus presentaciones.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="">
                    En la foto no puede aparecer una mano tocando el producto o alguien bebiendo el producto.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="">
                    No pueden aparecer menores de 18 años.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="">
                    El producto debe salir siempre cerrado y en buen estado.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="">
                    Los premios de la dinámica de <b>BOLETO DOBLE</b> para el festival de BBQ <b>“MILLER LITE GRILL TOGETHER”</b> podrán ser ganados únicamente por usuarios de: <b>Monterrey</b>. Ver los <Link className='underline' to={'/terms-and-conditions'}>Términos y Condiciones</Link> para más información.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="">
                    Puedes subir como máximo dos fotos cada 18hrs.
                  </p>
                </div>
              </div>
            </>
          )
        }
      },
      upload: {
        buttonSubmit: 'SI, AÑADIR',
        title: 'CARGAR FOTO',
        subtitle: 'CLIC PARA AGREGAR UNA IMAGEN',
        icon: <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M32.75 0.25H39.25V32.75H71.75V39.25H39.25V71.75H32.75V39.25H0.25V32.75H32.75V0.25Z" fill="#B98B39" />
        </svg>,
        rules: {
          title: 'REGLAS PARA SUBIR TU FOTO',
          list: (
            <ul>
              <li>
                <p className="flex gap-1">
                  <span>1.</span> Usa la cámara de tu celular para tomarle una foto a tu cerveza.
                </p>
              </li>
              <li className="my-3">
                <p className="flex gap-1">
                  <span>2.</span> Busca un lugar iluminado para tomar la foto.
                </p>
              </li>
              <li>
                <p className="flex gap-1">
                  <span>3.</span> Mantente quieto al momento de tomar la foto para obtener una imagen de buena calidad.
                </p>
              </li>
            </ul>
          )
        },
        alreadyUploaded: {
          title: <>Imagen cargada <br /> con éxito</>,
          icon: <svg width="125" height="125" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="62.084" cy="62.084" r="60.084" stroke="#34A853" strokeWidth="4" />
            <path d="M26 56L55 85L98 42" stroke="#34A853" strokeWidth="6" strokeLinecap="square" />
          </svg>,
          description: <>Se ha agregado correctamente tu foto. Sigue cargando tus fotos para tener más chances de estar entre los ganadores.<br /><br /><b>Si no tienes tus datos de dirección completos te pedimos que los completes.</b></>,
          button: {
            url: '/album/redirect/Consumo/upload',
            text: 'CARGAR OTRA FOTO'
          },
          button2: {
            url: '/profile',
            text: 'VAMOS A COMPLETAR MIS DATOS'
          },
          buttonBack: {
            text: 'VOLVER'
          },
        },
        howToTakePhoto: {
          title: 'IMAGEN DE REFERENCIA',
          subtitle: 'CONSEJOS para tomar una foto correcta',
          "how-take-photo-img": "takePhotoImgModelConsumo.png",
          "how-take-photo-list": (
            <ul style={{ padding: 'revert', listStyle: 'disc' }}>
              <li className="">
                Intenta sacar la foto con buena iluminación y sobre un fondo lo más limpio y plano posible (que no tenga muchos objetos de fondo)
              </li>
              <li className="">
                Procura que el logo de <b>MILLER LITE</b> se vea lo mejor y más completo posible, que se pueda leer <b>MILLER LITE</b> y se vea el escudo con ornamentos.
              </li>
              <li className="">
                Intenta que la foto salga nítida, clara y bien enfocada. Que la botella o lata esté centrada y no haya otros elementos.
              </li>
            </ul>
          )
        },
        errorModalValidate: {
          description: <>No podemos subir tu contenido<br />correctamente debido a:</>,
          list: <div style={{ width: '320px', color: '#002144', fontFamily: 'Roboto' }}>
            <div className="">
              <p className='ml-5'> <span className="font-bold">• Llegaste al máximo de contenido que puedes cargar en 18 hs. </span> (puedes subir máx. 2 consumos cada 18hs.)</p>
            </div>
          </div>,
          help: <div style={{ width: '320px', color: '#002144', fontFamily: 'Roboto' }} className='flex justify-center items-center flex-col mb-12'>
            <span className="font-bold text-center ">¿Te parece que no es así?</span>
            <span className="text-center ">Ponte en contacto con nosotros para que <br /> podamos ayudarte. <Link to="/help" className='underline'>Envíanos un mensaje.</Link> </span>
          </div>
        }
      },
    },
    imgUpload: <span style={{ color: '#002144' }}>Espera mientras revisamos.</span>,
    loader: {
      title: <span style={{ color: '#002144' }}>Espera mientras revisamos.</span>
    }
  },
}
