import { SpanPolitics } from "components/birthday-input/style.custom";
import { Link } from "react-router-dom";
import { TitleStart } from "views/start/text.style";

const Arrow = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#193973" />
      <path d="M11.3594 5.39062L15.6094 9.64062L15.9531 10L15.6094 10.3594L11.3594 14.6094L10.6406 13.8906L14.0312 10.5H4V9.5H14.0312L10.6406 6.10938L11.3594 5.39062Z" fill="white" />
    </svg>
  )
}

export const amstel = {

  legalage: {
    title: '¡BIENVENIDO!'
  },
  agegate: {
    title: <></>,
    description: <>
      <h1 className='BebasNeuePro font-bold text-center' style={{ color: '#193973', fontSize: '50px' }}>¡BIENVENIDO!</h1>
      <img src={`${AZURE_BASE_URL}/agegate.svg`} alt='bienvenida agegate' className='mx-auto w-full max-w-md -mb-5' />
      <p className='dinPro text-3xl font-normal text-center normal-case px-2'>¿ERES MAYOR DE EDAD?</p>
    </>,
    politics1: <span className='hidden'></span>,
    politics2: <SpanPolitics>
      Al confirmar, aceptas la <a href={`${process.env.PUBLIC_URL}/privacy-politics`} rel="noreferrer" target="_blank" className="underline">política de privacidad</a >, <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">términos yt condiciones y uso de cookies</a > de este sitio web. Este sitio web utiliza cookies. Para obtener más información, lee nuestra <a href={`${process.env.PUBLIC_URL}/privacy-politics`} rel="noreferrer" target="_blank" className="underline">política de privacidad</a > y uso de <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">cookies</a >.
    </SpanPolitics>
  },
  signup: {
    title: 'Bienvenido',
    description: 'Ahora eres parte del equipo. Revisa tus correos para comenzar a registrar tus latas para completar la colección. Consigue las 26 latas de Amstel Ultra y descubre los mensajes de Rafa Nadal.',
    welcome: {
      title: <>¡Bienvenido, nombre!</>,
      subtitle: ' ',
      description: <>Te enviamos un código de 6 dígitos a tu correo <br /> electrónico para que valides tu cuenta.</>,
      description2: ' '
    }
  },
  start: {
    signup: 'REGISTRARSE CON CORREO',
    signin: 'INGRESAR CON CORREO',
    social: 'ENTRAR CON:',
    title: {
      html:
        <div className="flex-1 w-full py-2 flex flex-col items-center justify-center" style={{ backgroundImage: `url(${AZURE_BASE_URL}/textura-inicial.png)` }}>
          <img src={`${AZURE_BASE_URL}/fondo-inicial.webp`} className="mx-auto" alt="amstel" />
          <TitleStart className="text-center uppercase mb-2">
            ¡PARTICIPA Y GANA!
          </TitleStart>
        </div>
    }
  },
  home: {
    title: <span className="font-bold" style={{ color: 'rgba(208, 155, 52, 1)' }}>¿QUÉ QUIERES HACER?</span>
  },
  profile: {
    information: 'DATOS PERSONALES',
    address: 'DIRECCIÓN',
    contact: 'CONTACTO',
    button: 'IR A VER LOS PREMIOS',
    form: {
      information: {
        firstname: {
          placeholder: 'Pon tu nombre aquí'
        },
        lastname: {
          placeholder: 'Pon tu apellido aquí'
        },
        nickname: {
          label: 'TU APODO (OPCIONAL)',
          placeholder: '¿Cómo te gusta que te llamen?'
        },
        email: {
          label: 'E-MAIL'
        }
      }
    },
    identification: {
      label: 'Mi AMSTEL ID',
      nav: 'Mi Amstel ID',
      button: 'Descargar AMSTEL ID'
    },
    view: {
      contact: {
        phoneTitle: ' ',
        notFound: '(vacío)'
      },
      history: {
        acquired: 'canjeado'
      }
    },
    stateNotFound: <>PARECE QUE AÚN NO HAS CANJEADO <br /> NINGÚN PREMIO <br /><br /> ¡Venga, tú puedes!</>,
  },
  config: {
    prizes: '/prizes'
  },
  congrats: {
    range: {
      title: "NUEVO LOGRO DESBLOQUEADO",
      description: <></>,
      description2: <>Felicitaciones, has desbloqueado nuevos <br /> premios y obtienes más chances de ganar.</>
    }
  },
  prizes: {
    start: {
      title: 'PREMIOS'
    },
    congrats: {
      description: <>Ahora atentx al mail que te vamos a mandar <br /> con toda la info del envío de tu canje <br /> ¡Felicidades!</>
    },
    stockName: 'DISPONIBLES',
    btn: {
      text: 'Canjear',
      cantRedeem: 'Todavía no puedes canjear'
    },
    exchange: {
      warningPopupText: <>Estás a punto de canjear costInPoints pointsName por un <br /> prizeName. <br /></>
    }
  },
  trivias: {
    questions1: <>Contesta </>,
    questions2: <> <span className="font-bold" style={{ color: '#D09B34' }}>preguntas</span> sobre el tema y gana:</>,
    congrats: '¡Felicidades ',
    'not-found': <span className="din font-bold text-2xl" style={{ color: '#193973' }}>¡Ya tenemos ganadxr!</span>,
    exchange: {
      button: {
        text: 'VAMOS POR MI VIAJE A PARÍS',
        url: '/prizes'
      }
    }
  },
  error: {
    505: {
      title: '¡QUE PENA!',
      description: <><b>Fallaste</b> y tenías 1 sola chance para contestar bien. ¡Mejor suerte la próxima!</>
    },
    153: {
      title: <>POR FAVOR COMPLETA <br /> tu dirección</>,
      description: <>Debemos validar tu dirección para mostrarte <br /> los premios disponibles cerca de ti.</>,
      button: {
        text: 'Actualizar mi dirección',
      }
    },
    1114: {
      title: <>No hemos podido <br /> validar tu imagen</>
    },
    71: {
      title: '¡UPS! Ha expirado el código OTP',
      description: <>Dale click en <b>REENVIAR</b> para recibir otro código e intentarlo de nuevo.</>
    },
    9630: {
      title: <>¡GRACIAS POR <br /> ESCRIBIRNOS! <br /> RECIBIMOS TU MENSAJE</>
    },
    59: {
      title: 'Por ahora no contamos con experiencias cerca de tu domicilio, ¡esperamos tener nuevas experiencias pronto!'
    },
    150: {
      title: 'Por ahora no contamos con experiencias cerca de tu domicilio, ¡esperamos tener nuevas experiencias pronto!'
    },
    252: {
      title: 'Por ahora no contamos con experiencias cerca de tu domicilio, ¡esperamos tener nuevas experiencias pronto!'
    },
  },
  cardid: {
    bottom: '48px'
  },
  album: {
    title: 'Retos',
    notFound: 'No hay retos disponibles por el momento, estate atento a nuestras nuevas dinámicas.',
  },
  social: {
    Ticket: {
      howToParticipate: {
        subtitle: 'SUBE TU TICKET Y PARTICIPA',
        list: (
          <ul className="flex-1 items-center justify-center flex-col flex w-full gap-7 pb-7">
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-1.svg`} style={{ maxWidth: '50px' }} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-bold text-lg dinPro leading-6" style={{ color: '#193973' }}>
                  Completa tu perfil
                </p>
                <p className="font-normal text-base leading-5 dinPro" style={{ color: '#193973' }}>
                  Termina de llenar tus datos personales en <b>“Mi información”</b>.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-2.svg`} style={{ maxWidth: '50px' }} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-normal text-lg dinPro leading-6" style={{ color: '#193973' }}>
                  <b>Sube una foto de tu ticket</b> donde se vea bien el detalle de cualquier producto AMSTEL ULTRA que hayas comprado en las tiendas SORIANA.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-4.svg`} style={{ maxWidth: '50px' }} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="text-lg dinPro leading-6 font-normal" style={{ color: '#193973' }}>
                  <b>Acumula tickets subidos </b> Mientras más tickets subas, mayores chances de ganar tendrás.
                  Los ganadores serán aquellos que tengan más tickets acumulados al finalizar la dinámica.
                </p>
              </div>
            </li>
          </ul>
        )
      },
      onBoarding: {
        rules: {
          list: (
            <>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal dinPro text-sm" style={{ color: '#193973' }}>
                    La foto debe ser de un ticket en formato físico.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal dinPro text-sm" style={{ color: '#193973' }}>
                    El ticket debe ser de productos <b>Amstel Ultra</b> en cualquiera de sus presentaciones.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal dinPro text-sm" style={{ color: '#193973' }}>
                    Los tickets que participan tienen que ser <b>únicamente</b> de compras realizadas en la cadena de supermercados <b>Soriana</b> (a nivel nacional).
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal dinPro text-sm" style={{ color: '#193973' }}>
                    Se debe visualizar la fecha de compra.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal dinPro text-sm" style={{ color: '#193973' }}>
                    No se debe subir un ticket repetido.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal dinPro text-sm" style={{ color: '#193973' }}>
                    Ganarán las 200 personas que hayan acumulado la mayor cantidad de tickets registrados en el tiempo de duración de la dinámica.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal dinPro text-sm" style={{ color: '#193973' }}>
                    Cada uno de los 200 ganadores de la dinámica ganará un frigobar.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal dinPro text-sm" style={{ color: '#193973' }}>
                    Puedes subir como máximo 2 tickets cada 24 hs.
                  </p>
                </div>
              </div>
            </>
          )
        }
      },
      upload: {
        buttonSubmit: 'Elegir y continuar',
        title: 'SUBIR TICKET',
        subtitle: 'Clic para agregar tu ticket',
        rules: {
          title: 'REGLAS PARA SUBIR TICKETS',
          list: (
            <ul style={{ color: '#7C7C7C' }}>
              <li>
                <p className="flex gap-1 dinPro text-sm">
                  <span>1.</span> Busca en las imágenes de tu dispositivo la foto del recibo que quieres subir.
                </p>
              </li>
              <li className="my-3">
                <p className="flex gap-1 dinPro text-sm">
                  <span>2.</span> Asegúrate de que el recibo tenga la información importante de forma legible.
                </p>
              </li>
              <li>
                <p className="flex gap-1 dinPro text-sm">
                  <span>3.</span> Intenta que la foto del recibo esté bien encuadrada y sin otros elementos.
                </p>
              </li>
            </ul>
          ),
          back: 'NO, VOLVER'
        },
        'ranking-subtitle': {
          progress: <>Este es el ranking con las historias más votadas. <br /> Dale clic a la que más te guste para votarla.</>,
          completed: <>Este reto ya ha finalizado y no se puede votar, <br /> pero puedes leer las historias ganadoras.</>
        },
        alreadyUploaded: {
          title: '¡Eso NAME!',
          description: 'Sigue subiendo tus tickets para tener mayores chances de ganar.',
          button: {
            url: '/profile/ranking',
            text: 'Ver mis Tickets acumulados'
          },
          icon: <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="45" cy="45" r="43" stroke="#D09B34" strokeWidth="4" />
            <path d="M24.6582 42.7867L41.7725 60.1602L71.047 30.4424" stroke="#D09B34" strokeWidth="8" />
          </svg>,
          buttonBack: {
            text: 'Quiero subir otro recibo'
          },
          button2: {
            url: '/album/redirect/Ticket/upload',
            text: 'Quiero subir otro recibo'
          }
        },
        errorModalValidate: {
          description: <>Aún no puedes subir un nuevo ticket<br />por las siguientes razones:</>,
          list: <div style={{ width: '320px' }}>
            <div className="dinPro text-base font-normal" style={{ color: '#193973' }}>
              <p className='ml-5'> <span className="font-bold">• Llegaste al máximo de tickets que puedes cargar en 24 hrs. </span> (puedes subir máx. 2 tickets cada 24 horas.)</p>
            </div>
          </div>,
          help: <div style={{ width: '320px' }} className='flex justify-center items-center flex-col mb-12 dinPro'>
            <span className="font-bold  text-center text-sm dinPro" style={{ color: '#193973' }}>¿Te parece que no es así?</span>
            <span className="text-center  text-sm dinPro" style={{ color: '#193973' }}>Ponte en contacto con nosotros para que podamos<br />ayudarte. <Link to="/help" className='underline'>Mándanos un mensaje.</Link> </span>
          </div>
        },
        icon: <svg width="87" height="84" viewBox="0 0 87 84" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="41.4114" y="12.8008" width="3.44461" height="57.2" fill="#193973" />
          <rect x="12.7058" y="43.0195" width="3.23774" height="60.8548" transform="rotate(-90 12.7058 43.0195)" fill="#193973" />
        </svg>,
        howToTakePhoto: {
          title: "Imagen de referencia",
          subtitle: <>
            <h2>CONSEJOS para cargar una foto correcta</h2>

            <ul>
              <li className="flex gap-1">
                <span>•</span> <p>Busca una foto del recibo que esté bien iluminada.</p>
              </li>

              <li className="flex gap-1">
                <span>•</span> <p>Procura que el recibo esté lo más plano, estirado y recto posible. Si es posible sobre un fondo limpio y sin otros elementos.</p>
              </li>

              <li className="flex gap-1">
                <span>•</span> <p>Asegurándote que se vean bien los artículos, la fecha y el lugar de compra y/o logo de Soriana.</p>
              </li>
            </ul>
          </>
        },
        isReady: 'Seleccionar imagen',
        buttonCancel: 'No, Volver'
      },
      congrats: {
        description: 'Sigue subiendo tus tickets y gana increíbles premios.',
        button2: 'QUIERO INGRESAR OTRA RECIBO',
        button1: 'VER MIS PUNTOS',
        'button2Link': '/album/redirect/Ticket/upload',
      },
      config: {
        prizes: '/profile/movements'
      }
    },
    imgUpload: 'Espera mientras revisamos tu imagen.',
    loader: {
      title: <>Estamos validando <br /> tu ticket</>
    }
  },
  mail: {
    title: <>¡Excelente, name!</>
  },
  otp: {
    received: "¿No la recibiste?",
    resend: "Reenviar código"
  },
  help: {
    subtitle: 'Acá estamos para lo que necesites, checa primero en nuestras preguntas frecuentes si encuetras la respuesta.',
    subtitleMessage: <>No te preocupes, déjanos un mensaje <br /> para poderte ayudar mejor</>,
    textArea: {
      placeholder: 'Deja tus comentarios aquí'
    }
  },
  endroute: {
    title: (
      <>
        ¡Lo sentimos! <br /> Esto es solo para mayores de edad
      </>
    )
  },
  survey: {
    button: 'FINALIZAR'
  }
}