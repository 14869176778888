import { PRIVACYPOLITICSMX, PRIVACYPOLITICSBACK } from 'constants/api'
import useAxios from 'axios-hooks'
import LoadingScreen from 'components/loading-screen'
import styled from 'styled-components'
import { Type } from 'theme/types'
import ErrorView from 'components/error-view'
import useRouter from 'hooks/use-router'
import useMainClass from 'hooks/use-main-cass'
import stylespp from './stylepp.module.scss'

const Div = styled.div`
  ${({ theme }: Type) => theme?.termsAndConditions?.div}
`

const PrivacyPolitics = () => {
  const { push } = useRouter()
  useMainClass('privacy-politics')
  const fetchURL = THEME_CONFIG.privacyPolitics !== 'MX' ? PRIVACYPOLITICSBACK : PRIVACYPOLITICSMX
  const [{ loading, error, data }] = useAxios({
    url: fetchURL,
    headers: { 'Ph-Api-Id': PH_API_ID??1 }
  })

  if (error) {
    return <ErrorView code={9999} buttonMenu={() => push('/home')} />
  }

  if (loading) {
    return <LoadingScreen />
  }

  if (data) {
    const body = data.replace("{", "").replace('"body":"', '').replace('";', '').replace('}', '');
    return <Div className={`${stylespp.wrapperpp} container-priv`}>
      <div dangerouslySetInnerHTML={{ __html: body }}></div>
    </Div>
  }

  return <Div className={stylespp.wrapperpp}>
    <p>Cargando...</p>
  </Div>
}

export default PrivacyPolitics
