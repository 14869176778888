import { AxiosError } from "axios";
import FormPhone from "components/form-phone";
import { LoaderContext } from "contexts/loader-context";
import { useSession } from "contexts/session-context";
import useRouter from "hooks/use-router";
import { useContext } from "react";
import ProfileService from "services/profile-services";

function ProfilePhoneUpdate() {

  const [{ user, data: session }, { setUser }] = useSession()
  const { setLoad } = useContext(LoaderContext)
  const { push } = useRouter()

  const onSubmit = async (data, setError) => {
    setLoad(true)
    try {
      const response = (!THEME_CONFIG?.profile?.phone?.useDirectPhone ? await ProfileService.otpVerifyPhone({ code: data.otp, session }) : await ProfileService.updatePhone({ phoneNumber: data.phoneNumber, session }))
      if (response?.status === 200) {
        setUser(response.data)
        push('/profile/phone')
      }
    } catch (err) {
      const typedError = err as AxiosError
      const { code } = typedError.response?.data || {}
      setError(code)
    } finally {
      setLoad(false)
    }
  }

  return (
    <div className="mt-7 flex flex-col w-full">
      <FormPhone handleSubmit={onSubmit} user={user} />
    </div>
  );
}

export default ProfilePhoneUpdate;