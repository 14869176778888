import { FAQS } from 'constants/api'
import useAxios from 'axios-hooks'
import useMainClass from 'hooks/use-main-cass'
import Input from 'components/input'
import LoadingScreen from 'components/loading-screen'
import { useRef } from 'react'
import Collapsible from 'react-collapsible'
import useRouter from 'hooks/use-router'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import ErrorView from 'components/error-view'
import textServices from 'services/text-services'
import { useSession } from '../../contexts/session-context'
import {
  DivTextHelp,
  AreaTextHelp,
  DivBgHelp,
  TitleHelp,
  SubtitleHelp,
  ContactTitle,
  ContactSubtitle,
  CollapsibleStyle,
  SectionHelp,
  ButtonHelp,
  CollapsibleStyleP
} from '../../theme/help'
import style from './help.module.scss'

const Help = () => {
  useMainClass('help')
  const session = useSession()
  const [{ loading: loadingFaqs, error: errorFaqs, data: faqs }] = useAxios({ url: FAQS, headers: { 'Ph-Api-Id': PH_API_ID ?? 1 } })
  const { history } = useRouter()
  const [{ user, data = {} as any }] = session
  const { phoneNumber } = user || {}
  const [{ response, loading, error }, fetch] = useAxios(
    {
      method: 'POST',
      url: 'https://' + API_BASE_USER_UPDATE + '/promohub/api/v1/profiles/send/contact',
      headers: { Authorization: `Bearer ${data?._tokenResponse?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 }
    },
    { manual: true }
  )

  const phoneRef = useRef<HTMLInputElement>(null)
  const textRef = useRef<HTMLTextAreaElement>(null)

  const submitHandler = async (e: any) => {
    e.preventDefault()
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'HELP - Contactanos', value: '10' })
    const data = {
      phoneNumber: phoneRef.current?.value,
      message: textRef.current?.value
    }

    if (!phoneRef.current?.value) {
      alert('se deben completar ambos campos')
    }

    if (!textRef.current?.value) {
      alert('se deben completar ambos campos')
    }

    await fetch({ data })
  }

  const messageSuccess = !(error && loading) && !!response

  if (messageSuccess) {
    // Cambiar nombre de componente
    return <ErrorView buttonMenu={() => history.push('/home')} code={9630} />
  }

  if (errorFaqs || error) {
    return <ErrorView buttonMenu={() => history.push('/home')} code={9999} />
  }

  if (loadingFaqs || loading) {
    return <LoadingScreen />
  }

  return (
    <SectionHelp className="flex flex-col w-full p-7 pb-0 sm:p-0 items-center help__container-main">
      <DivTextHelp className="space-y-4 pb-2">
        <TitleHelp className="uppercase text-3xl text-center font-bold help__title-faq">¿Tienes algún problema?</TitleHelp>
        <SubtitleHelp className="din uppercase text-center help__subtitle-faq">
          {textServices.findByText(
            'help/subtitle',
            'Revisa primero en nuestras preguntas frecuentes, si no encuentras la respuesta, contáctanos.'
          )}
        </SubtitleHelp>
        <CollapsibleStyle className='help__collapsible'>
          {faqs.map((faq: any) => (
            <div className={style.collapsible} key={faq.title}>
              <Collapsible
                trigger={faq.title}
                className='help__collapsible-cnt'
                onOpen={() => {
                  dlTrackEvent('user_interaction', {
                    action: 'click',
                    element: faq.title,
                    section: `help`
                  })
                }}
              >
                <CollapsibleStyleP className='help__collapsible-text' dangerouslySetInnerHTML={{ __html: faq.content }}></CollapsibleStyleP>
              </Collapsible>
            </div>
          ))}
        </CollapsibleStyle>
      </DivTextHelp>
      <DivBgHelp className="w-screen flex-1 flex justify-center pt-4 min-h-screen p-7 help__area-container">
        <div className="flex flex-col w-full sm:max-w-sm space-y-6">
          <ContactTitle className="uppercase text-3xl text-center font-bold pt-2 help__title-doubt">
            {textServices.findByText('help/title', '¿más dudas?')}
          </ContactTitle>
          <ContactSubtitle className="din uppercase text-center help__subtitle-doubt">
            {textServices.findByText('help/subtitleMessage', 'No te preocupes, déjanos un mensaje para solucionarlo.')}
          </ContactSubtitle>
          <form className="flex flex-1 flex-col space-y-4 input-container" onSubmit={submitHandler}>
            <Input
              type="tel"
              placeholder="Ingresa tu teléfono"
              className="text-center din text-sm font-bold help__input"
              ref={phoneRef}
              defaultValue={phoneNumber}
              maxLength={13}
              required
              onBlur={() => {
                dlTrackEvent('form_interaction', {
                  element: 'enviar mensaje',
                  form_name: 'mas dudas',
                  form_field: 'ingresa tu telefono',
                  form_action: 'empty',
                  section: `help`
                })
              }}
            />
            <AreaTextHelp
              ref={textRef}
              className="flex-1 uppercase din text-sm font-bold p-4 focus:outline-none help__textarea"
              placeholder={textServices.findByText('help/textArea/placeholder', 'Dinos, ¿para qué somos buenos?')}
              required
              onBlur={(e) => {
                dlTrackEvent('form_interaction', {
                  element: 'enviar mensaje',
                  form_name: 'mas dudas',
                  form_field: 'tus dudas',
                  form_action: e.target.value ? 'complete' : 'empty',
                  section: `help`
                })
              }}
            ></AreaTextHelp>
            <ButtonHelp
              className='help__button'
              onClick={() => {
                dlTrackEvent('form_send', {
                  element: 'enviar mensaje',
                  form_name: 'mas dudas',
                  form_field: 'faqs',
                  section: `help`
                })
              }}
            >
              ENVIAR MENSAJE
            </ButtonHelp>
          </form>
        </div>
      </DivBgHelp>
    </SectionHelp>
  )
}

export default Help
