import { VoidFunctionComponent } from 'react'
import Button from 'components/button'
import ButtonCloseMenu from 'components/button-close'
import { useHistory } from 'react-router-dom'
import useMainClass from 'hooks/use-main-cass'
import textServices from 'services/text-services'
import { useTrivia } from 'contexts/trivia-context'
// import { TriviaItem } from '../types'
import { DivModalContinue, ButtonModalContinue, TriviaModalH1, TriviaModelP, TriviaModalWarning } from '../../../theme/trivias'

export interface TriviaModalProps {
  onAccept: () => void
}

const TriviaModal: VoidFunctionComponent<TriviaModalProps> = ({ onAccept }) => {
  useMainClass('trivias__popup__intro')

  const history = useHistory()
  const [{ currentTrivia }] = useTrivia()

  return (
    <DivModalContinue className="flex flex-col w-full p-8 min-h-view">
      <ButtonCloseMenu paramFn={history.goBack} open={true} />
      <div className="flex-1 flex items-center justify-center flex-col gap-4 popup__intro-body">
        {currentTrivia?.imageUrl ? <img src={currentTrivia?.imageUrl ?? ''} alt={currentTrivia?.title} className='popup__intro-img' /> : <></>}
        <TriviaModalH1 className="uppercase text-3xl text-center font-bold popup__intro-title">{currentTrivia?.title}</TriviaModalH1>
        <TriviaModelP className="din text-center popup__intro-description">
          {currentTrivia?.costInPoints > 0 && (
            <>Estás a punto de canjear {currentTrivia?.costInPoints} {POINTS_NAME} para participar en esta trivia y ganar:</>
          )}
          {currentTrivia?.costInPoints === 0 && (
            <>Estás a punto de participar en esta trivia y ganar:</>
          )}
          <b> {currentTrivia?.prize}</b>
        </TriviaModelP>
        <TriviaModalWarning className="din text-center popup__intro-warning">
          {textServices.findByText('tivias/challenge/modal/description', '¿Segurísimo que quieres jugarla? Recuerda que una vez que des clic no hay vuelta atrás.')}
        </TriviaModalWarning>
      </div>

      <div className="flex justify-center flex-col pt-3">
        <Button onClick={onAccept}>
          {textServices.findByText('tivias/challenge/modal/button', 'Sí, claro')}
        </Button>

        <ButtonModalContinue className="uppercase underline text-xs din text-center mt-4 button-back" onClick={history.goBack}>
          {textServices.findByText('tivias/challenge/modal/back', 'No, volver')}
        </ButtonModalContinue>
      </div>
    </DivModalContinue>
  )
}

export default TriviaModal
