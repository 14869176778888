import { Link } from 'react-router-dom'
import Button from 'components/button'
import textServices from 'services/text-services'

function Guest() {

  return (
    <Link
      to={textServices.findByText('config/prizes', '/prizes')}
      className="w-full sm:w-72"
      onClick={() => {
        gtag('event', 'Click', {
          event_category: 'CódigoTecate',
          event_label: 'Ingresar como invitado',
          value: '10'
        })
      }}
    >
      <Button className="w-full uppercase guest" style={{ backgroundColor: '#C4C4C4' }}>
        {textServices.findByText('start/guest', 'ME DOY UNA VUELTA COMO INVITADO')}
      </Button>
    </Link>
  )
}

export default Guest
