import { Type } from 'theme/types';
import styled from "styled-components";

// MAIN PAGE
export const ContainerTitlePage = styled.div`
    ${({ theme }: Type) => theme?.memorama?.list?.containerTitle?.containerMain}
`

export const ContainerTitlePageText = styled.h1`
    ${({ theme }: Type) => theme?.memorama?.list?.containerTitle?.title}
`

export const NotFoundText = styled.p`
    ${({ theme }: Type) => theme?.memorama?.list?.notFoundText}
`

// LIST COMPONENT
export const ContainerMain = styled.section`
  ${({ theme }: Type) => theme?.memorama?.list?.containerMain}
`

export const ContainerTop = styled.div`
    ${({ theme }: Type) => theme?.memorama?.list?.containerTop?.containerMain}
`

export const ContainerTitle = styled.div`
    ${({ theme }: Type) => theme?.memorama?.list?.containerTop?.containerTitle}
`

export const ContainerTitleText = styled.h2`
    ${({ theme }: Type) => theme?.memorama?.list?.containerTop?.title}
`

export const ContainerSeparator = styled.div`
    ${({ theme }: Type) => theme?.memorama?.list?.containerTop?.containerSeparator}
`

export const ContainerSubtitle = styled.div`
    ${({ theme }: Type) => theme?.memorama?.list?.containerTop?.containerSubtitle}
`

export const ContainerSubtitleText = styled.p`
    ${({ theme }: Type) => theme?.memorama?.list?.containerTop?.subtitle}
`

export const ContainerCenter = styled.div`
    ${({ theme }: Type) => theme?.memorama?.list?.containerCenter?.containerMain}
`

export const CenterTextParticipate = styled.p`
    ${({ theme }: Type) => theme?.memorama?.list?.containerCenter?.title}
`

export const CenterTextName = styled.p`
    ${({ theme }: Type) => theme?.memorama?.list?.containerCenter?.memoramaName}
`

export const CenterStock = styled.p`
    ${({ theme }: Type) => theme?.memorama?.list?.containerCenter?.centerStock}
`

export const ContainerBottom = styled.div`
    ${({ theme }: Type) => theme?.memorama?.list?.containerBottom?.containerMain}
`  

export const BottomStart = styled.p`
    ${({ theme }: Type) => theme?.memorama?.list?.containerBottom?.countdownText}
`

export const BottomStartLeft = styled.p`
    ${({ theme }: Type) => theme?.memorama?.list?.containerBottom?.prize}
`

export const BottomStartLeftPoints = styled.p`
    ${({ theme }: Type) => theme?.memorama?.list?.containerBottom?.points}
`

export const BottomStartHours = styled.span`
    ${({ theme }: Type) => theme?.memorama?.list?.containerBottom?.countdownTextSpan}
`

// MODAL COMPONENT START

export const ContainerModal = styled.div`
    ${({ theme }: Type) => theme?.memorama?.list?.containerModal?.containerMain}
`

export const TitleName = styled.div`
    ${({ theme }: Type) => theme?.memorama?.list?.containerModal?.titleName}
`

export const BackButton = styled.p`
    ${({ theme }: Type) => theme?.memorama?.list?.containerModal?.backButton}
`

export const GenericText = styled.p`
    ${({ theme }: Type) => theme?.memorama?.list?.containerModal?.genericText}
`

// MODAL COMPONENT PLAY

export const ModalPlayTitle = styled.h1`
    ${({ theme }: Type) => theme?.memorama?.list?.modalPlay?.title}
`

export const ModalPlaySubtitle = styled.h3`
    ${({ theme }: Type) => theme?.memorama?.list?.modalPlay?.subtitle}
`

export const ModalPlayDescription = styled.p`
    ${({ theme }: Type) => theme?.memorama?.list?.modalPlay?.description}
`

export const ModalPlayWarning = styled.p`
    ${({ theme }: Type) => theme?.memorama?.list?.modalPlay?.warning}
`

