import textServices from 'services/text-services'
import { Back } from '../../../theme/theme'

function BackgroundImage() {
  return (
    <Back className={`flex flex-col flex-1 w-full pt-24 justify-center sm:items-center`}>
      <div className="w-full h-full flex items-center justify-center bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${`${AZURE_BASE_URL}/fondo-inicial.svg`})` }}>
        <h1 className="text-4xl font-normal saira text-center ">
          {textServices.findByText('start/backgroundimage/title', <>¿Listo para ser <br /> parte del equipo? <br /> <span className="font-semibold">¡Regístrate!</span></>)}
        </h1>
      </div>
    </Back>
  )
}

export default BackgroundImage
