import { SpanPolitics } from "components/birthday-input/style.custom";
import { SubTitleAge } from "components/legal-age/components/style/text";
import { Link } from "react-router-dom";

const Arrow = () => {
  return (
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.51074 0.43457L8.72949 7.65332L9.18066 8.125L8.72949 8.59668L1.51074 15.8154L0.567383 14.8721L7.31445 8.125L0.567383 1.37793L1.51074 0.43457Z" fill="black" />
    </svg>

  )
}

export const mixx = {
  legalage: {
    title: ' '
  },
  agegate: {
    title: '¡BIENVENIDO!',
    description: <div className="w-full flex flex-col items-center justify-center gap-3">
      <img className="text-white" src={`${AZURE_BASE_URL}/agegate.png`} alt="Logo Mixx" />
      <SubTitleAge className="text-center">¿Eres mayor de edad?</SubTitleAge>
    </div>,
    politics1: <span className='hidden'></span>,
    politics2: <SpanPolitics className="uppercase">
      Al confirmar, aceptas la <a href={`${process.env.PUBLIC_URL}/privacy-politics`} rel="noreferrer" target="_blank" className="underline">política de privacidad</a >, <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">términos yt condiciones y uso de cookies</a > de este sitio web. Este sitio web utiliza cookies. Para obtener más información, lee nuestra <a href={`${process.env.PUBLIC_URL}/privacy-politics`} rel="noreferrer" target="_blank" className="underline">política de privacidad</a > y uso de <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">cookies</a >.
    </SpanPolitics>
  },
  endroute: {
    title: (
      <>
        ¡Lo sentimos! <br />
        Esto es sólo para <br />
        mayores de edad
      </>
    ),
    description: 'Regresa cuando seas más grande.'
  },
  login: {
    exchange: {
      title: 'Excelente,',
      button: {
        text: 'IR A COMPRAR LOS BOLETOS'
      }
    }
  },
  signup: {
    title: 'Bienvenido',
    welcome: {
      title: <>¡Bienvenidx, <br /> nombre!</>,
      description: <span className="font-bold" style={{
        fontFamily: 'Industry-Demi'
      }}>
        <span className="font-normal industry-book">Te enviamos un código de 6 dígitos <br /> a tu correo electrónico</span>
        <br /> EMAIL <br />
        <span className="font-normal industry-book">para que lo ingreses y valides tu cuenta</span>
      </span>,
      description2: ' ',
      description3: <span className="industry font-bold">¿No lo recibiste?</span>,
      otp: {
        sendpassword: <><span className="industry-book font-normal">Una contraseña de un solo uso se envió a </span>EMAIL</>
      }
    }
  },
  password: {
    label: {
      newPassword: 'NUEVA CONTRASEÑA',
      newPasswordConfirm: 'REPETIR NUEVA CONTRASEÑA'
    }
  },
  start: {
    signup: 'REGÍSTRATE CON CORREO',
    signin: 'ENTRA CON CORREO',
    social: 'ENTRA CON',
    title: {
      html:
        <div className="flex-1 w-full py-2 flex flex-col items-center justify-center">
          <p className="text-white industry text-lg">¡PARTICIPA Y GANA!</p>
          <img src={`${AZURE_BASE_URL}/fondo-inicial.png`} className="w-full max-w-sm" alt="background" />
        </div>
    }
  },
  home: {
    title: <span className="industry text-xl" style={{ color: '#FFFFFF', fontWeight: 800 }}>¿QUÉ quieres hacer?</span>,
    description: <span className="industry" style={{ color: '#FFFFFF', fontWeight: 400 }}>¡COMPLETA TUS DATOS!</span>,
    arrowText: <Link to="/profile">IR A MI INFORMACIÓN</Link>,
    arrowTextPoints: <Link to="/profile/movements">VER PUNTOS</Link>,
    pointsText: <><span className="text-white">TIENES</span> TOTAL_POINTS POINTS_NAME</>
  },
  profile: {
    form: {
      information: {
        firstname: {
          label: '¿cómo te llamas?',
          placeholder: 'Pon tu nombre aquí'
        },
        lastname: {
          placeholder: 'Pon tu apellido aquí',
        },
        nickname: {
          label: 'apodo (opcional)',
          placeholder: '¿Cómo te gusta que te llamen?'
        },
        gender: {
          label: '¿CÓMO TE IDENTIFICAS?'
        },
        email: {
          placeholder: 'Pon tu correo aquí'
        }
      }
    },
    information: 'DATOS PERSONALES',
    address: 'DIRECCIÓN',
    contact: 'CONTACTO',
    button: 'ver mi dos equis id',
    identification: {
      nav: 'MI DOSEQUISID',
      title: 'MI DOSEQUISID',
      button: 'DESCARGAR ID',
      label: 'Mi DOSEQUISID'
    },
    view: {
      contact: {
        phoneTitle: ' ',
        notFound: '(vacío)'
      },
      arrow: <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.28125 0.78125L10.7188 2.21875L3.9375 9H24V11H3.9375L10.7188 17.7812L9.28125 19.2188L0.78125 10.7188L0.09375 10L0.78125 9.28125L9.28125 0.78125Z" fill="white" />
      </svg>,
      history: {
        acquired: 'CANJEADO'
      }
    }
  },
  config: {
    prizes: '/profile/identificacion'
  },
  congrats: {
    range: {
      title: "NUEVO LOGRO DESBLOQUEADO",
      description: <></>,
      description2: <>Felicitaciones, has desbloqueado nuevos <br /> premios y obtienes más chances de ganar.</>
    }
  },
  referrals: {
    description: 'Comparte tu código con amigos y gana más oportunidades de ganar.',
    subtitle: 'COMPARTE TU CÓDIGO Y GANA',
    left: 'COPIAR',
    right: 'COMPARTIR'
  },
  trivias: {
    congrats: '¡FELICIDADES',
    exit: {
      title: '¿Estás segurx que deseas salir de la trivia?',
      subtitle: 'Si cancelas ahora se perderá tu progreso y tendrás que volver a empezar.',
      cancel: 'No, volver'
    },
    exchange: {
      button: {
        text: 'VER PREMIOS',
        url: '/prizes'
      }
    },
    triviaQr: {
      register: {
        title: <>CONTESTA Y GANA</>,
        description: <><p>¿YA TIENES TU ID?</p><span className="industry-book font-normal">Si no lo tienes córrele a registrarte para poder participar.</span></>,
        typeImage: 'png'
      },
      late: {
        // title: (user: UserData) => `¡QUE PENA ${user?.firstName}!`,
        title: <>NO DISPONIBLE </>,
        description: <span className="font-normal">
          Lamentablemente este reto ya no está disponible. Estate atentx para contestar uno nuevo.
        </span>,
        button: {
          text: 'VER MÁS DINÁMICAS'
        }
      }
    },
    "not-found": 'No hay trivias disponibles por el momento, estate atento a nuestras nuevas dinámicas.'
  },
  tivias: {
    challenge: {
      modal: {
        description: '¿Segurísimx que quieres empezar a jugar? Una vez que des clic no hay vuelta atrás.'
      }
    }
  },
  survey: {
    title: 'CUÉNTANOS DE TI',
    button: 'RESPONDER',
    onboarding: {
      title: '',
      description: '',
      typeImage: '',
      button: '',
      html: ''
    }
  },
  mail: {
    title: <>¡Excelente, <br /> name!</>,
    description: <>Has confirmado con éxito tu cuenta. Ya puedes ingresar.</>
  },
  header: {
    logout: 'Cerrar sesión',
    pointsText: 'TIENES'
  },
  prizes: {
    start: {
      title: 'PREMIOS',
      card: {
        points: POINTS_NAME
      }
    },
    exchange: {
      warning: <> <br /> <br />¿Segurísimo que quieres este premio?</>,
      textWarning: <> <span className="industry-book">Estás a punto de canjear</span> costInPoints pointsName <span className="industry-book">por el premio </span> <span className="industry-book">{'"'}</span>prizeName<span className="industry-book">{'".'}</span></>,
      description: 'OBTUVISTE'
    },
    congrats: {
      description: 'Revisa tu email que te vamos a mandar toda la info del envío de tu canje ¡Felicidades!'
    },
    arrow: <Arrow />
  },
  help: {
    subtitle: 'ACÁ estamos para lo que necesites, checa primero en nuestras preguntas frecuentes si encuetras la respuesta.',
    textArea: {
      placeholder: 'Escribe tu mensaje'
    }
  },
  error: {
    153: {
      title: <>POR FAVOR completa tu dirección</>,
      description: <>Debemos validar tu dirección para mostrarte los premios disponibles <br /> cerca de ti.</>,
      button: 'Completar mi dirección'
    },
    9630: {
      title: '¡Gracias por escribirnos! Recibimos tu mensaje'
    },
    505: {
      button: 'volver a intentar'
    }
  },
  cardid: {
    bottom: '58px'
  },
  social: {
    Foto: {
      howToParticipate: {
        title: '¿Cómo participar?',
        subtitle: 'Sube tu foto y participa',
        list: (
          <ul className="flex-1 items-center justify-center flex-col flex w-full gap-7 pb-7">
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-1.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p>
                  <b>Sube una foto tuya</b> y selecciona entre los filtros <b>Dos Equis PRIDE</b> disponibles para armar tu foto final.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-2.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p>
                  <b>Comparte tu foto Dos Equis PRIDE</b>{" "}
                  con tus amigos o descárgala para tenerla en tu móvil.
                </p>
              </div>
            </li>
          </ul>
        )
      },
      onBoarding: {
        rules: {
          list: (
            <>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal Montserrat text-base">
                    Aparecer reciclando en la foto.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal Montserrat text-base">
                    No aparecer fumando.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal Montserrat text-base">
                    No mostrar un consumo excesivo o aparentemente excesivo de bebidas alcohólicas.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal Montserrat text-base">
                    No mostrar la marca en un entorno de exceso, peligro o violencia.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal Montserrat text-base">
                    No aparecer con otras marcas de cerveza.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal Montserrat text-base">
                    No aparecer con menores de edad.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal Montserrat text-base">
                    El usuario puede subir contenido 4 veces al día.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal Montserrat text-base">
                    El usuario puede canjear un premio del mismo tipo cada 30 días.
                  </p>
                </div>
              </div>
            </>
          )
        }
      },
      upload: {
        title: 'Cargar imagen',
        subtitle: 'Clic para subir una imagen',
        rules: {
          title: 'REGLAS PARA SUBIR CONTENIDO',
          list: (
            <ul>
              <li>
                <p className="flex gap-1">
                  <span>1.</span> Busca en las imágenes de tu dispositivo la foto que quieras utilizar.
                </p>
              </li>
              <li className="my-3">
                <p className="flex gap-1">
                  <span>2.</span> Asegúrate de que sea una foto bien iluminada y donde te veas bien en foco.
                </p>
              </li>
              <li>
                <p className="flex gap-1">
                  <span>3.</span> Intenta que tu foto esté bien encuadrada y sin otros elementos que te tapen.
                </p>
              </li>
            </ul>
          )
        },
        errorModalValidate: {
          list: <div style={{ width: '320px' }}>
            <div className="text-sm font-normal text-white industry">
              <p className='ml-5'>1. -</p>
            </div>
          </div>,
          help: <div style={{ width: '320px' }} className='flex justify-center items-center flex-col mb-12 pt-4 text-white industry'>
            <span className="font-bold text-center text-sm">¿Te parece que no es así?</span>
            <span className="text-center text-sm">Ponte en contacto con nosotros para que <br /> podamos ayudarte. <Link to="/help" className='underline'>Envíanos un mensaje.</Link> </span>
          </div>,
          description: <>En este momento no puedes subir tu <br /> foto debido a:</>
        },
        buttonSubmit: 'Confirmar selección',
        icon: (
          <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.75 0.250183H39.25V32.7502H71.75V39.2502H39.25V71.7502H32.75V39.2502H0.25V32.7502H32.75V0.250183Z" fill="#FF0000"/>
          </svg>
        ),
        isReady: 'Selecciona el FILTRO',
        buttonCancel: 'Volver'
      },
      congrats: {
        description: 'Sigue subiendo tus fotos y gana increíbles premios',
        button1: 'VER LOS PREMIOS',
        button2: 'QUIERO CARGAR OTRA IMAGEN',
        button2Link: '/album/redirect/Foto/upload'
      },
      config: {
        prizes: '/prizes'
      }
    },
    loader: {
      title: <span className="text-center">ESTAMOS <br /> VALIDANDO</span>,

    }
  },
  album: {
    title: 'DINÁMICAS',
    notFound: <>No hay retos disponibles por el momento, <br className="album__start__empty__br" /> estate atento a nuestras nuevas dinámicas.</>,
    states: {
      progress: 'ACTUALES',
      completed: 'PASADOS'
    }
  },
}