const redirectUri = `${window.location.origin}${process.env.PUBLIC_URL}/callback`;
const clientId = GOOGLE_CLIENTID || "";

export const goToUrlGoogle = () => {
  const state = encodeURIComponent(`google`);
  localStorage.setItem("stateAuthSocial", state);
  let url = "https://accounts.google.com/o/oauth2/v2/auth";
  url += "?response_type=token";
  url += "&client_id=" + encodeURIComponent(clientId);
  url += "&scope=" + encodeURIComponent(`https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile ${["dosequis", "tecate", "mixx"].includes(THEME) ? 'https://www.googleapis.com/auth/youtube.readonly' : ''}`);
  url += "&redirect_uri=" + encodeURIComponent(redirectUri);
  url += "&state=" + state;
  window.location.href = url;
}