import { SpanPolitics } from "components/birthday-input/style.custom"
import { Link } from "react-router-dom"
import { HowTakePhotoUl, HowTakePhotoLi } from "views/album/views/multimedia/styles/custom"
const Arrow = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 0.46875C12.3392 0.46875 14.0482 0.932617 15.627 1.86035C17.1569 2.75553 18.3695 3.9681 19.2646 5.49805C20.1924 7.07682 20.6562 8.78581 20.6562 10.625C20.6562 12.4642 20.1924 14.1732 19.2646 15.752C18.3695 17.2819 17.1569 18.4945 15.627 19.3896C14.0482 20.3174 12.3392 20.7812 10.5 20.7812C8.66081 20.7812 6.95182 20.3174 5.37305 19.3896C3.8431 18.4945 2.63053 17.2819 1.73535 15.752C0.807617 14.1732 0.34375 12.4642 0.34375 10.625C0.34375 8.78581 0.807617 7.07682 1.73535 5.49805C2.63053 3.9681 3.8431 2.75553 5.37305 1.86035C6.95182 0.932617 8.66081 0.46875 10.5 0.46875ZM10.5 2.03125C8.9375 2.03125 7.48893 2.42188 6.1543 3.20312C4.86849 3.9681 3.8431 4.99349 3.07812 6.2793C2.29688 7.61393 1.90625 9.0625 1.90625 10.625C1.90625 12.1875 2.29688 13.6361 3.07812 14.9707C3.8431 16.2565 4.86849 17.2819 6.1543 18.0469C7.48893 18.8281 8.9375 19.2188 10.5 19.2188C12.0625 19.2188 13.5111 18.8281 14.8457 18.0469C16.1315 17.2819 17.1569 16.2565 17.9219 14.9707C18.7031 13.6361 19.0938 12.1875 19.0938 10.625C19.0938 9.0625 18.7031 7.61393 17.9219 6.2793C17.1569 4.99349 16.1315 3.9681 14.8457 3.20312C13.5111 2.42188 12.0625 2.03125 10.5 2.03125ZM11.0615 5.62012L16.0664 10.625L11.0615 15.6299L9.93848 14.5312L13.0635 11.4062H5.03125V9.84375H13.0635L9.93848 6.71875L11.0615 5.62012Z"
        fill="#CBB88D"
      />
    </svg>
  )
}

export const cristal = {
  agegate: {
    description: <>
      <img src={`${AZURE_BASE_URL}/agegate.webp`} alt='Logo Cristal' className='mx-auto py-10' />
      <p className='agegate__description'>¿Eres mayor de edad?</p>
    </>,
    politics1: <span className='hidden'></span>,
    politics2: <SpanPolitics>
      Al confirmar, aceptas la <a href={`${process.env.PUBLIC_URL}/privacy-politics`} rel="noreferrer" target="_blank" className="underline">política de privacidad</a >, <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">términos y condiciones y uso de cookies</a > de este sitio web. Este sitio web utiliza cookies. Para obtener mas información, lee nuestra <a href={`${process.env.PUBLIC_URL}/privacy-politics`} rel="noreferrer" target="_blank" className="underline">política de privacidad</a > y <a href={`${process.env.PUBLIC_URL}/cookies`} rel="noreferrer" target="_blank" className="underline">uso de cookies</a >.
    </SpanPolitics>
  },
  signup: {
    title: 'Bienvenido',
    description: 'Ahora eres parte del equipo. Revisa tus correos para comenzar a registrar tus latas para completar la colección. Consigue las 26 latas de Amstel Ultra y descubre los mensajes de Rafa Nadal.',
    welcome: {
      description: ' ',
      description2: '',
      subtitle: <span style={{ color: '#4E2D1E' }}>Nos falta validar tu cuenta</span>
    }
  },
  start: {
    signup: 'REGÍSTRATE CON CORREO',
    signin: 'ENTRA CON CORREO',
    social: 'ENTRA CON:',
    title: {
      html:
        <div className="flex-1 w-full py-2 flex flex-col items-center justify-center">
          <img src={`${AZURE_BASE_URL}/fondo-inicial.png`} alt="background" />
        </div>
    }
  },
  home: {
    title: <span className="font-light font-bold" style={{ color: '#CF202F', fontSize: '21px', }}>¿QUÉ quieres hacer?</span>,
    description: '¡Completa tus datos!'
  },
  profile: {
    form: {
      information: {
        firstname: {
          label: '¿Cómo te llamas?',
          placeholder: 'Pon tu nombre aquí'
        },
        lastname: {
          placeholder: 'Pon tu apellido aquí',
        },
        nickname: {
          label: 'Apodo',
          placeholder: '¿Cómo te gusta que te llamen?'
        },
        gender: {
          label: 'Género'
        }
      }
    },
    information: 'DATOS PERSONALES',
    address: 'DIRECCIÓN',
    contact: 'CONTACTO',
    button: 'IR A VER LOS PREMIOS',
    identification: {
      nav: 'Mi identificación Cristal',
      label: 'Mi identificación Cristal',
      title: 'Mi identificación Cristal',
      button: 'Descargar identificación'
    },
    stateNotFound: <>Parece que aún no has <br /> conseguido ningún premio. <br /><br /> ¡VAMOS, tú puedes!</>,
  },
  config: {
    prizes: '/prizes'
  },
  congrats: {
    range: {
      title: "NUEVO LOGRO DESBLOQUEADO",
      description: <></>,
      description2: <>Felicitaciones, has desbloqueado nuevos <br /> premios y obtienes más chances de ganar.</>
    }
  },
  endroute: {
    title: <>¡Lo sentimos! <br /> Esto es solo <br /> para mayores de edad</>,
    description: 'Vuelve cuando sea más grande.'
  },
  referrals: {
    description: <>Comparte tu código con amigos y <br /> gana más oportunidades de ganar.</>,
    subtitle: 'Comparte ESTE código',
    left: 'COPIAR',
    right: 'COMPARTIR'
  },
  trivias: {
    exchange: {
      button: {
        text: 'SEGUIR JUGANDO',
        url: '/trivias'
      }
    },
    triviaQr: {
      register: {
        title: <div className="text-white" style={{ color: '#CCB988' }}>CONTESTA<br /><span>Y GANA</span></div>,
        description: <div className="uppercase" style={{ color: '#4E2D1E' }}>
          <b>¿Ya tienes tu CRISTAL ID?</b>
          <p className="mt-3">SI NO LO TIENES CÓRRELE A REGISTRARTE PARA PODER PARTICIPAR</p>
        </div>
      },
      late: {
        description: <p className="text-black">Lamentablemente esta trivia ya no está disponible. Busca nuevo QR dentro de las otras dinámicas que tenemos para ti, para contestar una trivia nueva</p>
      }
    },
    questions1: <>Contesta </>,
    questions2: <> <span className="font-bold" style={{ color: '#CCB988' }}>preguntas</span> sobre el tema y gana:</>,
    exit: {
      title: <>¿Estás seguro <br /> que quieres <br /> salir?</>,
      subtitle: <>Si cancelas ahora pierdes tus {POINTS_NAME} y <br />tiene que volver a empezar. <br /><br /> <b>¿Segurísimo que vamos por este camino?</b></>,
      accept: 'Si, claro'
    }
  },
  survey: {
    title: 'CUÉNTANOS DE TI',
    button: 'RESPONDER'
  },
  mail: {
    title: <>¡Listo, name!</>,
    description: <>Has confirmado con exito tu cuenta. <br /> Ya puedes ingresar. </>
  },
  prizes: {
    start: {
      title: <span style={{ fontFamily: 'Montserrat', fontWeight: 700, fontSize: '25px' }}>PREMIOS</span>
    },
    btn: {
      text: 'Canjear',
    },
    congrats: {
      description: <>Ahora atentx al mail que te vamos a <br /> mandar con toda la info del envío de <br /> tu canje ¡Felicidades!</>
    },
    description: {
      politic: <>Para más información revisa nuestros <br />{' '}
        <Link to="/terms-and-conditions" className="underline">
          <b>Términos y condiciones.</b>
        </Link>
      </>
    },
    exchange: {
      warning: '¿Segurísimo que quieres este premio?'
    }
  },
  social: {
    Foto: {
      howToParticipate: {
        title: '¿Cómo participar?',
        subtitle: 'Sube tu foto y participa',
        list: (
          <ul className="flex-1 items-center justify-center flex-col flex w-full gap-7 pb-7">
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-1.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-bold text-lg montserrat leading-6" style={{ fontFamily: 'Montserrat', color: '#4E2D1E' }}>
                  Completa tu datos
                </p>
                <p className="font-normal text-base leading-5 montserrat" style={{ fontFamily: 'Montserrat', color: '#4E2D1E' }}>
                  en tu perfil personal.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-2.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-normal text-lg montserrat leading-6" style={{ fontFamily: 'Montserrat', color: '#4E2D1E' }}>
                  <b>Sube una foto</b> reciclando
                </p>
                <p className="font-normal text-base leading-5 montserrat" style={{ fontFamily: 'Montserrat', color: '#4E2D1E' }}>
                  en alguno de los puntos designados de la Ruta Cristal.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-3.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-bold text-lg montserrat leading-6" style={{ fontFamily: 'Montserrat', color: '#4E2D1E' }}>
                  Acumula puntos
                </p>
                <p className="font-normal text-base leading-5 montserrat" style={{ fontFamily: 'Montserrat', color: '#4E2D1E' }}>
                  para redimir por piezas de la Colección Barú.
                </p>
              </div>
            </li>
          </ul>
        )
      },
      onBoarding: {
        rules: {
          list: (
            <>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal Montserrat text-base">
                    Aparecer reciclando en la foto.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal Montserrat text-base">
                    No aparecer fumando.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal Montserrat text-base">
                    No mostrar un consumo excesivo o aparentemente excesivo de bebidas alcohólicas.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal Montserrat text-base">
                    No mostrar la marca en un entorno de exceso, peligro o violencia.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal Montserrat text-base">
                    No aparecer con otras marcas de cerveza.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal Montserrat text-base">
                    No aparecer con menores de edad.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal Montserrat text-base">
                    El usuario puede subir contenido 4 veces al día.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal Montserrat text-base">
                    El usuario puede canjear un premio del mismo tipo cada 30 días.
                  </p>
                </div>
              </div>
            </>
          )
        }
      },
      upload: {
        title: 'Cargar imagen',
        subtitle: 'Clic para subir una imagen',
        rules: {
          title: 'REGLAS PARA SUBIR CONTENIDO',
          list: (
            <ul style={{ color: '#4E2D1E', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: 600 }}>
              <li>
                <p className="flex gap-1">
                  <span>1.</span> Usa la cámara de tu celular para tomarle una foto a tu cerveza.
                </p>
              </li>
              <li className="my-3">
                <p className="flex gap-1">
                  <span>2.</span> Busca un lugar iluminado para tomar la foto junto al centro de recolección.
                </p>
              </li>
              <li>
                <p className="flex gap-1">
                  <span>3.</span> Mantente quieto al momento de tomar la foto para obtener una imagen de buena calidad.
                </p>
              </li>
            </ul>
          )
        },
        errorModalValidate: {
          list: <div style={{ width: '320px' }}>
            <div style={{ color: '#4E2D1E' }} className="Montserrat text-sm font-normal">
              <p className='ml-5 mb-3'> • Llegaste al máximo de contenido que puedes cargar. (puedes subir máx. 4 veces)</p>
              <p className='ml-5'> Aún no pasaron los 45 min. desde que subiste tu cuarto contenido. (Son 15 minutos acumulativos desde que subes tu primer consumo)</p>
            </div>
          </div>,
          help: <div style={{ width: '320px', color: '#4E2D1E' }} className='flex justify-center items-center flex-col mb-12 Montserrat pt-4'>
            <span className="font-bold text-center text-sm Montserrat">¿Te parece que no es así?</span>
            <span className="text-center text-sm Montserrat">Ponte en contacto con nosotros para que <br /> podamos ayudarte. <Link to="/help" className='underline'>Envíanos un mensaje.</Link> </span>
          </div>
        },
        buttonSubmit: 'Si, añadir',
        howToTakePhoto: {
          "how-take-photo-list": (
            <HowTakePhotoUl>
              <HowTakePhotoLi className="text-white pb-5">
                Asegúrate de que se vea bien el punto de recolección con sus elementos de campaña.
              </HowTakePhotoLi>
              <HowTakePhotoLi className="text-white">
                La foto tiene que ser tomada en el momento donde estás ingresando tu botella o lata en el espacio de reciclaje de CRISTAL.
              </HowTakePhotoLi>
            </HowTakePhotoUl>
          )
        }
      },
      congrats: {
        description: 'Sigue subiendo tus fotos y gana increíbles premios',
        button1: 'VER LOS PREMIOS',
        button2: 'QUIERO CARGAR OTRA IMAGEN',
        button2Link: '/album/redirect/Foto/upload'
      },
      config: {
        prizes: '/prizes'
      }
    }
  },
  album: {
    title: 'Retos',
    states: {
      progress: 'ACTUALES',
      completed: 'PASADOS'
    },
    notFound: <>No hay retos disponibles por el momento, <br /> estate atento a nuestras nuevas dinámicas.</>
  },
  cardid: {
    bottom: '45px'
  },
  error: {
    10: {
      title: <>¡UPS! <br /> El código OTP <br /> es inválido</>
    },
    505: {
      title: '¡Que pena!',
      description: <>Esa no era la respuesta correcta. <br /> Inténtalo nuevamente.</>,
      button: {
        text: 'VOLVER A EMPEZAR',
      }
    }
  },
  tivias: {
    challenge: {
      modal: {
        description: <>¿Segurísimo que quieres empezar a <br /> jugar? una vez que des clic no <br /> hay vuelta atrás.</>
      }
    }
  },
  help: {
    subtitle: <>Aca estamos para lo que necesites, checa primero en nuestras preguntas frecuentes si encuetras la respuesta.</>
  }
}


