/* eslint-disable indent */
import { useSession } from "contexts/session-context";
import { UserData } from "contexts/session-context/session-reducer";
import { useCustomForm } from "hooks/use-custom-form";
import useRouter from "hooks/use-router";
import { useContext, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { LoaderContext } from "contexts/loader-context";
import ProfileService from "services/profile-services";
import { AxiosError } from "axios";
import ErrorView from "components/error-view";
import Phone from "./phone";
import Otp from "./otp";

function FormPhone({ handleSubmit, user }: { handleSubmit: (data: any, setError?: any) => void, user?: UserData }) {

  const { history } = useRouter()
  const [{ data: session }] = useSession()
  const { state, handleChangeState, nextStep, prevStep, backHistory } = useCustomForm({ step: 1, phoneNumber: user?.phoneNumber || '', otp: '' })
  const [error, setError] = useState(null)
  const [help, setHelp] = useState()
  const { setLoad } = useContext(LoaderContext)
  const recaptchaRef = useRef<ReCAPTCHA>(null)

  function onFormSubmitEndPoint(data: any) {
    handleSubmit(data, setError)
  }

  const otpGenerate = async () => {
    recaptchaRef.current?.reset()
    await recaptchaRef.current?.executeAsync().then(async (recaptchaToken: any) => {
      setLoad(true)
      try {
        const result = await ProfileService.otpGenerate({ phone: state.phoneNumber, recaptchaToken, session })
        if (result.status === 201) {
          setHelp({ expires: result.data?.expiresAt } as any)
        }
      } catch (err) {
        const typedError = err as AxiosError
        const { code } = typedError.response?.data || {}
        setError(code)
      } finally {
        setLoad(false)
      }
    })
  }

  useEffect(() => {
    if (error) {
      handleChangeState({ step: 10 } as any)
    }
  }, [error])

  const handleButton = () => {
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'MODIFICAR TELEFONO - Salir pantalla de error', value: '10' })
    setError(null)
    if (state?.step <= 1) return;
    backHistory()
  }

  const handleMenu = async (code: number) => {
    if (code === 71 || code === 10) {
      await otpGenerate()
    }
    setError(null)
    if (state?.step <= 1) return;
    backHistory()
  }

  switch (state.step) {
    case 1:
      return (
        <>
          <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={RECAPTCHA_SITEKEY} />
          <Phone
            state={state}
            handleChange={handleChangeState}
            nextStep={nextStep}
            session={session}
            setHelper={setHelp}
            setError={setError}
            recaptchaRef={recaptchaRef}
            onFormSubmitEndPoint={onFormSubmitEndPoint}
            prevStep={() => history.goBack()}
          />
        </>
      )
    case 2:
      return (
        <>
          <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={RECAPTCHA_SITEKEY} />
          <Otp
            state={state}
            handleChange={handleChangeState}
            onFormSubmitEndPoint={onFormSubmitEndPoint}
            session={session}
            prevStep={prevStep}
            helper={help}
            setError={setError}
            recaptchaRef={recaptchaRef}
            setHelper={setHelp}
          />
        </>
      )
    default:
      return <>
        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={RECAPTCHA_SITEKEY} />
        <ErrorView buttonMenu={handleMenu} code={error} onRetry={handleButton} />
      </>
  }
}

export default FormPhone;