function HeinekenID() {
  return (
    <div
      className='w-full bg-white flex justify-between items-center rounded-lg px-6'
      style={{
        height: '80px',
        background: 'linear-gradient(90deg, #062C56 0%, #1B4677 60.54%, #007F2D 80.54%, #BBBCBD 100%)'
      }}>
      <svg width="122" height="21" viewBox="0 0 122 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M93.4881 3.93617C93.6185 3.83186 93.8793 3.72755 93.8793 3.41462C93.8793 2.99738 93.4881 2.89307 93.097 2.89307H91.7931V4.87496H92.3146V4.14479H92.445C92.7058 4.14479 92.8362 4.2491 92.9666 4.45772C93.097 4.56203 93.097 4.77065 93.097 4.97927C93.3577 4.97927 93.4881 4.97927 93.7489 4.97927C93.6185 4.87496 93.6185 4.66634 93.4881 4.45772C93.7489 4.14479 93.7489 4.04048 93.4881 3.93617ZM92.9666 3.62324H92.5754V3.206H92.9666C93.097 3.206 93.3577 3.31031 93.3577 3.41462C93.2274 3.62324 93.097 3.62324 92.9666 3.62324Z" fill="white" />
        <path d="M24.5331 14.2076C23.9202 14.8651 23.0621 15.5226 22.3265 15.7856C21.591 16.0486 19.507 16.1801 18.7715 14.3391C18.6489 13.9446 18.7715 13.8131 19.1392 13.6816C19.6296 13.5501 20.9781 13.1556 22.3265 12.4981C23.5524 11.9721 24.7783 11.3147 25.0235 11.1832C25.6364 10.7887 25.759 10.1312 25.3912 9.47369C24.5331 7.36973 22.4491 5.13428 19.1392 5.79176C16.4423 6.31775 14.6035 8.9477 14.6035 11.7091C14.6035 15.2596 16.5649 17.3635 19.1392 18.021C21.591 18.6785 24.2879 17.3635 25.5138 15.3911C26.2494 14.7336 24.9009 13.8131 24.5331 14.2076ZM18.5263 11.4461C18.2811 10.6572 18.4037 8.29021 19.7522 7.89572C20.9781 7.50123 21.8362 8.15872 22.2039 9.47369C22.3265 9.99968 22.3265 10.5257 22.0814 10.6572C21.8362 10.9202 21.4684 11.0517 21.1006 11.3146L20.2425 11.7091C19.1392 12.1036 18.7715 12.1036 18.5263 11.4461Z" fill="white" />
        <path d="M56.4656 14.2076C55.8561 14.8651 55.0028 15.5226 54.2715 15.7856C53.5401 16.0486 51.4678 16.1801 50.7365 14.3391C50.6146 13.9446 50.7365 13.8131 51.1022 13.6816C51.5897 13.5501 52.9306 13.1556 54.2715 12.4981C55.4904 11.9721 56.7094 11.3147 56.9532 11.1832C57.5627 10.7887 57.6846 10.1312 57.3189 9.47369C56.4656 7.36973 54.3934 5.13428 51.1022 5.79176C48.4204 6.31775 46.592 8.9477 46.592 11.7091C46.592 15.2596 48.5423 17.3635 51.1022 18.021C53.5401 18.6785 56.2218 17.3635 57.4408 15.3911C58.2941 14.7336 56.9532 13.8131 56.4656 14.2076ZM50.4927 11.4461C50.3708 11.0517 50.3708 9.99968 50.6146 9.0792C50.7365 8.55321 51.1022 8.15872 51.5897 7.89572C52.1992 7.63273 52.6868 7.76422 53.0525 8.02722C53.4182 8.29021 53.7839 8.8162 54.0277 9.47369C54.1496 9.99968 54.1496 10.5257 53.9058 10.6572C53.662 10.9202 53.2963 11.0517 52.9306 11.3146C52.5649 11.4461 52.1992 11.5776 52.0773 11.7091C51.224 12.1036 50.7365 12.1036 50.4927 11.4461Z" fill="white" />
        <path d="M80.1653 14.2076C79.5524 14.8651 78.6943 15.5226 77.9587 15.7856C77.2232 16.0486 75.1392 16.1801 74.4037 14.3391C74.2811 13.9446 74.4037 13.8131 74.7714 13.6816C75.2618 13.5501 76.6103 13.1556 77.9587 12.4981C79.1846 11.9721 80.4105 11.3147 80.6557 11.1832C81.2686 10.7887 81.3912 10.1312 81.0234 9.47369C80.1653 7.36973 78.0813 5.13428 74.7714 5.79176C72.0745 6.31775 70.2357 8.9477 70.2357 11.7091C70.2357 15.2596 72.1971 17.3635 74.7714 18.021C77.2232 18.6785 79.9201 17.3635 81.146 15.3911C81.8816 14.7336 80.5331 13.8131 80.1653 14.2076ZM74.1585 11.4461C74.0359 11.0517 74.0359 9.99968 74.2811 9.0792C74.4037 8.55321 74.7714 8.15872 75.2618 7.89572C75.8747 7.63273 76.3651 7.76422 76.7328 8.02722C77.1006 8.29021 77.4684 8.8162 77.7136 9.47369C77.8361 9.99968 77.8361 10.5257 77.591 10.6572C77.3458 10.9202 76.978 11.0517 76.6103 11.3146C76.2425 11.4461 75.8747 11.5776 75.7521 11.7091C74.7714 12.1036 74.4037 12.1036 74.1585 11.4461Z" fill="white" />
        <path d="M13.3659 4.62036L14.1084 4.25022C14.2322 4.12685 14.2322 4.12685 14.2322 3.88009V3.75671L14.1084 3.13982C14.1084 2.89307 13.9847 2.89307 13.6134 2.89307H8.41555C8.04427 2.89307 7.92051 2.89307 7.92051 3.13982L7.79676 3.75671C7.79676 3.88009 7.79676 3.88009 7.79676 3.88009C7.79676 4.00347 7.79676 4.12685 7.92051 4.25022L8.5393 4.62036C8.91058 4.74373 8.91058 5.23725 8.91058 5.73076V8.44507C8.91058 8.56845 8.78682 8.8152 8.5393 8.8152H5.69287C5.56911 8.8152 5.32159 8.69182 5.32159 8.44507V5.73076C5.32159 5.36062 5.3216 4.86711 5.69287 4.62036L6.31166 4.25022C6.43542 4.12685 6.43542 4.12685 6.43542 3.88009V3.75671L6.31166 3.13982C6.31166 2.89307 6.1879 2.89307 5.81663 2.89307H0.61879C0.247516 2.89307 0.123758 2.89307 0.123758 3.13982L0 3.75671C0 3.88009 0 3.88009 0 3.88009C0 4.00347 -9.22069e-09 4.12685 0.123758 4.25022L0.866306 4.62036C1.23758 4.74373 1.36134 5.23725 1.36134 5.73076V15.1075C1.36134 15.4776 1.23758 15.9711 0.866306 16.2179L0.123758 16.588C-9.22069e-09 16.7114 0 16.7114 0 16.9581V17.0815L0.123758 17.9452C0.123758 18.1919 0.247516 18.1919 0.61879 18.1919H6.06414C6.43542 18.1919 6.55918 18.1919 6.55918 17.9452L6.68293 17.0815C6.68293 16.9581 6.68293 16.9581 6.68293 16.9581C6.68293 16.8348 6.68293 16.7114 6.55918 16.588L5.81663 16.2179C5.44535 16.0945 5.32159 15.601 5.32159 15.1075V11.2828C5.32159 11.1594 5.44535 10.9126 5.69287 10.9126H8.66306C8.78682 10.9126 9.03434 11.036 9.03434 11.2828V15.1075C9.03434 15.4776 8.91058 15.9711 8.5393 16.2179L7.79676 16.588C7.79676 16.7114 7.79676 16.7114 7.79676 16.9581V17.0815L7.92051 17.9452C7.92051 18.1919 8.04427 18.1919 8.41555 18.1919H13.9847C14.3559 18.1919 14.4797 18.1919 14.4797 17.9452L14.6034 17.0815C14.6034 16.9581 14.6034 16.9581 14.6034 16.9581C14.6034 16.8348 14.6034 16.7114 14.4797 16.588L13.7371 16.2179C13.3659 16.0945 13.2421 15.601 13.2421 15.1075V5.73076C12.8708 5.36062 12.9946 4.86711 13.3659 4.62036Z" fill="white" />
        <path d="M28.5115 5.67468C29.685 5.67468 30.5977 5.02563 30.5977 4.28387C30.5977 3.44939 29.685 2.89307 28.5115 2.89307C27.338 2.89307 26.4253 3.54211 26.4253 4.28387C26.4253 5.02563 27.338 5.67468 28.5115 5.67468Z" fill="white" />
        <path d="M31.8633 16.6024L31.2375 16.2356C30.862 16.1133 30.7368 15.6242 30.7368 15.1352V7.43224C30.7368 7.30997 30.6116 7.06543 30.3613 7.06543H26.6061C26.2306 7.06543 26.1054 7.06543 26.1054 7.30997L25.9802 7.92131C25.9802 8.04358 25.9802 8.04358 25.9802 8.04358C25.9802 8.16585 25.9802 8.28812 26.1054 8.41038L26.4809 8.65492C26.8564 8.77719 26.9816 9.38853 26.9816 9.8776V15.2574C26.9816 15.6242 26.8564 16.1133 26.4809 16.3578L25.8551 16.6024C25.7299 16.7246 25.7299 16.7246 25.7299 16.9692V17.0914L25.8551 17.9473C25.8551 18.1919 25.9802 18.1919 26.3558 18.1919H31.3626C31.7382 18.1919 31.7382 18.1919 31.8633 17.9473L31.9885 17.0914C31.9885 16.9692 31.9885 16.9692 31.9885 16.9692C31.9885 16.7246 31.9885 16.7246 31.8633 16.6024Z" fill="white" />
        <path d="M45.7123 15.9169C45.438 16.0366 45.0266 16.2761 45.0266 16.2761C44.8894 16.1564 44.8895 16.1564 44.8895 15.9169V10.1696C44.8895 8.25381 43.9295 7.5354 42.6952 7.17619C41.3238 6.81698 39.6781 7.41566 38.8552 7.77487C38.4438 7.8946 38.1695 8.01434 38.1695 8.01434L38.0324 7.5354C37.8953 7.41566 37.8953 7.17619 37.621 7.17619H33.6439C33.2325 7.17619 33.0953 7.17619 33.0953 7.41566L32.9582 8.01434C32.9582 8.13407 32.9582 8.13407 32.9582 8.13407C32.9582 8.25381 32.9582 8.37354 33.0953 8.49328L33.5067 8.73275C33.9182 8.85249 34.0553 9.45117 34.0553 9.93011V15.1985C34.0553 15.5577 33.9182 16.0366 33.5067 16.2761L32.821 16.6353C32.6839 16.755 32.6839 16.755 32.6839 16.9945V17.1142L32.821 17.9524C32.821 18.1919 32.9582 18.1919 33.3696 18.1919H38.8552C39.2667 18.1919 39.2667 18.1919 39.4038 17.9524L39.5409 17.1142C39.5409 16.9945 39.5409 16.9945 39.5409 16.9945C39.5409 16.8748 39.5409 16.755 39.4038 16.6353L38.7181 16.2761C38.3067 16.1564 38.1695 15.6774 38.1695 15.1985V11.0077C38.1695 9.93011 38.8552 9.33143 39.5409 9.2117C40.0895 9.09196 40.9124 9.45117 40.9124 10.2893V15.9169C40.9124 17.4735 42.5581 18.0721 43.6552 18.0721C45.0266 18.0721 46.1237 17.5932 46.5351 16.755C46.6723 16.3958 46.5351 16.2761 46.398 16.1564C46.1237 15.9169 45.9866 15.7972 45.7123 15.9169Z" fill="white" />
        <path d="M92.9642 15.9169C92.7173 16.0366 92.4705 16.2761 92.3471 16.2761C92.2236 16.1564 92.2236 16.1564 92.2236 15.9169V10.1696C92.2236 8.25381 91.3596 7.5354 90.2488 7.17619C89.0145 6.81698 87.5334 7.41566 86.7928 7.77487C86.4226 7.8946 86.1757 8.01434 86.1757 8.01434V7.5354C86.0523 7.41566 86.0523 7.17619 85.8054 7.17619H82.226C81.8558 7.17619 81.7323 7.17619 81.7323 7.41566L81.6089 8.01434C81.6089 8.13407 81.6089 8.13407 81.6089 8.13407C81.6089 8.25381 81.6089 8.37354 81.7323 8.49328L82.1026 8.73275C82.4729 8.85249 82.5963 9.45117 82.5963 9.93011V15.1985C82.5963 15.5577 82.4729 16.0366 82.1026 16.2761L81.4855 16.6353C81.3621 16.755 81.3621 16.755 81.3621 16.9945V17.1142L81.4855 17.9524C81.4855 18.1919 81.6089 18.1919 81.9792 18.1919H86.9163C87.2866 18.1919 87.2866 18.1919 87.41 17.9524L87.5334 17.1142C87.5334 16.9945 87.5334 16.9945 87.5334 16.9945C87.5334 16.8748 87.5334 16.755 87.41 16.6353L86.7928 16.2761C86.4226 16.1564 86.2991 15.6774 86.2991 15.1985V11.0077C86.2991 9.93011 86.9163 9.33143 87.5334 9.2117C88.0271 9.09196 88.7677 9.45117 88.7677 10.2893V15.9169C88.7677 17.4735 90.2488 18.0721 91.2362 18.0721C92.4705 18.0721 93.4579 17.5932 93.8282 16.755C93.9516 16.3958 93.8282 16.2761 93.7048 16.1564C93.4579 15.9169 93.211 15.7972 92.9642 15.9169Z" fill="white" />
        <path d="M69.9902 16.6135L69.3766 16.2492C69.0084 16.1278 68.763 15.7635 68.5176 15.3993L65.9405 10.7853L67.6585 9.20688C68.0267 8.84262 68.2721 8.59978 68.5176 8.47836C68.763 8.35694 69.1312 8.23552 69.1312 8.23552C69.2539 8.1141 69.4993 8.1141 69.4993 7.87126V7.74984L69.3766 7.02133C69.3766 6.77849 69.2539 6.77849 68.8857 6.77849H64.5906C64.3452 6.77849 64.2224 6.77849 64.0997 7.02133L63.977 7.74984C63.977 7.87126 63.977 7.87126 63.977 7.87126C63.977 7.99268 63.977 8.1141 64.0997 8.1141L64.7133 8.35694C64.836 8.47836 64.836 8.7212 64.7133 8.7212C64.5906 8.84262 64.2224 9.20688 63.977 9.3283L62.3817 10.6639V3.50016C62.3817 3.13591 62.1362 2.89307 61.7681 2.89307H58.2093C57.8411 2.89307 57.8411 2.89307 57.7184 3.13591V3.743C57.7184 3.86442 57.7184 3.86442 57.7184 3.86442C57.7184 3.98584 57.7184 4.10726 57.8411 4.22868L58.4547 4.59294C58.8228 4.71436 58.9456 5.20004 58.9456 5.68571V15.1564C58.9456 15.5207 58.8228 16.0064 58.4547 16.2492L57.8411 16.6135C57.7184 16.7349 57.7184 16.7349 57.7184 16.9777V17.0991L57.8411 17.9491C57.8411 18.1919 57.9638 18.1919 58.332 18.1919H63.2407C63.6089 18.1919 63.6089 18.1919 63.7316 17.9491L63.8543 17.0991C63.8543 16.9777 63.8543 16.9777 63.8543 16.9777C63.8543 16.8563 63.8543 16.7349 63.7316 16.6135L63.118 16.2492C62.7498 16.1278 62.6271 15.6421 62.6271 15.1564V13.9422L63.4861 13.0923L65.9405 17.4634C66.1859 17.8277 66.1859 18.0705 66.6768 18.0705H69.622C69.9902 18.0705 69.9902 18.0705 70.1129 17.8277L70.2356 16.9777C70.2356 16.8563 70.2356 16.8563 70.2356 16.8563C70.2356 16.8563 70.1129 16.7349 69.9902 16.6135Z" fill="white" />
        <path d="M92.4885 1.50244C91.3295 1.50244 90.4023 2.42964 90.4023 3.58865C90.4023 4.63175 91.3295 5.67486 92.4885 5.67486C93.6475 5.67486 94.5747 4.74765 94.5747 3.58865C94.4588 2.31374 93.6475 1.50244 92.4885 1.50244ZM92.4885 5.09535C91.5613 5.09535 90.8659 4.39995 90.8659 3.47275C90.8659 2.54555 91.5613 1.85014 92.4885 1.85014C93.2998 1.85014 94.1111 2.54555 94.1111 3.47275C94.1111 4.39995 93.4157 5.09535 92.4885 5.09535Z" fill="white" />
        <path d="M104.357 5.95808C103.215 5.95808 102.324 5.10624 102.324 4.02208C102.324 2.91856 103.215 2.10544 104.357 2.10544C105.499 2.10544 106.351 2.91856 106.351 4.02208C106.351 5.10624 105.499 5.95808 104.357 5.95808ZM101.317 18L101.259 16.4706C101.375 16.4125 101.627 16.3157 101.859 16.2382L102.15 16.1414C102.653 15.9672 102.75 15.7736 102.75 15.2315V9.6752C102.75 9.03632 102.595 8.92016 102.13 8.76528L101.84 8.66848C101.608 8.59104 101.375 8.5136 101.279 8.43616L101.337 6.90672H106.293V15.2315C106.293 15.7736 106.409 15.9866 106.893 16.1414L107.183 16.2382C107.416 16.3157 107.667 16.4125 107.784 16.4706L107.725 18H101.317ZM120.083 16.2382C120.315 16.3157 120.567 16.4125 120.683 16.4706L120.625 18H116.23V16.4899C115.552 17.6322 114.449 18.2323 113.132 18.2323C110.345 18.2323 108.409 15.9866 108.409 12.4437C108.409 8.9008 110.383 6.6744 113.055 6.6744C114.139 6.6744 115.03 7.10032 115.649 7.77792V5.5128C115.649 4.87392 115.494 4.75776 115.03 4.60288L114.739 4.50608C114.507 4.42864 114.294 4.3512 114.178 4.27376L114.236 2.74432H119.192V15.2315C119.192 15.7736 119.308 15.9866 119.792 16.1414L120.083 16.2382ZM113.887 16.1414C115.049 16.1414 115.649 14.9218 115.649 12.4437C115.649 9.90752 115.107 8.76528 113.965 8.76528C112.687 8.76528 112.029 9.9656 112.029 12.4243C112.029 14.9992 112.706 16.1414 113.887 16.1414Z" fill="#E6EBF1" />
      </svg>
      <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5293 0.0644531L13.3105 4.8457L13.6973 5.25L13.3105 5.6543L8.5293 10.4355L7.7207 9.62695L11.5352 5.8125H0.25V4.6875H11.5352L7.7207 0.873047L8.5293 0.0644531Z" fill="white" />
      </svg>
    </div>
  )
}

export default HeinekenID
