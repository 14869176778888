import {
  AUTH,
  GENRES,
  CHECK_ZIP,
  DO_RESET,
  OTP_GENERATE,
  OTP_VALIDATE,
  OTP_VALIDATE_EMAIL,
  SEND_EMAILVERIFY,
  SEND_RESET,
  UPDATE_ADDRESS,
  VERIFY_RESET,
  UPDATE_USER,
  POST_REFERRAL,
  PUT_CONTACT,
  SOCIAL_LOGIN,
  VERIFY_NICKNAME,
  VERIFY_REFERRALCODE,
  INSTAGRAM,
  PUT_AUTH_INSTAGRAM,
  UPDATE_CONSUMPTION,
  GET_MOVEMENTS,
  GET_MOVEMENTS_DETAILS,
  UPDATE_PHONE,
  UPDATE_NICKNAME
} from 'constants/api'
import axios from 'axios'

const ProfileService = {
  signUp(data) {
    return axios.post(
      AUTH,
      { ...data, utm: data.utm },
      { headers: { Authorization: `Bearer ${(data.credentials.user as any).accessToken}`, 'Ph-Api-Id': PH_API_ID??1 } }
    )
  },

  postReferral(data) {
    return axios.post(
      POST_REFERRAL,
      { code: data.code },
      { headers: { Authorization: `Bearer ${(data.credentials?.user as any)?.accessToken}`, 'Ph-Api-Id': PH_API_ID??1 } }
    )
  },

  socialLogin(data) {
    return axios.post(
      SOCIAL_LOGIN,
      { ...data.payload },
      { headers: { Authorization: `Bearer ${(data.credentials?.user as any)?.accessToken}`, 'Ph-Api-Id': PH_API_ID??1 } }
    )
  },

  signInWithEmailAndPassword(data) {
    return axios.post(
      AUTH,
      { isLogin: true, utm: data?.utm },
      { headers: { Authorization: `Bearer ${(data.credentials.user as any).accessToken}`, 'Ph-Api-Id': PH_API_ID??1 } }
    )
  },

  resendEmailVerify(params) {
    return axios.get(SEND_EMAILVERIFY, { params, headers: { 'Ph-Api-Id': PH_API_ID??1 } })
  },

  recoverPassword(params) {
    return axios.get(SEND_RESET, { params, headers: { 'Ph-Api-Id': PH_API_ID??1 } })
  },

  doReset(data) {
    return axios.put(DO_RESET, { ...data })
  },

  verifyReset(data) {
    return axios.post(VERIFY_RESET, data, { headers: { 'Content-Type': 'application/json', 'Ph-Api-Id': PH_API_ID??1 } })
  },

  checkZip(params, data) {
    return axios.get(CHECK_ZIP, {
      params,
      headers: { Authorization: `Bearer ${((data.session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID??1 }
    })
  },

  updateAddres(data) {
    return axios.post(
      UPDATE_ADDRESS,
      { ...data.address },
      { headers: { Authorization: `Bearer ${((data.session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID??1 } }
    )
  },

  otpGenerate(data) {
    return axios.post(
      OTP_GENERATE,
      { phone: data.phone, recaptchaToken: data.recaptchaToken },
      { headers: { Authorization: `Bearer ${((data.session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID??1 } }
    )
  },

  otpVerifyPhone(data) {
    return axios.post(
      OTP_VALIDATE,
      { code: data.code },
      { headers: { Authorization: `Bearer ${((data.session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID??1 } }
    )
  },

  otpVerifyEmail(data) {
    return axios.post(
      OTP_VALIDATE_EMAIL,
      { code: data.code },
      { headers: { Authorization: `Bearer ${(data.credentials.user as any).accessToken}`, 'Ph-Api-Id': PH_API_ID??1 } }
    )
  },
  
  otpVerifyEmailWithAccessToken(code: string, accessToken: string) {
    return axios.post(
      OTP_VALIDATE_EMAIL,
      { code },
      { headers: { Authorization: `Bearer ${accessToken}`, 'Ph-Api-Id': PH_API_ID??1 } }
    )
  },  

  genres() {
    return axios.get(GENRES)
  },

  updateProfile(data) {
    return axios.put(
      UPDATE_USER,
      { ...data.user },
      { headers: { Authorization: `Bearer ${((data.session as any)?._tokenResponse as any)?.idToken}` } }
    )
  },

  updateProfileContact(data) {
    return axios.put(
      PUT_CONTACT,
      { ...data.user },
      { headers: { Authorization: `Bearer ${((data.session as any)?._tokenResponse as any)?.idToken}` } }
    )
  },

  validateNickName(data) {
    return axios.post(VERIFY_NICKNAME, { nickname: data })
  },

  validateReferralCode(data) {
    return axios.post(VERIFY_REFERRALCODE, { code: data })
  },

  instagram(instagram: string, session: any) {
    return axios.put(INSTAGRAM, { instagram }, {headers: { Authorization: `Bearer ${session?._tokenResponse?.idToken}`, 'Ph-Api-Id': PH_API_ID??1 }})
  },

  authInstagram(code: string, session: any) {
    return axios.put(PUT_AUTH_INSTAGRAM, { code }, {headers: { Authorization: `Bearer ${session?._tokenResponse?.idToken}`, 'Ph-Api-Id': PH_API_ID??1}})
  },

  updateConsumption(data) {
    return axios.put(
      `${UPDATE_CONSUMPTION}/${data.consumptionId}`,
      {},
      { headers: { Authorization: `Bearer ${((data.session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID??1 } }
    )
  },

  getMovements(data) {
    return axios.get(
      GET_MOVEMENTS,
      { headers: { Authorization: `Bearer ${((data.session as any)?._tokenResponse as any)?.idToken}` } }
    )
  },

  getMovementsDetails(data) {
    return axios.get(
      GET_MOVEMENTS_DETAILS.replace(':categoryId', data.categoryId),
      { headers: { Authorization: `Bearer ${((data.session as any)?._tokenResponse as any)?.idToken}` } }
    )
  },

  updatePhone(data) {
    return axios.put(
      UPDATE_PHONE.replace(':phone', data.phoneNumber),
      { },
      { headers: { Authorization: `Bearer ${((data.session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID??1 } }
    )
  },

  updateNickname(data) {
    return axios.put(
      UPDATE_NICKNAME.replace(':nickname', data.nickName),
      { },
      { headers: { Authorization: `Bearer ${((data.session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID??1 } }
    )
  }
}

export default ProfileService
