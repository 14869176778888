import Button from "components/button"
import ButtonCloseMenu from 'components/button-close'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import CardAuctions from "components/card-auctions"
import { useEffect, useState } from "react"

function SelectAuctionCard({ item, setAuction, currentAuction, onSubmit }: any) {

  const [currentOffer, setCurrentOffer] = useState(+item.currentOffer + 200)
  const [modalAlert, setModalAlert] = useState(false)

  useEffect(() => {
    const event = new CustomEvent('background-color', {
      detail: {
        shouldUseWhiteBg: modalAlert
      }
    })

    window.dispatchEvent(event)
  }, [modalAlert])

  const Buttons = {
    default: <>
      <div className="gap-1 flex flex-col items-center justify-center w-full">
        <p>TU OFERTA DE {POINTS_NAME}</p>
        <div className="w-full flex flex-row items-center justify-center font-bold text-4xl">
          <button onClick={() => {
            +item.currentOffer + 200 < currentOffer && setCurrentOffer((state) => state - 200)
            gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'AUCTIONS - Disminuir valor a ofertar', value: '10' })
          }} className="flex items-center justify-center p-4 px-6 border border-white" style={{ borderRadius: '10px 0 0 10px' }}>-</button>
          <div className="flex-1 h-full items-center justify-center flex border border-white text-3xl">{currentOffer}</div>
          <button onClick={() => {
            setCurrentOffer((state) => state + 200)
            gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'AUCTIONS - Aumentar valor a ofertar', value: '10' })
          }} className="flex items-center justify-center p-4 px-6 border border-white" style={{ borderRadius: '0 10px 10px 0' }}>+</button>
        </div>
      </div>
      <Button onClick={() => {
        setModalAlert(true)
        gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'AUCTIONS - Ofertar valor', value: '10' })
      }}>OFERTAR</Button>
    </>,
    2: <>
      <p className="text-xs font-normal text-center">Solo puedes participar en 1 subasta por vez. <br />Puedes mirar pero tienes que esperar a que termine la que ya tienes activa.</p>
      <Button onClick={() => {
        currentAuction && setAuction(currentAuction)
        gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'AUCTIONS - Ver mi subasta activa', value: '10' })
      }}>VER MI SUBASTA ACTIVA</Button>
    </>
  }

  if (modalAlert) {
    return (
      <>
        <ButtonCloseMenu
          open={true}
          paramFn={() => {
            setModalAlert(false)
            gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'AUCTIONS - Cerrar modal de alerta para ofertar', value: '10' })
          }}
        />
        <section className="w-auto flex-1 flex flex-col justify-center items-center max-w-full space-y-4 mx-8 my-8 text-black">
          <div className="flex-1  flex flex-col itemx-center justify-center gap-4">
            <h1 className="text-center text-5xl uppercase font-bold">Pérate, pérate!</h1>
            <p className="text-center mx-2 text-xl saira">Estás a punto de ofertar {currentOffer} {POINTS_NAME} por {item.title}.<br /><b>¿Segurísimo que quieres ofertar esta cantidad de {POINTS_NAME}?</b></p>
          </div>
          <Button onClick={() => {
            onSubmit(currentOffer)
            gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'AUCTIONS - Ofertar valor', value: '10' })
          }}>SI, CLARO</Button>
          <button type="button" className="cursor-pointer" onClick={() => {
            setModalAlert(false)
            gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'AUCTIONS - Cerrar modal de alerta para ofertar', value: '10' })
            dlTrackEvent('user_interaction', { 
              action: 'click', 
              element: 'volver', 
              section: 'subasta' 
            })
          }}>
            <p
              className={`din text-center uppercase underline`}
              style={{ fontSize: 10 }}
            >
              VOLVER
            </p>
          </button>
        </section>
      </>
    )
  }

  return (
    <div className="flex-1 flex flex-col w-screen saira gap-7 pb-7">
      <CardAuctions item={item} modal />
      <div className="px-7 flex flex-col flex-1 gap-4">
        <div className="flex flex-col items-start flex-1 gap-4">
          <h1 className="text-lg font-bold uppercase">{item.title}</h1>
          <p className="font-normal text-base">{item.description}</p>
        </div>
        <div className="flex flex-col items-center justify-center gap-3 w-full">
          {Buttons[item.status] ? Buttons[item.status] : Buttons.default}
          <button type="button" className="cursor-pointer" onClick={() => {
            setAuction(null)
            gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'AUCTIONS - Volver', value: '10' })
            dlTrackEvent('user_interaction', { 
              action: 'click', 
              element: 'volver', 
              section: 'subasta' 
            })
          }}>
            <p
              className={`din text-center uppercase underline`}
              style={{ fontSize: 10 }}
            >
              VOLVER
            </p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default SelectAuctionCard