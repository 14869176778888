import Button from 'components/button'
import textServices from 'services/text-services'
import PopUp from '.'

function StartPopUp({ handleMenu }: { handleMenu: () => void }) {
  return (
    <PopUp handleMenu={handleMenu}>
      {textServices.findByText('popUps/start/html')}
      <Button onClick={handleMenu}>{textServices.findByText('popUps/start/button', 'ENTENDIDO')}</Button>
    </PopUp>
  )
}

export default StartPopUp