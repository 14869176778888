import React from "react";
import { SubtitleSurveyLabel, SubtitleLabelSurvey } from "theme/survey";
import { Option } from "contexts/survey-context/survey.interface";

export interface TextProps {
  questionId: number;
  title: string;
  subtitle: string;
  items?: Option[];
  onChange: (key: number, selected: string[], value?: string) => void;
}

const TextBox = (props: TextProps) => {
  
  const onchange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChange(props?.questionId, [`${props.items[0]?.optionId}`], e.target.value)
  }

  return (
    <>
      <div className="p-2 w-full label-survey">
        <SubtitleSurveyLabel className="font-black text-2xl text-center md:text-3xl subtitle-option">
          {props.title}
        </SubtitleSurveyLabel>
        <SubtitleLabelSurvey className="text-lg md:text-xl subtitle-option">
          {props.subtitle}
        </SubtitleLabelSurvey>
      </div>
      <div className={`flex flex-col my-2 px-2 gap-4 md:mx-auto w-full text-black`}>
        <textarea className="input-survey-textarea" placeholder={props.items[0]?.option} onChange={onchange} />
      </div>
    </>
  )
}

export default TextBox