import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import Button from "components/button";
import Clock from "components/icon/clock";
import OtpInput from "components/otp-input";
import { LoaderContext } from "contexts/loader-context";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorInput } from "theme/theme";
import { useCountdown } from "rooks";
import formServices from "services/form-builder-services";
import textServices from "services/text-services";
import ProfileService from "services/profile-services";
import { dlTrackEvent } from "components/dataLayer/dataLayer";
import { TextOtp, TextOtpLabelTwo, TextOtpResend, BackBtn } from "../text.style";
import { Div, PTime, TitleOtp } from "./styled.component";

function Otp({ state, helper, handleChange, onFormSubmitEndPoint, prevStep, setHelper, session, recaptchaRef, setError }: any) {

  const { handleSubmit, formState: { errors }, setValue } = useForm({ resolver: yupResolver(formServices.formGenerateSchema(["otp"])) })
  const [endTime, setEndtime] = useState(new Date(helper.expires))
  const { setLoad } = useContext(LoaderContext)
  const [endTimeTransform, setEndTimeTransform] = useState<string | null>(null)
  const count = useCountdown(endTime, { interval: 1000 })

  const secondsToString = (seconds: number) => {
    let minute: any = Math.floor((seconds / 60) % 60)
    minute = minute < 10 ? '0' + minute : minute
    let second: any = seconds % 60
    second = second < 10 ? '0' + second : second
    return minute + ':' + second
  }

  useEffect(() => {
    setEndtime(new Date(helper.expires))
  }, [helper.expires])

  useEffect(() => {
    if (count > 0) {
      setEndTimeTransform(secondsToString(count))
    } else if (count === 0) {
      setEndTimeTransform(null)
      setError(71)
    }
  }, [count])

  const otpGenerate = async () => {
    recaptchaRef.current?.reset()
    await recaptchaRef.current?.executeAsync().then(async (recaptchaToken: any) => {
      setLoad(true)
      try {
        const result = await ProfileService.otpGenerate({ phone: state.phoneNumber, recaptchaToken, session })
        if (result.status === 201) {
          setHelper({ expires: result.data?.expiresAt })
        }
      } catch (err) {
        const typedError = err as AxiosError
        const { code } = typedError.response?.data || {}
        setError(code)
      } finally {
        setLoad(false)
      }
    })
  }

  function onFormSubmit(data: any) {
    dlTrackEvent('user_interaction', {
      action: 'click',
      element: 'verificar cuenta',
      section: 'sign up'
    })
    handleChange(data)
    onFormSubmitEndPoint(data)
  }

  return (
    <Div className="flex flex-col flex-1 md:pt-10">
      <form className="flex h-full justify-between flex-col gap-4 mx-7 mb-7 otp-text-container" onSubmit={handleSubmit(onFormSubmit)} noValidate>
        <TitleOtp className="text-center text-lg font-medium saira uppercase otp-title">{textServices.findByText('otp/title', 'Ingresa el código de 6 dígitos que recibiste para verificar tu celular.')}</TitleOtp>
        <div className="flex-1 flex flex-col gap-4">
          <div className="gap-2 flex flex-col input-container">
            <OtpInput setValue={setValue} />
            <ErrorInput className="input-error">
              <ErrorMessage errors={errors} name="otp" />
            </ErrorInput>
          </div>
          <div className="flex-1 flex justify-center">
            <div className="h-full flex flex-col justify-center items-center text-center w-4/5">
              <PTime className="flex gap-2 otp-time">
                <Clock />
                {endTimeTransform}
              </PTime>
              <TextOtpLabelTwo className="my-4 otp-text">Una contraseña de un solo uso se envió al <b>{state.phoneNumber}</b></TextOtpLabelTwo>
              <TextOtp className="flex flex-col items-center otp-text-resend-container">
                <b className="text-resend">{textServices.findByText('otp/received', '¿No la recibió?')}</b>
                <TextOtpResend
                  className="underline cursor-pointer ml-2 text-resend-button"
                  onClick={() => {
                    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'MODIFICAR TELEFONO - Reenviar código OTP', value: '10' })
                    dlTrackEvent('user_interaction', {
                      action: 'click',
                      element: textServices.findByText('otp/received', '¿No la recibió?'),
                      section: 'sign up'
                    })
                    otpGenerate()
                  }}
                >
                  {textServices.findByText('otp/resend', 'reenviar código')}
                </TextOtpResend>
              </TextOtp>
            </div>
          </div>

        </div>
        <Button>
          <p className="uppercase">Verificar</p>
        </Button>
        <button type="button" className="cursor-pointer" onClick={() => {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'MODIFICAR TELEFONO - Volver atras', value: '10' })
          dlTrackEvent('user_interaction', {
            action: 'click',
            element: 'volver',
            section: 'sign up'
          })
          prevStep()
        }}>
          <BackBtn
            className={`din text-center mt-3 hover:text-red-100 uppercase underline button-back`}
            style={{ fontSize: 10 }}
          >
            VOLVER
          </BackBtn>
        </button>
      </form>
    </Div>
  )
}

export default Otp;