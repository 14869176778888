
import { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import useRedirect from 'hooks/use-redirect'
import AllowCookies from 'components/allow-cookies'
import textServices from 'services/text-services'
import { Back } from 'theme/theme'
import { Facebook, Google, Guest, SignUp, SignIn } from 'components/start'
import ErrorView from 'components/error-view'
import { useLocalStorage } from 'react-use'
import StartPopUp from 'components/popUps/startPopUp'
import useMainClass from 'hooks/use-main-cass'
import Twitch from 'components/start/twitch'
import AlreadyParticipate from 'views/sign-up/components/already-participate'
import { isInAppBrowser } from 'utilities/detectDeviceAgent'
import { Prize } from 'views/prizes/types'
import ExchangeComponent from 'components/exchange'
import { useSession } from '../../contexts/session-context'
import { LoaderContext } from '../../contexts/loader-context'
import { FormContainer } from '../../theme/start'
import { TextStart, TitleStart } from './text.style'
dayjs.extend(customParseFormat)
export interface FormValues {
  code: string
}

const Start = () => {
  const [session, { setError, setSession, setUser }] = useSession()
  const history = useHistory()
  const [{ url }, { redirect }] = useRedirect();
  const [firebaseError, setFirebaseError] = useState('')
  const [loginError, setLoginError] = useState<number | undefined>()
  const [allowCookies, setAllowCookies] = useState<{ callback: () => void, isAllow: boolean }>()
  const { setHead, setFooter } = useContext(LoaderContext)
  const [prize, setPrize] = useState<{ prize: Prize, name: string, callback: () => void }>(null)
  const [flowType] = useLocalStorage('flowType')
  useMainClass('start')

  const typedThemeButtons: string[] = THEME_CONFIG.buttonsStart

  const listCommon = useMemo(() => {
    return [
      {
        type: 'signUp',
        component: <SignUp />,
      },
      {
        type: 'signIn',
        component: <SignIn />,
      },
      {
        type: 'guest',
        component: <Guest />,
      },
    ].filter(({ type }) => typedThemeButtons.includes(type)).sort((a, b) => typedThemeButtons.indexOf(a.type) - typedThemeButtons.indexOf(b.type))
  }, [])

  const listSocial = useMemo(() => {
    return [
      {
        type: 'google',
        component: <Google
          setSession={setSession}
          setUser={setUser}
          setLoginError={setLoginError}
          setError={setError}
          setFirebaseError={setFirebaseError}
          setAllowCookies={setAllowCookies}
          setPrize={setPrize}
        />,
      },
      {
        type: 'facebook',
        component: <Facebook
          setSession={setSession}
          setUser={setUser}
          setLoginError={setLoginError}
          setError={setError}
          setFirebaseError={setFirebaseError}
          setAllowCookies={setAllowCookies}
          setPrize={setPrize}
        />
      },
      {
        type: 'twitch',
        component: <Twitch />
      },
    ].filter(({ type }) => {
      const isDisplayInTheme = typedThemeButtons.includes(type)

      return isDisplayInTheme
    }).sort((a, b) => typedThemeButtons.indexOf(a.type) - typedThemeButtons.indexOf(b.type))
  }, [])

  const [popUp, setPopUp] = useLocalStorage('startPopUp', false)

  useEffect(() => {
    setHead(true)
    setFooter(true)
  }, [])

  useEffect(() => {
    if (session.user) {
      if (url) return redirect()
      history.push('/home')
    }
  }, [session.user])

  const formContainerClasses = `my-8 flex-col w-full transition-colors duration-300 items-center px-7 sm:max-w-sm sm:items-center`

  if (prize) {
    return <ExchangeComponent
      buttons={{
        handleMenu: prize?.callback,
        handleButtonCommon: prize?.callback,
        handleButtonSticker: prize?.callback,
      }}
      item={prize?.prize}
      module="login"
      name={prize?.name}
    />
  }

  if (allowCookies?.isAllow) {
    return (
      <AllowCookies onSubmit={allowCookies?.callback} />
    )
  }

  if (!allowCookies?.isAllow && allowCookies?.callback && flowType === 'alreadyParticipate') return <AlreadyParticipate handleLogin={allowCookies?.callback} />

  if (loginError) {
    return <ErrorView code={loginError as number} buttonMenu={() => setLoginError(null)} onRetry={() => setLoginError(null)} />
  }

  if (firebaseError) {
    return <ErrorView code={firebaseError as unknown as number} buttonMenu={() => setFirebaseError('')} onRetry={(code) => +code === 107 ? history.push('/login') : setFirebaseError('')} />
  }

  if (!popUp && THEME_CONFIG?.startPopUp) {
    return <StartPopUp handleMenu={() => setPopUp(true)} />
  }

  return (
    <section className="flex-1 py-0 flex flex-col items-center din large-screen-padding" style={{ maxHeight: 1080 }}>
      {textServices.findByText('start/title/html',
        <Back className={`flex flex-col flex-1 w-full justify-center sm:items-center`}>
          <div className="w-full h-full flex items-center justify-center bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${`${AZURE_BASE_URL}/fondo-inicial.svg`})` }}>
            <TitleStart className="text-4xl font-normal text-center ">
              {textServices.findByText('start/title/h1', <>¿Listo para ser <br /> parte del equipo? <br /> <span className="font-semibold">¡Regístrate!</span></>)}
            </TitleStart>
          </div>
        </Back>
      )}
      <FormContainer
        className={formContainerClasses}
      >
        <div className=" flex flex-col items-center gap-4">
          {listSocial?.length > 0 && (
            <div className='flex w-full items-center justify-center gap-4 py-4 saira font-bold text-sm social-container'>
              <TextStart className='text'>{textServices.findByText('start/social', 'ENTRA CON:')}</TextStart>
              {listSocial.map((social, index) => {

                const inBrowserApp = isInAppBrowser()

                if (inBrowserApp && ['google', 'twitch'].includes(social?.type) && !['dosequis', 'mixx'].includes(THEME)) {
                  return null;
                }

                return (
                  <span className={`bg-white rounded-full border-2 border-black social`} key={index}>
                    {social.component}
                  </span>
                )
              })}
            </div>
          )}
          {listCommon.map((common, index) => {
            return <div key={index} className={`w-full sm:w-72`}>{common?.component}</div>
          })}
        </div>
      </FormContainer>
    </section>
  )
}

export default Start