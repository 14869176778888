import { Link, } from 'react-router-dom'
import { useAgegate } from 'hooks/use-agegate';
import useMainClass from 'hooks/use-main-cass';
import { useContext, useEffect } from 'react';
import textServices from 'services/text-services';
import { LoaderContext } from 'contexts/loader-context';
import { Section } from './style/section';
import { TitleEnd, SubTitleEnd, ButtonEnd } from './style/text';

function EndRoute() {

  const { removeAge } = useAgegate();
  useMainClass('endroute')
  
  const { setFooter } = useContext(LoaderContext)

  useEffect(() => {
    setFooter(false)
    return () => setFooter(true)
  }, [])

  return (
    <Section className="flex-1 py-0 mx-7 flex flex-col items-center din large-screen-padding" style={{ maxHeight: 1080 }}>
      <article className="flex-1 flex flex-col justify-center items-center text-center uppercase">
        <TitleEnd className="font-bold text-4xl uppercase text-center mb-6 endroute__title">{textServices.findByText('endroute/title', <>¡Lo sentimos! <br /> Esto es solo para mayores</>)}</TitleEnd>
        <SubTitleEnd className="text-base text-center font-medium endroute__description">{textServices.findByText('endroute/description', 'Vuelve cuando seas más grande.')}</SubTitleEnd>
      </article>
      <Link to="/agegate" className="w-full mb-8">
        <ButtonEnd
          onClick={() => {
            gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'END ROUTE - Menor de 18', value: '10' })
            removeAge()
          }}
        >
          {textServices.findByText('endroute/button', 'VOLVER')}
        </ButtonEnd>
      </Link>
    </Section>
  )
}

export default EndRoute
