import { ErrorMessage } from "@hookform/error-message"
import { yupResolver } from "@hookform/resolvers/yup"
import { AxiosError } from "axios"
import Button from "components/button"
import ErrorView from "components/error-view"
import Input from "components/input"
import { LoaderContext } from "contexts/loader-context"
import { useSession } from "contexts/session-context"
import useRouter from "hooks/use-router"
import { useContext, useState } from "react"
import ButtonCloseMenu from 'components/button-close'
import { useForm } from "react-hook-form"
import formServices from "services/form-builder-services"
import ProfileService from "services/profile-services"
import textServices from "services/text-services"
import { Label, ReturnText, ErrorInput } from "theme/theme"

function ProfileMailUpdate() {
  const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(formServices.formGenerateSchema(["contactMail"])) })
  const { history } = useRouter()
  const [{ user, data: session }, { setUser }] = useSession()
  const { setLoad } = useContext(LoaderContext)
  const [genericError, setGenericError] = useState(null)

  async function onFormSubmit(mail: any) {
    setLoad(true)
    try {
      const { data } = await ProfileService.updateProfileContact({ user: { email: mail?.contactMail }, session })
      setUser(data)
      history.goBack()
    } catch (err) {
      const typedError = err as AxiosError
      setGenericError(typedError?.response?.data?.code ?? 9999)
    } finally {
      setLoad(false)
    }
  }

  const handleMenu = () => {
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'MODIFICAR MAIL DE CONTACTO - Cerrar error', value: '10' })
    setGenericError(null)
  }

  const handleButton = () => {
    setGenericError(null)
  }

  if (genericError) {
    return (
      <ErrorView code={genericError} buttonMenu={handleMenu} onRetry={handleButton} />
    )
  }

  return (
    <div className="mt-7 flex flex-col w-full">
      <div className="flex flex-col flex-1 md:pt-10">
        <ButtonCloseMenu paramFn={() => {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'MODIFICAR MAIL DE CONTACTO - Volver atras', value: '10' })
          history.goBack()
        }} />
        <form className="flex h-full justify-between flex-col gap-4 mx-7 mb-7" onSubmit={handleSubmit(onFormSubmit)} noValidate>
          <div className="flex-1 flex flex-col gap-4">
            <div className="gap-2 flex flex-col input-container">
              <Label className="text-start text-2xl font-bold uppercase input-label">{textServices.findByText('profile/view/contact/emailTitle2', '¿E-mail de contacto?')}</Label>
              <Input
                className="text-center saira font-bold text-sm"
                type="text"
                placeholder={textServices.findByText('profile/form/information/email/placeholder', 'roberto@gmail.com')}
                name="contactMail"
                defaultValue={user?.contactEmail}
                {...register("contactMail")}
              />
              <ErrorInput className="input-error">
                <ErrorMessage errors={errors} name="contactMail" />
              </ErrorInput>
            </div>
          </div>
          <Button>
            <p className="uppercase">ACTUALIZAR</p>
          </Button>
          <button type="button" className="cursor-pointer" onClick={() => {
            gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'MODIFICAR MAIL DE CONTACTO - Volver atras', value: '10' })
            history.goBack()
          }}>
            <ReturnText
              className={`din text-center mt-3 hover:text-red-100 uppercase underline button-back`}
              style={{ fontSize: 10 }}
            >
              VOLVER
            </ReturnText>
          </button>
        </form>
      </div>
    </div>
  );
}

export default ProfileMailUpdate;