import { Link } from 'react-router-dom'

const Arrow = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 0.46875C12.3392 0.46875 14.0482 0.932617 15.627 1.86035C17.1569 2.75553 18.3695 3.9681 19.2646 5.49805C20.1924 7.07682 20.6562 8.78581 20.6562 10.625C20.6562 12.4642 20.1924 14.1732 19.2646 15.752C18.3695 17.2819 17.1569 18.4945 15.627 19.3896C14.0482 20.3174 12.3392 20.7812 10.5 20.7812C8.66081 20.7812 6.95182 20.3174 5.37305 19.3896C3.8431 18.4945 2.63053 17.2819 1.73535 15.752C0.807617 14.1732 0.34375 12.4642 0.34375 10.625C0.34375 8.78581 0.807617 7.07682 1.73535 5.49805C2.63053 3.9681 3.8431 2.75553 5.37305 1.86035C6.95182 0.932617 8.66081 0.46875 10.5 0.46875ZM10.5 2.03125C8.9375 2.03125 7.48893 2.42188 6.1543 3.20312C4.86849 3.9681 3.8431 4.99349 3.07812 6.2793C2.29688 7.61393 1.90625 9.0625 1.90625 10.625C1.90625 12.1875 2.29688 13.6361 3.07812 14.9707C3.8431 16.2565 4.86849 17.2819 6.1543 18.0469C7.48893 18.8281 8.9375 19.2188 10.5 19.2188C12.0625 19.2188 13.5111 18.8281 14.8457 18.0469C16.1315 17.2819 17.1569 16.2565 17.9219 14.9707C18.7031 13.6361 19.0938 12.1875 19.0938 10.625C19.0938 9.0625 18.7031 7.61393 17.9219 6.2793C17.1569 4.99349 16.1315 3.9681 14.8457 3.20312C13.5111 2.42188 12.0625 2.03125 10.5 2.03125ZM11.0615 5.62012L16.0664 10.625L11.0615 15.6299L9.93848 14.5312L13.0635 11.4062H5.03125V9.84375H13.0635L9.93848 6.71875L11.0615 5.62012Z"
        fill="#027219"
      />
    </svg>
  )
}

export const dosequis = {
  redeem: {
    description: "EMPIEZA A GANAR PUNTOS Y LLÉVATE PREMIOS ÚNICOS",
    description2: <span className='industry text-white text-base'>RESUELVE LAS PISTAS DEL TOTEM, INGRESA EL CÓDIGO Y FÍJATE SI ACIERTAS</span>
  },
  legalage: {
    title: ' ',
    button1: <span>SI, SOY MAYOR</span>,
    button2: <span>NO, SOY MENOR</span>,
    question: '¿Eres mayor de edad?',
    politics: ' '
  },
  agegate: {
    // title: '¿Listo para ganar con Carta Blanca?'
    title: (
      <div className='flex justify-center items-center flex-col'>
        <img src={`${AZURE_BASE_URL}/xx_logo_full-agegate.webp`} alt='Logo' />
        <h2 className='agegate__title'>¿Eres mayor de edad?</h2>
      </div>
    ),
    description: <p className='text-center Industry-Book agegate__description'>Ingresa tu fecha de nacimiento, A MODO <br /> DE validar tu mayoría de edad</p>

  },
  signup: {
    welcome: {
      description: <span>Te enviamos un código de 6 dígitos a tu correo electrónico</span>,
      description2: <span>para que lo ingreses y valides tu cuenta</span>
    },
    title: 'Bienvenido',
    description:
      'Ahora eres parte del equipo. Revisa tus correos para comenzar a registrar tus latas para completar la colección. Consigue las 26 latas de Amstel Ultra y descubre los mensajes de Rafa Nadal.'
  },
  start: {
    signup: 'REGÍSTRATE CON CORREO',
    signin: 'ENTRA CON CORREO',
    social: 'Entra con:',
    title: {
      html: (
        <div className="flex-1 w-full pb-2 flex flex-col items-center justify-center">
          <img src={`${AZURE_BASE_URL}/fondo-inicial.png`} alt="background" className="w-full" />
        </div>
      )
    }
  },
  home: {
    title: (
      <span
        style={{
          fontSize: '25px',
          fontWeight: 300,
          color: '#B98A22',
          fontFamily: 'Fresno',
          textTransform: 'uppercase'
        }}
      >
        ¿Qué quieres hacer?
      </span>
    ),
    description: '¡Completa tus datos!'
  },
  profile: {
    form: {
      information: {
        firstname: {
          label: '¿Cómo te llamas?',
          placeholder: 'Pon tu nombre aquí'
        },
        lastname: {
          placeholder: 'Pon tu apellido aquí'
        },
        nickname: {
          label: '¿Apodo?',
          placeholder: '¿Como le gusta que le digan?'
        },
        gender: {
          label: '¿Cómo te identificas?'
        }
      }
    },
    information: 'DATOS PERSONALES',
    address: 'DIRECCIÓN',
    contact: 'CONTACTO',
    button: 'IR A VER LOS PREMIOS',
    state: 'HISTORIAL DE CANJES',
    identification: {
      nav: 'MI DOSEQUIS ID',
      title: 'DOSEQUIS ID',
      button: 'DESCARGAR DOSEQUIS ID'
    }
  },
  config: {
    prizes: '/prizes'
  },
  congrats: {
    description: <>Tu historia se ha subido con éxito.<br />Has acumulado los siguientes X Points en tu cuenta:</>,
    button1: 'VAMOS A VER LOS PREMIOS',
    button2: 'QUIERO INGRESAR OTRA HISTORIA',
    'button2Link': '/album/redirect/Historia/upload',
    range: {
      title: 'NUEVO LOGRO DESBLOQUEADO',
      description: <></>,
      description2: (
        <>
          Felicitaciones, has desbloqueado nuevos <br /> premios y obtienes más chances de ganar.
        </>
      )
    }
  },
  endroute: {
    title: (
      <>
        ¡Lo sentimos! <br /> Esto es solo <br /> para mayores
      </>
    ),
    description: 'Vuelve cuando sea más grande.'
  },
  referrals: {
    description: 'Comparte tu código con amigos y gana más oportunidades de ganar.',
    subtitle: 'COMPARTE TU CÓDIGO Y GANA',
    left: 'COPIAR',
    right: 'COMPARTIR'
  },
  trivias: {
    exchange: {
      button: {
        text: 'SEGUIR JUGANDO',
        url: '/trivias'
      }
    },
    triviaQr: {
      register: {
        title: 'CONTESTA Y GANA',
        button: 'COMENCEMOS',
        typeImage: 'png'
      },
      late: {
        title: '¡UPS! YA ES TARDE',
        description: (
          <p className="">
            Lamentablemente esta trivia ya no está disponible. Estate atento para contestar una trivia nueva.
          </p>
        ),
        button: {
          text: 'VER MÁS DINÁMICAS'
        }
      }
    },
    questions1: <>Contesta </>,
    questions2: (
      <>
        {' '}
        <span className="font-bold">
          preguntas
        </span>{' '}
        sobre el tema y gana:
      </>
    ),
    "not-found": "No hay trivias disponibles por el momento, estate atento a nuestras nuevas dinámicas",
    "exit": {
      "cancel": "NO, VOLVER",
      "subtitle": "Si cancelas ahora se perderá tu progreso y tendrás que volver a empezar. "
    }
  },
  survey: {
    title: 'CUÉNTANOS DE TI',
    button: 'RESPONDER'
  },
  prizes: {
    start: {
      title: <span>PREMIOS</span>,
      card: {
        points: 'X-POINTS',
      }
    },
    congrats: {
      description: <>Revisa tu email que te vamos a mandar <br /> toda la info del envío de tu canje <br /> ¡Felicidades!</>
    },
    btn: {
      text: 'CANJEAR',
    },
    exchange: {
      button: 'SI CLARO',
      buttonBack: 'VOLVER',
      warning: <>¿Segurísimo que quieres este premio?</>,
      information: {
        description: <p style={{ fontSize: '10px', lineHeight: '140%' }}>*Es necesario que nos dejes estos datos para entregar tu premio</p>,
        confirm: {
          title: <p>¡LISTO, NAME!<br /><p style={{ fontFamily: 'Industry', fontSize: '16px', lineHeight: 'normal', textTransform: 'none' }}>Vamos a enviar tu canje con estos datos:</p></p>
        }
      }
    },
    stockName: 'DISPONIBLES',
  },
  album: {
    title: 'DINÁMICAS',
    notFound: 'No hay retos disponibles por el momento, estate atento a nuestras nuevas dinámicas.',
    states: {
      progress: 'ACTUALES',
      completed: 'PASADOS'
    }
  },
  social: {
    Consumo: {
      howToParticipate: {
        subtitle: 'SUBE UNA FOTO Y PARTICIPA',
        list: (
          <ul className="flex-1 items-center justify-center flex-col flex w-full gap-7 pb-7">
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-1.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-bold text-lg industry leading-6" style={{ color: 'black' }}>
                  Completa tus datos personales
                </p>
                <p className="font-normal text-base leading-5 industry" style={{ color: 'black' }}>
                  en el perfil de tu cuenta.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-7.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-normal text-lg industry leading-6" style={{ color: 'black' }}>
                  <b>Sube una foto</b> de tu cerveza, en lata o botella, y genera X-POINTS con cada imagen que subas.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-5.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="text-lg industry leading-6 font-normal" style={{ color: 'black' }}>
                  <b>Acumula X-POINTS</b> y canjéalos por premios en la vitrina.
                </p>
              </div>
            </li>
            <li className="flex flex-row mx-auto gap-5 items-center px-5 py-4" style={{ backgroundColor: 'rgba(2, 114, 25, 0.08)', border: '1px dashed #027219', borderRadius: '8px' }}>
              <div className='bg-white flex justify-center items-center rounded-full' style={{ width: '56px' }}>
                <img src={`${AZURE_BASE_URL}/icon_unlock.webp`} />
              </div>
              <div className="flex flex-col justify-center">
                <p className="industry leading-normal font-normal" style={{ color: '#027219', fontSize: '12px', maxWidth: '245px', fontFamily: 'Industry-Demi' }}>
                  ¡Completa este reto para desbloquear {" "}
                  <b className='industry'>“Personaliza tu lata”</b> {" "}con la chance de ganar
                  un 6 pack personalizado!
                </p>
              </div>
            </li>
          </ul>
        )
      },
      onBoarding: {
        rules: {
          list: (
            <>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">
                    No subir contenido inapropiado.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">
                    No debe aparecer la marca en un entorno de exceso, peligro o violencia.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">
                    No deben aparecer otras marcas de cerveza.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">Si se realiza más de una publicación que no va con las reglas se inactivará el usuario para que no pueda subir más contenido.</p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">Puedes subir como máximo 2 fotos de consumos consecutivos cada 24hrs.</p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">Puede subir contenido cada 15 minutos acumulativos, es decir cada 15min, 30min, 45min.</p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">Puedes subir hasta 12 fotos de consumos como máximo.</p>
                </div>
              </div>
            </>
          )
        }
      },
      upload: {
        buttonSubmit: 'SI, AÑADIR',
        title: 'CARGAR FOTO',
        subtitle: 'AGREGAR IMAGEN',
        rules: {
          title: 'REGLAS PARA SUBIR CONTENIDO',
          list: (
            <ul style={{ color: '#000', fontSize: '13px', fontFamily: 'Industry' }}>
              <li>
                <p className="flex gap-1">
                  <span>1.</span> Usa la cámara de tu celular para tomarle una foto a tu cerveza.
                </p>
              </li>
              <li className="my-3">
                <p className="flex gap-1">
                  <span>2.</span> Busca un lugar iluminado para tomar la foto.
                </p>
              </li>
              <li>
                <p className="flex gap-1">
                  <span>3.</span> Mantente quieto al momento de tomar la foto para obtener una imagen de buena calidad.
                </p>
              </li>
            </ul>
          )
        },
        'ranking-subtitle': {
          progress: <>Este es el ranking con las historias más votadas. <br /> Dale clic a la que más te guste para votarla.</>,
          completed: <>Este reto ya ha finalizado y no se puede votar, <br /> pero puedes leer las historias ganadoras.</>
        },
        alreadyUploaded: {
          description: 'Se ha agregado correctamente tu foto.',
          button: {
            url: '/album/congrats',
            text: '¡VAMOS!'
          }
        },
        errorModalValidate: {
          description: <>No podemos subir tu consumo<br />correctamente debido a:</>,
          list: <div style={{ width: '320px' }}>
            <div className="industry text-black text-base font-normal">
              <p className='ml-5'> <span className="font-bold">• Llegaste al máximo de contenido que puedes cargar cada 15 minutos. </span> (puede subir contenido cada 15 minutos acumulativos, es decir cada 15min, 30min, 45min.)</p>
            </div>
            <div className="industry text-black text-base font-normal">
              <p className='ml-5'> <span className="font-bold">• Llegaste al máximo de contenido que puedes cargar en 1 día. </span> (puedes subir máx. 2 fotos consecutivas por día)</p>
            </div>
            <div className="industry text-black text-base font-normal">
              <p className='ml-5'> <span className="font-bold">• Llegaste al máximo de contenido que puedes cargar.</span> (puedes subir máx. 12 fotos en total)</p>
            </div>
          </div>,
          help: <div style={{ width: '320px' }} className='flex justify-center items-center flex-col mb-12 industry'>
            <span className="font-bold text-black text-center text-base industry">¿Te parece que no es así?</span>
            <span className="text-center text-black text-base industry">Ponte en contacto con nosotros para que <br /> podamos ayudarte. <Link to="/help" className='underline'>Envíanos un mensaje.</Link> </span>
          </div>
        },
        isReady: '¿Esta OK esta foto?'
      },
      congrats: {
        description: <>Obtuviste <b>VAR_POINTS {POINTS_NAME}</b></>,
        button1: 'IR A PERSONALIZAR MI LATA',
        button2: 'QUIERO SUBIR OTRA FOTO',
        'button2Link': '/album/redirect/Consumo/upload',
        button3: 'QUIERO VER LOS PREMIOS',
        button3Link: '/prizes'
      },
      config: {
        prizes: '/album/redirect/Card/upload'
      }
    },
    Historia: {
      howToParticipate: {
        list: (
          <ul className="flex-1 items-center justify-center flex-col flex w-full gap-7 pb-7">
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-1.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-bold text-lg industry leading-6" style={{ color: 'black' }}>
                  Completa tu perfil
                </p>
                <p className="font-normal text-base leading-5 industry" style={{ color: 'black' }}>
                  Termina de armar tu cuenta con tus datos personales.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-2.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-normal text-lg industry leading-6" style={{ color: 'black' }}>
                  <b>Sube una historia</b> contando
                </p>
                <p className="font-normal text-base leading-5 industry" style={{ color: 'black' }}>
                  {`“Cómo vives con un par de Equis”`} <br /> (por ej. una anecdota en la que tuviste la actitud o tomaste decisiones con un par de Equis).
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-3.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-bold text-lg industry leading-6" style={{ color: 'black' }}>
                  Invita a tus amigos a votar.
                </p>
                <p className="font-normal text-base leading-5 industry" style={{ color: 'black' }}>
                  Quién más votos tenga ganará.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-4.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-bold text-lg industry leading-6" style={{ color: 'black' }}>
                  Mira en qué puesto estás.
                </p>
                <p className="font-normal text-base leading-5 industry" style={{ color: 'black' }}>
                  Checa el ranking para ver cómo va tu historia.
                </p>
              </div>
            </li>
          </ul>
        )
      },
      onBoarding: {
        rules: {
          list: (
            <>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">
                    Tu historia debe tener como máximo 280 caracteres.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">
                    No debe mencionar consumo excesivo de bebidas alcohólicas.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">
                    No debe mencionar a la marca en un entorno de exceso, peligro o violencia.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">No debe mencionar otras marcas de cerveza.</p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">No deben aparecer menores de edad.</p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">No insultar o usar lenguaje grosero.</p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">
                    Puedes subir dos historias en un día y 4 historias como máximo para reclamar puntos.
                  </p>
                </div>
              </div>
            </>
          )
        }
      },
      upload: {
        title: 'Sube tu imagen',
        subtitle: 'CLIC PARA SUBIR TU FOTO',
        rules: {
          title: 'REGLAS PARA SUBIR CONTENIDO',
          list: (
            <ul style={{ color: '#000', fontSize: '13px', fontFamily: 'Industry' }}>
              <li>
                <p className="flex gap-1">
                  <span>1.</span> Debes contarnos como vives con un par de equis en máximo 280 caracteres.
                </p>
              </li>
              <li className="my-3">
                <p className="flex gap-1">
                  <span>2.</span> Recuerda cuidar el lenguaje que usas cuando nos la cuentes.
                </p>
              </li>
              <li>
                <p className="flex gap-1">
                  <span>3.</span> Debe ser una historia en español.
                </p>
              </li>
            </ul>
          )
        },
        'ranking-subtitle': {
          progress: <>Este es el ranking con las historias más votadas. <br /> Dale clic a la que más te guste para votarla.</>,
          completed: <>Este reto ya ha finalizado y no se puede votar, <br /> pero puedes leer las historias ganadoras.</>
        },
        errorModalValidate: {
          list: <div style={{ width: '320px' }}>
            <div className="industry text-black text-base font-normal">
              <p className='ml-5 mb-3'> <span className="font-bold">• Llegaste al máximo de contenido que puedes cargar.</span> (puedes subir máx. 4 historias en total)</p>
              <p className='ml-5'> <span className="font-bold">• Llegaste al máximo de contenido que puedes cargar en 1 día. </span> (puedes subir máx. 2 historias por día)</p>
            </div>
          </div>,
          help: <div style={{ width: '320px' }} className='flex justify-center items-center flex-col mb-12 industry'>
            <span className="font-bold text-black text-center text-base industry">¿Te parece que no es así?</span>
            <span className="text-center text-black text-base industry">Ponte en contacto con nosotros para que <br /> podamos ayudarte. <Link to="/help" className='underline'>Envíanos un mensaje.</Link> </span>
          </div>
        }
      }
    },
    Ticket: {
      howToParticipate: {
        subtitle: 'SUBE TU TICKET Y PARTICIPA',
        list: (
          <ul className="flex-1 items-center justify-center flex-col flex w-full gap-7 pb-7">
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-1.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-bold text-lg industry leading-6" style={{ color: 'black' }}>
                  Completa tus datos personales
                </p>
                <p className="font-normal text-base leading-5 industry" style={{ color: 'black' }}>
                  en el perfil de tu cuenta.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-8.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-normal text-lg industry leading-6" style={{ color: 'black' }}>
                  <b>Sube una foto de tu recibo de compra</b> donde se vea bien el detalle de lo que compraste.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-5.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="text-lg industry leading-6 font-normal" style={{ color: 'black' }}>
                  <b>Acumula X-POINTS</b> y canjéalos por premios en la vitrina.
                </p>
              </div>
            </li>
            <li className="flex flex-row mx-auto gap-5 items-center px-5 py-4" style={{ backgroundColor: 'rgba(2, 114, 25, 0.08)', border: '1px dashed #027219', borderRadius: '8px' }}>
              <div className='bg-white flex justify-center items-center rounded-full' style={{ width: '56px' }}>
                <img src={`${AZURE_BASE_URL}/icon_unlock.webp`} />
              </div>
              <div className="flex flex-col justify-center">
                <p className="industry leading-normal font-normal" style={{ color: '#027219', fontSize: '12px', maxWidth: '245px', fontFamily: 'Industry-Demi' }}>
                  ¡Completa este reto para desbloquear {" "}
                  <b className='industry'>“Personaliza tu lata”</b> {" "}con la chance de ganar
                  un 6 pack personalizado!
                </p>
              </div>
            </li>
          </ul>
        )
      },
      onBoarding: {
        rules: {
          list: (
            <>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">
                    El ticket debe ser de productos Dos Equis en alguna de sus presentaciones (XX Lager, XX Ambar, XXUltra Lager).
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">
                    Se debe visualizar la fecha de compra.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">
                    No se debe cargar un ticket repetido.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">
                    Debe ser de un punto de venta (Walmart/Soriana/Chedraui/HEB/etc). No puede ser de restaurante.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">
                    Puedes subir como máximo dos tickets cada 24hrs.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="text-black font-normal industry text-base">
                    Los tickets validados añadirán puntos al perfil del usuario los cuales podrá usar para canjear premios en la vitrina.
                  </p>
                </div>
              </div>
            </>
          )
        }
      },
      upload: {
        buttonSubmit: 'SI, AÑADIR',
        title: 'CARGAR TICKET',
        subtitle: 'CLIC PARA CARGAR TU RECIBO',
        rules: {
          title: 'REGLAS PARA SUBIR TICKETS',
          list: (
            <ul style={{ color: '#000', fontSize: '13px', fontFamily: 'Industry' }}>
              <li>
                <p className="flex gap-1">
                  <span>1.</span> Usa la cámara de tu celular para tomarle una foto al recibo.
                </p>
              </li>
              <li className="my-3">
                <p className="flex gap-1">
                  <span>2.</span> Busca un lugar iluminado para tomar la foto.
                </p>
              </li>
              <li>
                <p className="flex gap-1">
                  <span>3.</span> Mantente quieto al momento de tomar la foto para obtener una imagen de buena calidad.
                </p>
              </li>
            </ul>
          )
        },
        'ranking-subtitle': {
          progress: <>Este es el ranking con las historias más votadas. <br /> Dale clic a la que más te guste para votarla.</>,
          completed: <>Este reto ya ha finalizado y no se puede votar, <br /> pero puedes leer las historias ganadoras.</>
        },
        alreadyUploaded: {
          description: 'Se ha agregado correctamente tu recibo.',
          button: {
            url: '/album/congrats',
            text: '¡VAMOS!'
          }
        },
        errorModalValidate: {
          description: <>No podemos subir tu recibo<br />correctamente debido a:</>,
          list: <div style={{ width: '320px' }}>
            <div className="industry text-black text-base font-normal">
              <p className='ml-5'> <span className="font-bold">• Llegaste al máximo de recibos que puedes cargar cada 15 minutos. </span> (puede subir recibos cada 15 minutos acumulativos, es decir cada 15min, 30min, 45min.)</p>
            </div>
            <div className="industry text-black text-base font-normal">
              <p className='ml-5'> <span className="font-bold">• Llegaste al máximo de recibos que puedes cargar en 1 día. </span> (puedes subir máx. 2 recibos consecutivas por día)</p>
            </div>
          </div>,
          help: <div style={{ width: '320px' }} className='flex justify-center items-center flex-col mb-12 industry'>
            <span className="font-bold text-black text-center text-base industry">¿Te parece que no es así?</span>
            <span className="text-center text-black text-base industry">Ponte en contacto con nosotros para que <br /> podamos ayudarte. <Link to="/help" className='underline'>Envíanos un mensaje.</Link> </span>
          </div>
        }
      },
      congrats: {
        description: <>Obtuviste <b>VAR_POINTS {POINTS_NAME}</b></>,
        button1: 'IR A PERSONALIZAR MI LATA',
        button2: 'QUIERO INGRESAR OTRA RECIBO',
        'button2Link': '/album/redirect/Ticket/upload',
        button3: 'QUIERO VER LOS PREMIOS',
        button3Link: '/prizes'
      },
      config: {
        prizes: '/album/redirect/Card/upload'
      }
    },
    profile: {
      title: 'MIS HISTORIAS',
      nextArrow: <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="17.5001" r="17.5" transform="rotate(180 17.5 17.5001)" fill="#C4281A" />
        <path d="M25.7071 17.7072C26.0976 17.3167 26.0976 16.6835 25.7071 16.293L19.3431 9.92905C18.9526 9.53853 18.3195 9.53853 17.9289 9.92905C17.5384 10.3196 17.5384 10.9527 17.9289 11.3433L23.5858 17.0001L17.9289 22.657C17.5384 23.0475 17.5384 23.6807 17.9289 24.0712C18.3195 24.4617 18.9526 24.4617 19.3431 24.0712L25.7071 17.7072ZM10 18.0001L25 18.0001L25 16.0001L10 16.0001L10 18.0001Z" fill="white" />
      </svg>,
      prevArrow: <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="17.5" r="17.5" fill="#C4281A" />
        <path d="M9.29289 17.2929C8.90237 17.6834 8.90237 18.3166 9.29289 18.7071L15.6569 25.0711C16.0474 25.4616 16.6805 25.4616 17.0711 25.0711C17.4616 24.6805 17.4616 24.0474 17.0711 23.6569L11.4142 18L17.0711 12.3431C17.4616 11.9526 17.4616 11.3195 17.0711 10.9289C16.6805 10.5384 16.0474 10.5384 15.6569 10.9289L9.29289 17.2929ZM25 17L10 17V19H25V17Z" fill="white" />
      </svg>
    },
    Card: {
      howToParticipate: {
        subtitle: 'CREA TU FRASE Y COMPÁRTELA',
        list: (
          <ul className="flex-1 items-center justify-center flex-col flex w-full gap-7 pb-7">
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/cuenta_icon.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="text-base font-normal industry leading-normal" style={{ fontFamily: 'Industry', color: '#000000' }}>
                  <b>Completa tus datos personales</b> <br /> en el perfil de tu cuenta.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/cargar-frase_icon.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-normal text-base industry leading-normal" style={{ fontFamily: 'Industry', color: '#000000' }}>
                  <b>Crea tu lata personalizada </b> <br /> con una frase personal que <br /> represente tu lado auténtico y <br /> muestre tu auto-confianza.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/compartir_icon.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-normal text-base industry leading-normal" style={{ fontFamily: 'Industry', color: '#000000' }}>
                  <b>Comparte tu nueva creación</b> <br /> Invita a tus amigos a votar, quién <br /> más votos tenga ganará.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/ranking_icon.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-normal text-base industry leading-normal" style={{ fontFamily: 'Industry', color: '#000000' }}>
                  <b>Mira en qué puesto estás</b> <br /> checa el ranking para ver cómo <br /> va tu frase. Habrá nuevos <br /> ganadores cada 15 días.
                </p>
              </div>
            </li>
          </ul>
        )
      },
      onBoarding: {
        rules: {
          list: (
            <>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    No subir contenido inapropiado.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    No nombrar otras marcas de cerveza.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    Si el contenido subido es considerado inapropiado, la plataforma no generará la imagen y se le comunicará al usuario los motivos por los cuales no se generó.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    Se permite subir hasta doce frases, con una posibilidad de ganar en total.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    Puedes subir contenido cada 15 minutos acumulativos, es decir tienes que esperar 15 min desde la primera carga y 30 min para subir la tercera vez.
                  </p>
                </div>
              </div>
            </>
          )
        }
      },
      upload: {
        title: 'Cargar frase',
        subtitle: 'Clic para subir una foto',
        rules: {
          title: 'REGLAS PARA SUBIR TU FRASE',
          list: (
            <ul style={{ color: '#15141A', fontSize: '14px', fontFamily: 'Industry-Book', fontWeight: 400 }}>
              <li>
                <p className="flex gap-1">
                  <span>1.</span> Crea una frase personal de autoconfianza para personalizar tu lata.
                </p>
              </li>
              <li className="my-3">
                <p className="flex gap-1">
                  <span>2.</span> Tiene que ser una frase corta y concreta.
                </p>
              </li>
              <li>
                <p className="flex gap-1">
                  <span>3.</span> Revisa cómo queda la imagen final para aprobarla o volver a escribirla.
                </p>
              </li>
            </ul>
          )
        },
        errorModalValidate: {
          description: <span style={{ fontWeight: 400, fontFamily: 'Industry-Book' }}>En este momento no puedes subir tu frase debido a:</span>,
          list: <div style={{ width: '320px' }}>
            <div style={{ color: 'white', fontFamily: 'Industry-Book' }} className=" text-sm font-normal">
              <p className='ml-5 text-black'> • <b style={{ fontFamily: 'Industry' }}>Aún no pasaron los 30 min. desde que subiste tu última frase.</b> (Son 15 minutos acumulativos desde que subes tu primer imagen).</p>
              <p className='ml-5 mb-3 text-black'> • <b style={{ fontFamily: 'Industry' }}>Llegaste al máximo de contenido que puedes cargar.</b> (puedes subir máx. 3 frases)</p>
            </div>
          </div>,
          help: <div style={{ width: '320px', color: 'black', fontFamily: 'Industry' }} className='flex justify-center items-center flex-col mb-12  pt-4'>
            <span className="font-bold text-center text-sm">¿Te parece que no es así?</span>
            <span className="text-center text-sm" style={{ fontFamily: 'Industry-Book' }}>Ponte en contacto con nosotros para que <br /> podamos ayudarte. <Link to="/help" className='underline'>Envíanos un mensaje.</Link> </span>
          </div>
        },
        icon: <svg width="80" height="84" viewBox="0 0 80 84" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="37.9841" y="12.8002" width="3.21363" height="57.2" fill="#DA2B1F" />
          <rect x="37.9841" y="12.8002" width="3.21363" height="57.2" fill="#DA2B1F" />
          <rect x="37.9841" y="12.8002" width="3.21363" height="57.2" fill="#DA2B1F" />
          <rect x="11.2036" y="43.019" width="3.23774" height="56.7742" transform="rotate(-90 11.2036 43.019)" fill="#DA2B1F" />
          <rect x="11.2036" y="43.019" width="3.23774" height="56.7742" transform="rotate(-90 11.2036 43.019)" fill="#DA2B1F" />
          <rect x="11.2036" y="43.019" width="3.23774" height="56.7742" transform="rotate(-90 11.2036 43.019)" fill="#DA2B1F" />
        </svg>,
        isReady: '¿Está ok esta imagen?',
        'ranking-subtitle': {
          'progress': 'Este es el ranking con las frases más votadas. Dale clic a la que más te guste para votarla.',
          'completed': 'Este reto ya ha finalizado y no se puede votar, pero puedes ver las frases ganadoras.'
        },
        alreadyUploaded: {
          icon: <>
            <svg width="125" height="125" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="62.084" cy="62.084" r="60.084" stroke="#FFCC71" strokeWidth="4" />
              <path d="M26 56L55 85L98 42" stroke="#FFCC71" strokeWidth="6" strokeLinecap="square" />
            </svg>
          </>,
          description: 'Se ha agregado correctamente tu contenido.',
          button: {
            url: '/album/redirect/Card/profile',
            text: 'Ver mi contenido'
          },
          button2: {
            url: '/album/redirect/Card/ranking',
            text: 'Ver galería'
          },
          buttonBack: {
            text: 'CARGAR OTRA IMAGEN'
          }
        }
      },
      profile: {
        button: {
          text: 'Agregar nueva frase'
        },
        return: {
          url: '/album/:id/ranking',
          text: 'IR A VOTAR'
        },
        shared: {
          text: 'UNETÉ A UN PARO PARA QUE LA CUESTA NO CUESTE ES PONERTE ESTE KIT Y TÚ LA TANDA',
          url: `${URL_BASE}${process.env.PUBLIC_URL}/album/redirect/Card/ranking`
        },
        title: 'MI CONTENIDO'
      },
      title: 'Cargar frase',
      maxLength: 55,
      example: {
        list: (
          <>
            <h3 className='album__card__example__list-title font-bold'>Así quedaría la imagen final con tu frase</h3>
            <ul className='album__card__example__ul flex gap-3 flex-col'>
              <li>Te sugerimos que pienses una <b>frase</b> que no sea muy larga, guíate por la cantidad de renglones y como va ocupando la lata el texto que ingrese.</li>
              <li><b>Firma</b> tu frase con tu nombre o apodo, tienes 10 caracteres cómo máximo.</li>
              <li><b>Esta no es una muestra de la lata de premio</b>, si no una imagen de referencia para que veas como queda la imagen generada en esta plataforma para el concurso.</li>
            </ul>
          </>
        )
      },
      congrats: {
        description: <>Obtuviste <b>VAR_POINTS {POINTS_NAME}</b></>,
        button1: 'VER mi contenido',
        button2: 'Quiero ingresar otra frase',
        button2Link: '/album/redirect/Card/upload'
      },
      config: {
        prizes: '/album/redirect/Card/profile'
      },
      isLocked: {
        description: (
          <div className='flex flex-col gap-5'>
            <p className='text-center industry'>
              Para desbloquearlo tienes que <br /> completar alguno de los otros retos, <br /> (cargar ticket o foto de tu compra).
            </p>

            <p className='text-center industry'>Vuelve cuando hayas completado algún <br /> reto anterior.</p>
          </div>
        )
      },
      ranking: {

      }
    }
  },
  modal: {
    list: <div style={{ width: '320px' }}>
      <div className="industry text-black text-base font-normal">
        <p className='ml-5 mb-3'> <span className="font-bold">• Llegaste al máximo de contenido que puedes cargar.</span> (puedes subir máx. 4 historias en total)</p>
        <p className='ml-5'> <span className="font-bold">• Llegaste al máximo de contenido que puedes cargar en 1 día. </span> (puedes subir máx. 2 historias por día)</p>
      </div>
    </div>,
    help: <div style={{ width: '320px' }} className='flex justify-center items-center flex-col mb-12 industry'>
      <span className="font-bold text-black text-center text-base industry">¿Te parece que no es así?</span>
      <span className="text-center text-black text-base industry">Ponte en contacto con nosotros para que <br /> podamos ayudarte. <Link to="/help" className='underline'>Envíanos un mensaje.</Link> </span>
    </div>
  },
  vote: {
    title: 'VOTA LA QUE MÁS TE GUSTA',
    success: <>Imagen votada <br /> con éxito</>,
    btn: <>VOLVER AL RANKING</>
  },
  error: {
    79: {
      message: <>Ya has votado <br /> esta imagen</>,
      description: 'Intenta con otra diferente.'
    },
    1114: {
      title: <>No hemos podido <br /> validar tu FOTO</>
    },
    71: {
      title: <>¡Ups! <br /> el código ha <br /> expirado </>,
      description: <>Dale click en <b>REENVIAR</b> para recibir <br /> otro código e intentarlo de nuevo.</>
    },
    153: {
      title: '¡Espera tantito!',
      description: 'No puedes avanzar si no tienes los datos completos. Revisa que todos los campos estén completos con tus datos correctos.',
      button: {
        text: 'REVISAR MIS DATOS'
      }
    }
  },
  help: {
    title: <span style={{ fontFamily: 'Fresno' }}>¿más dudas?</span>
  },
  otp: {
    title: <span className='text-black text-center industry text-base uppercase font-bold'>Ingresa el código de 6 dígitos QUE <br /> RECIBISTE para verificar tu celular.</span>,
    received: '¿No lo recibiste?',
    resend: 'Reenviar código'
  },
  header: {
    pointsText: 'Tienes'
  }
}