import React from 'react';
import { Texts } from 'texts';

class TextServices {

  private _texts: {
    [key: string]: any;
  };

  constructor(path?: string) {
    this._texts = this.findJson(path);
  }
    
  findJson(path?: string) {
    const textRaw = Texts[path]
    if (!textRaw) throw new Error('Texts not found');
    this._texts = textRaw;
    return textRaw;
  }

  findByText(path: string, defaultValue?: string | JSX.Element, replace?: { [key: string]: string }) {
    const pathSeparated = path.split('/');
    const text = pathSeparated.reduce((acc: any, item: string) => {
      if (!acc || !acc[item]) return null;
      return acc[item];
    }, this._texts);
  
    if (!text) {
      return defaultValue ?? path;
    }
  
    let replacedText = text;
  
    if (replace && text !== ' ') {
      Object.keys(replace).forEach((key) => {
        const value = replace[key];

        function mapChildren(children: any) {
          return React.Children.map(children, (child: any) => {
            if (typeof child === 'string') {
              return child.replace(new RegExp(key, 'g'), value);
            } else if (React.isValidElement(child)) {
              return React.cloneElement(child, {}, mapChildren((child.props as any)?.children));
            } else {
              return child;
            }
          });
        }
        
        // Reemplazar en el texto
        if (typeof text === 'string') {
          replacedText = replacedText.replace(new RegExp(key, 'g'), value);
        }
  
        // Reemplazar en el JSX.Element
        if (React.isValidElement(replacedText)) {
          const children = mapChildren(replacedText)
  
          replacedText = React.cloneElement(replacedText, {}, children);
        }
      });
    }
  
    return replacedText;
  }

}

const textServices = new TextServices(THEME);

export default textServices