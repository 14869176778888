/* eslint-disable indent */

import { MouseEventHandler, useContext, useEffect, useState, VoidFunctionComponent } from 'react'
import axios, { AxiosError } from 'axios'
import ExchangeComponent from 'components/exchange'
import ButtonCloseMenu from 'components/button-close'
import { useHistory } from 'react-router-dom'
import ErrorView from 'components/error-view'
import textServices from 'services/text-services'
import { useCountDownSeconds } from 'hooks/use-countdown'
import useMainClass from 'hooks/use-main-cass'
import LoadingScreen from 'components/loading-screen'
import { dlTrackError, dlTrackEvent } from 'components/dataLayer/dataLayer'
import { useTrivia } from '../../../contexts/trivia-context'
import { Trivia } from '../../../contexts/trivia-context/reducer'
import { LoaderContext } from '../../../contexts/loader-context'
import {
  DivSelector,
  LiSelectorOption,
  DivSelectOption,
  DivTitleTriviaList,
  H2TitleTriviaList,
  QuestionHeader,
  QuestionNumber,
  QuestionTitle,
  TriviaCountdown,
  TriviaTitle,
  TriviaNumber,
  CrownTrivia,
  QuestionImgContainer,
  QuestionImg,
  QuestionImgTitleContainer,
  QuestionImgTitle,
  ColorCountdown,
  CountdownContainer,
  QuestionContainerTwoQuestions,
  ImgLarge,
  OptionStyle,
  LiBorderColor
} from '../../../theme/trivias'
import AlertPopUp from './AlertPopup'


const OptionSelector: VoidFunctionComponent<{ trivia: Trivia }> = ({ trivia }) => {

  const [end, setEnd] = useState(trivia.timeInSeconds)
  const [countdown, startCountdown, pauseCountdown, resetCountdown] = useCountDownSeconds(end, 1000)
  const history = useHistory();
  const [question, setQuestion] = useState(1)
  const [selected, setSelected] = useState<null | number>(0) // Se default value to 0
  const { load, setLoad } = useContext(LoaderContext)
  const [state, { answerTrivia, resetTrivia, exchangePrize, validateTrivia }] = useTrivia()
  const [errorTrivia, setErrorTrivia] = useState<null | number>(null)
  const [executed, setExecuted] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<string>(null)

  useMainClass('trivias__trivia__trivia-game__selector')

  const { question: q } = trivia

  const selectOption: MouseEventHandler<HTMLLIElement> = (event) => {
    gtag('event', 'event_to_ga', { event_category: "Trivias", event_label: `Seleccionar Opción`, value: state?.currentTrivia?.id })
    dlTrackEvent('user_interaction', { action: 'click', element: 'selecciona opcion', section: `trivia :: ${state?.currentTrivia?.id}` })
    const { currentTarget } = event

    const id = currentTarget.getAttribute('data-id') as string

    setSelected(parseInt(id, 10))
  }

  useEffect(() => {
    if ((!countdown || selected) && !state.prize && !state.error) {
      setSelected(0)
      pauseCountdown()
      setTimeout(
        async () => {
          setLoad(true)
          try {
            if (!executed) {
              const shouldSetNext = await answerTrivia(trivia.id, q.questionId, selected as number)

              if (shouldSetNext) {
                //  Set in 0 to force the update of status InProcess to Fail in case that no option will selected in next question
                setQuestion((q) => q + 1)

                setEnd(trivia.timeInSeconds)
                resetCountdown()
                startCountdown()
              } else {
                dlTrackEvent('user_interaction', { action: 'click', element: 'Ganó trivia', section: `trivia :: ${state?.currentTrivia?.id}` })
                history.block(true)
              }
            }
          } catch (error) {
            history.block(true)
            gtag('event', 'event_to_ga', { event_category: "Trivias", event_label: `Erró al contestar`, value: state?.currentTrivia?.id })
            dlTrackError({ error_type: 'sign up', error_code: '', error_message: 'erró al contestar', section: `trivia :: ${state?.currentTrivia?.id}` })

            if (axios.isAxiosError(error)) {
              const err = error as AxiosError
              setErrorTrivia(err?.response?.data?.code)
              setExecuted(!executed)
              setEnd(0)
            }
          } finally {
            setLoad(false)
          }
        },
        selected ? 0 : 1000
      )
    }
  }, [countdown, selected])

  useEffect(() => {
    startCountdown()

    return () => {
      resetTrivia()
      resetCountdown()

    }
  }, [])

  useEffect(() => {
    const unblock = history.block((location) => {
      pauseCountdown()
      setShowAlert(location?.pathname)
      return false
    });

    return () => {
      unblock();
    };
  }, [history]);

  const handleRetryAlert = () => {
    setShowAlert(null)
    startCountdown()
  }

  const handleNavigateAlert = async () => {
    setLoad(true)
    await validateTrivia(trivia?.id || 0, trivia?.directAccess)
    history.block(true)
    history.push(showAlert)
  }

  const handleRetry = () => {
    const event = new CustomEvent('background-color', {
      detail: {
        shouldUseWhiteBg: true,
        from: 'trivia error close'
      }
    })

    gtag('event', 'event_to_ga', { event_category: "Trivias", event_label: `Vuelva a Intentarlo`, value: state?.currentTrivia?.id })
    dlTrackEvent('user_interaction', { action: 'click', element: 'vuelve a intentarlo', section: `trivia :: ${state?.currentTrivia?.id}` })
    window.dispatchEvent(event)

    if (trivia.directAccess) {
      window.onbeforeunload = null
      window.location.reload()
    } else {
      resetTrivia()
      if ((errorTrivia || state?.error?.response?.data.code) === 505) return history.push(textServices.findByText('error/505/button/link', '/home'))
      history.push('/trivias')
    }
  }


  const exitTrivia = () => {
    setShowAlert(location?.pathname)

    dlTrackEvent('user_interaction', {
      action: 'click',
      element: 'trivia :: cerrar',
      section: 'trivia'
    })
  }

  if (errorTrivia || state?.error) {
    return <ErrorView code={errorTrivia || (state?.error.response?.data.code ?? 9999)} buttonMenu={handleRetry} onRetry={handleRetry} />
  }

  if (state.prize) {
    return (
      <ExchangeComponent
        buttons={{
          text: state?.congrats?.buttonText,
          handleMenu: () => history.push('/trivias'),
          handleButtonCommon: () => {
            gtag('event', 'event_to_ga', { event_category: "Trivias", event_label: state?.congrats?.buttonText ?? textServices.findByText('trivias/exchange/button/text', 'VER MIS PREMIOS'), value: state?.currentTrivia?.id })
            dlTrackEvent('user_interaction', { action: 'click', element: state?.congrats?.buttonText ?? textServices.findByText('trivias/exchange/button/text', 'VER MIS PREMIOS'), section: `trivia :: ${state?.currentTrivia?.id}` })
            exchangePrize(state.prize?.points || 0)
          },
          handleButtonSticker: () => {
            gtag('event', 'event_to_ga', { event_category: "Trivias", event_label: state?.congrats?.buttonText ?? textServices.findByText('trivias/exchange/button/text', 'VER MIS SELLOS'), value: state?.currentTrivia?.id })
            dlTrackEvent('user_interaction', { action: 'click', element: state?.congrats?.buttonText ?? textServices.findByText('trivias/exchange/button/text', 'VER MIS PREMIOS'), section: `trivia :: ${state?.currentTrivia?.id}` })
            history.push(state?.congrats?.urlRedirect ?? textServices.findByText('trivias/exchange/button/url', '/collections'))
          },
          dontShowButtons: THEME_CONFIG?.trivia?.dontShowButtonExchange,
        }}
        item={state?.prize}
        module='trivias'
      />
    )
  }

  if (load) {
    return <></>
  }

  if (!q?.options) {
    return <LoadingScreen />
  }

  if (showAlert) {
    return (
      <AlertPopUp
        handleRetry={() => {
          gtag('event', 'event_to_ga', { event_category: 'volver_a_intentar_trivia', event_label: 'Salir del trivia' })
          handleRetryAlert()
        }}
        handleNavigate={() => {
          gtag('event', 'event_to_ga', { event_category: 'abandonar_trivia', event_label: 'Salir del trivia' })
          handleNavigateAlert()
        }}
      />
    )
  }
  if (q?.options?.length === 2 && q?.options[0]?.imageUrl !== '' && q?.options[1]?.imageUrl !== '') {
    return (
      <>
        <ButtonCloseMenu paramFn={exitTrivia} />
        <DivSelector className="overflow-hidden w-full flex flex-col trivia-game-cnt">
          <DivTitleTriviaList className={` flex justify-start items-center trivia-game-head`}>
            <H2TitleTriviaList className="font-bold uppercase text-lg py-4 pl-6 pr-4 trivia-game-head-int">{trivia.interest}</H2TitleTriviaList>
            <TriviaTitle className="text-sm py-4 pl-4 pr-6 uppercase trivia-game-head-name">{trivia.title}</TriviaTitle>
          </DivTitleTriviaList>
          <QuestionHeader className={`flex justify-start items-center trivia-game-head-question`}>
            <QuestionNumber className="uppercase text-lg py-4 pl-6 pr-4 trivia-game-head-question-num">
              {question}{")"}
            </QuestionNumber>
            <QuestionTitle className="uppercase text-sm py-4 pl-4 pr-6 trivia-game-head-question-text">{trivia.question?.question}</QuestionTitle>
          </QuestionHeader>

          <div className="flex flex-col gap-4 mx-5 mt-10 trivia-game-body">
            {q.options.map((option) => {
              const isSelected = option.optionId === selected
              return (
                <QuestionContainerTwoQuestions
                  isSelected={isSelected}
                  key={`option-${q.question.replace(/-/g, '')}-${option.optionId}`}
                  data-id={option?.optionId}
                  // @ts-ignore
                  onClick={selectOption}
                  className='trivia-game-body-image'
                >
                  <ImgLarge
                    src={option?.imageUrl ?? `${AZURE_BASE_URL}/default.svg`}
                    alt="img"
                  />
                  <QuestionImgTitleContainer className='trivia-game-body-image-option'>
                    <QuestionImgTitle className='trivia-game-body-image-option-text'>{option.option}</QuestionImgTitle>
                  </QuestionImgTitleContainer>
                </QuestionContainerTwoQuestions>
              )
            })}
          </div>

          <div className="flex flex-1 justify-center items-center trivia-game-footer">
            <CountdownContainer
              className="relative w-20 h-20 z-10 trivia-game-footer-countdown"
              style={{
                backgroundImage: `url('${AZURE_BASE_URL}/timer.svg')`,
                backgroundSize: 'contain'
              }}
            >
              <ColorCountdown className='trivia-game-footer-countdown-text'>
                {countdown}
              </ColorCountdown>
            </CountdownContainer>
          </div>
        </DivSelector>
      </>
    )
  } else if (
    q?.options?.length >= 3 &&
    q?.options[0]?.imageUrl !== '' &&
    q?.options[1]?.imageUrl !== '' &&
    q?.options[2]?.imageUrl !== '' &&
    q?.options[3]?.imageUrl !== ''
  ) {
    return (
      <>
        <ButtonCloseMenu paramFn={exitTrivia} />
        <DivSelector className="overflow-hidden w-full flex flex-col trivia-game-cnt">
          <DivTitleTriviaList className={` flex justify-start items-center trivia-game-head`}>
            <H2TitleTriviaList className="font-bold uppercase text-lg py-4 pl-6 pr-4 trivia-game-head-int">{trivia.interest}</H2TitleTriviaList>
            <TriviaTitle className="text-sm py-4 pl-4 pr-6 uppercase trivia-game-head-name">{trivia.title}</TriviaTitle>
          </DivTitleTriviaList>
          <QuestionHeader className={`flex justify-start items-center trivia-game-head-question`}>
            <QuestionNumber className="uppercase text-lg py-4 pl-6 pr-4 trivia-game-head-question-num">
              {question}{")"}
            </QuestionNumber>
            <QuestionTitle className="uppercase text-sm py-4 pl-4 pr-6 trivia-game-head-question-text">{trivia.question?.question}</QuestionTitle>
          </QuestionHeader>

          <div className="grid grid-cols-2 gap-4 place-items-center mx-5 mt-5 trivia-game-body">
            {q.options.map((option) => {
              const isSelected = option.optionId === selected
              return (
                <QuestionImgContainer
                  isSelected={isSelected}
                  key={`option-${q.question.replace(/-/g, '')}-${option.optionId}`}
                  data-id={option.optionId}
                  // @ts-ignore
                  onClick={selectOption}
                  className='trivia-game-body-image'
                >
                  <QuestionImg
                    src={option?.imageUrl ?? `${AZURE_BASE_URL}/default.svg`}
                    alt="img"
                  />
                  <QuestionImgTitleContainer className='trivia-game-body-image-option'>
                    <QuestionImgTitle className='trivia-game-body-image-option-text'>{option?.option}</QuestionImgTitle>
                  </QuestionImgTitleContainer>
                </QuestionImgContainer>
              )
            })}
          </div>
          <div className="flex flex-1 justify-center items-center trivia-game-footer">
            <CountdownContainer
              className="relative w-20 h-20 z-10 trivia-game-footer-countdown"
              style={{
                backgroundImage: `url('${AZURE_BASE_URL}/timer.svg')`,
                backgroundSize: 'contain'
              }}
            >
              <ColorCountdown className='trivia-game-footer-countdown-text'>
                {countdown}
              </ColorCountdown>
            </CountdownContainer>
          </div>
        </DivSelector>
      </>
    )
  } else {
    return (
      <>
        <ButtonCloseMenu paramFn={exitTrivia} />
        <DivSelector className="overflow-y-auto w-full flex flex-col trivia-game-cnt">
          <DivTitleTriviaList className={` flex justify-start items-center trivia-game-head`}>
            <H2TitleTriviaList className="font-bold uppercase text-lg py-4 pl-6 pr-4 trivia-game-head-int">
              {trivia.interest}
            </H2TitleTriviaList>
            {/* <h2 className="uppercase text-sm py-4 pl-4 pr-6">{trivia.title}</h2> */}
            <TriviaTitle className="text-sm py-4 pl-4 pr-6 uppercase trivia-game-head-name">{trivia.title}</TriviaTitle>

          </DivTitleTriviaList>
          <QuestionHeader className={`flex justify-start items-center trivia-game-head-question`}>
            <QuestionNumber className="uppercase text-lg py-4 pl-6 pr-4 trivia-game-head-question-num">
              {question}{")"}
            </QuestionNumber>
            <QuestionTitle className="uppercase text-sm py-4 pl-4 pr-6 trivia-game-head-question-text">{q?.question}</QuestionTitle>
          </QuestionHeader>
          <ul className='trivia-game-body'>
            {q?.options.map((option) => {
              const isSelected = option?.optionId === selected
              return (
                <LiSelectorOption
                  className={`h-16 px-8 flex items-center border-t transition-colors trivia-game-body-selector`}
                  isSelected={isSelected}
                  key={`option-${q?.question.replace(/-/g, '')}-${option?.optionId}`}
                  data-id={option?.optionId}
                  onClick={selectOption}
                >
                  <LiBorderColor
                    className={`w-6 h-6 border mr-6 circle rounded-full transition-all border-black flex justify-center items-center trivia-game-body-selector-li ${isSelected ? '' : 'opacity-40'
                      }`}
                  >
                    <DivSelectOption className={`w-4 h-4 circle rounded-full transition-all trivia-game-body-selector-li-selected`} isSelected={isSelected} />
                  </LiBorderColor>
                  <OptionStyle className='trivia-game-body-selector-text'>{option?.option}</OptionStyle>
                </LiSelectorOption>
              )
            })}
          </ul>

          <div className="flex flex-1 justify-center items-center trivia-game-footer">
            <div className="z-10">
              <CrownTrivia className="w-20 h-20 flex justify-center items-center trivia-game-footer-countdown" style={{ backgroundSize: 'contain' }}>
                <TriviaNumber
                  className="text-2xl text-center"
                >
                  <TriviaCountdown className='trivia-game-footer-countdown-text'>
                    {countdown}
                  </TriviaCountdown>
                </TriviaNumber>
              </CrownTrivia>
            </div>
          </div>
        </DivSelector>
      </>
    )
  }
}

export default OptionSelector
