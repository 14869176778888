import Header from 'components/header'
import ButtonCloseMenu from 'components/button-close'
import { SubTitle, Title } from 'theme/theme'

const Arrow = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12.5" r="9.5" fill="white" />
      <path
        d="M12.5 2.46875C14.3392 2.46875 16.0482 2.93262 17.627 3.86035C19.1569 4.75553 20.3695 5.9681 21.2646 7.49805C22.1924 9.07682 22.6562 10.7858 22.6562 12.625C22.6562 14.4642 22.1924 16.1732 21.2646 17.752C20.3695 19.2819 19.1569 20.4945 17.627 21.3896C16.0482 22.3174 14.3392 22.7812 12.5 22.7812C10.6608 22.7812 8.95182 22.3174 7.37305 21.3896C5.8431 20.4945 4.63053 19.2819 3.73535 17.752C2.80762 16.1732 2.34375 14.4642 2.34375 12.625C2.34375 10.7858 2.80762 9.07682 3.73535 7.49805C4.63053 5.9681 5.8431 4.75553 7.37305 3.86035C8.95182 2.93262 10.6608 2.46875 12.5 2.46875ZM12.5 4.03125C10.9375 4.03125 9.48893 4.42188 8.1543 5.20312C6.86849 5.9681 5.8431 6.99349 5.07812 8.2793C4.29688 9.61393 3.90625 11.0625 3.90625 12.625C3.90625 14.1875 4.29688 15.6361 5.07812 16.9707C5.8431 18.2565 6.86849 19.2819 8.1543 20.0469C9.48893 20.8281 10.9375 21.2188 12.5 21.2188C14.0625 21.2188 15.5111 20.8281 16.8457 20.0469C18.1315 19.2819 19.1569 18.2565 19.9219 16.9707C20.7031 15.6361 21.0938 14.1875 21.0938 12.625C21.0938 11.0625 20.7031 9.61393 19.9219 8.2793C19.1569 6.99349 18.1315 5.9681 16.8457 5.20312C15.5111 4.42188 14.0625 4.03125 12.5 4.03125ZM13.0615 7.62012L18.0664 12.625L13.0615 17.6299L11.9385 16.5312L15.0635 13.4062H7.03125V11.8438H15.0635L11.9385 8.71875L13.0615 7.62012Z"
        fill="#15636A"
      />
    </svg>
  )
}

export const silverOnTrade = {
  legalage: {
    title: ' ',
    button1: <span style={{ fontSize: '14px' }}>SI, SOY MAYOR</span>,
    button2: <span style={{ fontSize: '14px' }}>NO, SOY MENOR</span>,
    question: '¿Eres mayor de edad?'
  },
  agegate: {
    title: '¿Listo para ganar con Carta Blanca?'
  },
  signup: {
    title: 'Bienvenido',
    description:
      'Ahora eres parte del equipo. Revisa tus correos para comenzar a registrar tus latas para completar la colección. Consigue las 26 latas de Amstel Ultra y descubre los mensajes de Rafa Nadal.',
    button: 'SIGUIENTE',
    welcome: {
      subtitle: 'Solo nos falta validar tu cuenta',
      description: 'Te enviamos un código de 6 dígitos a tu correo electrónico para que valides.'
    }
  },
  start: {
    signup: 'REGÍSTRATE CON CORREO',
    signin: 'INGRESA CON CORREO',
    social: 'Entra con:',
    title: {
      html: (
        <div className="flex-1 w-full pb-2 flex flex-col items-center justify-center">
          <img src={`${AZURE_BASE_URL}/fondo-inicial.jpg`} alt="background" className="w-full" />
        </div>
      )
    }
  },
  home: {
    title: (
      <span className="font-bold" style={{ color: '#007118', fontSize: '30px' }}>
        ¿Qué quieres hacer?
      </span>
    ),
    description: '¡Completa tus datos!',
    popup: {
      two: {
        mobile: 'https://stblobphcentral.blob.core.windows.net/silver/assets/popup-2-mobile.png',
        desktop: 'https://stblobphcentral.blob.core.windows.net/silver/assets/popup-2-desktop.png',
        'desktop-1500': 'https://stblobphcentral.blob.core.windows.net/silver/assets/popup-2-desktop-1500.png'
      }
    }
  },
  profile: {
    form: {
      information: {
        firstname: {
          label: '¿Cómo te llamas?',
          placeholder: 'Pon tu nombre aquí'
        },
        lastname: {
          placeholder: 'Pon tu apellido aquí'
        },
        nickname: {
          label: '¿Cómo te gusta que te digan?',
          placeholder: '¿Como le gusta que le digan?'
        },
        gender: {
          label: '¿Cómo te identificas?'
        },
        email: {
          label: 'Correo electrónico'
        }
      }
    },
    information: 'DATOS PERSONALES',
    address: 'DIRECCIÓN',
    contact: 'CONTACTO',
    button: 'IR A MIS IMÁGENES',
    state: 'ESTADO DE CUENTA',
    identification: {
      nav: 'MI SILVER ID',
      title: 'SILVER ID',
      button: 'DESCARGAR SILVER ID'
    }
  },
  config: {
    prizes: '/album/redirect/Foto/profile'
  },
  congrats: {
    range: {
      title: 'NUEVO LOGRO DESBLOQUEADO',
      description: <></>,
      description2: (
        <>
          Felicitaciones, has desbloqueado nuevos <br /> premios y obtienes más chances de ganar.
        </>
      )
    }
  },
  endroute: {
    title: (
      <>
        ¡Lo sentimos! <br /> Esto es sólo <br /> para mayores
      </>
    ),
    description: 'Vuelve cuando sea más grande.'
  },
  referrals: {
    description: 'Comparte tu código con amigos y gana más oportunidades de ganar.',
    subtitle: 'COMPARTE TU CÓDIGO Y GANA',
    left: 'COPIAR',
    right: 'COMPARTIR'
  },
  trivias: {
    'not-found': <>No hay trivias disponibles <br /> por el momento, estate <br /> atento a nuestras nuevas <br /> dinámicas.</>,
    exchange: {
      button: {
        text: 'SUBIR OTRA IMAGEN',
        url: '/album/redirect/Foto/upload'
      }
    },
    triviaQr: {
      register: {
        title: (
          <div className="text-white">
            CONTESTA
            <br />
            <span style={{ color: '#CCB988' }}>Y GANA</span>
          </div>
        ),
        description: (
          <div className="text-white">
            <p>¿Ya tienes tu CRISTAL ID?</p>
            <b className="mt-3">SI NO LO TIENES CÓRRELE A REGISTRARTE PARA PODER PARTICIPAR</b>
            <img
              className="mx-auto mt-5"
              src="https://promohubstorage.blob.core.windows.net/cristal/assets/crista_id_ej.svg"
            />
          </div>
        ),
        html: (
          <>
            <div className="flex-1 w-full flex px-7 items-center justify-center text-center">
              <h1 className="source-serif-pro text-5xl font-bold text-white">
                Contesta
                <br />
                <span style={{ color: '#15636A' }}>y gana</span>
              </h1>
            </div>
            <img src={`${AZURE_BASE_URL}/trivia-qr-footer.png`} />
          </>
        )
      },
      late: {
        title: (
          <>
            ¡Ups!
            <br />
            Ya es tarde
          </>
        ),
        description: <p className="text-white">Lamentablemente esta trivia ya no está disponible.</p>
      }
    },
    questions1: <>Contesta </>,
    questions2: (
      <>
        {' '}
        <span className="font-bold" style={{ color: '#15636A' }}>
          preguntas
        </span>{' '}
        sobre el tema y gana:
      </>
    ),
    exit: {
      accept: "Si, claro",
      cancel: "No, volver",
      subtitle: 'Si cancelas ahora se perderá tu progreso y tendrás que volver a empezar.'
    }
  },
  tivias: {
    challenge: {
      modal: {
        description: '¿Seguro que quieres empezar a jugar? Una vez que des click no hay vuelta atrás.'
      }
    }
  },
  survey: {
    title: 'CUÉNTANOS DE TI',
    button: 'RESPONDER'
  },
  mail: {
    description: (
      <>
        Has confirmado con éxito tu cuenta. <br /> Ya puedes ingresar.
      </>
    )
  },
  prizes: {
    start: {
      title: <span style={{ fontFamily: 'Montserrat', fontWeight: 800, fontSize: '25px' }}>PREMIOS</span>
    }
  },
  'coming-soon': {
    html: ({ logout }) => {
      return (
        <div className="w-full min-h-view flex flex-col">
          <Header />
          <ButtonCloseMenu open={true} paramFn={logout} />
          <section className="flex-1 flex items-center justify-center flex-col" style={{ background: '#00A7B5' }}>
            <div className="flex-1 flex flex-col items-center justify-center gap-8 pt-28">
              <Title className="font-black text-5xl text-white">Próximamente</Title>
              <SubTitle className="font-semibold text-3xl text-center text-white">
                Podrás ver todas
                <br />
                nuestras dinámicas
                <br />
                aquí.
              </SubTitle>
            </div>
            <img src={`${AZURE_BASE_URL}/cs-image.png`} alt="nubes" className="w-full max-w-lg" />
          </section>
        </div>
      )
    }
  },
  album: {
    title: 'Retos',
    notFound: 'No hay retos activos en este momento',
    states: {
      progress: 'ACTUALES',
      completed: 'PASADOS'
    }
  },
  popUps: {
    start: {
      html: (
        <div className="flex-1 flex flex-col gap-5 items-center pt-5">
          <h1 className="font-bold text-3xl source-serif-pro">¿Cómo participar?</h1>
          <p className="source-serif-pro text-xl font-normal" style={{ color: '#00A7B5' }}>
            SUBE TU FOTO Y PARTICIPA
          </p>
          <ul className="flex-1 items-center justify-center flex-col flex w-full gap-7">
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/start-popup-1.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-bold text-lg source-serif-pro leading-4" style={{ color: '#00A7B5' }}>
                  Regístrate.
                </p>
                <p className="font-normal text-sm source-serif-pro" style={{ color: '#00A7B5' }}>
                  Crea tu cuenta.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/start-popup-3.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-bold text-lg source-serif-pro leading-4" style={{ color: '#00A7B5' }}>
                  Participa.
                </p>
                <p className="font-normal text-sm source-serif-pro" style={{ color: '#00A7B5' }}>
                  Sube tu foto de Heineken Silver.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/start-popup-2.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-bold text-lg source-serif-pro leading-4" style={{ color: '#00A7B5' }}>
                  Contesta correctamente.
                </p>
                <p className="font-normal text-sm source-serif-pro" style={{ color: '#00A7B5' }}>
                  y gana uno de los 3,000 vasos metálicos con una lata de regalo.
                </p>
              </div>
            </li>
          </ul>
          <img src={`${AZURE_BASE_URL}/start-popup-footer.png`} />
        </div>
      )
    }
  },
  social: {
    Foto: {
      upload: {
        alreadyUploaded: {
          description: <>Para completar tu participación <br /> contesta la pregunta de la semana.</>,
          button: {
            text: '¡VAMOS!',
            url: '/trivias'
          }
        },
        title: 'Sube tu imagen',
        subtitle: 'CLIC PARA SUBIR TU FOTO',
        rules: {
          title: 'REGLAS PARA SUBIR CONTENIDO',
          list: (
            <ul style={{ color: '#007118', fontSize: '13px', fontFamily: 'Helvetica' }}>
              <li>
                <p className="flex gap-1">
                  <span>1.</span> Usa la cámara de tu celular para tomarle una foto a tu cerveza Heineken Silver.
                </p>
              </li>
              <li className="my-3">
                <p className="flex gap-1">
                  <span>2.</span> Busca un lugar iluminado para tomar la foto.
                </p>
              </li>
              <li>
                <p className="flex gap-1">
                  <span>3.</span> Mantente quieto al momento de tomar la foto para obtener una imagen de buena calidad.
                </p>
              </li>
            </ul>
          )
        }
      },
      onBoarding: {
        rules: {
          list: (
            <>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="source-serif-pro text-white font-normal text-xl">No aparecer fumando.</p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="source-serif-pro text-white font-normal text-xl">
                    No aparecer con consumo excesivo o aparentemente excesivo de bebidas alcohólicas.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="source-serif-pro text-white font-normal text-xl">
                    No mostrar la marca en un entorno de exceso, peligro o violencia.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="source-serif-pro text-white font-normal text-xl">
                    No aparecer con otras marcas de cerveza que no sea Heineken Silver.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="source-serif-pro text-white font-normal text-xl">No aparecer con menores de edad.</p>
                </div>
              </div>
            </>
          )
        }
      }
    },
    profile: {
      return: {
        text: 'VER GANADORES',
        url: '/album/redirect/Foto/ranking?top=10'
      }
    },
    congrats: {
      range: {
        title: 'NUEVO LOGRO DESBLOQUEADO',
        description: <></>,
        description2: (
          <>
            Felicitaciones, has desbloqueado nuevos <br /> premios y obtienes más chances de ganar.
          </>
        )
      }
    },
  },
  error: {
    505: {
      title: '¡Qué pena!',
      description: <>Esa no era la respuesta correcta. <br /> Inténtalo nuevamente.</>,
      button: {
        link: '/trivias',
        text: 'VOLVER A EMPEZAR'
      }
    }
  }
}
