import { UserData } from 'contexts/session-context/session-reducer'
import { StartImg } from 'theme/start'
import { Back } from 'theme/theme'
import { Link } from 'react-router-dom'
import { GenericText } from 'views/memorama/views/list/styles/custom'
import { DescriptionText, SpanPolitics } from "../components/birthday-input/style.custom"
import { TitleAge } from "../components/legal-age/components/style/text"
const Arrow = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 0.46875C12.3392 0.46875 14.0482 0.932617 15.627 1.86035C17.1569 2.75553 18.3695 3.9681 19.2646 5.49805C20.1924 7.07682 20.6562 8.78581 20.6562 10.625C20.6562 12.4642 20.1924 14.1732 19.2646 15.752C18.3695 17.2819 17.1569 18.4945 15.627 19.3896C14.0482 20.3174 12.3392 20.7812 10.5 20.7812C8.66081 20.7812 6.95182 20.3174 5.37305 19.3896C3.8431 18.4945 2.63053 17.2819 1.73535 15.752C0.807617 14.1732 0.34375 12.4642 0.34375 10.625C0.34375 8.78581 0.807617 7.07682 1.73535 5.49805C2.63053 3.9681 3.8431 2.75553 5.37305 1.86035C6.95182 0.932617 8.66081 0.46875 10.5 0.46875ZM10.5 2.03125C8.9375 2.03125 7.48893 2.42188 6.1543 3.20312C4.86849 3.9681 3.8431 4.99349 3.07812 6.2793C2.29688 7.61393 1.90625 9.0625 1.90625 10.625C1.90625 12.1875 2.29688 13.6361 3.07812 14.9707C3.8431 16.2565 4.86849 17.2819 6.1543 18.0469C7.48893 18.8281 8.9375 19.2188 10.5 19.2188C12.0625 19.2188 13.5111 18.8281 14.8457 18.0469C16.1315 17.2819 17.1569 16.2565 17.9219 14.9707C18.7031 13.6361 19.0938 12.1875 19.0938 10.625C19.0938 9.0625 18.7031 7.61393 17.9219 6.2793C17.1569 4.99349 16.1315 3.9681 14.8457 3.20312C13.5111 2.42188 12.0625 2.03125 10.5 2.03125ZM11.0615 5.62012L16.0664 10.625L11.0615 15.6299L9.93848 14.5312L13.0635 11.4062H5.03125V9.84375H13.0635L9.93848 6.71875L11.0615 5.62012Z"
        fill="#DA2B1F"
      />
    </svg>
  )
}

export const tecate = {
  home: {
    title: <span className='text-black'>¿Que quieres hacer?</span>,
    description: '¡COMPLETA TUS DATOS!',
    percentageProfile: 'Completo'
  },
  profile: {
    form: {
      information: {
        firstname: {
          label: '',
          placeholder: 'Pon tu nombre aquí'
        },
        lastname: {
          placeholder: 'Pon tu apellido aquí'
        },
        nickname: {
          label: <>¿apodo? <span className='font-normal'>(OPCIONAL)</span></>,
          placeholder: '¿Cómo te gusta que te llamen?'
        },
        birthdate: {
          label: '¿fecha de nacimiento?'
        },
        gender: {
          label: '¿CÓMO TE IDENTIFICAS?'
        },
        email: {
          label: 'CORREO ELECTRÓNICO'
        }
      }
    },
    information: 'DATOS PERSONALES',
    informationEmail: 'EMAIL',
    address: '',
    contact: 'CONTACTO',
    button: 'MI TECATE ID',
    identification: {
      nav: 'MI TECATE ID',
      title: 'TECATE ID',
      button: 'DESCARGAR TECATE ID',
      label: 'Mi Tecate ID',
      name: 'TECATE ID'
    },
    stateNotFound: <>No has canjeado ningún premio aún  <br /> <b>¡venga, tú puedes!</b></>,
    view: {
      contact: {
        phoneTitle: ' ',
        emailTitle: 'Mail de contacto',
        emailTitle2: 'E-mail de contacto'
      }
    },
    state: "HISTORIAL DE CANJES"
  },
  config: {
    prizes: '/profile/identificacion'
  },
  agegate: {
    title: <span className='invisible'></span>,
    description: <div className='flex flex-col justify-between items-center gap-5 mx-7'>
      <div className='flex flex-col items-center justify-center'>
        <TitleAge>Te damos la bienvenida a</TitleAge>
        <img src={`${AZURE_BASE_URL}/logo-tecate-letras.svg`} alt="Titulo" />
      </div>
      <DescriptionText>
        Ingresa tu año de nacimiento, A MODO DE <br />
        validar tu mayoría de edad
      </DescriptionText>
      <img src={`${AZURE_BASE_URL}/Tecate-Arrows.png`} alt="Titulo" className='w-10' />
    </div>,
    politics1: <span className='hidden'></span>,
    politics2: <SpanPolitics>
      AL CONFIRMAR, ACEPTAS LA <a href={`${process.env.PUBLIC_URL}/privacy-politics`} rel="noreferrer" target="_blank" className="underline">POLÍTICA DE PRIVACIDAD</a >, <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">TÉRMINOS Y CONDICIONES Y USO DE COOKIES</a > DE ESTE SITIO WEB. ESTE SITIO WEB UTILIZA COOKIES. PARA OBTENER MÁS INFORMACIÓN, LEE NUESTRA POLÍTICA DE <a href={`${process.env.PUBLIC_URL}/privacy-politics`} rel="noreferrer" target="_blank" className="underline">PRIVACIDAD</a > Y USO DE <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">COOKIES</a >.
    </SpanPolitics>
  },
  legalage: {
    title: ''
  },
  start: {
    title: {
      html: (
        <Back className={`flex flex-col flex-1 w-full justify-center sm:items-center`}>
          <div className="w-full flex flex-1 flex-col text-lg  font-bold text-center gap-4 items-center justify-evenly">
            <StartImg src={`${AZURE_BASE_URL}/fondo-inicial.png`} alt="marca" className="px-10" />
          </div>
        </Back>
      )
    },
    signup: 'REGISTRARSE CON CORREO',
    signin: 'ENTRAR CON CORREO'
  },
  signup: {
    title: 'YA QUEDÓ, ',
    description:
      'Eres parte de Código Tecate, valida tu mail y checa tus correos para poder empezar a registrar tus códigos y ganar cosas chidas',
    welcome: {
      description: " ",
      description3: <><b>¿No te llegó ningún mail?</b> <br /> Intentémoslo de nuevo</>,
      "button-resend": "Dá clic aquí para reenviarlo."
    }
  },
  redeem: {
    subtitle: <div className='flex justify-center items-center flex-col'>
      <img src={`${AZURE_BASE_URL}/redeem-logo.png`} style={{ width: '70%' }} />
    </div>,
    description: (
      <>
        Empieza a ganar águilas <br /> y llévate premios únicos
      </>
    ),
    description2: (
      <>
        ¿SABES ORAR? PUES ÓRALE, <br /> INGRESA TU CÓDIGO
      </>
    ),
    imageUrl: `${AZURE_BASE_URL}/redeem-welcome.png`
  },
  congrats: {
    title: 'VEEEEENGA ',
    description: (
      <div className="flex flex-col items-center justify-center text-center gap-3">
        <span>INGRESASTE 1 CÓDIGO NUEVO CON ÉXITO.</span>
        <span>
          LAS ÁGUILAS QUE SUMAS PARA
          <br /> CANJEARLAS POR COSAS CHIDAS
          <br /> SON:
        </span>
      </div>
    ),
    button1: 'MIRA LOS PREMIOS',
    range: {
      title: 'VEEEEEEENGA ',
      description2: (
        <>
          FELICITACIONES
          <br />
          HAS DESBLOQUEADO NUEVAS
          <br />
          COSAS EN CÓDIGO TECATE
        </>
      )
    }
  },
  prizes: {
    start: {
      title: 'Premios',
      card: {
        points: 'Águilas'
      },
      'not-found': 'Puedes ganar premios participando en nuestras dinámicas'
    },
    head: {
      title: <>TUS {POINTS_NAME?.toLowerCase()}:</>,
      description: '¿Ya pensaste en qué las canjearás?'
    },
    exchange: {
      title: 'PÉRATE, PÉRATE',
      information: {
        description: <span style={{ fontSize: '10px' }}>*Es necesario que nos dejes estos datos para entregar tu premio</span>,
        button: 'CONFIRMAR MIS DATOS',
        back: '¡VAMOS A ACTUALIZAR MIS DATOS!',
        confirm: {
          title: <>¡LISTO NAME! <br /> <p className='text-base font-normal' style={{ fontFamily: 'Roboto', textTransform: 'none', lineHeight: '1.15rem' }}>Vamos a enviar tu premios considerando estos datos</p></>,
          button: 'CANJEAR',
          back: 'VOLVER'
        }
      },
      warning: '¿Segurísimo que quieres este premio?',
      textWarning: <>Estás a punto de canjear costInPoints pointsName por un prizeName.</>
    },
    congrats: {
      description: (
        <>
          <b>Tu premio ya va en camino.</b> <br /> Ponte al tiro con el mail que te haremos llegar, ahí vendrán los detalles de envío.
        </>
      ),
      title: 'Enhorabuena'
      // ¡Enhorabuena, Roberto!  Te quedan 3500 águilas, vamos por más.
    },
    arrow: (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M19.0422 10.1411L9.85912 19.3242L8.22338 17.7172L14.6516 11.289L0.676025 11.289L0.676025 8.99323L14.6516 8.99323L8.22338 2.56507L9.85912 0.958023L19.0422 10.1411Z" fill="#DA2B1F" />
      </svg>
    ),
    description: {
      politic: <>Para más información revisa nuestros <br />
        <Link to="/terms-and-conditions" className="underline">
          Términos y condiciones.
        </Link>
      </>
    }
  },
  trivias: {
    prefpicker: {
      title: 'Primero cuentanos tus preferencias',
      description: 'selecciona al menos tres categorías'
    },
    congrats: '¡FELICITACIONES',
    triviaQr: {
      register: {
        title: (
          <>
            <span style={{ color: '#000000', fontFamily: 'Termina' }}>CONTESTA</span>
            <br />
            <span style={{ color: '#E1251B', fontFamily: 'Termina' }}>Y GANA</span>
          </>
        ),
        description: (
          <>
            <p>¿Ya tienes tu TECATE ID?</p>
            <p>
              SI NO  CÓRRELE A <br />
              REGISTRARTE PARA <br />
              PODER PARTICIPAR
            </p>
          </>
        ),
        html: (
          <>
            <h1 className='text-black font-bold text-center uppercase' style={{
              fontSize: '72px',
              fontFamily: 'HeadingProUltracomp',
              fontWeight: 700
            }} >
              <span style={{ color: '#E1251B' }}>CONTESTA{" "}</span>
              Y GANA
            </h1>
            <img src={`${AZURE_BASE_URL}/logo-positivo.svg`} alt='logo' className='px-16' />

            <p className='text-center font-bold text-black uppercase' style={{ fontSize: '32px', fontFamily: 'HeadingProUltracomp', lineHeight: '120%', }}>
              ¿Ya tienes tu TECATE ID?
            </p>

            <p className='text-center termina font-medium text-black uppercase' style={{ fontSize: '16px', fontFamily: 'Roboto' }}>
              SI NO  CÓRRELE A REGISTRARTE PARA <br />
              PODER PARTICIPAR
            </p>
          </>
        )
      },
      late: {
        title: (user: UserData) => `¡QUE BUENO VERTE DE NUEVO ${user?.firstName.toUpperCase()}!`,
        description: (
          <span className='roboto'>
            Lamentablemente esta trivia <br /> ya no
            está disponible.
          </span>
        )
      }
    },
    exit: {
      title: <span className='text-center uppercase flex text-white ' style={{
        fontWeight: 700,
        lineHeight: '100%',
        fontSize: '56px',
        fontFamily: 'HeadingProUltracomp'
      }}>¿Estás seguro que <br /> quieres salir?</span>,
      subtitle: <span className='text-center flex text-white' style={{
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px'
      }}  >
        Si cancelas ahora se perderá tu progreso y <br /> tendrás que volver a empezar.
      </span>,
      accept: "SI, CLARO",
      cancel: "NO, VOLVER"
    },
    exchange: {
      title: 'FELICITACIONES',
      description: 'GANASTE:',
      button: {
        text: 'VAMOS A COMPLETAR MIS DATOS',
        url: '/profile/information'
      }
    }
  },
  auctions: {
    current: 'Puedes seguir mirando las otras ofertas mientras se termina la que ya ofertaste.'
  },
  refferal: {
    title: 'Código de referido de Tecate',
    text1: '¡Hola! ¡Usa mi código ',
    text2: ' y obtén una chance diaria para ganar el futbolito y otros premios en la nueva dinámica de Tecate!'
  },
  referrals: {
    title: <>INVITA A UN AMIGO <br /> PARA TENER MÁS CHANCES DE GANAR</>,
    subtitle: 'Comparte tu código',
    description: 'Comparte tu código con amigos y suma más oportunidades de ganar.'
  },
  error: {
    505: {
      title: <span className='uppercase text-white text-center'>¡oh no!</span>,
      description: <span className='uppercase text-white text-center font-normal'>
        <b>FALLASTE</b>
        <p>PUEDES VOLVER A INTENTAR</p>
      </span>,
      button: {
        text: 'VUELVE A INTENTARLO',
        link: '/trivias'
      }
    },
    10: {
      title: <>¡Ups! El código <br /> QUE INGRESASTE ES <br /> INVÁLIDO </>
    },
    50: {
      title: 'TU CUENTA AÚN NO FUE VALIDADA',
      description: 'Te hemos enviado un mail para avanzar.'
    },
    1116: {
      description: 'Lo sentimos, no es posible cargar en tan corto tiempo tus contenidos, te esperamos de vuelta más tarde.',
      button: {
        text: 'Volver',
        link: 'album/redirect/Card/upload'
      }
    },
    1114: {
      title: 'No hemos podido validar tu imagen',
      description: 'Tu imagen ha sido rechazada por:',
    }
  },
  tivias: {
    challenge: {
      modal: {
        back: <span className='text-white underline'>NO, VOLVER</span>
      }
    }
  },
  memorama: {
    list: {
      title: 'MEMORAMA',
      'not-found': 'No hay dinámicas disponibles por el momento, estate atento a las nuevas que tendremos próximamente.'
    },
    modal: {
      title: <>MEMORAMA<br /><span style={{ fontSize: '32px', color: '#CF202F' }}>INSTRUCCIONES</span></>,
      items: (
        <div className='flex-1'>
          <div className="flex gap-4 items-center">
            <img src={`${AZURE_BASE_URL}/memorama-instruccion-1.svg`} alt="icons" />
            <GenericText className='flex-1'>
              <b>Concéntrate y memoriza</b> el orden de las imágenes que van a aparecer.
            </GenericText>
          </div>
          <div className="flex gap-4 mt-10 items-center">
            <img src={`${AZURE_BASE_URL}/memorama-instruccion-2.svg`} alt="icons" />
            <GenericText className='flex-1'>
              <b>Estas desaparecerán</b> y debes recordar el lugar donde estaban.
            </GenericText>
          </div>
          <div className="flex gap-4 mt-10 items-center">
            <img src={`${AZURE_BASE_URL}/memorama-instruccion-3.svg`} alt="icons" />
            <GenericText className='flex-1'>
              <b>Si aciertas en todas, ¡GANAS!</b>
            </GenericText>
          </div>
          <div className="flex gap-4 mt-10 items-center">
            <img src={`${AZURE_BASE_URL}/memorama-instruccion-4.svg`} alt="icons" />
            <GenericText className='flex-1' style={{ color: '#DA2B1F' }}>
              <b>Atención:</b> solo podrán participar aquellos residentes que vivan en el mismo estado donde se llevará a cabo el partido.
            </GenericText>
          </div>
        </div>
      ),
      play: {
        description: 'Estás a punto de participar en este memorama y ganar:',
        warning: '¿Seguro que quieres empezar a jugar? Una vez que des clic no hay vuelta atrás.'
      },
    },
    exchange: {
      button: {
        text: 'Completar mis datos'
      }
    },
    exit: {
      accept: 'SI, CLARO',
      cancel: 'NO, VOLVER'
    }
  },
  album: {
    title: 'RETOS',
    states: {
      all: 'TODOS',
      progress: 'ACTUALES',
      completed: 'PASADOS'
    },
    notFound: 'No hay retos disponibles por el momento, estate atento a nuestras nuevas dinámicas.'
  },
  social: {
    Consumo: {
      congrats: {
        description: <>OBTUVISTE  <b>VAR_POINTS ÁGUILAS</b></>,
        button1: 'VER LOS PREMIOS',
        button2Link: '/album/redirect/Consumo/upload',
        button2: 'CARGAR OTRA IMAGEN'
      },
      howToParticipate: {
        subtitle: 'SUBE TU FOTO Y PARTICIPA',
        list: (
          <ul className="flex-1 items-center justify-center flex-col flex w-full gap-7 pb-7">
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-1.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="text-lg font-normal roboto leading-6" style={{ fontFamily: 'Roboto', color: '#000000' }}>
                  <b>Completa los datos</b> personales de perfil en tu cuenta.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-2.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-normal text-lg roboto leading-6" style={{ fontFamily: 'Roboto', color: '#000000' }}>
                  <b>Sube una foto</b> de tu cerveza en botella y genera ÁGUILAS con cada imagen que subas.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-3.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-normal text-lg roboto leading-6" style={{ fontFamily: 'Roboto', color: '#000000' }}>
                  <b>Acumula Águilas</b> y canjéalos por premios o merch en la vitrina.
                </p>
              </div>
            </li>
          </ul>
        )
      },
      onBoarding: {
        rules: {
          list: (
            <>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    No subir contenido inapropiado.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    No debe aparecer la marca en un entorno de exceso, peligro o violencia.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    No aparecer con menores de edad.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    No deben aparecer otras marcas de cerveza.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    Si se realiza más de una publicación que no va con las reglas se inactivará el usuario para que no pueda subir más contenido.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    Puedes subir como máximo 3 fotos de consumos consecutivos cada 24hrs.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    Puede subir contenido cada 15 minutos acumulativos, es decir a los 15 min, 30 min y 45 min.
                  </p>
                </div>
              </div>
            </>
          )
        }
      },
      upload: {
        title: 'Cargar foto',
        subtitle: 'Clic para subir una foto',
        rules: {
          title: 'REGLAS PARA SUBIR CONTENIDO',
          list: (
            <ul style={{ color: '#15141A', fontSize: '14px', fontFamily: 'Roboto', fontWeight: 400 }}>
              <li>
                <p className="flex gap-1">
                  <span>1.</span> Usa la cámara de tu celular para tomarle una foto a tu cerveza.
                </p>
              </li>
              <li className="my-3">
                <p className="flex gap-1">
                  <span>2.</span> Busca un lugar iluminado para tomar la foto.
                </p>
              </li>
              <li>
                <p className="flex gap-1">
                  <span>3.</span> Mantente quieto al momento de tomar la foto para obtener una imagen de buena calidad.
                </p>
              </li>
            </ul>
          )
        },
        errorModalValidate: {
          description: 'Aún no puedes subir nuevo contenido por las siguientes razones:',
          list: <div style={{ width: '320px' }}>
            <div style={{ color: 'white' }} className="Montserrat text-sm font-normal">
              <p className='ml-5 mb-3'> • Llegaste al máximo de contenido que puedes cargar en el día. (puedes subir máx. 3 por día)</p>
              <p className='ml-5 mb-3'> • Llegaste al máximo de contenido que puedes cargar en total. (puedes subir máx. 15 imágenes)</p>
              <p className='ml-5'> • Aún no pasaron los 30 min. desde que subiste tu segunda foto. (Son 15 minutos acumulativos desde que subes tu primer imagen).</p>
            </div>
          </div>,
          help: <div style={{ width: '320px', color: 'white' }} className='flex justify-center items-center flex-col mb-12 Montserrat pt-4'>
            <span className="font-bold text-center text-sm Montserrat">¿Te parece que no es así?</span>
            <span className="text-center text-sm Montserrat">Ponte en contacto con nosotros para que <br /> podamos ayudarte. <Link to="/help" className='underline'>Envíanos un mensaje.</Link> </span>
          </div>
        },
        icon: <svg width="80" height="84" viewBox="0 0 80 84" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="37.9841" y="12.8002" width="3.21363" height="57.2" fill="#DA2B1F" />
          <rect x="37.9841" y="12.8002" width="3.21363" height="57.2" fill="#DA2B1F" />
          <rect x="37.9841" y="12.8002" width="3.21363" height="57.2" fill="#DA2B1F" />
          <rect x="11.2036" y="43.019" width="3.23774" height="56.7742" transform="rotate(-90 11.2036 43.019)" fill="#DA2B1F" />
          <rect x="11.2036" y="43.019" width="3.23774" height="56.7742" transform="rotate(-90 11.2036 43.019)" fill="#DA2B1F" />
          <rect x="11.2036" y="43.019" width="3.23774" height="56.7742" transform="rotate(-90 11.2036 43.019)" fill="#DA2B1F" />
        </svg>,
        howToTakePhoto: {
          title: 'IMAGEN DE REFERENCIA',
          subtitle: 'CONSEJOS PARA TOMAR UNA FOTO CORRECTA',
          "how-take-photo-list": (
            <ul style={{ padding: 'revert', listStyle: 'disc' }}>
              <li className="text-white pb-5">
                Intenta sacar la foto con buena iluminación y sobre un fondo lo más limpio y plano posible (que no tenga muchos objetos de fondo)
              </li>
              <li className="text-white pb-5">
                Procura que el logo de TECATE se vea lo mejor y más completo posible, que se lea TECATE y se vea el águila.
              </li>
              <li className="text-white">
                Intenta que la foto salga nítida, clara y bien enfocada. Que la botella o lata esté centrada y no haya otros elementos.
              </li>
            </ul>
          )
        },
        isReady: '¿Está ok esta FOTO?'
      },
    },
    Card: {
      howToParticipate: {
        subtitle: 'Crea tu frase y compártela',
        list: (
          <ul className="flex-1 items-center justify-center flex-col flex w-full gap-7 pb-7">
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/cuenta_icon.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="text-base font-normal roboto leading-normal" style={{ fontFamily: 'Roboto', color: '#000000' }}>
                  <b>Completa tus datos</b> personales en el perfil de tu cuenta.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/cargar-frase_icon.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-normal text-base roboto leading-normal" style={{ fontFamily: 'Roboto', color: '#000000' }}>
                  <b>Completa la frase</b> con tus propias palabras y crea tu pieza original para participar por los premios.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/compartir_icon.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-normal text-base roboto leading-normal" style={{ fontFamily: 'Roboto', color: '#000000' }}>
                  <b>Comparte tu nueva creación</b> con tus amigos para que vean lo creativo que eres.
                </p>
              </div>
            </li>
          </ul>
        )
      },
      onBoarding: {
        rules: {
          list: (
            <>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    No subir contenido inapropiado.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    No nombrar otras marcas de cerveza.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    Si el contenido subido es considerado inapropiado, la plataforma no generará la imagen y se le comunicará al usuario los motivos por los cuales no se generó.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    Se permite subir hasta tres frases en total, con una sola posibilidad de ganar.
                  </p>
                </div>
              </div>
            </>
          )
        }
      },
      upload: {
        title: 'Cargar foto',
        subtitle: 'Clic para subir una foto',
        rules: {
          title: 'REGLAS PARA SUBIR CONTENIDO',
          list: (
            <ul style={{ color: '#15141A', fontSize: '14px', fontFamily: 'Roboto', fontWeight: 400 }}>
              <li>
                <p className="flex gap-1">
                  <span>1.</span> Completa la frase de la imagen con lo que te parezca que vaya mejor.
                </p>
              </li>
              <li className="my-3">
                <p className="flex gap-1">
                  <span>2.</span> Tiene que ser en muy pocas palabras.
                </p>
              </li>
              <li>
                <p className="flex gap-1">
                  <span>3.</span> Revisa como queda la imagen final para aprobarla o volver a hacerlo.
                </p>
              </li>
            </ul>
          )
        },
        errorModalValidate: {
          description: 'Aún no puedes subir nuevo contenido por la siguiente razón:',
          list: <div style={{ width: '320px' }}>
            <div style={{ color: 'white' }} className="Montserrat text-sm font-normal">
              <p className='ml-5 mb-3'> • Llegaste al máximo de contenido que puedes cargar en total. (puedes cargar máx. 3 imágenes)</p>
            </div>
          </div>,
          help: <div style={{ width: '320px', color: 'white' }} className='flex justify-center items-center flex-col mb-12 Montserrat pt-4'>
            <span className="font-bold text-center text-sm Montserrat">¿Te parece que no es así?</span>
            <span className="text-center text-sm Montserrat">Ponte en contacto con nosotros para que <br /> podamos ayudarte. <Link to="/help" className='underline'>Envíanos un mensaje.</Link> </span>
          </div>
        },
        icon: <svg width="80" height="84" viewBox="0 0 80 84" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="37.9841" y="12.8002" width="3.21363" height="57.2" fill="#DA2B1F" />
          <rect x="37.9841" y="12.8002" width="3.21363" height="57.2" fill="#DA2B1F" />
          <rect x="37.9841" y="12.8002" width="3.21363" height="57.2" fill="#DA2B1F" />
          <rect x="11.2036" y="43.019" width="3.23774" height="56.7742" transform="rotate(-90 11.2036 43.019)" fill="#DA2B1F" />
          <rect x="11.2036" y="43.019" width="3.23774" height="56.7742" transform="rotate(-90 11.2036 43.019)" fill="#DA2B1F" />
          <rect x="11.2036" y="43.019" width="3.23774" height="56.7742" transform="rotate(-90 11.2036 43.019)" fill="#DA2B1F" />
        </svg>,
        isReady: '¿Está ok esta imagen?',
        'ranking-subtitle': {
          'progress': 'En esta galería puedes ver todas las imágenes creadas por los participantes.',
          'completed': 'Aquí puedes ver las frases ganadores del reto.'
        },
        alreadyUploaded: {
          icon: <>
            <svg width="125" height="125" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="62.084" cy="62.084" r="60.084" stroke="#FFCC71" strokeWidth="4" />
              <path d="M26 56L55 85L98 42" stroke="#FFCC71" strokeWidth="6" strokeLinecap="square" />
            </svg>
          </>,
          description: 'Se ha agregado correctamente tu contenido.',
          button: {
            url: '/album/redirect/Card/profile',
            text: 'Ver mi contenido'
          },
          button2: {
            url: '/album/redirect/Card/ranking',
            text: 'Ver galería'
          },
          buttonBack: {
            text: 'CARGAR OTRA IMAGEN'
          }
        },
        howToTakePhoto: {
          "how-take-photo-list": (
            <ul style={{ padding: 'revert', listStyle: 'disc' }}>
              <li className="text-white pb-5">
                Intenta sacar la foto con buena iluminación y sobre un fondo lo más limpio y plano posible (que no tenga muchos objetos de fondo)
              </li>
              <li className="text-white pb-5">
                Procura que el logo de TECATE se vea lo mejor y más completo posible, que se lea TECATE y se vea el águila.
              </li>
              <li className="text-white">
                Intenta que la foto salga nítida, clara y bien enfocada. Que la botella o lata esté centrada y no haya otros elementos.
              </li>
            </ul>
          )
        }
      },
      profile: {
        button: {
          text: 'Agregar nueva imagen'
        },
        return: {
          url: '/album/:id/ranking',
          text: 'VER GALERÍA'
        },
        shared: {
          text: 'El calor pide este kit y tu...',
          url: `${URL_BASE}${process.env.PUBLIC_URL}/album/redirect/Card/ranking`
        },
        title: 'Mi contenido'
      },
      maxLength: 36,
    },
    Ticket: {
      howToParticipate: {
        subtitle: 'SUBE TU RECIBO Y PARTICIPA',
        list: (
          <ul className="flex-1 items-center justify-center flex-col flex w-full gap-7 pb-7">
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-10.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="text-lg font-normal roboto leading-6" style={{ fontFamily: 'Roboto', color: '#000000' }}>
                  <b>Completa tus datos</b> personales en el perfil de tu cuenta.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-11.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-normal text-lg roboto leading-6" style={{ fontFamily: 'Roboto', color: '#000000' }}>
                  <b>Sube una foto de tu recibo</b> donde se vea bien el detalle de tu compra de productos Tecate.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-12.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-normal text-lg roboto leading-6" style={{ fontFamily: 'Roboto', color: '#000000' }}>
                  <b>Acumula recibos</b> mientras más tickets subas más oportunidades de ganar tienes.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-13.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <p className="font-normal text-base roboto leading-6" style={{ fontFamily: 'Roboto', color: '#DA2B1F' }}>
                  <b>Atención:</b> solo podrán participar aquellos residentes que vivan en NORESTE: Coahuila/Nuevo León/ Tamaulipas (Norte y Sur)/ Durango):
                </p>
              </div>
            </li>
          </ul>
        )
      },
      onBoarding: {
        rules: {
          list: (
            <>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    El ticket debe ser de productos Tecate en alguna de sus presentaciones (Original o Light en lata, cuartito o caguamón).
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    Se debe visualizar la fecha de compra.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    No se debe cargar un ticket repetido.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    Debe ser de un establecimiento participante: Coahuila/Nuevo León/ Tamaulipas (Norte y Sur)/ Durango.
                    No puede ser de restaurante.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    Puedes subir como máximo tres tickets.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="font-normal roboto text-base">
                    Los ganadores serán aquellos que hayan contestado correctamente la trivia en el menor tiempo posible y hayan cargado sus tres tickets con los productos y establecimientos participantes.
                  </p>
                </div>
              </div>

            </>
          )
        }
      },
      upload: {
        title: 'Cargar RECIBO',
        subtitle: 'Clic para cargar tu recibo',
        rules: {
          title: 'REGLAS PARA SUBIR TICKETS',
          list: (
            <ul style={{ color: '#15141A', fontSize: '14px', fontFamily: 'Roboto', fontWeight: 400 }}>
              <li>
                <p className="flex gap-1">
                  <span>1.</span> Usa la cámara de tu celular para tomarle una foto al recibo.
                </p>
              </li>
              <li className="my-3">
                <p className="flex gap-1">
                  <span>2.</span> Busca un lugar iluminado para tomar la foto.
                </p>
              </li>
              <li>
                <p className="flex gap-1">
                  <span>3.</span> Mantente quieto al momento de tomar la foto para obtener una imagen de buena calidad.
                </p>
              </li>
            </ul>
          )
        },
        errorModalValidate: {
          description: 'Aún no puedes subir nuevo contenido por las siguientes razones:',
          list: <div style={{ width: '320px' }}>
            <div style={{ color: 'white' }} className="Montserrat text-sm font-normal">
              <p className='ml-5 mb-3'> • Llegaste al máximo de tickets que puedes cargar. (puedes subir máx. 3 veces al día)</p>
            </div>
          </div>,
          help: <div style={{ width: '320px', color: 'white' }} className='flex justify-center items-center flex-col mb-12 Montserrat pt-4'>
            <span className="font-bold text-center text-sm Montserrat">¿Te parece que no es así?</span>
            <span className="text-center text-sm Montserrat">Ponte en contacto con nosotros para que <br /> podamos ayudarte. <Link to="/help" className='underline'>Envíanos un mensaje.</Link> </span>
          </div>
        },
        icon: <svg width="80" height="84" viewBox="0 0 80 84" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="37.9841" y="12.8002" width="3.21363" height="57.2" fill="#DA2B1F" />
          <rect x="37.9841" y="12.8002" width="3.21363" height="57.2" fill="#DA2B1F" />
          <rect x="37.9841" y="12.8002" width="3.21363" height="57.2" fill="#DA2B1F" />
          <rect x="11.2036" y="43.019" width="3.23774" height="56.7742" transform="rotate(-90 11.2036 43.019)" fill="#DA2B1F" />
          <rect x="11.2036" y="43.019" width="3.23774" height="56.7742" transform="rotate(-90 11.2036 43.019)" fill="#DA2B1F" />
          <rect x="11.2036" y="43.019" width="3.23774" height="56.7742" transform="rotate(-90 11.2036 43.019)" fill="#DA2B1F" />
        </svg>,
        isReady: '¿Está ok esta imagen?',
        'ranking-subtitle': {
          'progress': 'En esta galería puedes ver todas las imágenes creadas por los participantes.',
          'completed': 'Aquí puedes ver las frases ganadores del reto.'
        },
        howToTakePhoto: {
          title: 'IMAGEN DE REFERENCIA',
          subtitle: 'CONSEJOS PARA TOMAR UNA FOTO CORRECTA',
          "how-take-photo-img": "takePhotoImgModel2.png",
          "how-take-photo-list": (
            <ul style={{ padding: 'revert', listStyle: 'disc' }}>
              <li className="text-white pb-5">
                Busca un lugar bien iluminado para tomar la foto.
              </li>
              <li className="text-white pb-5">

                Ubica el recibo sobre alguna superficie plana y deja el ticket lo más estirado y recto posible. Si es posible sobre un fondo limpio y sin otros elementos.
              </li>
              <li className="text-white">
                Toma la foto asegurándote que se vean bien los artículos, la fecha y el lugar de compra.
              </li>
            </ul>
          )
        },
        buttonSubmit: 'Si, añadir'
      },
      congrats: {
        title: 'ESO ',
        description: <>OBTUVISTE<br /><b className='uppercase'>Oportunidad de ganar pase doble tecate pal norte</b></>,
        button1: 'CONTINUAR',
        button2Link: '/album/redirect/Ticket/upload',
        button2: 'CARGAR OTRO RECIBO'
      },
      config: {
        prizes: '/home'
      }
    },
  },
  endroute: {
    title: <>
      ¡Lo sentimos! <br />
      Esto es solo para <br />
      mayores
    </>,
    description: 'Vuelve cuando seas más grande.'
  },
  header: {
    points: {
      br: <><br /></>,
    }
  },
  allowcookies: {
    checkbox: {
      p: {
        cookies: <span className='text-white'>Acepto los <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">Términos y Condiciones</a> y el <a href={`${process.env.PUBLIC_URL}/privacy-politics`} rel="noreferrer" target="_blank" className="underline">Aviso de privacidad</a>.</span>,
        novedades: <span className='text-white'>Quiero recibir novedades.</span>
      }
    }
  },
  mail: {
    title: <>¡Excelente, name!</>,
  },
  update: {
    street: {
      subtitle: <>Si es correcto, completa los últimos datos <br /> y dale a actualizar.</>
    }
  },
  password: {
    title: 'Resetear',
    label: {
      newPassword: 'NUEVA Contraseña',
      newPasswordConfirm: 'Confirmar Contraseña',
    }
  }
}