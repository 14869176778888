import useLocalStorage from 'hooks/use-local-storage';
import ButtonCloseMenu from 'components/button-close';
import { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import textServices from 'services/text-services';
import { SectionCongrats } from 'theme/congrats';
import { TitleRange } from './components/style/text'


interface RangeProps {
  data: {
    level: { id: number, name: string, imageUrl: string }
  }
  history: RouteComponentProps["history"],
  name: string
}

function Range({ data, history }: RangeProps) {

  const [, , removeRange] = useLocalStorage('range', {})

  useEffect(() => {
    removeRange()
  }, [])

  return (
    <>
      <ButtonCloseMenu 
        open={true}
        paramFn={() => {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'SUSCRIBE - Volver', value: '10' })
          history.push('/congrats')
        }}
      />
      <SectionCongrats className="flex-1 p-4 flex flex-col items-center justify-evenly gap-6 uppercase text-center text-5xl">
        <TitleRange className="font-bold text-center">¡{textServices.findByText('congrats/range/title', 'Vaaaaaaamos ')}!</TitleRange>
        <div className="flex flex-col items-center justify-center gap-6">
          <p className="text-3xl saira">{textServices.findByText('congrats/range/description', <>Ahora eres<br /><b>{data.level?.name}</b></>)}</p>
          <img src={data.level.imageUrl} className="w-50" />
        </div>
        <p className="text-lg">{textServices.findByText('congrats/range/description2', <>FELICITACIONES <br /> HAS DESBLOQUEADO NUEVAS <br /> COSAS EN PROMOHUB XP</>)}</p>
      </SectionCongrats>
    </>
  )
}

export default Range
