import { SubTitleAge } from "components/legal-age/components/style/text";
import { TitleStart, SubTitleStart } from "views/start/text.style";

export const cartablanca = {
  agegate: {
    title: '¿Listo para ganar con Carta Blanca?',
    description: <div className="w-full flex flex-col items-center justify-center gap-3 px-7">
      <img className="" src={`${AZURE_BASE_URL}/agegate.png`} alt="PRODUCTOS CARTA BLANCA" />
      <SubTitleAge className="text-center">Ingrese su fecha de nacimiento<br /> para empezar.</SubTitleAge>
    </div>
  },
  signup: {
    title: 'Bienvenido',
    description: 'Ahora eres parte del equipo. Revisa tus correos para comenzar a registrar tus latas para completar la colección. Consigue las 26 latas de Amstel Ultra y descubre los mensajes de Rafa Nadal.'
  },
  start: {
    signup: 'REGÍSTRESE CON CORREO',
    signin: 'INGRESE CON CORREO',
    social: 'ÉNTRELE CON:',
    title: {
      html:
        <div className="flex-1 w-full py-2 flex flex-col items-center justify-center">
          <TitleStart className="text-center uppercase">
            ¿Listo para el reto?
          </TitleStart>
          <SubTitleStart className="text-xl text-center uppercase px-3 pt-5 mb-3" >suba su foto y gane</SubTitleStart>
          <img src={`${AZURE_BASE_URL}/fondo-inicial.svg`} className="mx-auto" alt="background" />
        </div>
    }
  },
  home: {
    title: <span className="font-bold">¿QUÉ QUIERE HACER?</span>,
    description: '¡Complete sus datos!',
    popup: {
      two: {
        mobile: `${AZURE_BASE_URL}/popup-2-mobile.webp`,
        desktop: `${AZURE_BASE_URL}/popup-2-desktop.webp`,
        'desktop-1500': `${AZURE_BASE_URL}/popup-2-desktop-1500.webp`
      }
    }
  },
  profile: {
    form: {
      information: {
        firstname: {
          label: '¿CÓMO SE LLAMA?',
          placeholder: 'Ponga su nombre aquí'
        },
        lastname: {
          placeholder: 'Ponga su apellido aquí',
        },
        nickname: {
          label: '¿APODO?',
          placeholder: '¿Como le gusta que le digan?'
        },
        gender: {
          label: '¿CÓMO SE IDENTIFICA?'
        }
      }
    },
    information: 'DATOS PERSONALES',
    address: 'DIRECCIÓN',
    contact: 'CONTACTO',
    button: 'VAYA A SUS FOTOS'
  },
  config: {
    prizes: '/photographer/start?isYou=true'
  },
  congrats: {
    range: {
      title: "NUEVO LOGRO DESBLOQUEADO",
      description: <></>,
      description2: <>Felicitaciones, has desbloqueado nuevos <br /> premios y obtienes más chances de ganar.</>
    }
  },
  endroute: {
    title: <>¡Lo sentimos! <br /> Esto es solo para compas mayores de edad.</>,
    description: 'Regrese cuando sea más grande.'
  },
  album: {
    title: 'FOTOS CARTA BLANCA',
    states: {
      progress: "ACTUALES",
      completed: "PASADOS"
    }
  }
}