import { useSession } from 'contexts/session-context'
import ComingSoonHtml from './html'
const ComingSoon = ({ children }: { children: JSX.Element[] }) => {
  const [session] = useSession()

  if (THEME_CONFIG.comingSoon && session?.user?.id) {
    return <ComingSoonHtml />
  }
  return <>{children}</>
}

export default ComingSoon
