import { ErrorMessage } from '@hookform/error-message'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosError } from 'axios'
import AgegateInput from 'components/agegate-input'
import Button from 'components/button'
import { useAgegate } from 'hooks/use-agegate'
import { useEffect, useState } from 'react'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import { useForm } from 'react-hook-form'
import formServices from 'services/form-builder-services'
import ProfileService from 'services/profile-services'
import textServices from 'services/text-services'
import { Label, SelectGender, ErrorInput } from 'theme/theme'
import { BackBtn } from 'views/login/text.style'
import Steps from '../components/Steps'

function AgeGender({
  state,
  nextStep,
  handleChange,
  onFormSubmitEndPoint,
  isSignUp,
  prevStep,
  setError,
  setLoad,
  htmlSteps
}: any) {
  const {
    register,
    setValue,
    handleSubmit,
    setError: setErrors,
    formState: { errors }
  } = useForm({ defaultValues: { genreId: state?.genreId }, resolver: formServices.formValidateErrors(yupResolver(formServices.formGenerateSchema(['birthday', 'genreId'])), 'sign up :: step 2', 'sign up'), reValidateMode: 'onSubmit' })

  const { setAgeLocalForm } = useAgegate()
  const [birthday, setBirthday] = useState()
  const [gender, setGender] = useState([])
  const [birthdayDefault, setBirthdayDefault] = useState(null)

  useEffect(() => {
    const ageLS = JSON.parse(localStorage.getItem('agegate'))
    const dateBirthday = new Date(state?.birthday ?? ageLS?.age ?? new Date().toString())
    const day = dateBirthday.getDate() > 9 ? dateBirthday.getDate() : `0${dateBirthday.getDate()}`
    const month = dateBirthday.getMonth() + 1 > 9 ? dateBirthday.getMonth() + 1 : `0${dateBirthday.getMonth() + 1}`
    const year = dateBirthday.getFullYear()
    setBirthdayDefault({ day: day ?? '', month: month ?? '', year: year ?? '' })
  }, [state?.birthday])

  function onFormSubmit(data: any) {
    const response = setAgeLocalForm(birthday)
    if (response?.error) return setErrors('birthday', { type: 'custom', message: response?.error })
    if (isSignUp) {
      handleChange({ ...data, birthday })
      dlTrackEvent('form_send', {
        element: 'siguiente',
        form_name: 'datos personales',
        section: 'sign up :: step 2'
      })
      gtag('event', 'step_2', {
        event_label: 'Registro - Email',
        method: 'Email'
      })
      return nextStep()
    }
    onFormSubmitEndPoint({ ...data, birthday })
  }

  async function getGenres() {
    setLoad(true)
    try {
      const response = await ProfileService.genres()
      setGender(response.data)
    } catch (error) {
      setError((error as AxiosError).response?.data.code)
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    getGenres()
  }, [])

  return (
    <div className="flex flex-col flex-1 md:pt-10">
      {!THEME_CONFIG?.stepsNewVersion && (
        <div className="flex flex-row items-center justify-around w-full mb-4">{htmlSteps}</div>
      )}

      <form
        className="flex h-full justify-between flex-col gap-4 mx-7 mb-7"
        onSubmit={handleSubmit(onFormSubmit)}
        noValidate
      >
        <div className="flex-1 flex flex-col gap-4">
          <div className="gap-2 flex flex-col input-container">
            <Label className="text-start text-2xl font-bold uppercase input-label">
              {textServices.findByText('profile/form/information/birthdate/label', 'Fecha de nacimiento')}{' '}
            </Label>
            <AgegateInput
              setValue={setValue}
              setState={setBirthday}
              defaultValue={birthdayDefault}
              tagging={{ formName: 'datos personales', section: 'sign up :: step 2' }}
            />
            <ErrorInput className='input-error'>
              <ErrorMessage errors={errors} name="birthday" />
            </ErrorInput>
          </div>
          <div className="gap-2 flex flex-col input-container">
            <Label className="text-start text-2xl font-bold uppercase input-label">
              {textServices.findByText('profile/form/information/gender/label', '¿Cómo te identificas?')}
            </Label>
            <SelectGender
              name="genreId"
              {...register('genreId', {
                onBlur: (e) => {
                  dlTrackEvent('form_interaction', {
                    form_field: 'genero',
                    form_action: e.target.value ? 'complete' : 'empty',
                    form_name: 'datos personales',
                    section: 'sign up :: step 2'
                  })
                }
              })}
              className="text-xs font-bold p-4 w-full min-h-2 mx-auto saira uppercase input-select"
            >
              <option value="">{textServices.findByText('profile/form/information/gender/placeholder', 'Selecciona como te consideras')}</option>
              {gender?.map((item: { id: number; name: string }) => (
                <option key={item.id} value={item.id} selected={item.id === Number(state?.genreId) || state?.genreId === item?.name}>
                  {item?.name}
                </option>
              ))}
            </SelectGender>
            <ErrorInput className='input-error'>
              <ErrorMessage errors={errors} name="genreId" />
            </ErrorInput>
          </div>
        </div>

        {THEME_CONFIG?.stepsNewVersion && <Steps state={state} htmlSteps={htmlSteps} decrease={1.5} />}

        <Button>
          <p className="uppercase">{isSignUp ? 'SIGUIENTE' : 'ACTUALIZAR'}</p>
        </Button>
        <button
          type="button"
          className="cursor-pointer button-back"
          onClick={() => {
            dlTrackEvent('user_interaction', {
              action: 'click',
              element: 'volver',
              section: 'sign up :: step 2'
            })
            gtag('event', 'Click', {
              event_category: GTM_EVENT_CATEGORY,
              event_label: 'MODIFICAR DIRECCION - Volver atras',
              value: '10'
            })
            prevStep()
          }}
        >
          <BackBtn className={`din text-center mt-3 hover:text-red-100 uppercase underline`} style={{ fontSize: 10 }}>
            VOLVER
          </BackBtn>
        </button>
      </form>
    </div>
  )
}

export default AgeGender
