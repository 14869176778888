import { useContext, useEffect, useState } from "react"
import Button from "components/button"
import { dlTrackEvent } from "components/dataLayer/dataLayer"
import textServices from "services/text-services"
import useRouter from "hooks/use-router"
import ProfileService from "services/profile-services"
import { LoaderContext } from "contexts/loader-context"
import useMainClass from "hooks/use-main-cass"
import { SubTitleSignUpWelcome, TextSignUpWelcome, TitleSignUpWelcome, BackBtn, TextSecondSignUpWelcome } from "../styles/custom.style"
import Otp from "./otp"

interface IProps {
  name: string;
  email: string;
  handleConfirm: (data: any) => void;
}

function Welcome({ name = '', email = '', handleConfirm }: IProps) {

  const { history } = useRouter()
  const [otp, setOtp] = useState(false)
  const [resend, setResend] = useState(0)
  const { setLoad, setFooter } = useContext(LoaderContext)
  useMainClass('sign-up__welcome')

  useEffect(() => {
    setFooter(false)
  }, [])

  if (otp) return <Otp email={email} handleConfirm={handleConfirm} />

  const handleClick = () => {
    dlTrackEvent('user_interaction', {
      action: 'click',
      element: 'verificar cuenta',
      section: 'sign up'
    })
    gtag('event', 'verificar', {
      event_label: 'Registro - Email',
      method: "Email"
    })
    setOtp(true)
  }

  const handleResend = async () => {

    if (resend > 2) return alert('No se puede reenviar el código, intenta más tarde.')

    setLoad(true)
    try {
      dlTrackEvent('user_interaction', {
        action: 'click',
        element: 'reenviar código',
        section: 'sign up'
      })
      gtag('event', 'reenviar', {
        event_label: 'Registro - Email',
        method: "Email"
      })
      await ProfileService.resendEmailVerify({ email })
      setResend(resend + 1)
    } catch (error) {
      console.error(error)
    } finally {
      setLoad(false)
    }
  }

  return (
    <section className="flex flex-col flex-1 w-full items-center justify-center p-7">
      <article className="flex-1 flex flex-col items-center justify-center gap-8 text-center w-full">
        <TitleSignUpWelcome className="text-center text-5xl w-full whitespace-nowrap overflow-ellipsis overflow-hidden uppercase sign-up__welcome__title">
          {textServices.findByText('signup/welcome/title', <>¡Hola,<br />{name}!</>, { nombre: name })}
        </TitleSignUpWelcome>
        <SubTitleSignUpWelcome className="sign-up__welcome__subtitle">{textServices.findByText('signup/welcome/subtitle', 'Nos falta validar tu cuenta')}</SubTitleSignUpWelcome>
        <TextSignUpWelcome className="sign-up__welcome__description">{textServices.findByText('signup/welcome/description', ' código de 6 dígitos a tu correo electrónico para que valides', { EMAIL: email })}</TextSignUpWelcome>
        <TextSecondSignUpWelcome className="sign-up__welcome__description 2">{textServices.findByText('signup/welcome/description2', <>Una contraseña de un solo uso se envió a <b className="sign-up__welcome__description__email">{email}</b></>)}</TextSecondSignUpWelcome>
        {
          resend < 2 &&
          <div>
            <TextSignUpWelcome className="sign-up__welcome__description 3">{textServices.findByText('signup/welcome/description3', <b>¿No la recibiste?</b>)}</TextSignUpWelcome>
            <TextSignUpWelcome className="underline cursor-pointer sign-up__welcome__resend" onClick={handleResend}>{textServices.findByText('signup/welcome/button-resend', 'Reenviar código')}</TextSignUpWelcome>
          </div>
        }
      </article>
      <Button onClick={handleClick} className="mt-7">VERIFICAR CUENTA</Button>
      <button
        type="button"
        className="cursor-pointer mt-3"
        onClick={() => { history.goBack() }}
      >
        <BackBtn className='din text-center mt-3 hover:text-red-100 uppercase underline button-back' style={{ fontSize: 10 }}>
          VOLVER
        </BackBtn>
      </button>
    </section>
  )
}

export default Welcome