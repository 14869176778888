import { TitleStart, SubTitleStart } from "views/start/text.style";

export const fabs = {
  legalage: {
    title: ' '
  },
  signup: {
    title: 'Bienvenido',
    description: 'Ahora eres parte del equipo. Revisa tus correos para comenzar a registrar tus latas para completar la colección. Consigue las 26 latas de Amstel Ultra y descubre los mensajes de Rafa Nadal.'
  },
  start: {
    signup: 'REGISTRATE CON CORREO',
    signin: 'ENTRA AL SITIO',
    title: {
      html:
        <div className="flex-1 w-full py-2 flex flex-col items-center justify-center">
          <TitleStart className="text-center uppercase px-5">
            ¿Ya empezaste a coleccionar?
          </TitleStart>
          <SubTitleStart className="text-xl text-center px-3 pt-5" >Qué esperas para vivir las aventuras <br/> que tenemos para ti, obtener los sellos <br/> y llevarte increíbles premios</SubTitleStart>
          <img src={`${AZURE_BASE_URL}/fondo-inicial.svg`} className="mx-auto mt-8" alt="fondo inicial" />
        </div>
    }
  },
  home: {
    title: <span className="nearsans" style={{ color: 'rgba(255, 255, 255, 1)' }}>¿QUÉ QUIERES HACER?</span>,
    sellos: 'MIS SELLOS'
  },
  profile: {
    information: 'DATOS PERSONALES',
    address: 'DIRECCIÓN',
    contact: 'CONTACTO',
    button: 'IR A MIS SELLOS'
  },
  config: {
    prizes: '/collections'
  },
  congrats: {
    range: {
      title: "NUEVO LOGRO DESBLOQUEADO",
      description: <></>,
      description2: <>Felicitaciones, has desbloqueado nuevos <br /> premios y obtienes más chances de ganar.</>
    }
  },
  collections: {
    start: {
      title: "MIS SELLOS"
    }
  }
}