import { Redirect } from 'react-router-dom'
import { ScopeEnum } from 'routes/types'
import Congrats from 'views/congrats'
import Help from 'views/help'
import Login from 'views/login'
import MailVerify from 'views/mail-verify'
import PasswordReset from 'views/password-reset'
import Prizes from 'views/prizes'
import Profile from 'views/profile'
import SignUp from 'views/sign-up'
import Start from 'views/start'
import Redeem from 'views/redeem'
import TYC from 'views/terms-and-conditions'
import Trivia from 'views/trivia'
import TriviaChallenge from 'views/trivia/trivia'
import Agegate from 'views/agegate'
import Memorama from 'views/memorama'
import Suscribe from 'views/suscribe'
import Ranking from 'views/ranking'
import Errors from 'views/errors'
import EndRoute from 'views/end-route'
import ProfileInformationUpdate from 'views/profile-information-update'
import ProfileAddressUpdate from 'views/profile-address-update'
import ProfilePhoneUpdate from 'views/profile-phone-update'
import ProfileMailUpdate from 'views/profile-mail-update'
import Home from 'views/home'
import Auctions from 'views/auctions'
import Raffles from 'views/raffles'
import Referrals from 'views/referrals'
import Survey from 'views/survey'
import Collections from 'views/collections'
import Marketplaces from 'views/marketplace'
import Progress from 'views/progress'
import Photographer from 'views/photographer'
import TriviaQr from 'views/trivia-qr/trivia-qr'
import PrivacyPolitics from 'views/privacy-politics'
import Cookies from 'views/cookies'
import ToExternal from 'views/toexternal'
import Album from 'views/album'
import ProfileInstagramUpdate from 'views/profile-instagram-update'
import InstagramAuth from 'views/auth/instagram'
import ConsumptionsUpdate from 'views/profile-consumption-update'
import PageWithKey from 'views/page-whit-key'
import SurveyResponse from 'views/survey-response'
import SurveyQr from 'views/survey/survey-qr'
import IdentificationDynamic from 'views/identifaction'
import Callback from 'views/callback'
import WifiCallback from 'views/wifi'

const NotFound = () => <Redirect to="/" />

class Route {
  public path: string
  public component: JSX.Element
  public exact: boolean = false
  public scope: ScopeEnum
  public notAgegate: boolean = false

  constructor(path: string, component, scope = ScopeEnum.PRIVATE, exact = true, notAgegate = false) {
    this.path = path
    this.component = component
    this.exact = exact
    this.scope = scope
    this.notAgegate = notAgegate
  }

}

const routes = [
  new Route('/agegate', Agegate, ScopeEnum.AUTH),
  new Route('/end', EndRoute, ScopeEnum.AUTH),
  new Route('/start', Start, ScopeEnum.AUTH),
  new Route('/callback', Callback, ScopeEnum.AUTH),
  new Route('/external/callback', WifiCallback, ScopeEnum.AUTH, false, true),
  new Route('/home', Home),
  new Route('/redeem', Redeem),
  new Route('/progress', Progress),
  new Route('/referrals', Referrals),
  new Route('/congrats', Congrats),
  new Route('/congrats/range', Congrats),
  new Route('/prizes', Prizes, ScopeEnum.PUBLIC, false),
  new Route('/identification', IdentificationDynamic, ScopeEnum.PUBLIC, false, true),
  new Route('/prize/description/:id', Prizes, ScopeEnum.PUBLIC, false),
  new Route('/ranking', Ranking),
  new Route('/ranking/suscribe', Suscribe),
  new Route('/ranking/suscribe/confirm', Suscribe),
  new Route('/signup', SignUp, ScopeEnum.AUTH),
  new Route('/login', Login, ScopeEnum.AUTH),
  new Route('/mail-verify', MailVerify, ScopeEnum.AUTH, true, true),
  new Route('/password-reset', PasswordReset, ScopeEnum.AUTH),
  new Route('/survey', Survey),
  new Route('/survey/:id', Survey),
  new Route('/survey/:id/result', SurveyResponse),
  new Route('/survey/qr/:id', SurveyQr, ScopeEnum.PUBLIC),
  new Route('/profile', Profile, ScopeEnum.PRIVATE, false),
  new Route('/information/update', ProfileInformationUpdate),
  new Route('/address/update', ProfileAddressUpdate),
  new Route('/phone/update', ProfilePhoneUpdate),
  new Route('/mail/update', ProfileMailUpdate),
  new Route('/consumption/update', ConsumptionsUpdate),
  new Route('/instagram/update', ProfileInstagramUpdate),
  new Route('/terms-and-conditions', TYC, ScopeEnum.PUBLIC, true, true),
  new Route('/privacy-politics', PrivacyPolitics, ScopeEnum.PUBLIC, true, true),
  new Route('/page/:key', PageWithKey, ScopeEnum.PUBLIC, true, true),
  new Route('/page/auth/:key', PageWithKey, ScopeEnum.PRIVATE, true),
  new Route('/cookies', Cookies, ScopeEnum.PUBLIC, true, true),
  new Route('/help', Help, ScopeEnum.PRIVATE, true),
  new Route('/trivias', Trivia),
  new Route('/trivia/qr/:id', TriviaQr, ScopeEnum.PUBLIC),
  new Route('/trivia/:id', TriviaChallenge),
  new Route('/auctions', Auctions),
  new Route('/raffles', Raffles, ScopeEnum.PRIVATE, false),
  new Route('/photographer', Photographer, ScopeEnum.PRIVATE, false),
  new Route('/auth/instagram', InstagramAuth, ScopeEnum.PRIVATE, false),
  new Route('/collections', Collections, ScopeEnum.PRIVATE, false),
  new Route('/collections/sticker/redeem/:code', Collections, ScopeEnum.PRIVATE, false),
  new Route('/collection/:collectionid/sticker/:code', Collections, ScopeEnum.PRIVATE, false),
  new Route('/album', Album, ScopeEnum.PRIVATE, false),
  new Route('/memorama', Memorama, ScopeEnum.PRIVATE, false),
  new Route('/marketplaces', Marketplaces, ScopeEnum.PRIVATE, false),
  new Route('/toexternal/:code', ToExternal, ScopeEnum.PRIVATE, false),
  new Route('/error/:errorid', Errors),
  new Route('/', Start, ScopeEnum.AUTH),
  new Route('*', NotFound, ScopeEnum.PUBLIC),
]

export default routes
