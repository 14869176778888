import { useSession } from "contexts/session-context"
import { useRouteMatch } from "react-router-dom"
import { dlTrackEvent } from "components/dataLayer/dataLayer"
import { useLocalStorage } from "react-use"
import useRouter from "hooks/use-router"
import SurveyOnBoarding from "./components/surveyOnBoarding"
import Survey from "."

function SurveyQr() {

  const [{ user }] = useSession()
  const route = useRouteMatch<{ id: string }>()
  const { history } = useRouter()
  const [popUp, setPopUp] = useLocalStorage('triviaQrPopUp', false)

  const handleClick = () => {
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'Survey - Comencemos', value: '10' })
    dlTrackEvent('user_interaction', { action: 'click', element: 'comencemos', section: 'survey' })
    if (!user) {
      localStorage.setItem('internal_redirect', `/survey/qr/${route.params.id}`)
      return history.push('/start')
    }

    if (!popUp && THEME_CONFIG?.triviaQrPopUp) {
      setPopUp(true)
    }
  }

  if (!user || (!popUp && THEME_CONFIG?.triviaQrPopUp)) {
    return <SurveyOnBoarding handleClick={handleClick} />
  }

  return (
    <Survey isDirectAccess={true} />
  )
}

export default SurveyQr