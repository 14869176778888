/* eslint-disable indent */
import { AddressAdapter } from "adapters/address-adapter";
import { useSession } from "contexts/session-context";
import { UserData } from "contexts/session-context/session-reducer";
import { useCustomForm } from "hooks/use-custom-form";
import useRouter from "hooks/use-router";
import { useEffect, useState } from "react";
import ErrorView from "components/error-view";
import StepOne from "./step-one";
import StepTwo from "./step-two";
import MaxChange from "./max-change";

function FormAddress({ handleSubmit, user }: { handleSubmit: (data: any, setError: (error: number) => void) => void, user?: UserData }) {

  const { history } = useRouter()
  const [{ data: session }] = useSession()
  const { state, handleChangeState, nextStep, prevStep, backHistory } = useCustomForm({ 
    step: 0, 
    // curp: user?.address?.curp || '', 
    street1: user?.address?.street1 || '', 
    streetType: '', 
    streetNumber: user?.address?.streetNumber || '', 
    apartament: user?.address?.apartament || '', 
    postalCode: user?.address?.postalCode || '', 
    neighborhood: user?.address?.neighborhood || '', 
    city: user?.address?.city || '', 
    state: user?.address?.state || '' 
  })
  const [help, setHelp] = useState()
  const [error, setError] = useState(null)

  function onFormSubmitEndPoint(data: any) {
    const adaptedData = AddressAdapter({ ...state, ...data })
    handleSubmit(adaptedData, setError)
  }

  const handleMenu = (code: number) => {
    if (code === 61) return history.goBack()
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'MODIFICAR DIRECCION - Salir de la pantalla de error', value: '10' })
    setError(null)
    if (state?.step <= 1) return;
    backHistory()
  }

  const handleButton = async (code: number) => {
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'MODIFICAR DIRECCION - Salir de la pantalla de error', value: '10' })
    if (code === 61) return history.push('/help')
    setError(null)
    if (state?.step <= 1) return;
    backHistory()
  }

  useEffect(() => {
    const event = new CustomEvent('background-color', {
      detail: {
        shouldUseWhiteBg: (THEME_CONFIG?.addressUpdateBg || state.step === 0)
      }
    })
    window.dispatchEvent(event)
    if (error) {
      handleChangeState({ step: 10 } as any)
    }
  }, [state.step, error])

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (user?.addressCount === 0 || MAX_CHANGE_ADDRESS_COUNT == 0) handleChangeState({ step: 1 } as any)
  }, [])

  switch (state.step) {
    case 0:
      return (
        <MaxChange
          nextStep={nextStep}
          setError={setError}
          prevStep={() => history.goBack()}
          handleChangeState={handleChangeState}
        />
      )
    case 1:
      return (
        <StepOne
          state={state}
          handleChange={handleChangeState}
          nextStep={nextStep}
          session={session}
          setHelper={setHelp}
          setError={setError}
          prevStep={() => history.goBack()}
        />
      )
    case 2:
      return (
        <StepTwo
          state={state}
          handleChange={handleChangeState}
          onFormSubmitEndPoint={onFormSubmitEndPoint}
          prevStep={prevStep}
          helper={help}
        />
      )
    default:
      return (
        <ErrorView buttonMenu={handleMenu} code={error} onRetry={handleButton} />
      )
  }
}

export default FormAddress;