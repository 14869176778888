export const sol = {
  legalage: {
    title: ' ',
    button1: <span>SI, SOY MAYOR</span>,
    button2: <span>NO, SOY MENOR</span>,
    question: '¿Eres mayor de edad?',
    politics: ' '
  },
  agegate: {
    title: '¿Listo para ganar con Carta Blanca?',
  },
  signup: {
    title: 'Bienvenido',
    description: 'Ahora eres parte del equipo. Revisa tus correos para comenzar a registrar tus latas para completar la colección. Consigue las 26 latas de Amstel Ultra y descubre los mensajes de Rafa Nadal.'
  },
  start: {
    signup: 'REGÍSTRATE CON CORREO',
    signin: 'ÉNTRALE CON CORREO',
    social: 'Entra con:',
    title: {
      html:
        <div className="flex-1 w-full pb-2 flex flex-col items-center justify-center">
          <img src={`${AZURE_BASE_URL}/fondo-inicial.png`} alt="background" className="w-full" />
        </div>
    }
  },
  home: {
    title: <span style={{ fontSize: '35px', fontWeight: 300 }}>¿Qué quieres hacer?</span>,
    description: '¡Completa tus datos!'
  },
  profile: {
    form: {
      information: {
        firstname: {
          label: '¿Cómo te llamas?',
          placeholder: 'Pon tu nombre aquí'
        },
        lastname: {
          placeholder: 'Pon tu apellido aquí',
        },
        nickname: {
          label: '¿Apodo?',
          placeholder: '¿Como le gusta que le digan?'
        },
        gender: {
          label: '¿Cómo te identificas?'
        }
      }
    },
    information: 'DATOS PERSONALES',
    address: 'DIRECCIÓN',
    contact: 'CONTACTO',
    button: 'IR A MIS CREACIONES',
    state: 'PREMIOS',
    identification: {
      nav: 'MI SILVER ID',
      title: 'SILVER ID',
      button: 'DESCARGAR SILVER ID'
    },
  },
  config: {
    prizes: '/home'
  },
  congrats: {
    range: {
      title: "NUEVO LOGRO DESBLOQUEADO",
      description: <></>,
      description2: <>Felicitaciones, has desbloqueado nuevos <br /> premios y obtienes más chances de ganar.</>
    }
  },
  endroute: {
    title: <>¡Lo sentimos! <br /> Esto es solo <br /> para mayores</>,
    description: 'Vuelve cuando sea más grande.'
  },
  referrals: {
    description: 'Comparte tu código con amigos y gana más oportunidades de ganar.',
    subtitle: 'COMPARTE TU CÓDIGO Y GANA',
    left: 'COPIAR',
    right: 'COMPARTIR'
  },
  trivias: {
    exchange: {
      button: {
        text: 'SEGUIR JUGANDO',
        url: '/trivias'
      }
    },
    triviaQr: {
      register: {
        title: <div className="text-white">CONTESTA<br /><span style={{ color: '#CCB988' }}>Y GANA</span></div>,
        description: <div className="text-white">
          <p>¿Ya tienes tu CRISTAL ID?</p>
          <b className="mt-3">SI NO LO TIENES CÓRRELE A REGISTRARTE PARA PODER PARTICIPAR</b>
          <img className="mx-auto mt-5" src="https://promohubstorage.blob.core.windows.net/cristal/assets/crista_id_ej.svg" />
        </div>
      },
      late: {
        description: <p className="text-white">Lamentablemente esta trivia ya no está disponible. Busca nuevo QR dentro de las otras dinámicas que tenemos para ti, para contestar una trivia nueva</p>
      }
    },
    questions1: <>Contesta </>,
    questions2: <> <span className="font-bold" style={{ color: '#CCB988' }}>preguntas</span> sobre el tema y gana:</>,
  },
  survey: {
    title: 'CUÉNTANOS DE TI',
    button: 'RESPONDER'
  },
  mail: {
    description: <>Experimenta y crea tu mezcla perfecta con IA</>
  },
  prizes: {
    start: {
      title: <span style={{ fontFamily: 'Montserrat', fontWeight: 800, fontSize: '25px' }}>PREMIOS</span>
    }
  },
}