const redirectUri = `${window.location.origin}${process.env.PUBLIC_URL}/callback`;
const clientId = FC_CLIENTID || "";

export const goToUrlFacebook = () => {
  const state = encodeURIComponent(`meta`);
  localStorage.setItem("stateAuthSocial", state);
  let url = "https://www.facebook.com/v19.0/dialog/oauth";
  url += "?response_type=token";
  url += "&client_id=" + encodeURIComponent(clientId);
  url += "&scope=" + encodeURIComponent("email,user_birthday,user_likes");
  url += "&redirect_uri=" + encodeURIComponent(redirectUri);
  url += "&state=" + state;
  window.location.href = url;
}