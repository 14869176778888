import { Link } from "react-router-dom"
import textServices from "services/text-services"

export const ERRORS = [
  { code: 1112, message: 'La fotografía excede el tamaño permitido', description: '4 Mb es el limite', button: 'Volver a intentar' },
  { code: 1114, message: textServices.findByText('error/1114/title', 'Error al cargar tu imagen'), description: 'Tu foto ha sido rechazada por:', button: 'Volver a intentar' },
  { code: 10, message: textServices.findByText('error/10/title', '¡Ups! Has ingresado un código OTP incorrecto'), description: textServices.findByText('error/10/description', <>Dele click en <b>REENVIAR</b> para recibir otro código e intentarlo de nuevo.</>), button: 'Reenviar código' },
  { code: 11, message: '¡Ups! Ingresaste un código erróneo', description: 'No te preocupes e inténtalo de nuevo.', button: <><Link to="/redeem">VOLVER A INTENTAR</Link></> },
  { code: 12, message: '¡Ups! Ingresaste un código erróneo', description: 'No te preocupes e inténtalo de nuevo.', button: <><Link to="/redeem">VOLVER A INTENTAR</Link></> },
  { code: 15, message: '¡Ups!, has alcanzado el máximo de canjeo en el día, vuelve mañana', description: '' },
  { code: 17, message: 'Pss, este cupón ya no puede ser usado', description: 'No te preocupes e inténtalo de nuevo', button: <><Link to="/redeem">VOLVER A INTENTAR</Link></> },
  { code: 18, message: '¡Ups! Este código ya fue utilizado antes', description: 'No te preocupes e inténtalo de nuevo', button: <><Link to="/redeem">VOLVER A INTENTAR</Link></> },
  {
    code: 50,
    message: textServices.findByText('error/50/title', 'Tu cuenta aún no fue validada, te hemos enviado un email para avanzar'),
    description: textServices.findByText('error/50/description', ''),
    button: 'Volver a intentar'
  },
  { code: "auth/wrong-password", message: 'Contraseña invalida', button: 'Volver a intentar' },
  { code: "auth/user-not-found", message: 'Usuario inexistente', button: 'Volver a intentar' },
  // { code: "auth/email-already-in-use", message: textServices.findByText('error/auth/email-already-in-use/title', 'Este mail ya se encuentra registrado.'), button: 'Volver a intentar' },
  {
    code: "auth/email-already-in-use",
    message: 'Este mail ya se encuentra registrado',
    description: (
      <>
        <p>Estás intentando registrarte usando tu <br /> CORREO ELECTRÓNICO.</p>
        <br />
        <p>Prueba ingresar nuevamente utilizando tu <br /> <b>Cuenta de Facebook</b> o tu <b>Cuenta de Gmail</b>.</p>
      </>
    ),
    button: 'Volver a intentar'
  },
  // { code: "auth/account-exists-with-different-credential", message: 'UPS ya ingresaste con ese correo electrónico usando otra red social', button: 'Volver a intentar' },
  {
    code: "auth/account-exists-with-different-credential",
    message: 'Este mail ya se encuentra registrado',
    description: (
      <>
        <p>Estás intentando registrarte usando tu <br /> CORREO ELECTRÓNICO.</p>
        <br />
        <p>Prueba ingresar nuevamente utilizando tu <br /> <b>Cuenta de Facebook</b> o tu <b>Cuenta de Gmail</b>.</p>
      </>
    ),
    button: 'Volver a intentar'
  },
  {
    code: "auth/account-exists-with-different-credential-facebook",
    message: 'Este mail ya se encuentra registrado',
    description: (
      <>
        <p>Estás intentando registrarte usando tu <br /> CUENTA DE FACEBOOK.</p>
        <br />
        <p>Prueba ingresar nuevamente utilizando tu <br /> <b>Correo</b> o tu <b>Cuenta de Gmail</b>.</p>
      </>
    ),
    button: 'Volver a intentar'
  },
  {
    code: "auth/account-exists-with-different-credential-google",
    message: 'Este mail ya se encuentra registrado',
    description: (
      <>
        <p>Estás intentando registrarte usando tu <br /> CUENTA DE GMAIL.</p>
        <br />
        <p>Prueba ingresar nuevamente utilizando tu <br /> <b>Correo</b> o tu <b>Cuenta de Facebook</b>.</p>
      </>
    ),
    button: 'Volver a intentar'
  },
  { code: "auth/email-not-exist", message: 'Es necesario disponer de una cuenta con email', description: 'Intenta nuevamente con otra cuenta o red social', button: 'Volver a intentar' },
  { code: 106, message: 'Es necesario disponer de una cuenta con email', description: 'Intenta nuevamente con otra cuenta o red social', button: 'Volver a intentar' },
  { code: "auth/network-request-failed", message: '¡Ups! Parece que hay un inconveniente con la conexión', button: 'Volver a intentar' },
  {
    code: 51,
    message: '¡Ups! Tu cuenta de Facebook no tiene email',
    description: 'Necesitamos saber tu email para enviarte notificaciones'
  },
  { code: 58, message: 'No se ha encontrado el usuario por el código de referido', description: '', button: 'VOLVER' },
  { code: 59, message: textServices.findByText('error/59/title', '¡Chin! No vamos para ese destino, pero no te preocupes'), description: '' },
  {
    code: 61,
    message: 'Has alcanzado el límite de cambios en tu dirección',
    description: 'Para cualquier duda ingresa:'
  },
  {
    code: 62,
    message: '¡Ups! Has ingresado la misma dirección',
    description: ''
  },
  {
    code: 63,
    message: 'La dirección ingresada ya existe en otro usuario',
    description: '',
    button: 'CORREGIR DIRECCIÓN'
  },
  {
    code: 64,
    message: '¡Oh no! El número parece incorrecto',
    description: 'Por favor ingresa un número válido. Recuerda que debe ser un número de celular.',
    button: 'CORREGIR NÚMERO DE CELULAR'
  },
  {
    code: 66,
    message: textServices.findByText('error/66/title', 'Es una lástima, tu cuenta ha sido bloqueada por 48 hrs. Mañana podrás intentarlo.'),
    description: textServices.findByText('error/66/description', <>Esto es por seguridad debido a ingresos de códigos sospechosos. <br />No te apures si es un mal entendido nos pondremos en contacto contigo.</>),
    button: textServices.findByText('error/66/button', 'NECESITAS AYUDA?')
  },
  {
    code: 67,
    message: '¡Nos engañaste! has sido bloqueado de forma permanente',
    description: <>Al haber sido bloqueado 3 veces hemos bloqueado tu cuenta. <br />No te apures si es un mal entendido nos pondremos en contacto contigo.</>,
    button: 'NECESITAS AYUDA?'
  },
  {
    code: 68,
    message: 'EL CURP ya existe en otra cuenta',
    description: 'Por el momento no puedes utilizar este CURP dado que ya existe en otra cuenta activa.'
  },
  {
    code: 69,
    message: 'El celular ya existe en otra cuenta',
    description: 'Por el momento no puedes utilizar este celular dado que ya existe en otra cuenta activa.',
    button: 'CAMBIAR NÚMERO DE CELULAR'
  },
  {
    code: 71,
    message: textServices.findByText('error/71/title', '¡Ups!, parece que el código ingresado ha expirado'),
    description: '',
    button: 'Reenviar código'
  },
  { code: 72, message: '¡Ups!, parece que el apodo ya existe', description: 'Prueba con un apodo distinto', button: 'VOLVER' },
  { code: 83, message: 'Llegaste al máximo permitido de votaciones', description: ' ', button: 'VOLVER' },
  { code: 100, message: '¡Ups!, parece que hubo un problema con tu código de validación, por favor intenta nuevamente', description: 'No hemos podido validar tu correo correctamente. Prueba de nuevo', button: 'VOLVER A INTENTAR' },
  { code: 102, message: 'La cuenta ingresada es inexistente', description: 'No hemos podido validar tu correo correctamente. Prueba de nuevo', button: 'VOLVER A INTENTAR' },
  { code: 107, message: 'La cuenta de correo ya está validada', button: 'INICIAR SESIÓN' },
  { code: 150, message: textServices.findByText('error/150/title', '¡Chin! No vamos para ese destino, pero no te preocupes'), description: '' },
  {
    code: 153,
    message: textServices.findByText('error/153/title', 'Es necesario completar tu dirección para poder acceder'),
    description: textServices.findByText('error/153/description', 'Debemos validar correctamente tu dirección'),
    button: textServices.findByText('error/153/button/text', 'CAMBIAR MI DIRECCIÓN')
  },
  { code: 251, message: '¡Chin! Nos hemos quedado sin disponibilidad por el momento', description: '' },
  { code: 252, message: textServices.findByText('error/252/title', '¡Chin! No vamos para ese destino, pero no te preocupes'), description: '' },
  { code: 253, message: `¡Ups!, parece que no tienes suficientes ${POINTS_NAME}`, description: '' },
  { code: 254, message: 'Pss, tienes que esperar para conseguir otro', description: '' },
  { code: 351, message: '¡Ups!, No existe la pagina', description: '' },
  { code: 456, message: 'El código debe tener al menos 4 caracteres', description: '' },
  // -- TRIVIA --
  {
    code: 502,
    message: '¡UPS! Ya has ganado esta trivia',
    description: 'Lamentablemente ya no está disponible porque ya las has completado. Prueba eligiendo otra trivia distinta.'
  },
  {
    code: 503,
    message: textServices.findByText('error/503/title', <>¡UPS! <br />No tienes saldo suficiente</>),
    description: textServices.findByText('error/503/description', 'Recuerda que puedes intentarlo nuevamente, ¡Échale ganas!')
  },
  {
    code: 504,
    message: <>¡Ups! No hay suficiente stock del premio</>,
    description: <>Intente nuevamente mas tarde.</>,
  },
  {
    code: 505,
    message: textServices.findByText('error/505/title', '¡Oh no! fallaste'),
    description: textServices.findByText('error/505/description', 'Recuerda que puedes intentarlo nuevamente, ¡Échale ganas!'),
    button: textServices.findByText('error/505/button/text', 'VOLVER A HOME')
  },
  {
    code: 506,
    message: <>¡Ups! Te quedaste <br /> sin tiempo</>,
    description: <></>,
    button: textServices.findByText('error/9854/button/text', 'Volver a intentar')
  },
  {
    code: 507,
    message: '¡Ups! La trivia no es valida',
    description: <></>,
    button: textServices.findByText('error/507/button/text', 'VOLVER A HOME'),

  },
  {
    code: 508,
    message: '¡Ups! Esta trivia no esta en proceso',
    description: 'Es posible que haya terminado el tiempo'
  },
  {
    code: 509,
    message: textServices.findByText('error/509/title', <>¡Ups! Esta trivia, no esta disponible en este momento</>),
    description: textServices.findByText('error/509/description', <></>),
    button: textServices.findByText('error/509/button/text', 'VOLVER A HOME')
  },
  {
    code: 510,
    message: '¡Ups! Nos falta tu dirección',
    description: 'Completa en tu perfil, tus datos de dirección para poder participar en esta trivia. Recuerda poner los datos correctos para poder enviarte tu premio a casa.',
    button: 'VAMOS A COMPLETAR MIS DATOS'
  },
  {
    code: 511, // el premio no esta disponible en la direccion del usuario - btn cambiar direccion - /profile/address
    message: '¡Ups! El premio no esta disponible en tu dirección',
    description: 'Es posible que tengas que cambiar tu dirección',
    button: textServices.findByText('error/511/button/text', 'Cambiar dirección')
  },
  {
    code: 512,
    message: textServices.findByText('error/512/title', '¡Ups! Llegaste tarde'),
    description: textServices.findByText('error/512/description', <></>),
    button: textServices.findByText('error/509/button/button/text', null)
  },
  {
    code: 513,
    message: '¡Ups! Parece que este link no es el correcto',
    description: <></>
  },
  {
    code: 514,
    message: '¡Ups! Esta trivia está muy concurrida',
    description: 'Esperate tantito a que bajen los participantes, mientras puedes checar otra trivia para jugar.'
  },

  // -- SURVEY --
  {
    code: 859,
    message: <>¡Ups! Esta encuesta, no esta disponible en este momento</>,
    description: <></>,
    button: textServices.findByText('error/859/button/text', 'VOLVER A HOME')
  },
  {
    code: 860,
    message: '¡UPS! Ya has ganado esta encuesta',
    description: 'Lamentablemente ya no está disponible porque ya las has completado. Prueba eligiendo otra encuesta distinta.'
  },

  // -- MEMORAMA -- 
  {
    code: 901,
    message: '¡Ups! Este memorama se encuentra en proceso en tu cuenta.',
    description: 'Estate atento a las nuevas dinámicas que tendremos para ti.',
    button: 'Ver mas dinámicas'
  },
  {
    code: 902,
    message: '¡Ups! Este memorama ya ha sido respondido en tu cuenta.',
    description: 'Estate atento a las nuevas dinámicas que tendremos para ti.',
    button: 'Ver mas dinámicas'
  },
  {
    code: 905,
    message: '¡Qué pena!',
    description: 'Has agotado tus chances pero no temas, puedes volver a intentarlo.',
    button: 'Volver a empezar'
  },
  {
    code: 906,
    message: '¡Qué pena!',
    description: 'Se te acabó el tiempo',
    button: 'Volver a empezar'
  },
  {
    code: 907,
    message: '¡Ups! Este memorama no esta en proceso',
    description: 'Es posible que ya no se encuentre más disponible, estate atento a las nuevas dinámicas que tendremos para ti.',
    button: 'Ver mas dinámicas'
  },
  {
    code: 908,
    message: '¡Ups! Este memorama no esta en proceso',
    description: 'Es posible que ya no se encuentre más disponible, estate atento a las nuevas dinámicas que tendremos para ti.',
    button: 'Ver mas dinámicas'
  },
  {
    code: 909,
    message: '¡Ups! Este memorama no esta en proceso',
    description: 'Es posible que ya no se encuentre más disponible, estate atento a las nuevas dinámicas que tendremos para ti.',
    button: 'Ver mas dinámicas'
  },
  {
    code: 910,
    message: '¡Ups! Nos falta tu dirección',
    description: 'Completa en tu perfil, tus datos de dirección para poder participar en este memorama. Recuerda poner los datos correctos para poder enviarte tu premio a casa.',
    button: 'VAMOS A COMPLETAR MIS DATOS'
  },
  {
    code: 911,
    message: '¡Ups! El premio no está disponible en tu dirección activa',
    description: 'Estate atento a las nuevas dinámicas que tendremos para ti.',
    button: 'Ver mas dinámicas'
  },
  {
    code: 912,
    message: '¡Ups! Este memorama ya llego a su máximo de usos.',
    description: 'Estate atento a las nuevas dinámicas que tendremos para ti.',
    button: 'Ver mas dinámicas'
  },
  {
    code: 913,
    message: '¡Ups! Al parecer no completaste el memorama.',
    description: 'Pero no temas, puedes volver a intentarlo',
    button: 'Volver a empezar'
  },
  {
    code: 900,
    message: <>¡Aún no puede<br />subir su foto!</>,
    description: <>Falta que termine de completar su perfil</>,
    button: '¡VAMOS A COMPLETARLO!'
  },
  {
    code: 1006,
    message: <>¡Ups! El código <br /> parece inválido</>,
    description: <>Es posible que el código ingresado no exista <br /> o ya haya sido utilizado por otro usuario.</>,
  },
  {
    code: 1108,
    message: <>Dinámica finalizada</>,
    description: textServices.findByText('error/1108/description', 'Esta dinámica ya tiene sus ganadores.'),
    button: textServices.findByText('error/1108/button/text', 'Volver')
  },
  {
    code: 1116,
    message: <>Espera tantito</>,
    description: textServices.findByText('error/1116/description', <>Lo sentimos, no es posible cargar en tan corto tiempo tus consumos, te esperamos de vuelta más tarde.</>),
    button: textServices.findByText('error/1116/button/text', 'Volver')
  },
  {
    code: 1301,
    message: <>No se ha podido acceder al perfil del usuario</>,
    description: <>intente nuevamente.</>,
  },
  {
    code: 1302,
    message: <>El perfil no dispone de fotos para obtener</>,
    description: <>intente nuevamente con un perfil que tenga fotos para subir.</>,
  },
  {
    code: 'user_denied',
    message: <>Acceso denegado</>,
    description: <>Intente nuevamente dándole permisos a Instagram.</>,
  },
  {
    code: 1506,
    message: '¡Ups! Parece que el tipo de archivo no es valido',
    description: 'Asegúrate de que el archivo sea una imagen de tipo png, jpeg o jpg',
  },
  {
    code: 79,
    message: textServices.findByText('error/79/message', 'Ya has votado esta foto'),
    description: textServices.findByText('error/79/description', 'Puedes votar por otra foto'),
  },
  {
    code: 858,
    message: 'Te han quedado preguntas sin responder',
    description: 'Intente nuevamente',
    button: 'Volver a intentar'
  },
  {
    code: 9628,
    message: '¡Qué pena!',
    description: 'Has usado tus 3 chances pero no temas, puedes volver a intentarlo.',
    button: 'Volver a empezar'
  },
  {
    code: 9631,
    message: '¡Qué pena!',
    description: 'Has agotado tus chances pero no temas, puedes volver a intentarlo.',
    button: 'Volver a empezar'
  },
  {
    code: 9629,
    message: '¡Qué pena!',
    description: 'Se te acabó el tiempo',
    button: 'Volver a empezar'
  },
  {
    code: 9630,
    message: textServices.findByText('error/9630/title', '¡Gracias por escribirnos! Recibimos tu mensaje.'),
    description: textServices.findByText('error/9630/description', 'Danos un par de días para contestarte y estamos seguros que te vamos a poder ayudar. Recuerda que te llegará la respuesta por mail.')
  },
  { code: 99999, message: 'Ha ocurrido un error', description: '', button: <>¿ES UN ERROR?<br /> PARA CUALQUIER DUDA INGRESA AQUI</> },
  // album ticket
  {
    code: 1214,
    message: '¡Espera!',
    description: 'No puedes avanzar si no tienes los datos completos DE TU NÚMERO DE CLIENTE.',
    button: textServices.findByText('error/1214/button/text', 'COMPLETAR')
  }
]

export const prettyError = (code?: number | string) => {
  const defaultMessage = ERRORS.find((error) => error.code === 99999)?.message
  const errorMesg = ERRORS.find((error) => error.code === code)
  return errorMesg?.message ? errorMesg?.message : defaultMessage
}

export const prettyErrorDescription = (code?: number | string) => {
  const defaultMessage = ERRORS.find((error) => error.code === 99999)?.description
  const errorMesg = ERRORS.find((error) => error.code === code)
  return errorMesg?.description ? errorMesg?.description : defaultMessage
}

export const prettyErrorButton = (code?: number | string) => {
  const errorMesg = ERRORS.find((error) => error.code === code)
  return errorMesg?.button
}
