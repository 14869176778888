/* eslint-disable indent */
import { BIRTHDAY, EMAIL_REGEXP, PHONE_REGEXP } from 'constants/regexp'
import { dlTrackError } from 'components/dataLayer/dataLayer'
import * as yup from 'yup'
import { RequiredStringSchema } from 'yup/lib/string'
import { AnyObject } from 'yup/lib/types'

yup.setLocale({
  mixed: {
    default: 'El campo no es válido',
    required: 'El campo no puede estar vacío'
  },
  string: {
    email: 'El email no es válido',
    min: ({ min }) => `Debe contener al menos ${min} caracteres`,
    max: ({ max }) => `Debe contener como maximo ${max} caracteres`,
    length: ({ length }) => `El campo debe contener una longitud de ${length}`,
    url: 'La URL no es válida',
    lowercase: 'El campo solo puede estar en minúsculas',
    uppercase: 'El campo solo puede estar en mayúsculas'
  },
  number: {
    min: ({ min }) => `Debe ser mayor o igual a ${min}`,
    max: ({ max }) => `Debe ser menor o igual a ${max}`,
    lessThan: ({ less }) => `Deber ser menor a ${less}`,
    moreThan: ({ more }) => `Debe ser mayor a ${more}`,
    positive: 'Debe ser un valor positivo',
    negative: 'Debe ser un valor negativo',
    integer: 'Debe ser un valor entero'
  },
  date: {
    min: 'Tiene que ser mayor',
    max: 'Tiene que ser menor'
  }
})

const yupRequired = (name: string, yup: RequiredStringSchema<string, AnyObject>) => {
  if (!THEME_CONFIG.notAvailableFields?.includes(name)) {
    return yup.required()
  }
  return yup
}

const yupWhen = (schemaWhen) => {
  return (value: any, schema) => {
    if (value === '') return
    return schema.concat(schemaWhen)
  }
}

const formServices = {

  formBuilder(schema: any, value: string | number, name: string, type: any) {

    if (type === 'number' && value === '') {
      return 'El campo no puede estar vacío'
    }

    const formSchema = yup.object().shape({
      [name]: schema
    })
    const response = formSchema.validate({ [name]: value }).catch((err) => {
      return err.errors[0]
    })
    return response

  },

  formSelectYup(name: string) {
    switch (name) {
      case 'textcard':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().min(5).max(60))))
      case 'titleHistory':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().min(2).max(80))))
      case 'textHistory':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().min(30).max(280))))
      case 'firstName':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().matches(/^[aA-zZ\sñÑ\u00E0-\u00FC]+$/, 'El nombre debe ser alfabético').min(2).max(30))))
      case 'lastName':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().matches(/^[aA-zZ\sñÑ\u00E0-\u00FC]+$/, 'El apellido debe ser alfabético.').min(2).max(30))))
      case 'nickName':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().matches(/^[aA-zZ-Z0-9-ñÑ\u00E0-\u00FC]*$/, 'El apodo debe ser alfanumérico (sin espacios)').min(2).max(30))))
      // return yup.string().trim().required().matches(/^[aA-zZ-Z0-9-ñÑ\u00E0-\u00FC]*$/, 'El apodo debe ser alfanumérico (sin espacios)').min(2).max(30)
      case 'email':
        return yupRequired(name, yup.string().email().when(yupWhen(yup.string().matches(EMAIL_REGEXP, 'El email no es válido'))))
      // return yup.string().email().required().matches(EMAIL_REGEXP, 'El email no es válido')
      case 'contactMail':
        return yupRequired(name, yup.string().email().when(yupWhen(yup.string().matches(EMAIL_REGEXP, 'El email no es válido'))))
      // return yup.string().email().required().matches(EMAIL_REGEXP, 'El email no es válido')
      case 'password':
        return yupRequired(name, yup.string().when(yupWhen(yup.string().min(6))))
      // return yup.string().required().min(6)
      case 'password_confirm':
        return yupRequired(name, yup.string().when(yupWhen(yup.string().oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden'))))
      // return yup.string().required().oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden')
      case 'curp':
        return yupRequired(name, yup.string().when(yupWhen(yup.string().min(17).max(18).matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, 'El CURP debe ser alfanumérico'))))
      // return yup.string().min(17).max(18).matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, 'El CURP debe ser alfanumérico').required()
      case 'street1':
        return yupRequired(name, yup.string().when(yupWhen(yup.string().min(4).matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, 'Debe ser alfanumérico'))))
      // return yup.string().trim().required().min(4).matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, 'Debe ser alfanumérico')
      case 'streetType':
        return yupRequired(name, yup.string().nullable().when(yupWhen(yup.string())))
      // return yup.string().nullable().trim().required('Debe seleccionar una opción')
      case 'postalCode':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().min(4).max(5).matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, 'El código postal debe ser numérico'))))
      // return yup.string().trim().required().min(4).matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, 'Debe ser alfanumérico')
      case 'birthday':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().matches(BIRTHDAY, 'La fecha debe ser válida').min(10, 'La fecha debe ser válida'))))
      // return yup.string().trim().required().matches(BIRTHDAY, 'La fecha debe ser válida').min(10, 'La fecha debe ser válida')
      case 'streetNumber':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, 'Debe ser alfanumérico'))))
      // return yup.string().trim().required().min(2).matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, 'Debe ser alfanumérico')
      case 'neighborhood':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, 'Debe ser alfanumérico'))))
      // return yup.string().trim().required().matches(/^[aA-zZ0-9\sñÑ\u00E0-\u00FC]+$/, 'Debe ser alfanumérico')
      case 'phoneNumber':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().matches(PHONE_REGEXP, 'El teléfono debe contener 10 números'))))
      // return yup.string().trim().required().matches(PHONE_REGEXP, 'El celular debe contener 10 números')
      case 'otp':
        return yupRequired(name, yup.string().trim().when(yupWhen(yup.string().min(6, 'OTP Incorrecto').max(6, 'OTP Incorrecto'))))
      // return yup.string().required().min(6, 'OTP Incorrecto').max(6, 'OTP Incorrecto')
      case 'genreId':
        return yupRequired(name, yup.string().when(yupWhen(yup.string())))
      // return yup.string().required()
      case 'instagram':
        return yupRequired(name, yup.string().when(yupWhen(yup.string().max(20, 'El nombre de usuario debe ser menor a 20 caracteres'))))
      case 'sign':
        return yupRequired(name, yup.string().when(yupWhen(yup.string().max(10, 'La firma tiene que ser menor a 10 caracteres'))))
      case 'clientNum':
        return yupRequired(name, yup.string().trim().matches(/^[a-zA-Z0-9]{9}$/, 'El número de cliente debe contener solo letras y números').when(yupWhen(yup.string().max(9, 'El número de cliente es de 9 digitos').min(9, 'El número de cliente tiene minimo 9 digitos'))))
      default:
        return null
    }
  },

  formGenerateSchema(names: string[]) {
    const object = {}
    names.forEach((name) => {
      object[name] = this.formSelectYup(name)
    })

    return yup.object().shape({
      ...object
    })
  },

  formValidateErrors(yupResolver: any, section: string, errorType: string ) {
    return async (data: any, context: any, options: any) => {
      const resolver = await yupResolver(data, context, options) as any;
      if (resolver?.errors) {
        for (const key in resolver?.errors) {
          if (Object.prototype.hasOwnProperty.call(resolver?.errors, key)) {
            const element = resolver?.errors[key];
            dlTrackError({
              error_type: errorType,
              error_code: key,
              error_message: element?.message,
              section: section
            })
          }
        }
      }
      return resolver;
    }
  }

}

export default formServices
