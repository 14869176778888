/* eslint-disable node/no-callback-literal */
import { AUTH } from 'constants/api'
import { useContext, useState } from "react"
import { AuthError, FacebookAuthProvider, getAuth, signInWithCredential } from '@firebase/auth'
import axios, { AxiosError } from 'axios'
import useRedirect from 'hooks/use-redirect'
import utmServices from 'services/utm-services'
import FacebookIcon from 'components/icon/facebook'
import ProfileService from 'services/profile-services'
import MetaCAPIService from 'services/meta-capi.services'
import { dlSetUserInfo, dlTrackEvent, createUserProperties } from 'components/dataLayer/dataLayer'
import useLocalStorage from 'hooks/use-local-storage'
import { getBirthdayLocal } from 'utilities/birthday'
import { UserData } from 'contexts/session-context/session-reducer'
import { Prize } from 'views/prizes/types'
import useRouter from 'hooks/use-router'
import { LoaderContext } from '../../../contexts/loader-context'
import { goToUrlFacebook } from './goToUrlFacebook'

function Facebook({ setSession, setUser, setLoginError, setError, setFirebaseError, setAllowCookies, setPrize }) {

  const { setLoad } = useContext(LoaderContext)
  const [{ url }, { redirect }] = useRedirect();
  const auth = getAuth()
  const [flowType] = useLocalStorage('flowType')
  const { push } = useRouter();
  const [birthday] = useState(getBirthdayLocal())

  const completeData = (user: UserData, session: Object) => {
    setSession(session)
    setUser(user)

    const external = localStorage.getItem('toexternal')
    if (external) {
      localStorage.removeItem('toexternal')
      return window.location.assign(decodeURI(JSON.parse(external)))
    };

    if (url) return redirect();

    const availableFields = THEME_CONFIG?.notAvailableFields
    const isComplete = ((availableFields?.includes('nickName') ? false : !user?.nickName) || !user?.birthday || (!user?.genre || !user?.genreId))
    if (!THEME_CONFIG?.signUp?.base && isComplete) return push('/information/update?isNew=true');

    return push('/home')
  }

  const callback = async (data?: { termsOfUse?: boolean, subscribe?: boolean, code?: string, session: Object, user: UserData, prize?: Prize }) => {
    setLoad(true)
    const { session, user, termsOfUse, subscribe, code, prize } = data || {}
    let payload = { termsOfUse, subscribe, code }
    const consumption = localStorage.getItem('consumption')
    if (consumption) {
      payload = { ...payload, consumptionCenterId: JSON.parse(consumption) ?? 0 } as any
    }
    try {
      dlSetUserInfo(user?.hashId ?? user?.id)
      createUserProperties(user, 'facebook')
      let userData = user

      if (termsOfUse || subscribe || code) {
        const response = await ProfileService.socialLogin({ payload, credentials: session })
        userData = response?.data
      }

      if (prize) return setPrize({ prize, name: userData?.firstName, callback: () => completeData(userData, session) })

      return completeData(userData, session)
    } catch (error) {
      setAllowCookies({ callback, isAllow: false })
      const code = (error as AxiosError).response?.data.code || 99999
      setLoginError(code)
    } finally {
      setLoad(false)
    }
  }

  const loginSDK = async (response: any) => {
    try {
      setLoad(true)
      const accessToken = response?.authResponse?.accessToken;
      const credentials = FacebookAuthProvider.credential(accessToken)
      const result = await signInWithCredential(auth, credentials);
      const names = result?.user?.displayName?.split(' ') || ['', '']
      const firstName = names[0]
      const lastName = names[names.length - 1]
      const utm = utmServices.utmRead()

      const body: any = { accessToken, firstName, lastName, utm }

      const bithdayUser = (result as any)?.user?.reloadUserInfo?.dateOfBirth ? new Date((result as any)?.user?.reloadUserInfo?.dateOfBirth) : birthday

      if (bithdayUser && !Number.isNaN(bithdayUser)) {
        body.birthday = bithdayUser;
      }

      const loginResponse = await axios.post(
        AUTH, body,
        {
          headers: { Authorization: `Bearer ${(result?.user as any)?.accessToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 }
        }
      )
      setLoad(false)
      if (!loginResponse?.data?.profile?.termsOfUse) {
        gtag('event', 'sign_up', {
          event_label: 'SignUp - Meta',
          method: "Meta"
        })
        MetaCAPIService.emitCompleteRegistration(result.user.email, firstName, lastName, '', window.location.href, 'Meta', MetaCAPIService.uuidv4())
        return setAllowCookies({ callback: (data: { termsOfUse: boolean, subscribe: boolean, code?: string }) => callback({ ...data, session: result, user: loginResponse?.data?.profile, prize: loginResponse?.data?.prize }), isAllow: true })
      }
      gtag("event", "login", {
        event_label: 'Login - Meta',
        method: "Meta"
      });

      dlTrackEvent('login', {
        action: 'click',
        element: 'Facebook',
        section: 'sign in / sign up',
        method: 'facebook'
      })

      if (flowType === 'alreadyParticipate') return setAllowCookies({
        callback: () => callback({ session: result, user: loginResponse?.data?.profile, prize: loginResponse?.data?.prize }),
        isAllow: false
      })

      callback({ session: result, user: loginResponse?.data?.profile, prize: loginResponse?.data?.prize })
    } catch (error) {
      if ((error as AxiosError).isAxiosError) {
        const code = (error as AxiosError)?.response?.data.code || 99999
        setLoginError(code)
      } else {
        const typedError = error as AuthError
        setError(typedError)
        const isAccountExistsError = typedError.code === 'auth/account-exists-with-different-credential';
        const errorKey = isAccountExistsError ? `${typedError.code}-facebook` : typedError.code;
        setFirebaseError(errorKey);
      }
    } finally {
      setLoad(false)
    }
  }

  const handleClick = () => {
    dlTrackEvent('user_interaction', {
      action: "click",
      element: 'facebook',
      section: "login"
    })
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'Click - Meta', value: 'Meta' })
    if (FC_CLIENTID && !["dosequis", "tecate", "mixx", "cristal"].includes(THEME)) {
      window.FB.login((response) => {
        if (response?.authResponse) {
          loginSDK(response)
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      }, { scope: 'email,user_birthday,user_likes' });
    } else {
      return goToUrlFacebook()
    }
  }

  return (
    <div onClick={handleClick} className="cursor-pointer">
      <FacebookIcon className='facebook' />
    </div>
  )
}

export default Facebook
