import { GET_CARRUSEL, GET_LEVELS } from 'constants/api'
import axios from 'axios'
import ButtonNav from 'components/button-nav'
import GoArrow from 'components/icon/goArrow'
import LineSteps from 'components/line-steps'
import { LoaderContext } from 'contexts/loader-context'
import { useSession } from 'contexts/session-context'
import UsePercentageProfile from 'hooks/use-percentage-profle'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import textServices from 'services/text-services'
import { useSurveyRedirect } from 'contexts/survey-context/useSurvey'
import { SurveyTypeEnum } from 'contexts/survey-context/survey.interface'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import useMainClass from 'hooks/use-main-cass'
import { DivProgress, DivProgressSecond } from 'theme/home'
import PopUp from './components/PopUp'
import { Dots, HrHome, NavContainer, Points } from './style/home.style'
import { TextHomeSecondary, TitleHome, TextPoints, TextDynamics, FloatImgHome, ContainerTextProgress, TextArrow, TextTotalPoints, TextArrowColor } from './style/text.style'

const Type = { 1: 'onclick', 2: 'href', 3: 'to' }

function Home() {
  const [{ user, data: session }] = useSession()
  const { percentageProfile } = UsePercentageProfile()
  const { setLoad, setFooter } = useContext(LoaderContext)
  const [carrousel, setCarrusel] =
    useState<{ src: string; to?: string; href?: string; id: number; onclick?: string }[]>()
  const [asyncSteps, setAsyncSteps] = useState<{ id: number; name: string; imageUrl: string }[]>([])
  const [steps, setSteps] = useState<{ name: string; image: string; active?: boolean }[]>([])
  const [popUp, setPopUp] = useState<string | number>()
  useSurveyRedirect(SurveyTypeEnum.REGISTER)

  useMainClass('home')
  const getAsyncStates = async () => {
    setLoad(true)
    try {
      const { data: steps } = await axios.get<{ id: number; name: string; imageUrl: string }[]>(GET_LEVELS, {
        headers: { Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 }
      })
      if (steps.length > 0) {
        setAsyncSteps(steps)
      }
      const { data: carrousel } = await axios.get<{ imageUrl: string; type: number; url: string }[]>(GET_CARRUSEL, {
        headers: { 'Ph-Api-Id': PH_API_ID ?? 1 }
      })
      if (carrousel.length > 0) {
        setCarrusel(
          carrousel.map(({ imageUrl, type, url }, index) => ({
            src: imageUrl,
            [Type[type] ?? 'href']: url,
            id: index
          }))
        )
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    if (asyncSteps?.length > 0) {
      setSteps(
        asyncSteps?.map(({ id, name, imageUrl }) => ({
          id,
          name,
          image: imageUrl,
          active: id === user?.level?.id
        }))
      )
    }
  }, [user, asyncSteps])

  useEffect(() => {
    setFooter(true)
    getAsyncStates()
    return () => {
      setCarrusel([])
      setSteps([])
    }
  }, [])

  const dots = () => <Dots></Dots>

  const settings = {
    centerPadding: carrousel?.length > 1 ? '15px' : '0px',
    centerMode: true,
    dots: true,
    infinite: false,
    speed: 500,
    autoplaySpeed: 4000,
    autoplay: true,
    customPaging: dots,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (currentSlide: number) => { dlTrackEvent('view_promotion', { promotion_id: carrousel[currentSlide]?.id, promotion_name: carrousel[currentSlide]?.to, creative_name: carrousel[currentSlide]?.src, section: 'home' }) }
  }

  if (popUp) {
    return <PopUp popUp={popUp} setPopUpFalse={() => setPopUp(undefined)} />
  }

  return (
    <div className={`py-7 px-6 flex flex-col w-full gap-4 relative`}>
      <div className="text-3xl uppercase home__title-container">
        <TitleHome className="font-black leading-5 title-1">Hola {user?.firstName}</TitleHome>
        <TitleHome className='title-2'>{textServices.findByText('home/title', '¿Qué quieres hacer?')}</TitleHome>
      </div>
      <NavContainer className="flex flex-row items-center gap-4 justify-between home__nav-container">
        {THEME_CONFIG.routesHome.map((route: string, index: number) => (
          <ButtonNav key={index} route={route} />
        ))}
      </NavContainer>{' '}
      {
        THEME_CONFIG?.dynamics?.length > 0 ? (
          <div className='flex flex-col gap-2'>
            <TextDynamics>DINÁMICAS</TextDynamics>
            <nav className='flex items-center justify-between gap-2'>
              {
                THEME_CONFIG?.dynamics?.map((dynamic: any, index: number) => (
                  <ButtonNav key={index} route={dynamic} />
                ))
              }
            </nav>
          </div>
        ) : null
      }
      {THEME_CONFIG?.usePointsHome && (
        <>
          <HrHome />
          <section className="gap-2 flex flex-col items-center justify-center">
            <div className="flex items-center justify-between w-full">
              <TextPoints className="font-black text-2xl flex-1 text-left uppercase text">
                Tienes{' '}
                <Points>
                  {user?.totalPoints} {POINTS_NAME}
                </Points>
              </TextPoints>
              {/* <Link to={'/progress'}> */}
              <img src={user?.level?.imageUrl} alt={user?.level?.name} className="w-10" />
              {/* </Link> */}
            </div>
            <LineSteps steps={steps} />
          </section>{' '}
        </>
      )}
      {THEME_CONFIG?.useProfilePointsHome && (
        <>
          <HrHome />
          <Link to="/profile/movements" className="gap-2 flex flex-col items-center justify-center">
            <div className="flex items-center justify-between w-full">
              <div className='flex items-center flex-1 gap-2'>
                <img src={user?.level?.imageUrl} alt={user?.level?.name} className="w-9" />
                <TextPoints className="font-black text-2xl flex-1 text-left uppercase text">
                  Tienes{' '}
                  <Points>
                    {user?.totalPoints} {POINTS_NAME}
                  </Points>
                </TextPoints>
              </div>
              <TextArrow className='flex gap-3 items-center justify-center uppercase'>
                {textServices.findByText('home/arrowTextPointsProfile', `VER ${POINTS_NAME}`)}
                <GoArrow />
              </TextArrow>
            </div>
          </Link>{' '}
        </>
      )}

      {
        THEME_CONFIG?.usePointsText && (
          <Link to="/profile/movements">
            <HrHome />
            <div className='flex justify-between pt-5'>
              <TextTotalPoints>
                {textServices.findByText('home/pointsText', <>TIENES {user?.totalPoints} {POINTS_NAME}</>, { TOTAL_POINTS: user?.totalPoints, POINTS_NAME: POINTS_NAME })}
              </TextTotalPoints>
              <TextArrow className='flex gap-3 items-center justify-center'>
                {textServices.findByText('home/arrowTextPoints', '')}
                <GoArrow />
              </TextArrow>
            </div>
          </Link>
        )
      }

      <HrHome />
      <section className="gap-2 flex flex-col items-center justify-center">
        <Link to="/profile" className="w-full" onClick={() => {
          dlTrackEvent('user_interaction', {
            action: "click",
            element: 'ver perfil',
            section: "home"
          })
        }}>
          <ContainerTextProgress className="flex items-center justify-between w-full ">
            <TextHomeSecondary className={`font-normal text-base flex-1 text-left text ${percentageProfile < 100 && 'animate-bounce'}`}>
              {
                percentageProfile === 100
                  ? textServices.findByText('home/descriptionComplete', '¡Tus datos están completos!')
                  : textServices.findByText('home/description', '¡Completa tus datos!')
              }
            </TextHomeSecondary>

            <TextArrowColor className='flex gap-3 items-center justify-center'>
              <TextArrow>
                {textServices.findByText('home/arrowText', '')}
              </TextArrow>
              <GoArrow />
            </TextArrowColor>
          </ContainerTextProgress>
        </Link>
        <DivProgress className="w-full h-7 rounded-full relative flex items-center progress-container">
          <DivProgressSecond
            className={`w-full h-full bg-left rounded-full relative progress-complete ${percentageProfile < 100 && 'animate-pulse'}`}
            style={{ width: `${percentageProfile}%`, transition: 'all 0.9s ease-out' }}
          >
            <span className="absolute right-4 h-full flex items-center progress-text">
              {percentageProfile}% {percentageProfile > 30 && textServices.findByText('home/percentageProfile', '')}
            </span>
          </DivProgressSecond>
        </DivProgress>
      </section>
      <HrHome />
      <section className="w-full relative">
        <Slider {...settings} className="w-full">
          {carrousel?.map(({ src, to, href, id, onclick }) => {
            const processedHref = href?.replace('{{ID}}', user?.id?.toString());

            if (href)
              return (
                <a href={processedHref} key={id} target="_blank" rel="noreferrer" onClick={() => {
                  gtag('event', 'event_to_ga', { event_category: "Click en Banner", event_label: href })
                  dlTrackEvent('select_promotion', { promotion_id: id, promotion_name: href, creative_name: src, section: 'home' })
                }}>
                  <img src={src} alt="carrusel" className="w-full object-cover rounded-xl cursor-pointer" />
                </a>
              )
            if (to) {
              return (
                <Link to={to} key={id} onClick={() => {
                  gtag('event', 'event_to_ga', { event_category: "Click en Banner", event_label: href })
                  dlTrackEvent('select_promotion', { promotion_id: id, promotion_name: to, creative_name: src, section: 'home' })
                }}>
                  <img src={src} alt="carrusel" className="w-full object-cover rounded-xl cursor-pointer" />
                </Link>
              )
            }
            return (
              <img
                className="rounded-xl w-full object-cover cursor-pointer"
                src={src}
                key={id}
                onClick={() => {
                  gtag('event', 'event_to_ga', { event_category: "Click en Banner", event_label: "Abre popup" })
                  dlTrackEvent('select_promotion', { promotion_id: id, promotion_name: onclick, creative_name: src, section: 'home' })
                  setPopUp(onclick)
                }}
              />
            )
          })}
        </Slider>
      </section>

      {
        THEME_CONFIG?.useImgInHome && (
          <FloatImgHome src={`${AZURE_BASE_URL}/selloHome.png`} alt="Sello home" />
        )
      }
    </div>
  )
}

export default Home
