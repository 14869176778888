import Header from 'components/header'
import ButtonCloseMenu from 'components/button-close'
import { SubTitle, Title } from 'theme/theme'
import { DescriptionText, SpanPolitics } from 'components/birthday-input/style.custom'
import { Link } from 'react-router-dom'

const Arrow = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 0.46875C12.3392 0.46875 14.0482 0.932617 15.627 1.86035C17.1569 2.75553 18.3695 3.9681 19.2646 5.49805C20.1924 7.07682 20.6562 8.78581 20.6562 10.625C20.6562 12.4642 20.1924 14.1732 19.2646 15.752C18.3695 17.2819 17.1569 18.4945 15.627 19.3896C14.0482 20.3174 12.3392 20.7812 10.5 20.7812C8.66081 20.7812 6.95182 20.3174 5.37305 19.3896C3.8431 18.4945 2.63053 17.2819 1.73535 15.752C0.807617 14.1732 0.34375 12.4642 0.34375 10.625C0.34375 8.78581 0.807617 7.07682 1.73535 5.49805C2.63053 3.9681 3.8431 2.75553 5.37305 1.86035C6.95182 0.932617 8.66081 0.46875 10.5 0.46875ZM10.5 2.03125C8.9375 2.03125 7.48893 2.42188 6.1543 3.20312C4.86849 3.9681 3.8431 4.99349 3.07812 6.2793C2.29688 7.61393 1.90625 9.0625 1.90625 10.625C1.90625 12.1875 2.29688 13.6361 3.07812 14.9707C3.8431 16.2565 4.86849 17.2819 6.1543 18.0469C7.48893 18.8281 8.9375 19.2188 10.5 19.2188C12.0625 19.2188 13.5111 18.8281 14.8457 18.0469C16.1315 17.2819 17.1569 16.2565 17.9219 14.9707C18.7031 13.6361 19.0938 12.1875 19.0938 10.625C19.0938 9.0625 18.7031 7.61393 17.9219 6.2793C17.1569 4.99349 16.1315 3.9681 14.8457 3.20312C13.5111 2.42188 12.0625 2.03125 10.5 2.03125ZM11.0615 5.62012L16.0664 10.625L11.0615 15.6299L9.93848 14.5312L13.0635 11.4062H5.03125V9.84375H13.0635L9.93848 6.71875L11.0615 5.62012Z" fill="#FF2B00" />
    </svg>
  )
}

export const heineken00 = {
  legalage: {
    title: ' ',
    button1: <span style={{ fontSize: '14px' }}>SI, SOY MAYOR</span>,
    button2: <span style={{ fontSize: '14px' }}>NO, SOY MENOR</span>,
    question: '¿Eres mayor de edad?'
  },
  agegate: {
    title: <span className='invisible'></span>,
    description: <div className='flex flex-col justify-between items-center gap-5 flex-1'>
      <img src={`${AZURE_BASE_URL}/agegate.png`} alt="Titulo" />
      <DescriptionText className='normal-case mx-7 text-center helvetica text-sm font-normal agegate__description'>
        Ingresa tu año de nacimiento para validar que eres mayor de edad.
      </DescriptionText>
    </div>,
    politics1: <span className='hidden'></span>,
    politics2: <SpanPolitics className='agegate__politics'>
      AL CONFIRMAR, ACEPTAS LA <a href={`${process.env.PUBLIC_URL}/privacy-politics`} rel="noreferrer" target="_blank" className="underline">POLÍTICA DE PRIVACIDAD</a >, <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">TÉRMINOS Y CONDICIONES Y USO DE COOKIES</a > DE ESTE SITIO WEB. ESTE SITIO WEB UTILIZA COOKIES. PARA OBTENER MÁS INFORMACIÓN, LEE NUESTRA POLÍTICA DE <a href={`${process.env.PUBLIC_URL}/privacy-politics`} rel="noreferrer" target="_blank" className="underline">PRIVACIDAD</a > Y USO DE <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">COOKIES</a >.
    </SpanPolitics>
  },
  signup: {
    title: 'Bienvenido',
    description:
      'Ahora eres parte del equipo. Revisa tus correos para comenzar a registrar tus latas para completar la colección. Consigue las 26 latas de Amstel Ultra y descubre los mensajes de Rafa Nadal.',
    button: 'SIGUIENTE',
    welcome: {
      subtitle: 'Solo falta validar tu cuenta.',
      description: <>Te enviamos un código de 6 dígitos a tu correo electrónico para que valides.</>,
      description3: <b>¿No lo recibiste?</b>
    }
  },
  start: {
    signup: 'REGÍSTRATE CON CORREO',
    signin: 'INGRESA CON CORREO',
    social: 'Entra con:',
    title: {
      html: (
        <div className="flex-1 w-full pb-2 flex flex-col items-center justify-center">
          <img src={`${AZURE_BASE_URL}/fondo-inicial.png`} alt="background" className="w-full" />
        </div>
      )
    }
  },
  home: {
    title: '¿Qué quieres hacer?',
    description: '¡Completa tus datos!',
    popup: {
      two: {
        mobile: 'https://stblobphcentral.blob.core.windows.net/silver/assets/popup-2-mobile.png',
        desktop: 'https://stblobphcentral.blob.core.windows.net/silver/assets/popup-2-desktop.png',
        'desktop-1500': 'https://stblobphcentral.blob.core.windows.net/silver/assets/popup-2-desktop-1500.png'
      }
    }
  },
  password: {
    label: {
      newPassword: 'Nueva contraseña',
      newPasswordConfirm: 'Repetir nueva contraseña'
    }
  },
  profile: {
    form: {
      information: {
        firstname: {
          label: '¿Cómo te llamas?',
          placeholder: 'Pon tu nombre aquí'
        },
        lastname: {
          placeholder: 'Pon tu apellido aquí'
        },
        nickname: {
          label: <>¿Apodo? <span style={{ fontWeight: 400 }}>(opcional)</span></>,
          placeholder: '¿Cómo te gusta que te llamen?'
        },
        gender: {
          label: '¿Cómo te identificas?',
          placeholder: 'Selecciona una opción'
        },
        email: {
          label: 'Correo electrónico'
        }
      }
    },
    information: 'Datos personales',
    address: 'Dirección',
    contact: 'Contacto',
    button: 'IR A VER LOS PREMIOS',
    state: 'HISTORIAL DE CANJES',
    identification: {
      button: 'DESCARGAR ID',
      label: 'Mi HNK ID',
      nav: 'Mi HNK ID'
    },
    view: {
      arrow: <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.96094 0.0859375L8.03906 1.16406L2.95312 6.25H18V7.75H2.95312L8.03906 12.8359L6.96094 13.9141L0.585938 7.53906L0.0703125 7L0.585938 6.46094L6.96094 0.0859375Z" fill="#BBBCBD" />
      </svg>
    },
    stateNotFound: <>Aún no has canjeado ningún premio. <br /> <b>¡Venga, tu puedes!</b></>,
  },
  config: {
    prizes: '/album/redirect/Tickets/upload'
  },
  congrats: {
    range: {
      title: 'NUEVO LOGRO DESBLOQUEADO',
      description: <></>,
      description2: (
        <>
          Felicitaciones, has desbloqueado nuevos <br /> premios y obtienes más chances de ganar.
        </>
      )
    }
  },
  endroute: {
    title: (
      <>
        ¡Lo sentimos!<br />Esto es sólo para mayores de edad
      </>
    ),
    description: 'Vuelve cuando sea más grande.'
  },
  referrals: {
    description: 'Comparte tu código con amigos y gana más oportunidades de ganar.',
    subtitle: 'COMPARTE TU CÓDIGO Y GANA',
    left: 'COPIAR',
    right: 'COMPARTIR'
  },
  trivias: {
    'not-found': <>No hay trivias disponibles por el momento, <br /> estate atento a nuestras nuevas dinámicas.</>,
    exchange: {
      button: {
        text: 'COMPLETAR MIS DATOS',
        url: '/profile'
      }
    },
    triviaQr: {
      register: {
        title: (
          <div className="text-white antonio">
            CONTESTA Y GANA
          </div>
        ),
        description: (
          <div className="text-white">
            <p>¿Ya tienes tu CRISTAL ID?</p>
            <b className="mt-3">SI NO LO TIENES CÓRRELE A REGISTRARTE PARA PODER PARTICIPAR</b>
            <img
              className="mx-auto mt-5"
              src="https://promohubstorage.blob.core.windows.net/cristal/assets/crista_id_ej.svg"
            />
          </div>
        ),
        html: (
          <>
            <div className="flex-1 flex-col gap-6 w-full flex px-7 items-center justify-center text-center">
              <h1 className="antonio text-4xl font-bold text-white">
                CONTESTA Y GANA
              </h1>
              <p className='helvetica text-base text-white font-bold'>¿Ya tienes tu ID?</p>
              <p className='helvetica text-base text-white font-normal'>Si no lo tienes córrele a registrarte para poder participar.</p>
            </div>
            <img src={`${AZURE_BASE_URL}/trivia-qr-footer.png`} />
          </>
        )
      },
      late: {
        title: (
          <>
            NO DISPONIBLE
          </>
        ),
        description: <p className="text-white">Lamentablemente esta trivia ya no está disponible. Estate atento para contestar otra.</p>,
        button: {
          text: 'VER MÁS TRIVIAS'
        }
      }
    },
    questions1: <>Contesta </>,
    questions2: (
      <>
        {' '}
        <span className="font-bold" style={{ color: '#15636A' }}>
          preguntas
        </span>{' '}
        sobre el tema y gana:
      </>
    ),
    exit: {
      accept: "Si, claro",
      cancel: "No, volver",
      subtitle: 'Si cancelas ahora se perderá tu progreso y tendrás que volver a empezar.'
    }
  },
  tivias: {
    challenge: {
      modal: {
        description: '¿Seguro que quieres empezar a jugar? Una vez que des click no hay vuelta atrás.'
      }
    }
  },
  survey: {
    title: 'CUÉNTANOS DE TI',
    button: 'RESPONDER'
  },
  mail: {
    description: (
      <>
        Has confirmado con éxito tu cuenta. <br /> Ya puedes ingresar.
      </>
    ),
    title: <>¡Excelente name!</>
  },
  prizes: {
    start: {
      title: <span style={{ fontFamily: 'Antonio', fontWeight: 500, fontSize: '24px' }}>PREMIOS</span>
    },
    description: {
      politic: <>Recuerda que debes tener tus datos actualizados para poder ganar</>
    },
    congrats: {
      description: <>Ahora atentx al mail que te vamos a mandar con toda la info del envío de tu canje<br />¡Felicidades!</>
    }
  },
  'coming-soon': {
    html: ({ logout }) => {
      return (
        <div className="w-full min-h-view flex flex-col">
          <Header />
          <ButtonCloseMenu open={true} paramFn={logout} />
          <section className="flex-1 flex items-center justify-center flex-col" style={{ background: '#00A7B5' }}>
            <div className="flex-1 flex flex-col items-center justify-center gap-8 pt-28">
              <Title className="font-black text-5xl text-white">Próximamente</Title>
              <SubTitle className="font-semibold text-3xl text-center text-white">
                Podrás ver todas
                <br />
                nuestras dinámicas
                <br />
                aquí.
              </SubTitle>
            </div>
            <img src={`${AZURE_BASE_URL}/cs-image.png`} alt="nubes" className="w-full max-w-lg" />
          </section>
        </div>
      )
    }
  },
  popUps: {
    start: {
      html: (
        <div className="flex-1 flex flex-col gap-4 items-center pt-5">
          <h1 className="font-semibold text-3xl antonio uppercase mb-3">¿Cómo participar?</h1>
          <ul className="flex-1 items-center flex-col flex w-full gap-6 mb-6">
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/start-popup-1.svg`} />
              <div className="flex-1 flex flex-col justify-center gap-2">
                <p className="font-bold text-lg antonio leading-4" style={{ color: '#E30613' }}>
                  Regístrate.
                </p>
                <p className="font-normal text-sm helvetica" style={{ color: '#00A7B5', lineHeight: '120%' }}>
                  Completa tus datos para empezar a juntar Silver Stars.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/start-popup-3.svg`} />
              <div className="flex-1 flex flex-col justify-center gap-2">
                <p className="font-bold text-lg antonio leading-4" style={{ color: '#E30613' }}>
                  Participa.
                </p>
                <p className="font-normal text-sm helvetica" style={{ color: '#00A7B5', lineHeight: '120%' }}>
                  Escanea los QRs de los Totems para participar en las dinámicas y juntar Silver Stars.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/start-popup-2.svg`} />
              <div className="flex-1 flex flex-col justify-center gap-2">
                <p className="font-bold text-lg antonio leading-4" style={{ color: '#E30613' }}>
                  Gana.
                </p>
                <p className="font-normal text-sm helvetica" style={{ color: '#00A7B5', lineHeight: '120%' }}>
                  Acumula tus Silver Stars en el menor tiempo posible y participa por grandes premios.
                </p>
              </div>
            </li>
          </ul>
        </div>
      )
    }
  },
  album: {
    title: 'Dinámicas',
    states: {
      all: 'TODOS',
      progress: 'ACTUALES',
      completed: 'PASADOS'
    },
    notFound: 'No hay retos disponibles por el momento, estate atento a nuestras nuevas dinámicas.'
  },
  social: {
    loader: {
      title: 'Estamos validando',
    },
    imgUpload: 'Espera mientras revisamos tu contenido.',
    Ticket: {
      howToParticipate: {
        title: '¿Cómo participar?',
        subtitle: 'SUBE TU RECIBO Y GANA',
        list: (
          <ul className="flex-1 items-center justify-center flex-col flex w-full gap-7 pb-7">
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-1.svg`} className='object-top' />
              <div className="flex-1 flex flex-col justify-center">
                <p className="" style={{ fontFamily: 'PT Sans', color: 'white', fontSize: '16px' }}>
                  <b>Completa tus datos</b> personales en el perfil de tu cuenta.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-2.svg`} className='object-top' />
              <div className="flex-1 flex flex-col justify-center">
                <p className="" style={{ fontFamily: 'PT Sans', color: 'white', fontSize: '16px' }}>
                  <b>Sube una foto de tu recibo</b> donde se vea bien el detalle de tu compra de productos Heineken.
                </p>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-3.svg`} className='object-top' />
              <div className="flex-1 flex flex-col justify-center">
                <p className="" style={{ fontFamily: 'PT Sans', color: 'white', fontSize: '16px' }}>
                  <b>Acumula recibos</b><br />Los ganadores* serán aquellos que más tickets suban en el tiempo de duración de la dinámica. Para empezar a participar tienes que cargar como mínimo 3 tickets.<br /><br /><span style={{ fontSize: '10px' }}>* stock de premios: 300 unidades.</span>
                </p>
              </div>
            </li>
          </ul>
        )
      },
      onBoarding: {
        rules: {
          list: (
            <>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="">
                    El ticket debe ser de productos Heineken en alguna de sus presentaciones (Original, Light o 0.0).
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="">
                    Se debe visualizar la fecha de compra.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="">
                    No se debe cargar un ticket repetido.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="">
                    El recibo tiene que tener al menos 1 (un) producto Heineken para ser válido, ya sea Original, Light o 0.0.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="">
                    Podrás cargar tickets desde el 2 abril al 1ro de junio inclusive.
                  </p>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <p className="">
                    Los ganadores serán aquellos usuarios que más recibos hayan subido en el tiempo que dure la dinámica, siempre y cuando hayan subido por lo menos 3 tickets.
                  </p>
                </div>
              </div>

            </>
          )
        }
      },
      upload: {
        title: 'Cargar imagen',
        subtitle: 'Clic para subir una imagen',
        howToTakePhotoText: 'Ver imagen de referencia',
        rules: {
          title: 'REGLAS PARA SUBIR CONTENIDO',
          list: (
            <ul style={{ color: '#1B4677', fontSize: '12px', fontFamily: 'PT Sans', fontWeight: 400 }}>
              <li>
                <p className="flex gap-1">
                  <span>1.</span> Usa la cámara de tu celular para tomarle una foto a tu recibo/ticket.
                </p>
              </li>
              <li className="my-3">
                <p className="flex gap-1">
                  <span>2.</span> Busca un lugar iluminado para tomar la foto.
                </p>
              </li>
              <li>
                <p className="flex gap-1">
                  <span>3.</span> Mantente quieto al momento de tomar la foto para obtener una imagen de buena calidad.
                </p>
              </li>
            </ul>
          )
        },
        errorModalValidate: {
          description: 'Aún no puedes subir nuevo contenido por las siguientes razones:',
          list: <div style={{ width: '320px' }}>
            <div style={{ color: 'white' }} className="Montserrat text-sm font-normal">
              <p className='ml-5 mb-3'> • Llegaste al máximo de tickets que puedes cargar. (puedes subir máx. 3 veces al día)</p>
            </div>
          </div>,
          help: <div style={{ width: '320px', color: 'white' }} className='flex justify-center items-center flex-col mb-12 Montserrat pt-4'>
            <span className="font-bold text-center text-sm Montserrat">¿Te parece que no es así?</span>
            <span className="text-center text-sm Montserrat">Ponte en contacto con nosotros para que <br /> podamos ayudarte. <Link to="/help" className='underline'>Envíanos un mensaje.</Link> </span>
          </div>
        },
        icon: <svg width="80" height="84" viewBox="0 0 80 84" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="37.9841" y="12.8002" width="3.21363" height="57.2" fill="#DA2B1F" />
          <rect x="37.9841" y="12.8002" width="3.21363" height="57.2" fill="#DA2B1F" />
          <rect x="37.9841" y="12.8002" width="3.21363" height="57.2" fill="#DA2B1F" />
          <rect x="11.2036" y="43.019" width="3.23774" height="56.7742" transform="rotate(-90 11.2036 43.019)" fill="#DA2B1F" />
          <rect x="11.2036" y="43.019" width="3.23774" height="56.7742" transform="rotate(-90 11.2036 43.019)" fill="#DA2B1F" />
          <rect x="11.2036" y="43.019" width="3.23774" height="56.7742" transform="rotate(-90 11.2036 43.019)" fill="#DA2B1F" />
        </svg>,
        isReady: '¿Está ok esta imagen?',
        'ranking-subtitle': {
          'progress': 'En esta galería puedes ver todas las imágenes creadas por los participantes.',
          'completed': 'Aquí puedes ver las frases ganadores del reto.'
        },
        howToTakePhoto: {
          title: 'Imagen de referencia',
          subtitle: 'Consejos para cargar el contenido:',
          "how-take-photo-img": "takePhotoImgModel.png",
          "how-take-photo-list": (
            <ul style={{ padding: 'revert', listStyle: 'disc' }}>
              <li className="pb-5">
                Busca un lugar bien iluminado para tomar la foto.
              </li>
              <li className="pb-5">

                Ubica el recibo sobre alguna superficie plana y deja el ticket lo más estirado y recto posible. Si es posible sobre un fondo limpio y sin otros elementos.
              </li>
              <li className="">
                Toma la foto asegurándote que se vean bien los artículos, la fecha y el lugar de compra.
              </li>
            </ul>
          )
        },
        buttonSubmit: 'Si, añadir'
      },
      congrats: {
        title: 'Eso ',
        description: <>GANASTE<br /><b className='uppercase'>Oportunidad para ganar gorra Heineken</b></>,
        button1: 'CARGAR OTRO RECIBO',
        button2Link: '/home',
        button2: 'VOLVER AL HOME'
      },
      config: {
        prizes: '/album/redirect/Ticket/upload'
      }
    },
  },
  error: {
    505: {
      title: '¡Qué pena!',
      description: <>Esa no era la respuesta correcta. <br /> Inténtalo nuevamente.</>,
      button: {
        link: '/trivias',
        text: 'VOLVER A EMPEZAR'
      }
    },
    10: {
      title: '¡UPS! el código OTP es inválido'
    }
  },
  help: {
    title: '¿Más dudas?'
  }
}
