
import { useEffect, useState } from 'react'
import ButtonGoRedeem from 'components/button-go'
import { BackRedeem, TextSub, DivRedeemBottomText, ImgRedeemContainer } from 'theme/theme'
import textServices from 'services/text-services'
import ErrorView from 'components/error-view'
import { SurveyTypeEnum } from 'contexts/survey-context/survey.interface'
import { useSurveyRedirect } from 'contexts/survey-context/useSurvey'
import useMainClass from 'hooks/use-main-cass'
import { useSession } from 'contexts/session-context'
import { useHistory } from 'react-router-dom'

const Redeem = () => {
  useMainClass('redeem')
  const [{ user }] = useSession()
  const [isFocused, setIsFocused] = useState(false)
  const [redeemError, setRedeemError] = useState<number | null>(null)
  const history = useHistory()
  useSurveyRedirect(SurveyTypeEnum.REDEEM)

  useEffect(() => {
    const event = new CustomEvent('background-color', {
      detail: {
        shouldUseWhiteBg: redeemError !== null
      }
    })
    window.dispatchEvent(event)
  }, [redeemError])

  if (redeemError) return <ErrorView code={redeemError} buttonMenu={() => setRedeemError(null)} onRetry={() => setRedeemError(null)} />


  const handleRetry = () => {
    return history.push('/')
  }

  const handleButton = () => {
    history.push('/consumption/update')
  }

  if (!user?.consumptionCenter?.internalId && THEME_CONFIG?.popupClientNum) {
    return <ErrorView onRetry={handleButton} code={1214} buttonMenu={handleRetry} />
  }

  return (
    <section className="flex-1 py-0 flex flex-col items-center din large-screen-paddingb" style={{ maxHeight: 1080 }}>
      <BackRedeem className="flex flex-1 w-full mt-24 bg-no-repeat bg-cover sm:bg-contain bg-center items-center" style={{ backgroundImage: `url(${`${AZURE_BASE_URL}/fondo-secundario.svg`})` }}>
        {
          textServices.findByText('redeem/title') !== 'redeem/title' ?
            <TextSub className="font-black uppercase text-center text-xml17 leading-none sm:max-w-sm  px-7 mt-6">
              {textServices.findByText('redeem/title')}
            </TextSub>
            :
            <ImgRedeemContainer className='flex flex-col justify-center items-center'>
              {textServices.findByText('redeem/subtitle', '')}
              <img src={textServices.findByText('redeem/imageUrl', `${AZURE_BASE_URL}/redeem-welcome.svg`)} className="mx-10 w-full mt-6 max-w-md img-welcome" />
            </ImgRedeemContainer>
        }

      </BackRedeem>
      <DivRedeemBottomText className={`flex flex-col flex-2 sm:mt-1 w-full justify-center px-7 sm:max-w-sm sm:items-center`}>
        <div className="flex flex-col mt-6 mb-6 gap-4" >
          <p className="text-center uppercase">
            {textServices.findByText('redeem/description', <>Ingresa el código que se encuentra dentro de <br /> los 12 pack para sumar puntos en el ranking</>)}
          </p>
        </div>
      </DivRedeemBottomText>
      <ButtonGoRedeem setIsFocused={setIsFocused} isFocused={isFocused} redeemError={redeemError} setRedeemError={setRedeemError} />
    </section>
  )
}

export default Redeem
