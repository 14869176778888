import { TitleStart, SubTitleStart } from "views/start/text.style";

export const matchpaint = {

  legalage: {
    title: ' '
  },
  signup: {
    title: 'Bienvenido',
    description: 'Ahora eres parte del equipo. Revisa tus correos para comenzar a registrar tus latas para completar la colección. Consigue las 26 latas de Amstel Ultra y descubre los mensajes de Rafa Nadal.'
  },
  start: {
    signup: 'REGISTRARSE CON CORREO',
    signin: 'INGRESAR AL SITIO',
    title: {
      html:
        <div className="flex-1 w-full py-2 flex flex-col items-center justify-center">
          <TitleStart className="text-center uppercase">
            ¿ERES TEAM RAFA NADAL?
          </TitleStart>
          <SubTitleStart className="text-xl text-center uppercase px-3 pt-5" >que esperas para coleccionar tus latas y canjear <br /> los increibles premios que tenemos para ti</SubTitleStart>
          <img src={`${AZURE_BASE_URL}/fondo-inicial.svg`} className="mx-auto" alt="background" />
        </div>
    }
  },
  home: {
    title: <span className="font-bold" style={{ color: 'rgba(208, 155, 52, 1)' }}>¿QUÉ QUIERES HACER?</span>
  },
  profile: {
    information: 'DATOS PERSONALES',
    address: 'DIRECCIÓN',
    contact: 'CONTACTO',
    button: 'IR A MIS COLECCIONES'
  },
  config: {
    prizes: '/collections'
  },
  congrats: {
    range: {
      title: "NUEVO LOGRO DESBLOQUEADO",
      description: <></>,
      description2: <>Felicitaciones, has desbloqueado nuevos <br /> premios y obtienes más chances de ganar.</>
    }
  },
  prizes: {
    congrats: {
      description: <>Tu canje se ha realizado con éxito.<br />Revisa el correo que te haremos llegar con todos los detalles.</>
    }
  },
  trivias: {
    questions1: <>Contesta </>,
    questions2: <> <span className="font-bold" style={{ color: '#D09B34' }}>preguntas</span> sobre el tema y gana:</>,
    congrats: '¡Felicidades ',
    'not-found': <span className="din font-bold text-2xl" style={{ color: '#193973' }}>¡Ya tenemos ganadxr!</span>,
    exchange: {
      button: {
        text: 'VAMOS POR MI VIAJE A PARÍS',
        url: '/prizes'
      }
    }
  },
  error: {
    505: {
      title: '¡QUE PENA!',
      description: <><b>Fallaste</b> y tenías 1 sola chance para contestar bien. ¡Mejor suerte la próxima!</>
    }
  }
}