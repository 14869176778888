import textServices from "services/text-services";
import { Multimedia, RallyStatusEnum, RallyType } from "views/album/types";

interface IBasicConfig {
  ranking: {
    titleUpload: boolean
    viewMore: boolean
    showSocial: 'like|share' | 'like' | 'share' | ''
  }
  profile: {
    showSocial: 'like|share' | 'like' | 'share' | '',
    profileMultimediaOutputShare: 'url' | 'base64'
  }
  upload: {
    isTakeFoto: boolean
    showBackButton: boolean
    showCallbackButton: boolean
    showHowToParticipate: boolean
    validate: boolean
    useLoaderValidate: boolean
    useCongrats: boolean
    useCongratsDirect: boolean
    howTakePhoto: boolean
    warningPhoto: boolean
    filters: string[]
  }
  imageId: {
    showSocial: 'like|share' | 'like' | 'share' | ''
  },
  list: {
    v1: boolean
    handleClick: boolean
    urlRedirectAlbumComplete?: string
    urlRedirectAlbumProgress?: string
  },
  congrats: {
    showButton3: boolean
  },
  popupClientNum: boolean
}

interface IInitialConfig{
  [RallyType.Consumo]?: IBasicConfig
  [RallyType.Foto]?: IBasicConfig
  [RallyType.Historia]?: IBasicConfig
  [RallyType.Social]?: IBasicConfig
  [RallyType.Ticket]?: IBasicConfig
  [RallyType.Card]?: IBasicConfig
}

class CanvasGenerator {
  private canvas: HTMLCanvasElement;
  private phrase: string;
  private context: CanvasRenderingContext2D;
  private sign: string;

  constructor(phrase: string, canvasParam: HTMLCanvasElement, sign?: string) {
    this.phrase = phrase
    this.canvas = canvasParam
    this.context = this.canvas.getContext("2d")
    this.sign = sign
  }

  private generatetecateCard = () => {
    this.context.font = 'bold 86px HeadingProUltracomp';
    this.context.fillStyle = 'white';
    this.context.textAlign = "start";
    this.context.textBaseline = "top";
    const maxWidth = this.canvas.width - 380;
    this.context.fillText('EL CALOR PIDE', 70, 120);
    this.context.fillText('ESTE KIT Y TU,', 70, 195);

    const textUpper = `${this.phrase}`.toLocaleUpperCase();
    const words = textUpper.split(' ');
    let currentLine = '';
    let y = 275;

    this.context.fillStyle = "#E31E32";

    for (const word of words) {
      const testLine = currentLine + (currentLine ? ' ' : '') + word;
      const metrics = this.context.measureText(testLine);
      const testWidth = metrics.width;

      if (testWidth <= maxWidth) {
        currentLine = testLine;
      } else {

        this.context.fillStyle = 'white'
        this.context.fillRect(70, y - 2.5, this.canvas.width - 375, 86);

        this.context.fillStyle = "#E31E32";
        this.context.fillText(currentLine, 70, y);

        y += 75;
        currentLine = word;
      }
    }

    this.context.fillStyle ='white'
    this.context.fillRect(70, y - 2.5, this.canvas.width - 375, 86);

    this.context.fillStyle = "#E31E32";
    this.context.fillText(currentLine, 70, y);
  }

  private generatedosequisCard = () => {
    this.context.font = '60px Gitsans';
    this.context.fillStyle = "#CACACA";
    this.context.textAlign = "center";
    this.context.textBaseline = "top";
  
    const maxWidth = this.canvas.width - 450;
  
    const textUpper = this.phrase.toLocaleUpperCase();
    const words = textUpper.split(' ');
    let currentLine = '';
    let y = 350;
  
    for (const word of words) {
      const testLine = currentLine + (currentLine ? ' ' : '') + word;
      const metrics = this.context.measureText(testLine);
      const testWidth = metrics.width;
  
      if (testWidth > maxWidth && currentLine !== '') {
        // Render the current line and reset it
        (this.context as any).letterSpacing = "0.5px"
        this.context.fillText(currentLine, this.canvas.width / 2, y);
        currentLine = word;
        y += 70; // Move down to the next line
      } else {
        currentLine = testLine; // Continue building the current line
      }
    }
  
    // Ensure the last line is rendered
    if (currentLine) {
      this.context.fillText(currentLine, this.canvas.width / 2, y);
    }

    if (this.sign) {
      const font = new FontFace(
        'Industry',
        "url('https://stblobphcentral.blob.core.windows.net/fonts/Industry/Industry-Bold.woff')"
      )
      
      font.load().then((font) => {
        document.fonts.add(font)
        this.context.font = 'bold 28px Industry';
        this.context.fillText(this.sign.toLocaleUpperCase(), this.canvas.width / 2, this.canvas.height - 220);
      })
    }
  }

  public generateCard = () => {
    if (this[`generate${THEME}Card`] instanceof Function) {
      const createImage = (font: any) => {
        if (font) {
          document.fonts.add(font)
        }
        const image = new Image();
        image.crossOrigin = 'anonymous'
        image.src = `${AZURE_BASE_URL}/rally/text_background.webp`;
  
        image.onload = () => {
          this.context.drawImage(image, 0, 0, this.canvas.width, this.canvas.height);
          this[`generate${THEME}Card`]()
        };
      }
  
      const font = new FontFace(
        'HeadingProUltracomp',
        "url('https://promohubstorage.blob.core.windows.net/fonts/headingProUltracomp/HeadingProUltracomp-Bold.woff2')"
      )
      
      font.load().then(createImage).catch(createImage)

      return this.context
    }
  }
}

class CanvasGeneratorImage {
  private canvas: HTMLCanvasElement;
  private image: string;
  private context: CanvasRenderingContext2D;

  constructor(image: string, canvasParam: HTMLCanvasElement) {
    this.image = image
    this.canvas = canvasParam
    this.context = this.canvas.getContext("2d")
  }

  private generatemixxImage = (data: { filter?: string, img?: string }) => {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.context.fillStyle = 'white';
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);

    const imageUser = new Image()
    imageUser.crossOrigin = 'anonymous'
    imageUser.src = `${data.img}`;

    const image = new Image();
    image.crossOrigin = 'anonymous'
    image.src = `${AZURE_BASE_URL}${data?.filter}`;

    imageUser.onload = () => {
      this.context.rotate(2 * Math.PI / 180)
      this.context.drawImage(imageUser, 128, 255, this.canvas.width - 200, this.canvas.height- 800);
      this.context.setTransform(1, 0, 0, 1, 0, 0);

      image.onload = () => {
        this.context.rotate(0)
        this.context.drawImage(image, 0, 0, this.canvas.width , this.canvas.height );
      };
    }

  }

  public generateImageCanva = (data: { filter?: string, img?: string }) => {
    if (this[`generate${THEME}Image`] instanceof Function) {
      this[`generate${THEME}Image`]({ filter: data?.filter, img: data?.img })
      
      return this.context
    }
  }
}


class AlbumConfig {

  private initialConfig: IInitialConfig = THEME_CONFIG.album
  private configs: IBasicConfig

  constructor(public album: Multimedia) {
    this.setInitialConfig(album)
  }

  private setInitialConfig = (album: Multimedia) => {
    if (!this.initialConfig[album.rallyType]) return console.error(`No existe configuración para el rally ${album.rallyType}`)
    this.configs = this.initialConfig[album.rallyType]
  }

  // -- GENERAL CONFIGURATION -- //

  get isHistory(): boolean {
    return this.album.rallyType === RallyType.Historia
  }

  get isPhoto(): boolean {
    return this.album.rallyType === RallyType.Foto
  }

  get isSocial(): boolean {
    return this.album.rallyType === RallyType.Social
  }

  get isTicket(): boolean {
    return this.album.rallyType === RallyType.Ticket
  }

  get isConsumo(): boolean {
    return this.album.rallyType === RallyType.Consumo
  }

  get isCard(): boolean {
    return this.album.rallyType === RallyType.Card
  }

  public texts(path: string, defaultResponse?: any, replace?: {[key: string]: string}) {
    const pathRallyType = `social/${this.album?.rallyType}/${path}`
    const textRallyType = textServices.findByText(`social/${this.album?.rallyType}/${path}`, defaultResponse, replace)
    if(textRallyType === (defaultResponse || pathRallyType)) return textServices.findByText(`social/${path}`, defaultResponse, replace)
    return textRallyType
  }

  get isFinished(): boolean {
    return this.album.statusId === RallyStatusEnum.COMPLETED
  }

  get filters(): string[] {
    return this.configs.upload.filters ?? []
  }
  // -- RANKING CONFIGURATION -- //

  get rankingTitleUpload(): boolean {
    return this.configs?.ranking?.titleUpload ?? false
  }

  get rankingViewMore(): boolean {
    return this.configs?.ranking?.viewMore ?? false
  }

  get rankingShowSocial(): 'like|share' | 'like' | 'share' | '' {
    return this.configs?.ranking?.showSocial ?? ''
  }

  // -- PROFILE CONFIGURATION -- //

  get profileShowSocial(): 'like|share' | 'like' | 'share' | '' {
    return this.configs?.profile?.showSocial ?? 'like|share'
  }

  get profileMultimediaOutputShare(): 'url' | 'base64' {
    return this.configs?.profile?.profileMultimediaOutputShare ?? 'url'
  }

  // -- UPLOAD CONFIGURATION -- //

  get uploadIsTakeFoto(): boolean {
    return this.configs?.upload?.isTakeFoto ?? false
  }

  get uploadShowBackButton(): boolean {
    return this.configs?.upload?.showBackButton ?? false
  }

  get uploadShowCallbackButton(): boolean {
    return this.configs?.upload?.showCallbackButton ?? false
  }

  get uploadShowHowToParticipate(): boolean {
    return this.configs?.upload?.showHowToParticipate ?? false
  }

  get uploadValidate(): boolean {
    return this.configs?.upload?.validate ?? false
  }

  get uploadUseLoaderValidate(): boolean {
    return this.configs?.upload?.useLoaderValidate ?? false
  }

  get uploadUseCongrats(): boolean {
    return this.configs?.upload?.useCongrats ?? false
  }

  get uploadUseCongratsDirect(): boolean {
    return this.configs?.upload?.useCongratsDirect ?? false
  }

  get howTakePhoto(): boolean {
    return this.configs?.upload?.howTakePhoto ?? false
  }

  get warningPhoto(): boolean {
    return this.configs?.upload?.warningPhoto ?? false
  }

  // -- IMAGE ID CONFIGURATION -- //

  get imageIdShowSocial(): 'like|share' | 'like' | 'share' | '' {
    return this.configs?.imageId?.showSocial ?? ''
  }

  // -- LIST CONFIGURATION -- //

  get listHandleClick(): boolean {
    return this.configs?.list?.handleClick ?? false
  }

  get listV1(): boolean {
    return this.configs?.list?.v1 ?? false
  }

  get urlRedirectAlbumComplete(): string | undefined {
    if (this.album.statusId === (RallyStatusEnum.COMPLETED || RallyStatusEnum.TERMINADO)) return this.configs?.list?.urlRedirectAlbumComplete
  }

  get urlRedirectAlbumProgress(): string | undefined {
    return this.configs?.list?.urlRedirectAlbumProgress
  }

  // -- CONGRATS CONFIGURATION -- //

  get congratsShowButton3(): boolean {
    return this.configs?.congrats?.showButton3 ?? false
  }

  public generateCard(phrase: string, canvasParam: HTMLCanvasElement, sign?: string): any {
    if (this?.isCard) {
      const canvas = new CanvasGenerator(phrase, canvasParam, sign)
      return canvas.generateCard()
    } else {
      console.error('RallyType is not CARD')
    }
  }

  public generateImage(filter?: string, canvasParam?: HTMLCanvasElement, img?: string): any {
    if (this?.isPhoto) {
      const canvas = new CanvasGeneratorImage(filter, canvasParam)
      return canvas.generateImageCanva({ filter, img })
    } else {
      console.error('RallyType is not PHOTO')
    }
  }
}

export default AlbumConfig


