import styled from 'styled-components';
import { Type } from 'theme/types';
import { SubTitle, Text, Title } from './../../theme/theme';

export const TitleStart = styled(Title)`
  font-size: 2.25rem;
  ${({ theme }: Type) => theme?.start?.text?.title}
`;

export const SubTitleStart = styled(SubTitle)`
  font-size: 1.25rem;
  ${({ theme }: Type) => theme?.start?.text?.subtitle}
`;

export const TextStart = styled(Text)`
  font-size: 1rem;
  ${({ theme }: Type) => theme?.start?.text?.text}
`;