import { useContext, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Survey, SurveyResponse } from 'contexts/survey-context/survey.interface'
import useMainClass from 'hooks/use-main-cass';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { LoaderContext } from 'contexts/loader-context';
import { AxiosError } from 'axios';
import useRouter from 'hooks/use-router';
import { dlTrackEvent } from 'components/dataLayer/dataLayer';
import textServices from 'services/text-services';
import ButtonCloseMenu from 'components/button-close';
import Template from './Template';

interface IProps {
  survey: Survey
  resetSurvey: () => void
  updatePolarisSurvey: (select: SurveyResponse[][], surveyId: number) => Promise<void>
  redirect: () => void
}

function SurveyStepper({ survey, resetSurvey, updatePolarisSurvey, redirect }: IProps) {
  useMainClass('survey__stepper');
  const { setLoad } = useContext(LoaderContext);
  const [schema, setSchema] = useState(null);
  const { push } = useRouter();
  const methods = useForm({ defaultValues: { step: 1, max: survey?.questions?.length }, resolver: yupResolver(schema) });

  const schemas = useMemo(() => {
    return survey?.questions?.map((question) => {
      return yup.object().shape({
        [question?.questionId]: yup
          .array()
          .min(question?.amountAwnsers, `Debes seleccionar al menos ${question?.amountAwnsers}`)
          .max(question?.amountAwnsers, `Puedes seleccionar como máximo ${question?.amountAwnsers}`)
      });
    })
  }, [survey])

  useEffect(() => {
    methods.watch();
  }, [])

  useEffect(() => {
    setSchema(schemas[methods?.control?._formValues?.step - 1])
  }, [methods?.control?._formValues?.step])

  const handleSubmit = async (data: any) => {
    setLoad(true)
    try {
      const select = []
      for (const key in data) {
        if (parseInt(key)) {
          select.push({ questionId: parseInt(key), optionIds: data[key], textValue: '' })
        }
      }
      dlTrackEvent('user_interaction', {
        action: 'click',
        element: textServices.findByText('survey/button', 'ACTUALIZAR'),
        section: `survey`
      })
      await updatePolarisSurvey(select, survey?.id)
    } catch (error) {
      const err = error as AxiosError
      resetSurvey()
      return push(`/error/${err?.response?.data?.code ?? 99999}`)
    } finally {
      setLoad(false)
    }
  }

  return (
    <FormProvider {...methods}>
      <ButtonCloseMenu
        open={true}
        paramFn={() => {
          if (survey?.optional) {
            gtag('event', 'Click', {
              event_category: GTM_EVENT_CATEGORY,
              event_label: 'Survey - Cerrar modal',
              value: '10'
            })
            redirect()
          }
        }}
      />
      <div className='w-full flex flex-col survey__stepper-container'>
        <header className='pt-5 pb-4 px-4 text-black text-opacity-30 survey__stepper-header'>
          <h3 className='font-bold text-4xl survey__stepper-header-title'>{survey?.name}</h3>
          <p className='text-sm survey__stepper-header-description'>Contesta estas breves preguntas para participar por la raspadita.</p>
        </header>
        <div className='flex flex-col flex-1 relative'>
          {
            survey?.questions?.map((question, index) => (
              <Template step={index + 1} question={question} submit={handleSubmit} key={`${index}-${question?.question}`} />
            ))
          }
        </div>
      </div>
    </FormProvider>
  )
}

export default SurveyStepper