import { useContext } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoaderContext } from 'contexts/loader-context'
import { useSession } from 'contexts/session-context'
import Button from 'components/button'
import Input from 'components/input'
import { useForm } from 'react-hook-form'
import formServices from 'services/form-builder-services'
import ProfileService from 'services/profile-services'
import { AxiosError } from 'axios'
import { Label, ErrorInput } from 'theme/theme'
import textServices from 'services/text-services'
import { BackBtn } from 'views/login/text.style'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import Steps from '../components/Steps'

function Names({ state, nextStep, handleChange, prevStep, htmlSteps, setError }: any) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver: formServices.formValidateErrors(yupResolver(formServices.formGenerateSchema(['firstName', 'lastName', 'nickName'])), 'sign up :: step 1', 'sign up'), reValidateMode: 'onSubmit' })
  const { setLoad } = useContext(LoaderContext)
  const [{ user }] = useSession()

  const validateNickName = async (nickName: string) => {
    setLoad(true)
    try {
      if (!THEME_CONFIG.notShowFields?.includes('nickName')) {
        await ProfileService.validateNickName(nickName)
      }
      nextStep()
    } catch (error) {
      if ((error as AxiosError).isAxiosError) {
        const code = (error as AxiosError).response?.data.code ?? 9999;
        const message = (error as AxiosError).response?.data.message ?? 'NA';
        gtag('event', 'Click', {
          event_category: GTM_EVENT_CATEGORY,
          event_label: 'SINGUP - NickName existente',
          value: '10'
        })
        dlTrackEvent('error', {
          "error_type": "sign up",
          "error_code": code,
          "error_message": message,
          "section": "sign up :: step 1"
        })
        return setError((error as AxiosError).response?.data.code ?? 9999)
      }
    } finally {
      setLoad(false)
    }
  }

  const onFormSubmit = async (data: any) => {
    handleChange(data)
    const { nickName } = data
    gtag('event', 'step_1', {
      event_label: 'Registro - Email',
      method: 'Email'
    })
    dlTrackEvent('form_send', {
      element: 'siguiente',
      form_name: 'datos cuenta',
      section: 'sign up :: step 1'
    })
    if (user?.nickName?.trim()?.toLowerCase() !== nickName?.trim()?.toLowerCase()) {
      return validateNickName(nickName)
    }
    nextStep()
  }

  return (
    <div className="flex flex-col flex-1 md:pt-10">
      {
        !THEME_CONFIG?.stepsNewVersion && (
          <div className="flex flex-row items-center justify-around w-full mb-4">{htmlSteps}</div>
        )
      }
      <form
        className="flex h-full justify-between flex-col gap-4 mx-7 mb-7"
        onSubmit={handleSubmit(onFormSubmit)}
        noValidate
      >
        <div className="flex-1 flex flex-col gap-4">
          <div className="gap-2 flex flex-col input-container">
            <Label className="text-start text-2xl font-bold uppercase input-label">
              {textServices.findByText('profile/form/information/firstname/label', '¿Cómo te llamas?')}
            </Label>
            <Input
              className="text-center saira font-bold text-sm"
              type="text"
              placeholder={textServices.findByText(
                'profile/form/information/firstname/placeholder',
                'PON TU NOMBRE AQUÍ'
              )}
              name="firstName"
              defaultValue={state?.firstName}
              {...register('firstName', {
                onBlur: (e) => {
                  dlTrackEvent('form_interaction', {
                    form_name: 'datos cuenta',
                    form_field: 'first name',
                    form_action: e.target.value ? 'complete' : 'empty',
                    section: 'sign up :: step 1'
                  })
                }
              })}
            />
            <ErrorInput className='input-error'>
              <ErrorMessage errors={errors} name="firstName" />
            </ErrorInput>
            <Input
              className="text-center saira font-bold text-sm"
              type="text"
              placeholder={textServices.findByText(
                'profile/form/information/lastname/placeholder',
                'PON TU APELLIDO AQUÍ'
              )}
              name="lastName"
              defaultValue={state?.lastName}
              {...register('lastName', {
                onBlur: (e) => {
                  dlTrackEvent('form_interaction', {
                    form_name: 'datos cuenta',
                    form_field: 'last name',
                    form_action: e.target.value ? 'complete' : 'empty',
                    section: 'sign up :: step 1'
                  })
                }
              })}
            />
            <ErrorInput className='input-error'>
              <ErrorMessage errors={errors} name="lastName" />
            </ErrorInput>
          </div>
          {!THEME_CONFIG.notShowFields?.includes('nickName') && (
            <div className="gap-2 flex flex-col input-container">
              <Label className="text-start text-2xl font-bold uppercase input-label">
                {textServices.findByText('profile/form/information/nickname/label', 'Apodo')}
              </Label>
              <Input
                className="text-center saira font-bold text-sm"
                type="text"
                placeholder={textServices.findByText(
                  'profile/form/information/nickname/placeholder',
                  '¿COMO TE GUSTA QUE TE LLAMEN?'
                )}
                name="nickName"
                defaultValue={state?.nickName}
                {...register('nickName', {
                  onBlur: (e) => {
                    dlTrackEvent('form_interaction', {
                      form_name: 'datos cuenta',
                      form_field: 'nickname',
                      form_action: e.target.value ? 'complete' : 'empty',
                      section: 'sign up :: step 1'
                    })
                  }
                })}
              />
              <ErrorInput className='input-error'>
                <ErrorMessage errors={errors} name="nickName" />
              </ErrorInput>
            </div>
          )}
        </div>

        {
          THEME_CONFIG?.stepsNewVersion && (
            <Steps state={state} htmlSteps={htmlSteps} decrease={0.5} />
          )
        }

        <Button>
          <p className="uppercase">Siguiente</p>
        </Button>
        <button
          type="button"
          className="cursor-pointe"
          onClick={() => {
            dlTrackEvent('user_interaction', {
              action: "click",
              element: 'volver',
              section: "sign up :: step 1"
            })
            gtag('event', 'Click', {
              event_category: GTM_EVENT_CATEGORY,
              event_label: 'MODIFICAR INFORMACION - Volver atras',
              value: '10'
            })
            prevStep()
          }}
        >
          <BackBtn className={`din text-center mt-3 hover:text-red-100 uppercase underline button-back`} style={{ fontSize: 10 }}>
            VOLVER
          </BackBtn>
        </button>
      </form>
    </div>
  )
}

export default Names
