import { ErrorMessage } from "@hookform/error-message"
import { yupResolver } from "@hookform/resolvers/yup"
import { AxiosError } from "axios"
import Button from "components/button"
import ErrorView from "components/error-view"
import Input from "components/input"
import { LoaderContext } from "contexts/loader-context"
import { useSession } from "contexts/session-context"
import useLocalStorage from "hooks/use-local-storage"
import useRouter from "hooks/use-router"
import { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import formServices from "services/form-builder-services"
import ProfileService from "services/profile-services"
import { Label, ErrorInput } from "theme/theme"

function ProfileInstagramUpdate() {
  const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(formServices.formGenerateSchema(["instagram"])) })
  const { history } = useRouter()
  const [{ user, data: session }, { setUser }] = useSession()
  const { setLoad } = useContext(LoaderContext)
  const [genericError, setGenericError] = useState(null)
  const [state, setState] = useLocalStorage('state', {})

  async function onFormSubmit(data: any) {
    setLoad(true)
    try {
      if (data?.instagram) {
        const response = await ProfileService.instagram(data?.instagram, session)
        setUser(response.data)
        setState({ ...state, user: response.data })
      }
      history.goBack()
    } catch (err) {
      const typedError = err as AxiosError
      setGenericError(typedError?.response?.data?.code ?? 9999)
    } finally {
      setLoad(false)
    }
  }

  const handleMenu = () => {
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'MODIFICAR INSTAGRAM   - Cerrar error', value: '10' })
    setGenericError(null)
  }

  const handleButton = () => {
    setGenericError(null)
  }

  if (genericError) {
    return (
      <ErrorView code={genericError} buttonMenu={handleMenu} onRetry={handleButton} />
    )
  }

  return (
    <div className="mt-7 flex flex-col w-full">
      <div className="flex flex-col flex-1 md:pt-10">
        <form className="flex h-full justify-between flex-col gap-4 mx-7 mb-7" onSubmit={handleSubmit(onFormSubmit)} noValidate>
          <div className="flex-1 flex flex-col gap-4">
            <div className="gap-2 flex flex-col">
              <Label className="text-start text-2xl font-bold uppercase">INGRESE SU CUENTA DE INSTAGRAM</Label>
              <Input
                className="text-center saira font-bold text-sm"
                type="text"
                placeholder="@"
                name="instagram"
                defaultValue={user?.instagram}
                {...register("instagram")}
              />
              <ErrorInput>
                <ErrorMessage errors={errors} name="instagram" />
              </ErrorInput>
            </div>
          </div>
          <Button>
            <p className="uppercase">ACTUALIZAR</p>
          </Button>
          <button type="button" className="cursor-pointer" onClick={() => {
            gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'MODIFICAR INSTAGRAM - Volver atras', value: '10' })
            history.goBack()
          }}>
            <p
              className={`din text-center mt-3 hover:text-red-100 uppercase underline`}
              style={{ fontSize: 10 }}
            >
              VOLVER
            </p>
          </button>
        </form>
      </div>
    </div>
  );
}

export default ProfileInstagramUpdate;