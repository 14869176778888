import Button from 'components/button'
import useRouter from 'hooks/use-router'
import textServices from 'services/text-services'
import ButtonCloseMenu from 'components/button-close'

interface IProps {
  setShowIntroduction: (show: boolean) => void
}

function IntroductionTrivia({ setShowIntroduction }: IProps) {

  const { history } = useRouter()

  const closeIntroduction = () => {
    localStorage.setItem('introductionTrivias', 'true')
    setShowIntroduction(false)
  }

  return (
    <div className="w-full flex-1 pb-8">
      <ButtonCloseMenu
        open={true}
        paramFn={() => history.goBack()}
      />
      <img src={`${AZURE_BASE_URL}/popup-trivias-mobile.svg`} className="w-full" />
      <div className='px-8 flex flex-col items-center gap-4'>
        <Button onClick={closeIntroduction}>
          {textServices.findByText('trivia/introduction', 'PARTICIPA AQUÍ')}
        </Button>
        <button type="button" className="cursor-pointer" onClick={() => history.goBack()}>
          <p
            className={`din text-center uppercase underline`}
            style={{ fontSize: 10 }}
          >
            VOLVER
          </p>
        </button>
      </div>
    </div>
  )
}

export default IntroductionTrivia