import Button from 'components/button'
import React from 'react'
import textServices from 'services/text-services'
import useMainClass from 'hooks/use-main-cass'
import { ContainerTriviaQr, DescriptionTriviaQr, ImgTriviaQr, TitleTriviaQr } from './styles/styled.components'

function TriviaPopUp({ handleClick }: { handleClick: () => void }) {
  useMainClass('trivia-qr__pop-up')

  return (
    <ContainerTriviaQr className="flex-1 w-full flex flex-col gap-6 py-7">
      {textServices?.findByText('trivias/triviaQr/register/html', <div className="w-full flex flex-col gap-6 px-7 flex-1">
        <div className="flex-1 w-full flex flex-col items-center justify-center gap-6 text-center">
          <TitleTriviaQr>{textServices?.findByText('trivias/triviaQr/register/title', <>CONTESTA<br />Y GANA</>)}</TitleTriviaQr>
          <DescriptionTriviaQr className="flex flex-col items-center justify-center gap-5">{textServices?.findByText('trivias/triviaQr/register/description', <><b>¿Ya tienes tu ID?</b>Si no lo tienes córrele a registrarte para poder participar</>)}</DescriptionTriviaQr>
        </div>
        <ImgTriviaQr src={`${AZURE_BASE_URL}/trivia-qr-card-id.${textServices.findByText('trivias/triviaQr/register/typeImage', 'svg')}`} alt="card id" />
      </div>)}
      <div className="w-full px-7">
        <Button onClick={handleClick}>{textServices?.findByText('trivias/triviaQr/register/button', 'COMENCEMOS')}</Button>
      </div>
    </ContainerTriviaQr>
  )
}

export default TriviaPopUp