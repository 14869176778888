import dayjs from 'dayjs'

export const calendar = {
  day: 'DIA',
  month: 'MES',
  year: 'AÑO'
}

export function calculateAge(birthday: Date) {
  const age = dayjs().diff(birthday, 'year', true)

  return Math.floor(age)
}