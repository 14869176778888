import { useSession } from "contexts/session-context";
import { useSurvey } from "contexts/survey-context/useSurvey";
import { useEffect, useState } from "react";

const informationFields = ["firstName", "lastName", "nickName", "phoneNumber", "email", "birthday", "address", "interests", "genreId", "consumptionCenter"]
const addressFields = ["neighborhood", "postalCode", "city", "state", "country", "street1", "streetNumber"]
const notAvailableFields = THEME_CONFIG?.notAvailableFields || []

const UsePercentageProfile = () => {
  const [{ user }] = useSession()
  const [{ state, surveyLoading }] = useSurvey()
  const [percentageProfile, setPercentageProfile] = useState(0)
  const [requiredField, setRequiredFields] = useState<string[]>()
  const total = informationFields.length + addressFields?.length

  // const surveyFieldsCompleted = () => {
  //   const surveyCompleted = state?.survey?.filter(survey => survey?.isAnswered && survey?.profilePercentIncluded)
  //   const surveyRequired = state?.survey?.filter(survey => survey?.profilePercentIncluded)
  //   return {surveyRequired: surveyRequired?.length, surveyCompleted: surveyCompleted?.length}
  // }

  const userFieldsCompletedCount = () => {
    const fieldsInfNotCompleted = informationFields?.filter(field => {
      if (notAvailableFields.includes(field)) return false
      return !user[field]
    })
    const fieldsAddressNotCompleted = addressFields?.filter(field => {
      if (notAvailableFields.includes(field)) return false
      if (!user?.address) return true
      return !user?.address[field]
    })
    const fieldsNC = fieldsInfNotCompleted.length + fieldsAddressNotCompleted.length
    return {count : total - fieldsNC, fieldsNull: [...fieldsInfNotCompleted, ...fieldsAddressNotCompleted]}
  }

  useEffect(() => {
    if (!user) return 
    if (surveyLoading) return  
    const {count, fieldsNull} = userFieldsCompletedCount()
    setRequiredFields(fieldsNull)
    // const { surveyRequired, surveyCompleted } = surveyFieldsCompleted()
    const valuePercent = count ;
    if (Number.isNaN(valuePercent)) {
      return setPercentageProfile(count)
    }
    return setPercentageProfile(Math.round(((valuePercent) / (total + 0)) * 100))
  }, [user, state, surveyLoading])

  return { percentageProfile, requiredField }

}

export default UsePercentageProfile;